import moment from 'moment'
import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Card, CardBody, Table } from 'reactstrap'
import { API } from '../../../api/API'
import styles from './Reservation.module.scss'

const ReservationLogs = ({ authObj }) => {
  const { reservationId } = useParams()
  const [reservationLogs, setReservationLogs] = useState([])
  const [, setLoader] = useState(true)
  const [, setErrMsg] = useState('')

  const getReservationLogs = async () => {
    setErrMsg('Fetching reservation detail...')
    setLoader(true)
    try {
      const res = await API.getReservationLogs(authObj.account.accountId, authObj.token, reservationId)
      setLoader(false)
      if (res && res.data && res.data.reservations) {
        setErrMsg('')
        setReservationLogs(res.data.reservations.data)
      } else {
        setErrMsg('Unable to get reservations (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get reservations (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    getReservationLogs()
  }, [reservationId])

  return (
    <Card>
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>Progress</th>
              <th>Created At</th>
              <th>Logs</th>
            </tr>
          </thead>
          <tbody>
            {reservationLogs &&
              reservationLogs.map((logs, i) => {
                return (
                  <tr key={logs.id} className={styles.tableRow}>
                    <td className={styles.tableCell}>{logs.progress}</td>
                    <td className={styles.tableCell}>{moment(logs.createdAt).format('MM-DD-YYYY HH:mm a')}</td>
                    <td className={styles.tableCell}>
                      {logs.raw && (
                        <div className={styles.scroll}>
                          <p>{JSON.stringify(logs.raw)}</p>
                        </div>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export { ReservationLogs }
