import { Row, Col, Card, CardBody, CardTitle, CardText, Table, Button } from 'reactstrap'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReservationSlots } from './ReservationSlots'
import { ReservationLogs } from './ReservationLogs'
import { API } from '../../../api/API'

const ReservationDetail = ({ authObj }) => {
  const [errMsg, setErrMsg] = useState('')
  const [program] = useState(undefined)
  const [reservationDetails, setReservationDetails] = useState(undefined)
  const [slots, setSlots] = useState(undefined)
  const [payments, setPayments] = useState(undefined)
  const [discounts, setDiscounts] = useState(undefined)
  const [reservationEmail, setReservationEmail] = useState(undefined)
  const { reservationId } = useParams()

  const getReservationDetails = async () => {
    setErrMsg('Fetching reservation detail...')
    const res = await API.getReservationDetails(authObj.account.accountId, authObj.token, reservationId)
    if (res && res.data && res.data.data) {
      setErrMsg('')
      setReservationDetails(res.data.data.reservationDetails)
      setSlots(res.data.data.slots)
      setPayments(res.data.data.reservationPayments)
      setDiscounts(res.data.data.reservationDiscounts)
      setReservationEmail(res.data.data.reservationEmail)
    } else {
      setErrMsg('Unable to get reservation detail')
    }
  }

  const refundTransaction = async () => {
    const requestObj = {
      reservationId
    }
    const res = await API.refundTransaction(authObj.account.accountId, authObj.token, requestObj)
    if (res && res.data && res.data.refunds) {
      setErrMsg('')
      getReservationDetails()
    } else {
      setErrMsg('Unable to do refund transaction')
    }
  }

  const resendEmail = async () => {
    const requestObj = {
      reservationEmailId: reservationEmail.id
    }
    const res = await API.resendEmail(authObj.account.accountId, authObj.token, requestObj)
    if (res && res.data) {
      setErrMsg('')
      getReservationDetails()
    } else {
      setErrMsg('Unable to resend email')
    }
  }
  useEffect(() => {
    getReservationDetails()
  }, [reservationId])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12}>
            <header className='panel_header'>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <h2 className='title float-left'>Reservation Overview</h2>
            </header>
          </Col>
          <Col xs={12}>
            <Card>
              <CardBody>
                <CardTitle>{program?.title}</CardTitle>
                <CardText>
                  <p>
                    <strong>ReservationId:</strong> &nbsp;{reservationId}
                  </p>
                  <p>
                    <strong>TransactionId:</strong> &nbsp;
                    {reservationDetails && reservationDetails.transactionId}
                  </p>
                  <p>
                    <strong>Total Price:</strong> &nbsp; $ {reservationDetails && reservationDetails.total}
                  </p>
                  <p>
                    <strong>Amount Paid:</strong> &nbsp;
                  </p>
                  <Button
                    color='primary'
                    style={{ position: 'absolute', right: 0, top: '28px' }}
                    onClick={() => {
                      const r = window.confirm(
                        `Are you sure you want to refund ${payments[0].email} for $${reservationDetails.total}?`
                      )
                      if (r === true) {
                        refundTransaction()
                      }
                    }}
                  >
                    Refund Payment
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12}>
            <h3>Slots Details</h3>
            {slots && <ReservationSlots authObj={authObj} slots={slots} />}
          </Col>
          <Col xs={12}>
            <h3>Payment History</h3>
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Created At</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    {payments &&
                      payments.map(payment => (
                        <tr key={payment.id}>
                          <td>{payment.name}</td>
                          <td>$ {payment.total}</td>
                          <td> {moment(payment.createdAt).format('MM-DD-YYYY HH:mm a')}</td>
                          <td>{payment.status}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12}>
            <h3>Discount Applied</h3>
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <th>Discount</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Amount deducted</th>
                  </thead>
                  <tbody>
                    {discounts &&
                      discounts.map(discount => (
                        <tr key={discount.id}>
                          <td>{discount.title}</td>
                          <td>{discount.type}</td>
                          <td>{discount.discountAmount}</td>
                          <td>$ {discount.amountDeducted}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          {reservationEmail && (
            <Col xs={12}>
              <h3>Email Status</h3>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <th>Subject</th>
                      <th>Recipients</th>
                      <th>Time Stamp</th>
                      <th>Status</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td> {reservationEmail.subject}</td>
                        <td>{reservationEmail.guardianEmails.concat(reservationEmail.adminEmails).join()}</td>
                        <td>
                          {moment(reservationEmail.updatedAt)
                            .tz(authObj.user.timeZone || 'America/Los_Angeles')
                            .format('MM-DD-YYYY HH:mm a')}
                        </td>
                        <td>{reservationEmail.status}</td>
                        <td>
                          <Button
                            color='primary'
                            size='sm'
                            onClick={() => {
                              resendEmail()
                            }}
                          >
                            Resend Email
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          )}
          <Col xs={12}>
            <h3>Logs</h3>
            <ReservationLogs authObj={authObj} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { ReservationDetail }
