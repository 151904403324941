import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Spinner, Table, Form } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { API } from '../../../api/API'

const DataMappings = ({ authObj }) => {
  const history = useHistory()

  const [loader] = useState(false)
  const [errMsg] = useState('')

  const [dataMappings, setDataMappings] = useState()

  const getMappings = async () => {
    const res = await API.getMappings(authObj.account.accountId, authObj.token)
    if (res && res.data) {
      setDataMappings(res.data)
    }
  }

  const updateStatus = async (destMappingId, checked) => {
    setDataMappings(currMappings => {
      return currMappings.map(m => {
        const tempM = { ...m }
        if (tempM.id === destMappingId) {
          tempM.status = checked ? 'active' : 'inactive'
        }
        return tempM
      })
    })
    const res = await API.updateMapping(authObj.account.accountId, authObj.token, {
      destMappingId,
      status: checked ? 'active' : 'inactive'
    })
    if (res && res.data) {
      getMappings()
    }
  }

  useEffect(() => {
    getMappings()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box'>
                <header className='panel_header'>
                  <h2 className='title float-left'>Data Mapping</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    <Button color='primary' onClick={() => history.push('data-mappings/step1')}>
                      Create Mapping
                    </Button>
                  </div>
                </header>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table bordered hover style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Destination</th>
                            <th>Status</th>
                            <th>Enabled</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataMappings &&
                            dataMappings.map(d => {
                              return (
                                <tr key={d.id}>
                                  <td
                                    onClick={() =>
                                      history.push(`/data-mappings/step2?dest=${d.destination}&id=${d.id}`)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {d.title}
                                  </td>
                                  <td>{d.destination}</td>
                                  <td>{d.status}</td>
                                  <td>
                                    <Form.Check
                                      id={`status_${d.id}`}
                                      name={`status_${d.id}`}
                                      type='switch'
                                      onChange={e => {
                                        updateStatus(d.id, e.target.checked)
                                      }}
                                      checked={d.status === 'active'}
                                    />
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                      <br />
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { DataMappings }
