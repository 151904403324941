import React, { useState, useEffect } from 'react'
import { config } from '../../../config'
import menuIcon from '../../../assets/img2/menu.svg'
import editIcon from '../../../assets/img2/edit.svg'
import deleteIcon from '../../../assets/img2/trash.svg'
import { useHistory } from 'react-router-dom'
import lightning from '../../../assets/img2/lightning.svg'
import addNewIcon from '../../../assets/img2/add-new.svg'
import failedRuns from '../../../assets/img2/failedRuns.svg'
import axios from 'axios'
import { useSelector } from 'react-redux'
import previousPrice from '../../../assets/img2/previousPrice.svg'
import hireYourAI from '../../../assets/img2/hireYourAI.svg'
import addIcon from '../../../assets/img2/addIcon.svg'
import { API } from '../../../api/API'
import moment from 'moment'
import leftArrow from '../../../assets/img2/arrowLeft.svg'
import warningIcon from '../../../assets/img2/warning-circle.svg'
import successIcon from '../../../assets/img2/check-circle.svg'
import reloadIcon from '../../../assets/img2/reload.svg'
import ignoreRed from '../../../assets/img2/ignoreRed.svg'
import rightPage from '../../../assets/img2/arrowRight.svg'
import yellowLightning from '../../../assets/img2/yellowLightning.svg'
import redCross from '../../../assets/img2/redCross.svg'
import HistoryDetail from '../../../components/Modals/HistoryDetail/HistoryDetail'
import WorkflowRules from '../../../components/Modals/WorkflowRules/WorkflowRules'
import { RetrainAI } from '../../../components/Modals/RetrainAI/RetrainAI'
import { DeleteAI } from '../../../components/Modals/DeleteAI/DeleteAI'

const WorkflowLegacy = ({ authObj }) => {
  const { userData } = useSelector(state => state.auth)
  const { isLoading, processes } = useSelector(state => state.process)
  const history = useHistory()
  const [deleteProcessModal, showDeleteProcessModal] = useState(false)
  const [updateProcessModal, showUpdateProcessModal] = useState(false)
  const [historyDetailModal, showHistoryDetailModal] = useState(false)
  const [workflowRuleModal, showWorkflowRuleModal] = useState(false)
  const [moreOptions, showMoreOptions] = useState(false)
  const [historyID, setHistoryID] = useState('')
  const [processList, setProcessList] = useState([])

  const [selectedProcessID, setSelectedProcessId] = useState(null)
  const [, setErrMsg] = useState('')

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const [showFailedOnly, setShowFailedOnly] = useState(false)
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [processesList, setProcessesList] = useState(null)
  const [failedProcessesList, setFailedProcessesList] = useState([])
  const [processHistory, setProcessHistory] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [message, setMessage] = useState('Currently, There are no process runs in this process.')

  const viewItemsCount = [10, 20, 30]

  const replayAutomation = async id => {
    await API.replayAutomation(authObj.account.accountId, authObj.token, id)
  }

  const getProcessesList = async (loading = true) => {
    loading && setLoading(true)
    await Promise.all([
      axios
        .get(
          `${config.apiUrl}/app/process-runs?processId=${selectedProcessID}&page=${page}&pageSize=${pageSize}&includeProcess=true`,
          {
            headers: {
              Authorization: `Bearer ${authObj.token}`,
              accountid: authObj.account.accountId
            }
          }
        )
        .then(response => {
          if (response && response.data) {
            if (!showFailedOnly) {
              setProcessHistory(response.data?.history)
            }
            response.data?.status === 'active' ? setStatus(true) : setStatus(false)
            setProcessesList(response.data)
            setErrMsg('')
          } else {
            setErrMsg('Unable to get processes information')
          }
          loading && setLoading(false)
        })
        .catch(err => {
          loading && setLoading(false)
          if (err && err.response && err.response.data && err.response.data.msg) {
            return setErrMsg(`${err.response.data.msg}`)
          }
          return setErrMsg('Unable to get processes information. Please contact support for help!')
        }),
      axios
        .get(
          `${config.apiUrl}/app/process-runs?processId=${selectedProcessID}&page=${page}&pageSize=${pageSize}&includeProcess=true&failed=true`,
          {
            headers: {
              Authorization: `Bearer ${authObj.token}`,
              accountid: authObj.account.accountId
            }
          }
        )
        .then(response => {
          if (response && response.data) {
            if (showFailedOnly) {
              setProcessHistory(response.data?.history)
            }
            setFailedProcessesList(response.data?.history)
          } else {
            setErrMsg('Unable to get processes information')
          }
          loading && setLoading(false)
        })
        .catch(err => {
          loading && setLoading(false)
          if (err && err.response && err.response.data && err.response.data.msg) {
            return setErrMsg(`${err.response.data.msg}`)
          }
          return setErrMsg('Unable to get processes information. Please contact support for help!')
        })
    ])
  }

  const showFailedProcessesOnly = isChecked => {
    if (isChecked) {
      setProcessHistory(failedProcessesList)
      setMessage('Currently, There are no failed runs in this process.')
    } else {
      setProcessHistory(processesList?.history || processHistory)
      setMessage('Currently, There are no process runs in this process.')
    }
  }

  const reRunProcess = id => {
    axios
      .post(
        `${config.apiUrl}/app/processes/rerun?processRunId=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authObj.token}`,
            accountid: authObj.account.accountId
          }
        }
      )
      .then(response => {
        if (response.data && response.data.msg === 'success') {
          getProcessesList()
        }
      })
      .catch(err => {
        if (err && err.response && err.response.data && err.response.data.msg) {
          return setErrMsg(`${err.response.data.msg}`)
        }
        return setErrMsg('Unable to re-run processes information. Please contact support for help!')
      })
  }

  const updateStatus = async checked => {
    try {
      setStatus(checked)
      const data = {
        id: selectedProcessID,
        status: checked ? 'active' : 'inactive'
      }
      const response = await axios.put(`${config.apiUrl}/app/processes`, data, {
        headers: {
          Authorization: `Bearer ${authObj.token}`,
          accountid: authObj.account.accountId
        }
      })
      if (response && response.data && response.data.msg === 'success') {
        getProcessesList(false)
        setErrMsg('')
      } else {
        setErrMsg('Unable to update processes information')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update processes information. Please contact support for help!')
    }
  }

  const getPageSize = viewItemsCount.map((t, i) => {
    return (
      <option key={t} value={t}>
        {t}
      </option>
    )
  })

  const showDetailInformation = id => {
    setHistoryID(id)
    showHistoryDetailModal(true)
  }

  const navigateToContract = () => {
    showWorkflowRuleModal(true)
  }

  const getFormattedDate = dateString => {
    const updatedDate = new Date(dateString)
    return `${month[updatedDate.getMonth()]} ${updatedDate.getDate()}, ${updatedDate.getFullYear()}`
  }

  useEffect(() => {
    showFailedProcessesOnly(showFailedOnly)
  }, [showFailedOnly])

  useEffect(() => {
    if (selectedProcessID) {
      getProcessesList()
    }
  }, [page, pageSize])

  useEffect(() => {
    if (selectedProcessID) {
      getProcessesList()
    }
  }, [selectedProcessID])

  useEffect(() => {
    setProcessList(processes)
  }, [processes])

  return (
    <>
      <div className='aiPageLayout' onClick={() => moreOptions && showMoreOptions(false)}>
        <div className='leftContainer'>
          <div className='header'>
            <div className='title'>Workflows</div>
          </div>
          <button className='floatingActionButton' onClick={() => history.push('/workflow/create')}>
            <img src={addIcon} alt='add icon' />
          </button>
          {isLoading
            ? (
              <div className='loader'>
                <div className='spinner' />
                <div className='text'>Loading, please wait</div>
              </div>
              )
            : (
              <div className='processBody'>
                {processes.length > 0
                  ? (
                    <div className='body'>
                      {processList.length > 0 &&
                    processList.map(process => (
                      <div
                        className={`workflow-card ${process.status === 'pending' && 'pending'}`}
                        key={process.id}
                        onClick={() => {
                          if (process.status !== 'pending') {
                            setSelectedProcessId(process.id)
                          }
                        }}
                      >
                        <div className='workflow-card-header'>
                          <div className='title'>{process.title}</div>
                        </div>
                        <div className='sub-title'>Updated on {getFormattedDate(process.updatedAt)}</div>
                        {process.status === 'active' || process.status === 'inactive'
                          ? (
                            <div className='spaceBetweenRow'>
                              <div className='processDetails'>
                                <div className='actionsContainer'>
                                  <div className='row'>
                                    <img className='icon' src={lightning} alt='' />
                                    <div className='process'>{process.successfulRuns}</div>
                                  </div>
                                  <div className='text'>Successful Runs</div>
                                </div>
                                <div className='priceContainer'>
                                  <div className='row'>
                                    <img className='icon' src={failedRuns} alt='' />
                                    <div className='process'>{process.failedRuns}</div>
                                  </div>
                                  <div className='text'>Failed Runs</div>
                                </div>
                              </div>
                            </div>
                            )
                          : (
                            <div className='spaceBetweenRow'>
                              <div className='processDetails'>
                                <div className='circularStatus' />
                                <div className='statusText'>Under Review</div>
                              </div>
                            </div>
                            )}
                      </div>
                    ))}
                    </div>
                    )
                  : (
                    <div className='emptyAI'>
                      <div className='onBoarding'>
                        <div className='row'>
                          <div className='circularStatus' />
                          <p className='info'>Limited Promo is Active</p>
                        </div>
                        <div className='row'>
                          <img src={previousPrice} alt='$99' />
                          <div className='whiteText'>$99/month =</div>
                          <div className='blueText'>1 AI</div>
                        </div>
                      </div>
                      <div className='welcomeText'>Welcome {userData?.user?.firstName}!</div>
                      <img src={hireYourAI} alt='Create Workflow' />
                      <button className='create-workflow-btn' onClick={() => history.push('/workflow/create')}>
                        <img src={addNewIcon} alt='add icon' />
                        <span>Create Your First Workflow</span>
                      </button>
                    </div>
                    )}
              </div>
              )}
        </div>
        <div className='rightContainer'>
          <div className='verticalLine' />
          {loading
            ? (
              <div className='loader'>
                <div className='spinner' />
                <div className='text'>Loading, please wait</div>
              </div>
              )
            : processesList
              ? (
                <div className='processDetailsPage' onClick={() => moreOptions && showMoreOptions(false)}>
                  <div className='headerContainer'>
                    <div className='titleArea'>
                      <div className='titleText'>{processesList?.title}</div>
                    </div>
                    <div className='row'>
                      <div
                        className='iconArea'
                        onClick={e => {
                          e.stopPropagation()
                          showMoreOptions(true)
                        }}
                      >
                        <img src={menuIcon} alt='' />
                        {moreOptions && (
                          <div className='menu'>
                            <div
                              className='update'
                              onClick={() => {
                                showMoreOptions(false)
                                showUpdateProcessModal(true)
                              }}
                            >
                              <img src={editIcon} alt='edit' />
                              <span>Re-run</span>
                            </div>
                            <div
                              className='delete'
                              onClick={() => {
                                showMoreOptions(false)
                                showDeleteProcessModal(true)
                              }}
                            >
                              <img src={deleteIcon} alt='delete' />
                              <span>Remove</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {!isLoading && (
                        <div className='switchArea'>
                          <div className='switch-container'>
                            <label htmlFor='status'>
                              <input
                                id='status'
                                type='checkbox'
                                checked={status}
                                onChange={e => {
                                  updateStatus(e.target.checked)
                                }}
                              />
                              <span />
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='processStatusContainer'>
                    <div className='spaceBetweenContainer'>
                      <div className='statusTitle'>AI Status</div>
                      <button className='primaryButton' onClick={() => navigateToContract()}>
                        Workflow Rules
                      </button>
                    </div>
                    <div className='statusContainer'>
                      <div className='column'>
                        <div className='row'>
                          <img src={yellowLightning} className='icon' alt='' />
                          <div className='title yellow'>{processesList?.successfulRuns}</div>
                        </div>
                        <div className='subtitle'>
                          <div>Successfull Runs</div>
                        </div>
                      </div>
                      <div className='column'>
                        <div className='row'>
                          <img src={redCross} className='icon' alt='' />
                          <div className='title red'>{processesList?.failedRuns}</div>
                        </div>
                        <div className='subtitle'>
                          <div>Failed Runs</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='processHistory'>
                    <div className='historyTitleArea'>
                      <div className='title'>AI History</div>
                    </div>
                    <div className='historyContainer'>
                      <div className='row'>
                        <div className='totalRuns'>
                          {`${processHistory?.length} Total Run${processHistory?.length > 1 ? 's' : ''}`}
                        </div>
                        <div className='failedProcessOnly'>
                          <div className='switch-container'>
                            <label htmlFor='failedProcess'>
                              <input
                                id='failedProcess'
                                type='checkbox'
                                checked={showFailedOnly}
                                onChange={e => {
                                  setShowFailedOnly(e.target.checked)
                                }}
                              />
                              <span />
                            </label>
                            <span>Failed process only</span>
                          </div>
                        </div>
                      </div>
                      {isLoading
                        ? (
                          <div className='loader'>
                            <div className='spinner' />
                            <div className='text'>Loading, please wait</div>
                          </div>
                          )
                        : (
                          <>
                            {processHistory?.length
                              ? (
                                  processHistory?.map(a => (
                                    <div key={a.id} className='accordion' onClick={() => showDetailInformation(a.id)}>
                                      <div className='header'>
                                        {a.status === 'success'
                                          ? (
                                            <img src={successIcon} alt='success' />
                                            )
                                          : (
                                            <img src={warningIcon} alt='failed' />
                                            )}
                                        <div className='identifierSection'>
                                          <span className='title'>Identifier</span>
                                          <span className='sub-title'>{a.identifier}</span>
                                        </div>
                                        <div className='dateAndTimeSection'>
                                          <span className='title'>Date &amp; Time</span>
                                          <span className='sub-title'>{moment(a.createdAt).format('MMM D, YYYY @ HH:mm a')}</span>
                                        </div>
                                        {a.status !== 'success' && (
                                          <div className='actionsArea'>
                                            <div className='re-run' onClick={() => reRunProcess(a.id)}>
                                              <img src={reloadIcon} alt='reload' />
                                              <span>Re-run</span>
                                            </div>
                                            <div className='ignore' onClick={() => replayAutomation(a.id)}>
                                              <img src={ignoreRed} alt='ignore' />
                                              <span>Ignore</span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))
                                )
                              : (
                                <div className='emptyText'>{message}</div>
                                )}
                          </>
                          )}
                    </div>
                  </div>
                  <div className='filterAndPaginationContainer'>
                    <div className='filter'>
                      View items by:
                      <div className='select'>
                        <select
                          className=''
                          value={pageSize}
                          name='pageSize'
                          onChange={e => {
                            setPage(1)
                            setPageSize(e.target.value)
                          }}
                        >
                          {getPageSize}
                        </select>
                      </div>
                    </div>
                    <div className='pagination'>
                      {page > 1 && !isLoading && (
                        <img src={leftArrow} className='leftArrow' onClick={() => setPage(page - 1)} />
                      )}
                      {page > 1 && !isLoading && (
                        <div className='previousPageNumber' onClick={() => setPage(page - 1)}>
                          {page - 1}
                        </div>
                      )}
                      <div className='pageNumber'>{page}</div>
                      {processHistory?.length >= pageSize && !isLoading && (
                        <div className='nextPageNumber' onClick={() => setPage(page + 1)}>
                          {page + 1}
                        </div>
                      )}
                      {processHistory?.length >= pageSize && !isLoading && (
                        <img src={rightPage} className='rightArrow' onClick={() => setPage(page + 1)} />
                      )}
                    </div>
                  </div>
                </div>
                )
              : (
                <div className='emptyContainer'>
                  <p className='notSelectedText'>You don’t have any AI selected</p>
                </div>
                )}
        </div>
      </div>

      {updateProcessModal && <div className='backdropOverlay' onClick={() => showUpdateProcessModal(false)} />}
      {updateProcessModal && <RetrainAI />}
      {deleteProcessModal && <div className='backdropOverlay' onClick={() => showDeleteProcessModal(false)} />}
      {deleteProcessModal && <DeleteAI title={processesList?.title} showDeleteProcessModal={showDeleteProcessModal} />}
      {workflowRuleModal && <div className='backdropOverlay' onClick={() => showWorkflowRuleModal(false)} />}
      {workflowRuleModal && <WorkflowRules processesList={processesList} />}
      {historyDetailModal && <div className='backdropOverlay' onClick={() => showHistoryDetailModal(false)} />}
      {historyDetailModal && (
        <HistoryDetail
          processesList={processesList}
          historyID={historyID}
          isLoading={isLoading}
          reRunProcess={reRunProcess}
          replayAutomation={replayAutomation}
        />
      )}
    </>
  )
}

export { WorkflowLegacy }
