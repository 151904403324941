import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Sidebar3 = ({ url, pathName }) => {
  const [currentCase, setCurrentCase] = useState(0)

  const handleActive = e => {
    setCurrentCase(Number(e.target.parentElement.value))
  }

  useEffect(() => {
    if (pathName.includes('/general-settings')) {
      setCurrentCase(2)
    } else if (pathName.includes('/change-password')) {
      setCurrentCase(1)
    } else {
      setCurrentCase(0)
    }
  }, [])

  return (
    <div className='preferences'>
      <ul className='listContainer'>
        <li className='list-unstyled' value={0} onClick={e => handleActive(e)}>
          <Link className={`preferenceLink btn ${currentCase === 0 ? 'selected-btn' : ''}`} to={`${url}`}>
            My Profile
          </Link>
        </li>
        <li className='list-unstyled' value={1} onClick={e => handleActive(e)}>
          <Link
            className={`preferenceLink btn ${currentCase === 1 ? 'selected-btn' : ''}`}
            to={`${url}/change-password`}
          >
            Change Password
          </Link>
        </li>
        <li className='list-unstyled' value={2} onClick={e => handleActive(e)}>
          <Link
            className={`preferenceLink btn ${currentCase === 2 ? 'selected-btn' : ''}`}
            to={`${url}/general-settings`}
          >
            General Settings
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Sidebar3
