import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import { API } from '../../../api/API'
import '../../../assets/scss/theme.scss'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'

const AddProgramModal = ({ authObj, modalOpen, setModalOpen, refreshTable }) => {
  // fields
  const [categoryId] = useState(undefined)
  const [locationId] = useState(undefined)
  const [formId] = useState(undefined)
  const [title, setTitle] = useState('')
  const [view, setView] = useState('table')
  const [price] = useState(undefined)
  const [imgUrl, setImgUrl] = useState('')
  const [description, setDescription] = useState('')
  const [minAttendees] = useState(undefined)
  const [maxAttendees] = useState(undefined)
  const [minSessions] = useState(undefined)
  const [maxSessions] = useState(undefined)
  const [sessionDurationType] = useState('')
  const [sessionDurationValue] = useState('')
  const [sessionType] = useState('')
  const [status, setStatus] = useState('active')

  // variables from api
  const [, setCategories] = useState([])
  const [, setLocations] = useState([])
  const [, setForms] = useState([])

  const [errMsg, setErrMsg] = useState('')

  const addProgram = async () => {
    setErrMsg('Adding Program...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const createObj = {
        title,
        view,
        imgUrl,
        description,
        sessionDurationType,
        sessionDurationValue,
        sessionType,
        status
      }

      if (categoryId) {
        createObj.categoryId = categoryId
      }
      if (locationId) {
        createObj.locationId = locationId
      }
      if (formId) {
        createObj.formId = formId
      }
      if (price) {
        createObj.price = price
      }
      if (minAttendees) {
        createObj.minAttendees = minAttendees
      }
      if (maxAttendees) {
        createObj.maxAttendees = maxAttendees
      }
      if (minSessions) {
        createObj.minSessions = minSessions
      }
      if (maxSessions) {
        createObj.maxSessions = maxSessions
      }

      const res = await axios.post(`${config.apiUrl}/app/programs`, createObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Program Added Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to add program (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to add program (2)')
    }
  }

  const getCategories = async () => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/categories`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setCategories(res.data)
      } else {
        setErrMsg('Unable to fetch categories (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch categories (2)')
    }
  }
  const getLocations = async () => {
    try {
      const res = await API.getLocations(authObj.account.accountId, authObj.token)

      if (res && res.data && res.data.data) {
        setErrMsg('')

        setLocations(res.data.data)
      } else {
        setErrMsg('Unable to fetch locations (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch locations (2)')
    }
  }
  const getForms = async () => {
    try {
      const res = await API.getForms(authObj.account.accountId, authObj.token)

      if (res && res.data) {
        setErrMsg('')

        setForms(res.data)
      } else {
        setErrMsg('Unable to fetch forms (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch forms (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
    getCategories()
    getLocations()
    getForms()
  }, [])

  return (
    <div>
      <div className='content'>
        <Modal
          style={{ display: 'flex', alignItems: 'center' }}
          isOpen={modalOpen}
          toggle={() => {
            setModalOpen(!modalOpen)
          }}
        >
          <ModalHeader>Add A New Program</ModalHeader>
          <ModalBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for='title'>
                      <strong className='required-field'>Title</strong>
                    </Label>
                    <Input
                      type='text'
                      name='title'
                      placeholder='Title'
                      value={title}
                      onChange={e => {
                        setTitle(e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md={12}>
              <FormGroup>
                <Label for="category">
                  <strong>Category</strong>
                </Label>
                <Input
                  type="select"
                  name="category"
                  value={categoryId}
                  onChange={e => {
                    setCategoryId(e.target.value)
                  }}
                >
                  <option value="">No Category</option>
                  {categories.map(c => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.title}
                      </option>
                    )
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="location">
                  <strong>Location</strong>
                </Label>
                <Input
                  type="select"
                  name="location"
                  value={locationId}
                  onChange={e => {
                    setLocationId(e.target.value)
                  }}
                >
                  <option value="">No Location</option>
                  {locations.map(c => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.title}
                      </option>
                    )
                  })}
                </Input>
              </FormGroup>
            </Col> */}
                <Col md={12}>
                  <FormGroup>
                    <Label for='view'>
                      <strong>View</strong>
                    </Label>
                    <Input
                      type='select'
                      name='status'
                      value={view}
                      onChange={e => {
                        setView(e.target.value)
                      }}
                    >
                      <option value='table'>Table</option>
                      <option value='calendar'>Calendar</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* <Col md={12}>
              <FormGroup>
                <Label for="form">
                  <strong>Forms</strong>
                </Label>
                <Input
                  type="select"
                  name="form"
                  value={formId}
                  onChange={e => {
                    setFormId(e.target.value)
                  }}
                >
                  <option value="">No Form</option>
                  {forms.map(f => {
                    return (
                      <option key={f.id} value={f.id}>
                        {f.title}
                      </option>
                    )
                  })}
                </Input>
              </FormGroup>
            </Col> */}
                <Col md={12}>
                  <FormGroup>
                    <Label for='description'>
                      <strong>Description</strong>
                    </Label>
                    <br />
                    <textarea
                      name='description'
                      value={description}
                      onChange={e => {
                        setDescription(e.target.value)
                      }}
                      style={{ width: '100%' }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for='imgUrl'>
                      <strong>Image Url</strong>
                    </Label>
                    <Input
                      type='text'
                      name='imgUrl'
                      value={imgUrl}
                      onChange={e => {
                        setImgUrl(e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md={12}>
              <FormGroup>
                <Label for="price">
                  <strong>Price (00.00)</strong>
                </Label>
                <Input
                  type="text"
                  name="price"
                  value={price || ""}
                  onChange={e => {
                    setPrice(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for="minAttendees">
                  <strong>Min Attendees</strong>
                </Label>
                <Input
                  type="text"
                  name="minAttendees"
                  value={minAttendees || ""}
                  onChange={e => {
                    setMinAttendees(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for="maxAttendees">
                  <strong>Max Attendees</strong>
                </Label>
                <Input
                  type="text"
                  name="maxAttendees"
                  value={maxAttendees || ""}
                  onChange={e => {
                    setMaxAttendees(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for="minSessions">
                  <strong>Min Sessions</strong>
                </Label>
                <Input
                  type="text"
                  name="minSessions"
                  value={minSessions || ""}
                  onChange={e => {
                    setMinSessions(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for="maxSessions">
                  <strong>Max Sessions</strong>
                </Label>
                <Input
                  type="text"
                  name="maxSessions"
                  value={maxSessions || ""}
                  onChange={e => {
                    setMaxSessions(e.target.value)
                  }}
                />
              </FormGroup>
            </Col> */}
                <Col md={12}>
                  <FormGroup>
                    <Label for='status'>
                      <strong>Visible To Public</strong>
                    </Label>
                    <Input
                      type='select'
                      name='status'
                      value={status}
                      onChange={e => {
                        setStatus(e.target.value)
                      }}
                    >
                      <option value='active'>Yes</option>
                      <option value='inactive'>No</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <div style={{ color: 'red' }}>{errMsg}</div>
            <Button
              color='secondary'
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              onClick={() => {
                addProgram()
              }}
            >
              Add
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
}

export { AddProgramModal }
