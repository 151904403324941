import React from 'react'

const TypeOfOrder = ({ icon, type, orderType, setOrderType }) => {
  return (
    <div className={`orderType ${orderType === type ? 'selectedOrderType' : ''}`} onClick={() => setOrderType(type)} >
      <img src={icon} className='icon' />
      <div className='type'>{type}</div>
    </div>
  )
}

export default TypeOfOrder