import triangle from '../../../../assets/icons/shapes/triangle.svg'
import square from '../../../../assets/icons/shapes/square.svg'
import circle from '../../../../assets/icons/shapes/circle.svg'
import hexagon from '../../../../assets/icons/shapes/hexagon.svg'

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const getOrderIcon = type => {
  if (type === 'Fix existing automation') {
    return triangle
  } else if (type === 'Improve existing automation') {
    return hexagon
  } else if (type === 'Build new automation') {
    return circle
  } else {
    return square
  }
}

export const formattedDate = timeStamp => {
  if (!timeStamp) return ''
  const date = new Date(timeStamp)
  return `${month[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}
