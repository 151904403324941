import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDrag } from 'react-dnd'
import { nanoid } from 'nanoid'
import { config } from '../../../config'
import 'react-form-builder2/dist/app.css'

import './FormBuilder.scss'

import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ListGroup, Spinner } from 'reactstrap'

import FormFieldSingleLineInput from './Fields/SingleLineInput/SingleLineInput'
import FormFieldParagraph from './Fields/Paragraph/Paragraph'
import FormFieldDropdown from './Fields/Dropdown/Dropdown'
import FormFieldMultipleChoice from './Fields/MultipleChoice/MultipleChoice'
import FormFieldCheckBoxes from './Fields/CheckBoxes/CheckBoxes'
import FormFieldButton from './Fields/Button/Button'
import FormFieldDate from './Fields/Date/Date'
import FormFieldPayment from './Fields/Payment/Payment'
import FormFieldSpacer from './Fields/Spacer/Spacer'
import FormFieldText from './Fields/Text/Text'
import FormFieldPrograms from './Fields/Programs/Programs'
import FormFieldSessionsTable from './Fields/SessionsTable/SessionsTable'
import FormFieldCalendarView from './Fields/CalendarView/CalendarView'
import FormFieldTermsAndConditions from './Fields/TermsAndConditions/TermsAndConditions'

import FieldGroup, { DropZone } from './FieldGroup/FieldGroup'

import { FieldTag } from './FieldTypes'
import { API } from '../../../api/API'
import Step from './Step/Step'
import SideBar from './Fields/SideBar/SideBar'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
const FormBuilder = ({ authObj }) => {
  const query = useQuery()
  const history = useHistory()
  const [formId, setFormId] = useState(query.get('formId'))

  const [errMsg, setErrMsg] = useState('')
  const [title, setTitle] = useState('title')

  const [steps, setSteps] = useState([])
  const [activeStep, setActiveStep] = useState('')
  const [showSidebar, setShowSidebar] = useState(false)

  // field groups
  const [fieldGroupsList, setFieldGroupsList] = useState([])
  const [fieldGroupsViewMap, setFieldGroupsViewMap] = useState({})

  // views hooked
  const [fieldsList, setFieldsList] = useState([])

  // settings modal
  const [fieldSettingsModalOpen, setFieldSettingsModalOpen] = useState(false)
  const [settingInModal, setSettingInModal] = useState(undefined)
  const [hasChanged, setHasChanged] = useState(false)
  const [onSaveLoader, setOnSaveLoader] = useState(false)

  // embed modal
  const [embedModalOpen, setEmbedModalOpen] = useState(false)

  const [companyLogo, setCompanyLogo] = useState('')

  const [resetStoredValues, setResetStoredValues] = useState({})
  // draggable
  const [{ DraggingSingleLineInput }, draggableSingleLineInput] = useDrag({
    item: { type: FieldTag.SINGLELINEINPUT },
    type: FieldTag.SINGLELINEINPUT,
    collect: monitor => ({
      DraggingSingleLineInput: !!monitor.isDragging()
    })
  })
  const [{ isDraggingParagraph }, draggableParagraph] = useDrag({
    item: { type: FieldTag.PARAGRAPH },
    type: FieldTag.PARAGRAPH,
    collect: monitor => ({
      isDraggingParagraph: !!monitor.isDragging()
    })
  })
  const [{ isDraggingDropdown }, draggableDropdown] = useDrag({
    item: { type: FieldTag.DROPDOWN },
    type: FieldTag.DROPDOWN,
    collect: monitor => ({
      isDraggingDropdown: !!monitor.isDragging()
    })
  })
  const [{ isDraggingMultipleChoice }, draggableMultipleChoice] = useDrag({
    item: { type: FieldTag.MULTIPLECHOICE },
    type: FieldTag.MULTIPLECHOICE,
    collect: monitor => ({
      isDraggingMultipleChoice: !!monitor.isDragging()
    })
  })
  const [{ isDraggingCheckBoxes }, draggableCheckBoxes] = useDrag({
    item: { type: FieldTag.CHECKBOXES },
    type: FieldTag.CHECKBOXES,
    collect: monitor => ({
      isDraggingCheckBoxes: !!monitor.isDragging()
    })
  })
  const [{ isDraggingButton }, draggableButton] = useDrag({
    item: { type: FieldTag.BUTTON },
    type: FieldTag.BUTTON,
    collect: monitor => ({
      isDraggingButton: !!monitor.isDragging()
    })
  })
  const [{ isDraggingDate }, draggableDate] = useDrag({
    item: { type: FieldTag.DATE },
    type: FieldTag.DATE,
    collect: monitor => ({
      isDraggingCalendar: !!monitor.isDragging()
    })
  })
  const [{ isDraggingPayment }, draggablePayment] = useDrag({
    item: { type: FieldTag.PAYMENT },
    type: FieldTag.PAYMENT,
    collect: monitor => ({
      isDraggingPayment: !!monitor.isDragging()
    })
  })
  const [{ isDraggingSpacer }, draggableSpacer] = useDrag({
    item: { type: FieldTag.SPACER },
    type: FieldTag.SPACER,
    collect: monitor => ({
      isDraggingSpacer: !!monitor.isDragging()
    })
  })
  const [{ isDraggingText }, draggableText] = useDrag({
    item: { type: FieldTag.TEXT },
    type: FieldTag.TEXT,
    collect: monitor => ({
      isDraggingText: !!monitor.isDragging()
    })
  })
  const [{ isDraggingPrograms }, draggablePrograms] = useDrag({
    item: { type: FieldTag.PROGRAMS },
    type: FieldTag.PROGRAMS,
    collect: monitor => ({
      isDraggingPrograms: !!monitor.isDragging()
    })
  })
  const [{ isDraggingSessions }, draggableSessions] = useDrag({
    item: { type: FieldTag.SESSIONS },
    type: FieldTag.SESSIONS,
    collect: monitor => ({
      isDraggingSessions: !!monitor.isDragging()
    })
  })
  const [{ isDraggingCalendarView }, draggableCalendarView] = useDrag({
    item: { type: FieldTag.CALENDAR_VIEW },
    type: FieldTag.CALENDAR_VIEW,
    collect: monitor => ({
      isDraggingCalendarView: !!monitor.isDragging()
    })
  })
  const [{ isDraggingTermsAndConditions }, draggableTermsAndConditions] = useDrag({
    item: { type: FieldTag.TERMS_AND_CONDITIONS },
    type: FieldTag.TERMS_AND_CONDITIONS,
    collect: monitor => ({
      isDraggingTermsAndConditions: !!monitor.isDragging()
    })
  })

  const createForm = async () => {
    setErrMsg('Creating form...')
    try {
      setOnSaveLoader(true)
      const res = await API.createForm(authObj.account.accountId, authObj.token, {
        title: 'form',
        formFields: fieldsList,
        fieldGroups: fieldGroupsList,
        steps,
        status: 'active'
      })
      if (res && res.data && res.data.formId) {
        setErrMsg('Form created successfully!')
        setTimeout(() => {
          setErrMsg('')
        }, 5000)
        history.replace(`?formId=${res.data.formId}`)
        setFormId(res.data.formId)
      } else {
        setErrMsg('Unable to create form (1)')
      }
      setHasChanged(false)
      setOnSaveLoader(false)
    } catch (err) {
      setErrMsg(`Unable to create form (2) : ${err}`)

      setHasChanged(false)
      setOnSaveLoader(false)
    }
  }

  const updateForm = async () => {
    setErrMsg('Updating form...')
    try {
      setOnSaveLoader(true)
      const res = await API.updateForm(authObj.account.accountId, authObj.token, {
        formId,
        title,
        formFields: fieldsList,
        fieldGroups: fieldGroupsList,
        steps,
        status: 'active'
      })
      if (res && res.data) {
        setErrMsg('')
        getForm('Form updated successfully!')
      } else {
        setErrMsg('Unable to update form (1)')
      }
      setHasChanged(false)
      setOnSaveLoader(false)
    } catch (err) {
      setHasChanged(false)
      setOnSaveLoader(false)
      setErrMsg(`Unable to update form (2) : ${err}`)
    }
  }

  const getForm = async updateMsg => {
    setErrMsg('Fetching form...')
    try {
      const res = await API.getForms(authObj.account.accountId, authObj.token)
      if (res.err) {
        history.push('/settings')
      }
      if (res && res.data && res.data.length > 0) {
        setErrMsg('')
        if (updateMsg) {
          setErrMsg(updateMsg)
          setTimeout(() => {
            setErrMsg('')
          }, 5000)
        }

        const { id, formFields, fieldGroups, steps, title } = res.data[0] ?? {}

        setFormId(id)
        setFieldsList(formFields || [])
        setFieldGroupsList(fieldGroups || [])
        setSteps(steps || [])
        setTitle(title || '')
        if (steps) {
          setActiveStep(steps[0]?.id)
        }
        const backup = res.data[0]
        setResetStoredValues(backup)
      } else {
        setErrMsg('')
        initializeForm()
      }
    } catch (err) {
      setErrMsg(`Unable to fetch form (2) : ${err}`)
    }
  }

  const savePreview = async () => {
    setErrMsg('Creating preview...')
    try {
      let res
      if (formId && formId !== '') {
        res = await API.updateForm(authObj.account.accountId, authObj.token, {
          formId,
          title,
          preview: {
            formFields: fieldsList,
            fieldGroups: fieldGroupsList,
            steps
          },
          status: 'active'
        })
      } else {
        res = await API.createForm(authObj.account.accountId, authObj.token, {
          title,
          preview: {
            formFields: fieldsList,
            fieldGroups: fieldGroupsList,
            steps
          },
          status: 'active'
        })
      }
      if (res && res.data) {
        setErrMsg('')
        if (res.data.formId) {
          setFormId(res.data.formId)
        }
        window.open(`${config.clientUrl}/form/preview?fId=${res.data.formId || formId}`)
      } else {
        setErrMsg('Unable to save preview (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to save preview (2) : ${err}`)
    }
  }

  const getSettings = async () => {
    try {
      const { err, data: { accountSettings: { companyLogo }, account: { name } } = {} } = await API.getSettings(
        authObj.account.accountId,
        authObj.token
      )
      if (err) {
        return setErrMsg(err)
      }
      setCompanyLogo(companyLogo || `https://ui-avatars.com/api/?name=${name}`)
    } catch (err) {
      setErrMsg(`Unable to fetch settings (2) : ${err}`)
    }
  }

  const initializeForm = () => {
    // initialize with a field and a field group
    const formStepId = nanoid()
    setSteps([{ id: formStepId, order: 0, displayName: 'Program', icon: 'fas fa-users' }])
    const fieldGroupId = nanoid()
    setFieldGroupsList([{ id: fieldGroupId, formStepId, order: 0, repeat: 'none', alignment: 'left' }])
    setFieldsList([
      {
        id: nanoid(),
        order: 0,
        type: FieldTag.SINGLELINEINPUT,
        fieldGroupId,
        displayName: 'Label',
        internalName: 'label',
        required: false,
        placeholder: 'placeholder',
        instructions: '',
        width: 33,
        options: [
          { option: 'Basketball', value: 'basketball', order: 0 },
          { option: 'Soccer', value: 'soccer', order: 1 },
          { option: 'Volleyball', value: 'volleyball', order: 2 }
        ],
        action: 'none',
        showWhen: 'always',
        content: ''
      }
    ])
    setActiveStep(formStepId)
    setHasChanged(false)
  }

  // update visible setting in modal
  const updateCurrentSetting = setting => {
    setSettingInModal(setting)
  }

  const addStep = () => {
    setHasChanged(true)
    setSteps(currSteps => {
      const newId = nanoid()
      setActiveStep(newId)
      return [
        ...currSteps,
        { id: newId, order: currSteps.length, displayName: 'Program', icon: 'fas fa-users', showCart: false }
      ]
    })
  }

  const removeStep = id => {
    const answer = window.confirm('Are you sure you want to remove this step?')
    if (answer === false) {
      return
    }
    setHasChanged(true)

    // remove fields and field groups from this step
    const fieldGroupIds = fieldGroupsList.filter(fg => fg.formStepId === id).map(fg => fg.id)
    const newFieldsList = fieldsList.filter(f => fieldGroupIds.indexOf(f.fieldGroupId) === -1)
    setFieldsList(newFieldsList)
    const newFieldGroupsList = fieldGroupsList.filter(fg => fieldGroupIds.indexOf(fg.id) === -1)
    setFieldGroupsList(newFieldGroupsList)

    // step
    const newSteps = steps.filter(s => s.id !== id)
    setSteps(newSteps)
    setActiveStep(newSteps[newSteps.length - 1]?.id)
  }

  // add a new blank field group
  const addFieldGroup = () => {
    setHasChanged(true)
    setFieldGroupsList(currFieldGroupsList => {
      const newId = nanoid()
      return [
        ...currFieldGroupsList,
        { id: newId, formStepId: activeStep, order: currFieldGroupsList.length, repeat: 'none', alignment: 'left' }
      ]
    })
  }

  const removeFieldGroup = id => {
    const answer = window.confirm('Are you sure you want to remove this section?')
    if (answer === false) {
      return
    }

    setHasChanged(true)
    // remove fields in this group
    const newFieldsList = fieldsList.filter(f => f.fieldGroupId !== id)
    setFieldsList(newFieldsList)

    // remove field group
    const newFieldGroupsList = fieldGroupsList.filter(fg => fg.id !== id)
    setFieldGroupsList(newFieldGroupsList)
  }

  const addField = (type, fieldGroupId) => {
    setHasChanged(true)
    setFieldsList(currFieldsList => {
      const newId = nanoid()
      return [
        ...currFieldsList,
        {
          id: newId,
          order: currFieldsList.length,
          type,
          fieldGroupId,
          displayName: 'Label',
          internalName: 'label',
          required: false,
          placeholder: 'placeholder',
          instructions: '',
          width: 33,
          options: [
            { option: 'Basketball', value: 'basketball', order: 0 },
            { option: 'Soccer', value: 'soccer', order: 1 },
            { option: 'Volleyball', value: 'volleyball', order: 2 }
          ],
          action: 'none',
          showWhen: 'always',
          content:
            type === FieldTag.TERMS_AND_CONDITIONS
              ? 'Your terms and conditions here'
              : type === FieldTag.TEXT
                ? 'Title'
                : ''
        }
      ]
    })
  }

  // individual fields will call this to remove a field from the list
  const removeField = id => {
    const answer = window.confirm('Are you sure you want to remove this field?')
    if (answer === false) {
      return
    }
    setHasChanged(true)

    // remove field with the given id
    const newFieldsList = fieldsList.filter(f => f.id !== id)
    setFieldsList(newFieldsList)
  }

  // update settings for a field
  const updateFieldSettings = (id, settings) => {
    // find field and update its settings
    const updatedFields = fieldsList.map(f => {
      if (f.id === id) {
        for (const key in settings) {
          f[key] = settings[key]
          if (key === 'displayName') {
            f.internalName = settings[key]
          }
        }
      }
      return f
    })
    setFieldsList(updatedFields)
    // find field group and update its settings
    const updatedFieldGroups = fieldGroupsList.map(f => {
      if (f.id === id) {
        for (const key in settings) {
          f[key] = settings[key]
        }
      }
      return f
    })
    setFieldGroupsList(updatedFieldGroups)
    // find step and update its settings
    const updatedSteps = steps.map(s => {
      if (s.id === id) {
        for (const key in settings) {
          if (id === activeStep && key === 'showCart') {
            setShowSidebar(settings[key])
          }
          s[key] = settings[key]
        }
      }
      return s
    })
    setSteps(updatedSteps)
  }

  const setFieldOrderFunc = (id, order) => {
    // bring order in the correct range
    order = Math.max(order, 0)
    order = Math.min(order, fieldsList.length - 1)

    const field = fieldsList.find(f => f.id === id)
    const fieldsInSameGroup = fieldsList.filter(f => f.fieldGroupId === field.fieldGroupId)
    for (let i = 0; i < fieldsInSameGroup.length; i += 1) {
      // shift other fields to the right
      if (order < field.order && fieldsInSameGroup[i].order >= order) {
        fieldsInSameGroup[i].order += 1
      } else if (order > field.order && fieldsInSameGroup[i].order <= order) {
        // shift other fields to the left
        fieldsInSameGroup[i].order -= 1
      }
    }
    field.order = order
    setFieldsList([...fieldsList])
  }

  const setFieldGroupOrder = (id, order) => {
    // bring order in the correct range
    order = Math.max(order, 0)
    order = Math.min(order, fieldGroupsList.length - 1)

    const fieldGroup = fieldGroupsList.find(fg => fg.id === id)
    for (let i = 0; i < fieldGroupsList.length; i += 1) {
      // shift other groups to the bottom
      if (order < fieldGroup.order && fieldGroupsList[i].order >= order) {
        fieldGroupsList[i].order += 1
      } else if (order > fieldGroup.order && fieldGroupsList[i].order <= order) {
        // shift other groups to the top
        fieldGroupsList[i].order -= 1
      }
    }
    fieldGroup.order = order
    setFieldGroupsList([...fieldGroupsList])
  }

  const setStepOrder = (id, order) => {
    // bring order in the correct range
    order = Math.max(order, 0)
    order = Math.min(order, steps.length - 1)

    const step = steps.find(s => s.id === id)
    for (let i = 0; i < steps.length; i += 1) {
      // shift other groups to the bottom
      if (order < step.order && steps[i].order >= order) {
        steps[i].order += 1
      } else if (order > step.order && steps[i].order <= order) {
        // shift other groups to the top
        steps[i].order -= 1
      }
    }
    step.order = order
    setSteps([...steps])
  }

  const getStepOrder = currentStep => {
    return steps?.find(s => s.id === currentStep)?.order
  }

  // every time fieldsList updates, rerender the view
  const updateFieldsListView = () => {
    // sort by order
    fieldsList.sort((a, b) => {
      return a.order - b.order
    })
    fieldGroupsList.sort((a, b) => {
      return a.order - b.order
    })
    steps.sort((a, b) => {
      return a.order - b.order
    })

    steps.forEach((s, i) => {
      s.order = i
    })

    // sort field groups wrt to steps
    const fieldGroupOrderMapTemp = {}
    fieldGroupsList.forEach(f => {
      if (fieldGroupOrderMapTemp[f.formStepId] === undefined) {
        fieldGroupOrderMapTemp[f.formStepId] = 0
      }
      f.order = fieldGroupOrderMapTemp[f.formStepId]
      fieldGroupOrderMapTemp[f.formStepId] += 1
    })

    // sort fields wrt to field groups
    const fieldOrderMapTemp = {}
    fieldsList.forEach(f => {
      if (fieldOrderMapTemp[f.fieldGroupId] === undefined) {
        fieldOrderMapTemp[f.fieldGroupId] = 0
      }
      f.order = fieldOrderMapTemp[f.fieldGroupId]
      fieldOrderMapTemp[f.fieldGroupId] += 1
    })

    const fieldGroupsViewMapTemp = {}

    fieldsList.forEach(f => {
      if (!fieldGroupsViewMapTemp[f.fieldGroupId]) {
        fieldGroupsViewMapTemp[f.fieldGroupId] = []
      }
      let componentItem
      let isWidthNeeded = true
      switch (f.type) {
        case FieldTag.SINGLELINEINPUT:
          componentItem = (
            <FormFieldSingleLineInput
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.PARAGRAPH:
          componentItem = (
            <FormFieldParagraph
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.DROPDOWN:
          componentItem = (
            <FormFieldDropdown
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.MULTIPLECHOICE:
          componentItem = (
            <FormFieldMultipleChoice
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.CHECKBOXES:
          componentItem = (
            <FormFieldCheckBoxes
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.BUTTON:
          componentItem = (
            <FormFieldButton
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.DATE:
          componentItem = (
            <FormFieldDate
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.PAYMENT:
          isWidthNeeded = false
          componentItem = (
            <FormFieldPayment
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.SPACER:
          componentItem = (
            <FormFieldSpacer
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.TEXT:
          componentItem = (
            <FormFieldText
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.PROGRAMS:
          isWidthNeeded = false
          componentItem = (
            <FormFieldPrograms
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.SESSIONS:
          isWidthNeeded = false
          componentItem = (
            <FormFieldSessionsTable
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.CALENDAR_VIEW:
          isWidthNeeded = false
          componentItem = (
            <FormFieldCalendarView
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        case FieldTag.TERMS_AND_CONDITIONS:
          componentItem = (
            <FormFieldTermsAndConditions
              key={f.id}
              field={f}
              removeFunc={removeField}
              setFieldOrderFunc={setFieldOrderFunc}
              updateCurrentSetting={updateCurrentSetting}
              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
              updateFieldSettings={updateFieldSettings}
            />
          )
          break
        default:
          break
      }
      fieldGroupsViewMapTemp[f.fieldGroupId].push(
        <>
          <DropZone
            width={isWidthNeeded ? f.width : 100}
            data={{
              parentId: f.fieldGroupId,
              position: fieldGroupsViewMapTemp[f.fieldGroupId].length
            }}
            onDrop={(data, item) => {
              setFieldOrderFunc(item.id, data.position)
            }}
            className='horizontalDrag'
          >
            {componentItem}
          </DropZone>
        </>
      )
    })
    setFieldGroupsViewMap(fieldGroupsViewMapTemp)
  }

  const onSave = () => {
    if (formId && formId !== '') {
      updateForm()
    } else {
      createForm()
    }
  }

  const onReset = () => {
    if (formId && formId !== '') {
      const { id, formFields, fieldGroups, steps = [], title } = resetStoredValues ?? {}
      setFormId(id)
      setFieldsList(formFields || [])
      setFieldGroupsList(fieldGroups || [])
      setSteps(steps || [])
      setTitle(title || '')
      if (steps[0]) {
        setActiveStep(steps[0]?.id)
      }
    } else {
      initializeForm(true)
    }
  }

  useEffect(() => {
    setShowSidebar(steps.find(s => s.id === activeStep)?.showCart)
  }, [activeStep])

  useEffect(() => {
    updateFieldsListView()
  }, [fieldsList, fieldGroupsList, steps])

  useEffect(() => {
    getForm()
    getSettings()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div className='col-12' style={{ paddingTop: !hasChanged && '30px' }}>
              <section className='box '>
                {hasChanged && (
                  <>
                    <Row className='bg-dark text-light py-2 m-0 fixed-action-bar'>
                      <Col className='d-flex align-items-center'>
                        {errMsg ? <span>{errMsg}</span> : <span>You have unsaved changes!</span>}
                      </Col>
                      <Col className='d-flex justify-content-end'>
                        <Button disabled={onSaveLoader} variant='success' onClick={onSave} size='sm'>
                          {onSaveLoader ? <Spinner size='sm' animation='border' role='status' /> : 'Save'}
                        </Button>
                        <Button color='primary' size='sm' onClick={onReset}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                    <div
                      style={{
                        padding: '28px'
                      }}
                    />
                  </>
                )}
                <header className='panel_header'>
                  <div style={{ float: 'left' }}>
                    <h2 className='title'>Form Builder</h2>
                    <p style={{ marginLeft: '30px' }}>{errMsg}</p>
                  </div>
                  <div style={{ padding: '30px', float: 'right' }}>
                    {/* <input type="text" placeholder="title" value={title} onChange={e => setTitle(e.target.value)} /> */}
                    {formId && (
                      <Button
                        color='primary'
                        size='sm'
                        onClick={() => {
                          savePreview()
                        }}
                      >
                        Preview
                      </Button>
                    )}
                    <Button color='primary' size='sm' onClick={onSave}>
                      Save
                    </Button>
                    {formId && (
                      <Button
                        color='primary'
                        size='sm'
                        onClick={() => {
                          setEmbedModalOpen(true)
                        }}
                      >
                        Embed
                      </Button>
                    )}
                  </div>
                </header>

                <div className='content-body'>
                  <Row style={{ paddingBottom: '50px' }}>
                    <Col md={3}>
                      <Col md={12} className='sticky-top' style={{ top: '100px', zIndex: 3 }}>
                        <div>
                          <div
                            style={{
                              color: '#fff',
                              backgroundColor: '#302a52',
                              padding: '0.75rem 1.25rem'
                            }}
                          >
                            <i className='fas fa-home fa-xs' />
                            <span style={{ paddingLeft: '5px' }}>Fields</span>
                          </div>
                          <Row>
                            <Col md='12'>
                              <ListGroup>
                                <div
                                  ref={draggableSingleLineInput}
                                  style={{
                                    opacity: DraggingSingleLineInput ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: DraggingSingleLineInput ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: DraggingSingleLineInput ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-font fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Single Line Input</span>
                                </div>

                                <div
                                  ref={draggableParagraph}
                                  style={{
                                    opacity: isDraggingParagraph ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingParagraph ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingParagraph ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-paragraph fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Paragraph</span>
                                </div>
                                <div
                                  ref={draggableDropdown}
                                  style={{
                                    opacity: isDraggingDropdown ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingDropdown ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingDropdown ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-caret-square-down fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Dropdown</span>
                                </div>
                                <div
                                  ref={draggableMultipleChoice}
                                  style={{
                                    opacity: isDraggingMultipleChoice ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingMultipleChoice ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingMultipleChoice ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-dot-circle fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Multiple Choice</span>
                                </div>
                                <div
                                  ref={draggableCheckBoxes}
                                  style={{
                                    opacity: isDraggingCheckBoxes ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingCheckBoxes ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingCheckBoxes ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-check-square fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Checkboxes</span>
                                </div>
                                <div
                                  ref={draggableButton}
                                  style={{
                                    opacity: isDraggingButton ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingButton ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingButton ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-hand-pointer fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Button</span>
                                </div>

                                <div
                                  ref={draggableDate}
                                  style={{
                                    opacity: isDraggingDate ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingDate ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingDate ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-calendar-alt fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Date</span>
                                </div>
                                <div
                                  ref={draggablePayment}
                                  style={{
                                    opacity: isDraggingPayment ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingPayment ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingPayment ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-shopping-cart fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Payment</span>
                                </div>

                                <div
                                  ref={draggableSpacer}
                                  style={{
                                    opacity: isDraggingSpacer ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingSpacer ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingSpacer ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-bars fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Spacer</span>
                                </div>
                                <div
                                  ref={draggableText}
                                  style={{
                                    opacity: isDraggingText ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingText ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingText ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-bars fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Text</span>
                                </div>
                                <div
                                  ref={draggablePrograms}
                                  style={{
                                    opacity: isDraggingPrograms ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingPrograms ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingPrograms ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-volleyball-ball fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Programs</span>
                                </div>
                                <div
                                  ref={draggableSessions}
                                  style={{
                                    opacity: isDraggingSessions ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingSessions ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingSessions ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-clock fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Sessions</span>
                                </div>
                                <div
                                  ref={draggableCalendarView}
                                  style={{
                                    opacity: isDraggingCalendarView ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingCalendarView ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingCalendarView ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-calendar fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Calendar</span>
                                </div>
                                <div
                                  ref={draggableTermsAndConditions}
                                  style={{
                                    opacity: isDraggingTermsAndConditions ? 0.5 : 1,
                                    color: '#333',
                                    backgroundColor: isDraggingTermsAndConditions ? 'lightblue' : 'white',
                                    cursor: 'move',
                                    border: isDraggingTermsAndConditions ? '1px dashed gray' : '1px solid #eaeaea',
                                    padding: '0.75rem 1.25rem'
                                  }}
                                >
                                  <i className='fas fa-bars fa-xs' />
                                  <span style={{ paddingLeft: '5px' }}>Terms and Conditions</span>
                                </div>
                              </ListGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Col>
                    <Col md={9} style={{ boxShadow: '0px 0px 10px 3px #f2f2f2' }}>
                      <ul id='progressbar' style={{ paddingTop: '30px' }}>
                        {steps.map(s => {
                          return (
                            <Step
                              key={s.id}
                              step={s}
                              activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              removeStep={removeStep}
                              setStepOrder={setStepOrder}
                              updateCurrentSetting={updateCurrentSetting}
                              setFieldSettingsModalOpen={setFieldSettingsModalOpen}
                              updateFieldSettings={updateFieldSettings}
                            />
                          )
                        })}
                        {steps.length < 6 && (
                          <li className='active' onClick={() => addStep()}>
                            <i className='fas fa-plus' />
                            <strong>Add step</strong>
                          </li>
                        )}
                      </ul>
                      <div className='progress'>
                        <div
                          className='progress-bar progress-bar-striped progress-bar-animated'
                          role='progressbar'
                          aria-valuemin='0'
                          aria-valuemax='100'
                          style={{
                            width: `${(
                              ((getStepOrder(activeStep) || 0) + 1) *
                              (100 / (steps?.length || 1))
                            ).toFixed()}%`
                          }}
                        />
                      </div>
                      <Row>
                        <Col xs={12} md={3}>
                          {showSidebar && <SideBar companyLogo={companyLogo} />}
                        </Col>
                        <Col xs={12} md={showSidebar ? 9 : 12}>
                          {fieldGroupsList.map(fieldGroup => {
                            if (fieldGroup.formStepId === activeStep) {
                              return (
                                <FieldGroup
                                  key={fieldGroup.id}
                                  fieldGroup={fieldGroup}
                                  removeFieldGroup={removeFieldGroup}
                                  addField={addField}
                                  formFieldsView={fieldGroupsViewMap[fieldGroup.id]}
                                  setFieldGroupOrder={setFieldGroupOrder}
                                  updateCurrentSetting={updateCurrentSetting}
                                  setFieldSettingsModalOpen={setFieldSettingsModalOpen}
                                  updateFieldSettings={updateFieldSettings}
                                />
                              )
                            }
                            return null
                          })}
                        </Col>
                      </Row>
                      <Button
                        color='primary'
                        style={{ position: 'relative', width: '100%', transform: 'none' }}
                        onClick={() => addFieldGroup()}
                      >
                        + Add section
                      </Button>
                    </Col>
                  </Row>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        style={{ height: '100%', display: 'flex', alignItems: 'center' }}
        isOpen={fieldSettingsModalOpen}
        toggle={() => {
          setFieldSettingsModalOpen(!fieldSettingsModalOpen)
        }}
        size='lg'
      >
        <ModalHeader>Field settings and options</ModalHeader>
        <ModalBody>
          {settingInModal}
          <p>Note: Changes are saved automatically</p>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={embedModalOpen}
        toggle={() => {
          setEmbedModalOpen(!embedModalOpen)
        }}
      >
        <ModalHeader>Embed this form</ModalHeader>
        <ModalBody>
          <pre style={{ padding: '10px' }}>{`${config.clientUrl}/form?fId=${formId}`}</pre>
          <hr />
          <div style={{ textAlign: 'center', width: '100%' }}>OR</div>
          <hr />
          <pre style={{ padding: '10px' }}>
            {`<div class="edbridge-inline-widget" data-url="${config.clientUrl}/form?fId=${formId}"></div>
<script type="text/javascript" src="${config.clientUrl}/widget.js" async></script>`}
          </pre>
          <span>Paste the above script(s) into your website</span>
        </ModalBody>
      </Modal>
    </div>
  )
}

export { FormBuilder }
