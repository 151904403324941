import React from 'react'

const Status = ({ status }) => {

  const getFormattedStatus = rawStatus => { 
    switch (rawStatus) {
      case 'pending':
        return "Pending Payment"
      case 'review':
        return "In Review"
      case 'draft':
        return "Draft"
      case 'paid':
        return "Paid"
      case 'active':
        return "Active"    
      default:
        return ""
    }
  }

  const getStatusColor = rawStatus => { 
    switch (rawStatus) {
      case 'pending':
        return "#F2D749"
      case 'review':
        return "#51B0FF"
      case 'draft':
        return "#C8CAD2"
      case 'paid':
        return "#65D630"
      case 'active':
        return "#65D630"    
      default:
        return ""
    }
  }
  return (
    <div className="orderStatusArea">
      <div
        className="statusContainer"
        style={{ backgroundColor: `${getStatusColor(status)}` }}
      />
      <div
        className="statusText"
      >
        {getFormattedStatus(status)}
      </div>
    </div>
  )
}

export default Status