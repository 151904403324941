import React, { useEffect, useState } from 'react'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { useSelector, useDispatch } from 'react-redux'
import TypeOfOrder from './components/TypeOfOrder'
import { useHistory } from 'react-router-dom'

import build from '../../../assets/icons/TypesOfOrder/build.svg'
import fix from '../../../assets/icons/TypesOfOrder/fix.svg'
import improve from '../../../assets/icons/TypesOfOrder/improve.svg'
import ConfirmationModal from './components/ConfirmationModal'
import { createOrder } from '../../../services/order.services'

const CreateOrder = () => {
  const dispatch = useDispatch()
  const { userData } = useSelector(state => state?.auth)
  const { token, account } = userData
  const { theme } = useSelector(state => state.themes)
  const history = useHistory()
  const [orderType, setOrderType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [orderDescription, setOrderDescription] = useState('')

  const submitHandler = () => { 
    if (orderType?.length === 0) {
      setErrorMessage('Please select the type of order you want to make.')
      setShowModal(false)
    } else if (orderDescription?.length === 0) {
      setErrorMessage('Please add order description of order you want to make.')
      setShowModal(false)
    } else { 
      setShowModal(true)
    }
  }

  const saveHandler = () => { 
    const data = {
      type: orderType,
      description: orderDescription
    }
    dispatch(createOrder(account?.accountId, token, data, true))
      .then(() => {
        hideModal()
        history.push('/orders')
      })
      .catch(err => console.log(err))
  }

  const hideModal = () => { 
    setShowModal(false)
  }

  useEffect(() => { 
    setErrorMessage('')
  }, [orderType, orderDescription])

  return (
    <>
      <div className="createOrderPage">
        <PageTitle page="createOrder" theme={theme} title='Make a Order' subtitle='Send us a Order that you have in mind' />
        <div className="createOrderContainer">
          <div className="title">Type of Order</div>
          <div className="subtitle">Select the type of order work to be done.</div>
          <div className="typesOfOrderContainer">
            <TypeOfOrder
              icon={build}
              type="Build new automation"
              orderType={orderType}
              setOrderType={setOrderType}
            />
            <TypeOfOrder
              icon={fix}
              type="Fix existing automation"
              orderType={orderType}
              setOrderType={setOrderType}
            />
            <TypeOfOrder
              icon={improve}
              type="Improve existing automation"
              orderType={orderType}
              setOrderType={setOrderType}
            />
          </div>
          <div className="descriptionArea">
            <div className="descriptionTitle">
              Describe what you want done in one sentence
            </div>
            <input
              type="text"
              className="primaryInputField"
              placeholder='Enter your answer...'
              value={orderDescription}
              onChange={(e) => setOrderDescription(e.target.value)}
            />
          </div>
          <p className="err">{errorMessage}</p>
          <button className="primaryButton" onClick={() => submitHandler()}>Submit Order</button>
        </div>
      </div>
      {showModal ? <div className='appOverlay' onClick={() => hideModal()} /> : null}
      {showModal && (
        <ConfirmationModal
          hideModal={hideModal}
          saveHandler={saveHandler}
        />
      )}
    </>
  )
}

export default CreateOrder