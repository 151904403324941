import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import arrowLeft from '../../../assets/icons/arrowLeft-ios.svg'
import { apps, deepCopy } from '../AppsOffered/OfferedApps'
import optionsIcon from '../../../assets/icons/optionsIcon.svg'
import campminderIcon from '../../../assets/icons/campminderIcon.svg'
import slackIcon from '../../../assets/icons/slackIcon.svg'
import googleSheetsIcon from '../../../assets/icons/googleSheetsIcon.svg'
import sharpspringIcon from '../../../assets/icons/sharpspringIcon.svg'
import googleFormsIcon from '../../../assets/icons/googleFormsIcon.svg'
import hubspotIcon from '../../../assets/icons/hubspotIcon.svg'
import mailchimpIcon from '../../../assets/icons/mailchimpIcon.svg'
import googleDriveIcon from '../../../assets/icons/googleDriveIcon.svg'
import googleCalendarIcon from '../../../assets/icons/googleCalendarIcon.svg'
import gmailIcon from '../../../assets/icons/gmailIcon.svg'
import salesforceIcon from '../../../assets/icons/salesforceIcon.svg'
import shopifyIcon from '../../../assets/icons/shopifyIcon.svg'
import bigQueryIcon from '../../../assets/icons/bigQueryIcon.svg'
import campBrainIcon from '../../../assets/icons/campBrainIcon.svg'
import campSiteIcon from '../../../assets/icons/campSiteIcon.svg'
import campSpotIcon from '../../../assets/icons/campSpotIcon.svg'
import shipCalmIcon from '../../../assets/icons/shipCalmIcon.svg'
import copyToClipboardBlue from '../../../assets/icons/copyToClipboardBlue.svg'
import infoYellow from '../../../assets/icons/infoYellow.svg'
import removeIcon from '../../../assets/icons/removeRed.svg'
import closeIcon from '../../../assets/icons/closeIcon.svg'
import CodeBox from './CodeBox'
import GoogleAppSettings from './Settings/GoogleAppSettings'
import SlackAppSettings from './Settings/SlackAppSettings'
import HubspotAppSettings from './Settings/HubspotAppSettings'
import MailchimpAppSettings from './Settings/MailchimpAppSettings'
import SalesforceAppSettings from './Settings/SalesforceAppSettings'
import ShopifyAppSettings from './Settings/ShopifyAppSettings'
import integrateButtonIcon from '../../../assets/icons/integrateButtonIcon.svg'
import { API } from '../../../api/API'
import { config } from '../../../config'
import BackIcon from '../../../components/Icons/BackIcon'
import CopyToClipBoard from '../../../components/Icons/CopyToClipBoard'
import InfoIcon from '../../../components/Icons/InfoIcon'

const AppSettings = () => {
  const history = useHistory()
  const { userData } = useSelector(state => state.auth)
  const { theme } = useSelector(state => state.themes)
  const [connectedApps, setConnectedApps] = useState([])
  const allApps = deepCopy(apps).map((r, i) => {
    r.icon = [
      campminderIcon,
      gmailIcon,
      googleSheetsIcon,
      sharpspringIcon,
      slackIcon,
      googleFormsIcon,
      hubspotIcon,
      mailchimpIcon,
      googleDriveIcon,
      googleCalendarIcon,
      salesforceIcon,
      shopifyIcon,
      bigQueryIcon,
      campBrainIcon,
      campSiteIcon,
      campSpotIcon,
      shipCalmIcon
    ][i]
    r.isAdded = !!connectedApps?.find(ca => ca?.slug === r.slug)
    r.settings = connectedApps?.find(ca => ca?.slug === r.slug)?.settings
    return r
  })
  const accountId = userData?.account?.accountId
  const userEmail = userData?.user?.email
  const app = allApps.find(a => a.slug === history?.location?.pathname.split('/')[2])
  const [moreOptions, showMoreOptions] = useState(false)
  const [isCodeCopied, setCodeCopied] = useState(false)
  const [isLinkCopied, setLinkCopied] = useState(false)
  const [userId, setUserId] = useState('')
  const [email, setEmail] = useState('')
  const [linkUrl, setLinkUrl] = useState(`${config.widgetUrl}/portal?aId=${accountId}?userID={userId}&email={email}`)
  const [removeIntegrationModal, showRemoveIntegrationModal] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [settings, setSettings] = useState({
    redirectUri: config.integrateUrl
  })
  const [offeredAppId, setOfferedAppId] = useState(undefined)
  const offeredApps = allApps.filter(app => !!connectedApps?.find(ca => ca?.slug === app?.slug))

  useEffect(() => {
    setLinkUrl(
      `${config.widgetUrl}/portal?accountid=${accountId}&email=${email.length > 0 ? email : '{email}'}&userid=${
        userId.length > 0 ? userId : '{userId}'
      }`
    )
  }, [userId, email, accountId])

  const code = `
    <div id="champselect-inline-widget" data-accountid="${accountId}" data-email="{email}" data-userid="{userId}" >
    </div>
    <link href="${config.widgetUrl}/widget/widget.css" rel="stylesheet" />
    <script src="${config.widgetUrl}/widget/widget.js" >
    </script>
  `

  const getAppsOffered = async () => {
    setLoading(true)
    const res = await API.getAppsOffered(userData.account.accountId, userData.token)
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to get apps offered.')
    }
    setConnectedApps(res.data)
    const currentApp = res.data?.find(a => a?.slug === app?.slug)
    if (currentApp) {
      setOfferedAppId(currentApp?.id)
      setSettings(currentApp?.settings)
    }
  }

  const createAppsOffered = async () => {
    setLoading(true)
    const res = await API.createAppsOffered(userData.account.accountId, userData.token, {
      slug: app?.slug,
      settings,
      status: 'active'
    })
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to create apps offered.')
    }
    history.replace('/apps')
  }

  const updateAppsOffered = async () => {
    setLoading(true)
    const res = await API.updateAppsOffered(userData.account.accountId, userData.token, {
      id: offeredAppId,
      slug: app?.slug,
      settings
    })
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to update apps offered.')
    }
    history.replace('/apps')
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code)
    setCodeCopied(true)
    setTimeout(() => {
      setCodeCopied(false)
    }, 3000)
  }

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(linkUrl)
    setLinkCopied(true)
    setTimeout(() => {
      setLinkCopied(false)
    }, 3000)
  }

  const renderRespectiveSettings = name => {
    switch (name) {
      case 'gmail':
      case 'google_sheets':
      case 'google_forms':
      case 'google_drive':
      case 'google_calendar':
      case 'bigquery':
        return <GoogleAppSettings app={app} settings={settings} setSettings={setSettings} />
      case 'slack':
        return <SlackAppSettings app={app} settings={settings} setSettings={setSettings} />
      case 'hubspot':
        return <HubspotAppSettings app={app} settings={settings} setSettings={setSettings} />
      case 'mailchimp':
        return <MailchimpAppSettings app={app} settings={settings} setSettings={setSettings} />
      case 'salesforce':
        return <SalesforceAppSettings app={app} settings={settings} setSettings={setSettings} />
      case 'shopify':
        return <ShopifyAppSettings app={app} settings={settings} setSettings={setSettings} />
      default:
        break
    }
  }

  const removeIntegrationHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await API.updateAppsOffered(userData.account.accountId, userData.token, {
      slug: app?.slug,
      status: 'archived'
    })
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to remove integration.')
    }
    getAppsOffered()
      .then( () => {
        showRemoveIntegrationModal( false )
        setOfferedAppId(undefined)
      })
  }

  useEffect(() => {
    if (app?.slug && userData?.account) {
      getAppsOffered()
    }
  }, [app?.slug, userData?.account])

  if (isLoading) {
    return (
      <div className="appSettingsPage">
        <div className="loader">
          <div className="spinner" />
          <div className="text">Loading, please wait</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className="appSettingsPage"
        onClick={e => {
          if (moreOptions && !e.target.className.includes('moreOptions')) {
            showMoreOptions(false)
          }
        }}
      >
        <div className="navigationArea">
          <div className="backIconContainer" onClick={() => history.push('/apps')}>
            <BackIcon color={ theme === 'dark' ? '#ffffff' : '#424451'}/>
          </div>
          <p className="navigationText" onClick={() => history.push('/apps')}>
            Apps Offered
          </p>
          <p className="navigationText">/</p>
          <p className="navigationText">{app?.name}</p>
          {offeredAppId ? (
            <button className="primaryButton" onClick={() => updateAppsOffered()}>
              Update Integration
            </button>
          ) : (
            <button className="primaryButton" onClick={() => createAppsOffered()}>
              Save Integration
            </button>
          )}
        </div>
        {errMsg && <p style={{ color: 'red' }}>{errMsg}</p>}
        <div className="header" style={{ backgroundColor: `${app?.primaryColor}` }}>
          <div className="left">
            <div className="whiteBackground" style={{ width: `${app?.slug === 'shipcalm' ? '120px' : '60px'}` }}>
              <img
                src={app?.icon}
                className="icon"
                style={{ width: `${app?.slug === 'shipcalm' ? '80px' : '40px'}` }}
                alt=""
              />
            </div>
            <div className="textArea">
              <div className="titleText">{app?.name}</div>
            </div>
          </div>
          {offeredAppId && (
            <div className="right" onClick={() => showMoreOptions(true)}>
              <img src={optionsIcon} className="icon moreOptions" alt="" />
              {moreOptions && (
                <div className="dropdownContainer">
                  <div
                    onClick={() => {
                      showMoreOptions(false)
                      showRemoveIntegrationModal(true)
                    }}
                    className="menuItem"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <img className="icon" src={removeIcon} alt="profile & settings" />
                    Remove Integration
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {renderRespectiveSettings(app?.slug)}

        <div className="optionsContainer">
          <h2 className="optionsTitle">Ways for your clients to integrate:</h2>
          <div className="options">
            <div className="option">
              <h3 className="title">Option 1. Embed on portal app/site</h3>
              <div className="subtitle">
                Copy and paste the code below into your app/site. Note that the userID and email fields must be
                populated with actual user data to track your users.
              </div>
              <CodeBox theme={theme} accountId={accountId} app={app} email={userEmail} />
              <div className="copyToClipboard" onClick={() => copyToClipboard()}>
                <CopyToClipBoard color={ theme === 'dark' ? "#81b9f9" : "#1f90ff" } />
                <div className="copyText">{isCodeCopied ? 'Copied' : 'Click here to copy'}</div>
              </div>
            </div>
            <div className="optionTwo">
              <div className="row">
                <h4 className="title">Widget Preview</h4>
                <button
                  onClick={() => {
                    window.open(
                      'https://docs.google.com/document/d/e/2PACX-1vQgfpXYY8GJovQR-PtgX6E9_kObkRyccQkgJZoAYJAc1_XVWviZ-5H74a5RjeOk5epoIazQCWulhQTU/pub'
                    )
                  }}
                >
                  Need Help?
                </button>
              </div>
              <div className="connectAppsCard">
                <div className="cardTitle">Connect Apps</div>
                <p className="cardSubtitle">
                  Powered by <span>ChampSelect.ai</span>
                </p>
                <div className="scrollableContainer">
                  <div className="buttonsContainer">
                    {offeredApps.filter(a => a.slug === app?.slug).length > 0
                      ? null : (
                      <div className="button">
                        <div className="left">
                          <img
                            src={app?.icon}
                            className="icon"
                            alt={app?.slug}
                            style={{ width: `${app?.slug === 'shipcalm' ? '80px' : '40px'}` }}
                          />
                          <div className="appName">{app?.name}</div>
                        </div>
                        <button className="buttonPreview">
                          Integrate
                          <img src={integrateButtonIcon} className="buttonIcon" />
                        </button>
                      </div>
                      )
                    }
                    {offeredApps.length > 0
                      ? offeredApps.map(app => (
                          <div className="button" key={app?.slug}>
                            <div className="left">
                              <img
                                src={app?.icon}
                                className="icon"
                                alt={app?.slug}
                                style={{ width: `${app?.slug === 'shipcalm' ? '80px' : '40px'}` }}
                              />
                              <div className="appName">{app?.name}</div>
                            </div>
                            <button className="buttonPreview">
                              Integrate
                              <img src={integrateButtonIcon} className="buttonIcon" />
                            </button>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="horizontalLine" />
          <div className="infoCard">
            <InfoIcon color={ theme === 'dark' ? "#f4edab" : "#e58e26"} />
            <p className="infoText">
              <span>How to identify your clients:</span> To identify your clients and tie them to their integrations and
              accounts, fill in the Email and User ID fields.
            </p>
          </div>
          <div className="options">
            <div className="option">
              <h3 className="title">Option 2. Generate a link</h3>
              <p className="whiteText">Dont have access to embed in your app/site?</p>
              <p className="subtitle">
                If you know who you are creating this integration for, then fill in their userId and/or email and simply
                hand them the following link.
              </p>
              <div className="column">
                <div className="label">Email (Required)</div>
                <input
                  className="input"
                  type="text"
                  placeholder="User Email"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className="column">
                <div className="label">User ID (Optional)</div>
                <input
                  className="input"
                  type="text"
                  placeholder="Enter User ID"
                  onChange={e => setUserId(e.target.value)}
                />
              </div>
            </div>
            <div className="outerPaddingContainer">
              <div className="preview">
                <h4 className="titleText">Link Preview</h4>
                <div className="innerCard">{linkUrl}</div>
                <div className="copyToClipboard" onClick={() => copyLinkToClipboard()}>
                  <CopyToClipBoard color={ theme === 'dark' ? "#81b9f9" : "#1f90ff" } />
                  <div className="copyText">{isLinkCopied ? 'Copied' : 'Click here to copy'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {removeIntegrationModal ? <div className="appOverlay" onClick={() => showRemoveIntegrationModal(false)} /> : null}
      {removeIntegrationModal ? (
        <div className="appModal">
          <div className="titleArea" style={{ backgroundColor: `${app?.primaryColor}` }}>
            <div className="left">
              <div className="whiteBackground">
                <img src={app?.icon} className="icon" alt="" />
              </div>
              <div className="textArea">
                <div className="titleText">{app?.name}</div>
                <div className="subtitleText">Remove {app?.name} as an intergration offered for your clients.</div>
              </div>
            </div>
            <div className="right" onClick={() => showRemoveIntegrationModal(false)}>
              <img src={closeIcon} className="icon" alt="" />
            </div>
          </div>
          <div className="contentArea">
            <p className="contentText">
              Are you sure you want to remove {app?.name} as a data integration option for your clients? This will also
              remove any workflows associated with it and might break active processes.
            </p>
          </div>
          <div className="buttonArea">
            <button className="callToAction remove" onClick={(e) => removeIntegrationHandler(e)}>Remove Integration</button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export { AppSettings }
