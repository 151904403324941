import React from 'react'

const CopyToClipBoard = ({ color }) => {
  return (
    <svg className='copyToClipboardIcon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9999 22.0002H3.99995C3.46402 22.0188 2.94442 21.8141 2.56524 21.4349C2.18605 21.0557 1.98132 20.5361 1.99995 20.0002V10.0002C1.98132 9.46427 2.18605 8.94467 2.56524 8.56548C2.94442 8.1863 3.46402 7.98157 3.99995 8.00019H7.99995V4.00019C7.98132 3.46427 8.18605 2.94467 8.56524 2.56548C8.94442 2.1863 9.46402 1.98157 9.99995 2.00019H19.9999C20.5359 1.98157 21.0555 2.1863 21.4347 2.56548C21.8138 2.94467 22.0186 3.46427 21.9999 4.00019V14.0002C22.0183 14.536 21.8134 15.0555 21.4343 15.4346C21.0552 15.8137 20.5358 16.0185 19.9999 16.0002H15.9999V20.0002C16.0183 20.536 15.8134 21.0555 15.4343 21.4346C15.0552 21.8137 14.5358 22.0185 13.9999 22.0002ZM3.99995 10.0002V20.0002H13.9999V16.0002H9.99995C9.46411 16.0185 8.94468 15.8137 8.56557 15.4346C8.18645 15.0555 7.98162 14.536 7.99995 14.0002V10.0002H3.99995ZM9.99995 4.00019V14.0002H19.9999V4.00019H9.99995Z" fill={ color } />
</svg>
  )
}

export default CopyToClipBoard