import moment from 'moment'
import React from 'react'
import CalendarIcon from '../../../assets/img2/calendar-dark.svg'

const ScheduledTrigger = ({ scheduledTrigger, setScheduledTrigger, setSelectedTrigger, isEditing }) => {
  const getTimeUnitText = () =>
    ({ minutes: 'Minute(s)', hourly: 'Hour(s)', daily: 'Day(s)', weekly: 'Week(s)' }[scheduledTrigger.timeUnit])

  return (
    <div className='trigger-container'>
      <div className="horizontalLine" />
      <div className='scheduled'>
        <label htmlFor='time-unit'>How often should this Workflow run?</label>
        {isEditing ? (
          <select
            id='time-unit'
            value={scheduledTrigger.timeUnit}
            onChange={e => setScheduledTrigger({ type: 'timeUnit', payload: e.target.value })}
          >
            <option value='minutes'>Minutes</option>
            <option value='hourly'>Hourly</option>
            <option value='daily'>Daily</option>
          </select>
        ) : (
          <div className="bigText">{scheduledTrigger.timeUnit}</div>
        )}
        <label htmlFor='time'>When should this Workflow run?</label>
        {isEditing ? (
          <div>
            Every
            <input
              type='number'
              placeholder='1'
              value={scheduledTrigger.frequency}
              onChange={e => setScheduledTrigger({ type: 'frequency', payload: e.target.value })}
            />{' '}
            {getTimeUnitText()} at
            <input
              type='time'
              value={scheduledTrigger.atTime}
              onChange={e => setScheduledTrigger({ type: 'atTime', payload: e.target.value })}
            />
            <select
              name='timezone'
              id='timezone'
              value={scheduledTrigger.timeZone}
              onChange={e => setScheduledTrigger({ type: 'timeZone', payload: e.target.value })}
            >
              {moment.tz.names().map(name => {
                return (
                  <option value={name} key={name}>
                    {name}
                  </option>
                )
              })}
            </select>
          </div>
        ) : (
          <div className="bigText">
            Every {scheduledTrigger.frequency} {getTimeUnitText()} at {scheduledTrigger.atTime} in
            {scheduledTrigger.timeZone} timezone
          </div>
        )}
        
      </div>
    </div>
  )
}

export { ScheduledTrigger }
