import React from 'react'

const AppsOfferedIcon = ({ color }) => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00008 25.2467C8.9947 26.8179 11.4609 27.6705 14.0001 27.6667C16.6421 27.6667 19.0734 26.7627 21.0001 25.2467M10.6667 5.49805C6.03408 6.92138 2.66675 11.234 2.66675 16.3334C2.66675 17.6167 2.88008 18.85 3.27341 20M17.3334 5.49805C21.9661 6.92138 25.3334 11.234 25.3334 16.3334C25.3334 17.6167 25.1201 18.85 24.7267 20" stroke={color} stroke-width="2.66667" />
      <path d="M26.6667 22.9999C26.6667 23.8946 26.3147 24.7066 25.7414 25.3053C25.206 25.8646 24.4925 26.2201 23.7236 26.3107C22.9546 26.4014 22.1781 26.2215 21.5273 25.802C20.8765 25.3824 20.392 24.7494 20.1571 24.0116C19.9222 23.2738 19.9515 22.4772 20.2399 21.7587C20.5284 21.0401 21.0579 20.4443 21.7378 20.0737C22.4176 19.7031 23.2052 19.5806 23.9655 19.7275C24.7257 19.8743 25.4111 20.2812 25.9041 20.8784C26.397 21.4755 26.6666 22.2256 26.6667 22.9999V22.9999ZM8.00004 22.9999C8.00004 23.8946 7.64804 24.7066 7.07471 25.3053C6.5393 25.8646 5.82587 26.2201 5.0569 26.3107C4.28792 26.4014 3.51138 26.2215 2.8606 25.802C2.20981 25.3824 1.72538 24.7494 1.49048 24.0116C1.25557 23.2738 1.28484 22.4772 1.57327 21.7587C1.86169 21.0401 2.39128 20.4443 3.0711 20.0737C3.75093 19.7031 4.53857 19.5806 5.29882 19.7275C6.05907 19.8743 6.74448 20.2812 7.23739 20.8784C7.7303 21.4755 7.99995 22.2256 8.00004 22.9999ZM17.3334 4.99992C17.3334 5.89458 16.9814 6.70658 16.408 7.30525C15.8726 7.8646 15.1592 8.22011 14.3902 8.31075C13.6213 8.40139 12.8447 8.22149 12.1939 7.80195C11.5431 7.38241 11.0587 6.7494 10.8238 6.0116C10.5889 5.2738 10.6182 4.47723 10.9066 3.75866C11.195 3.04009 11.7246 2.44434 12.4044 2.0737C13.0843 1.70306 13.8719 1.58065 14.6322 1.72748C15.3924 1.87431 16.0778 2.28123 16.5707 2.87837C17.0636 3.47551 17.3333 4.22562 17.3334 4.99992V4.99992Z" stroke={color} stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default AppsOfferedIcon