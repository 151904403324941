import React, { useState, useEffect } from 'react'
import { Col, Spinner, Table, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import Dropdown from 'react-multilevel-dropdown'
import { API } from '../../../api/API'
import googleLogo from '../Settings/googleLogo.svg'

const Sheets = ({ authObj, attendeeFields, guardianFields, additionalFields, destMappingId }) => {
  const history = useHistory()

  const [dataMap, setDataMap] = useState()
  const [isSheetsIntegrated, setIsSheetsIntegrated] = useState(false)
  const [sheets, setSheets] = useState(undefined)
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(undefined)
  const [selectedWorksheet, setSelectedWorksheet] = useState(undefined)
  const [sheet, setSheet] = useState(undefined)
  const [oauthUrl, setOauthUrl] = useState(undefined)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(undefined)

  const [loader, setLoader] = useState(false)

  const validateSheetsIntegration = async () => {
    setLoader(true)
    const res = await API.validateSheetsIntegration(authObj.account.accountId, authObj.token, search)
    if (res && res.data) {
      if (res.data.generatedUrl) {
        setIsSheetsIntegrated(false)
        setOauthUrl(res.data.generatedUrl)
      } else {
        setIsSheetsIntegrated(true)
        setSheets(res.data.files)
      }
    } else {
      setIsSheetsIntegrated(false)
      setSheets(undefined)
    }
    setLoader(false)
  }

  const getSheetById = async () => {
    setLoader(true)
    const res = await API.getSheetById(authObj.account.accountId, authObj.token, selectedSpreadsheet)
    if (res && res.data) {
      setSheet(res.data)
    } else {
      setSheet(undefined)
    }
    setLoader(false)
  }

  const getSheetFields = async () => {
    setLoader(true)
    const res = await API.getSheetFields(
      authObj.account.accountId,
      authObj.token,
      selectedSpreadsheet,
      selectedWorksheet
    )
    if (res && res.data) {
      setDataMap(res.data.map(d => [d, undefined]))
    } else {
      setDataMap(undefined)
    }
    setLoader(false)
  }

  const createMapping = async () => {
    setLoader(true)
    const spreadSheetName = sheets.find(s => s.id === selectedSpreadsheet).name
    const res = await API.createMapping(authObj.account.accountId, authObj.token, {
      title: `GSheet-${spreadSheetName}-${selectedWorksheet}`,
      destination: 'sheets',
      mapping: dataMap,
      spreadsheetId: selectedSpreadsheet,
      worksheetTitle: selectedWorksheet,
      status: 'active'
    })
    if (res && res.data) {
      history.push('/data-mappings')
    }
    setLoader(false)
  }

  const updateMapping = async () => {
    setLoader(true)
    const spreadSheetName = sheets.find(s => s.id === selectedSpreadsheet).name
    const res = await API.updateMapping(authObj.account.accountId, authObj.token, {
      destMappingId,
      title: `GSheet-${spreadSheetName}-${selectedWorksheet}`,
      destination: 'sheets',
      mapping: dataMap,
      spreadsheetId: selectedSpreadsheet,
      worksheetTitle: selectedWorksheet,
      status: 'active'
    })
    if (res && res.data) {
      history.push('/data-mappings')
    }
    setLoader(false)
  }

  const getMapping = async () => {
    const res = await API.getMappings(authObj.account.accountId, authObj.token, destMappingId)
    if (res && res.data && res.data.length > 0) {
      const { spreadsheetId, worksheetTitle, dataMap } = res.data[0]
      setSelectedSpreadsheet(spreadsheetId)
      setSelectedWorksheet(worksheetTitle)
      setDataMap(dataMap)
    }
  }

  const handleClick = key => value => {
    setDataMap(currDataMap => {
      const newDataMap = currDataMap.map(c => {
        if (c[0] === key) {
          c[1] = value
        }
        return c
      })
      return newDataMap
    })
    document.getElementById(`${key}_ddid`).click() // workaround for closing the dropdown
  }

  useEffect(() => {
    if (selectedWorksheet) {
      setDataMap(undefined)
      getSheetFields()
    }
  }, [selectedWorksheet])

  useEffect(() => {
    if (selectedSpreadsheet) {
      getSheetById()
    }
  }, [selectedSpreadsheet])

  useEffect(() => {
    if (search || search === '') {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        validateSheetsIntegration()
      }, 1000)
      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    if (destMappingId && destMappingId !== '') {
      getMapping()
    }
  }, [destMappingId])

  useEffect(() => {
    validateSheetsIntegration()
  }, [])

  return (
    <Col xs={12}>
      {!isSheetsIntegrated && oauthUrl && (
        <button
          className='btn'
          style={{ border: 'none', background: '#f2f2f2', borderRadius: '5px', padding: '5px' }}
          onClick={() => {
            const googleAuthWindow = window.open(oauthUrl, '', 'width=500,height=500')
            const checkWindowClose = setInterval(() => {
              if (googleAuthWindow.closed) {
                clearInterval(checkWindowClose)
                validateSheetsIntegration()
              }
            }, 500)
          }}
        >
          <img src={googleLogo} />
          Sign in with Google
        </button>
      )}
      {sheets && (
        <Row style={{ alignItems: 'flex-end' }}>
          <Col xs={12} md={2}>
            <input
              className='form-control'
              type='text'
              value={search}
              placeholder='Search sheet'
              onChange={e => {
                setSearch(e.target.value)
              }}
            />
          </Col>
          <Col xs={12} md={10}>
            <label htmlFor='selectSpreadsheet'>Select a Spreadsheet</label>
            <select
              id='selectSpreadsheet'
              className='form-control'
              onChange={e => setSelectedSpreadsheet(e.target.value)}
              value={selectedSpreadsheet}
            >
              <option>Select</option>
              {sheets.map(s => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      )}
      {sheet?.sheets && (
        <>
          <label htmlFor='selectWorksheet'>Select a Worksheet</label>
          <select
            id='selectWorksheet'
            className='form-control'
            onChange={e => setSelectedWorksheet(e.target.value)}
            value={selectedWorksheet}
          >
            <option>Select</option>
            {sheet.sheets.map(s => (
              <option key={s.properties.id} value={s.properties.title}>
                {s.properties.title}
              </option>
            ))}
          </select>
        </>
      )}
      <br />
      <br />
      {dataMap && (
        <Table bordered hover>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {dataMap.map(([key, value]) => {
              const handleClickWithField = handleClick(key)
              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    {value}{' '}
                    <Dropdown title='Select data' position='right' id={`${key}_ddid`}>
                      <Dropdown.Item>
                        Registration
                        <Dropdown.Submenu position='right'>
                          <Dropdown.Item onClick={() => handleClickWithField('reservation.id')}>Id</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleClickWithField('reservation.total')}>Total</Dropdown.Item>
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Transaction
                        <Dropdown.Submenu position='right'>
                          <Dropdown.Item onClick={() => handleClickWithField('transaction.id')}>Id</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleClickWithField('transaction.log')}>Log</Dropdown.Item>
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Slots
                        <Dropdown.Submenu position='right'>
                          <Dropdown.Item>
                            Program
                            <Dropdown.Submenu position='right'>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.program.title')}>
                                Title
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.program.status')}>
                                Status
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            Session
                            <Dropdown.Submenu position='right'>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.session.title')}>
                                Title
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.session.startTime')}>
                                Start time
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.session.endTime')}>
                                End time
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.session.price')}>
                                Price
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            Option
                            <Dropdown.Submenu position='right'>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.option.title')}>
                                Title
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.option.startTime')}>
                                Start time
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.option.endTime')}>
                                End time
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleClickWithField('slot.option.price')}>
                                Price
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            Attendee
                            <Dropdown.Submenu position='right'>
                              {attendeeFields.map(af => {
                                return (
                                  <Dropdown.Item
                                    key={af.id}
                                    onClick={() => handleClickWithField(`slot.attendee.${af.internalName}`)}
                                  >
                                    {af.displayName}
                                  </Dropdown.Item>
                                )
                              })}
                            </Dropdown.Submenu>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            Guardian
                            <Dropdown.Submenu position='right'>
                              {guardianFields.map(gf => {
                                return (
                                  <Dropdown.Item
                                    key={gf.id}
                                    onClick={() => handleClickWithField(`slot.guardian.${gf.internalName}`)}
                                  >
                                    {gf.displayName}
                                  </Dropdown.Item>
                                )
                              })}
                            </Dropdown.Submenu>
                          </Dropdown.Item>
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Discounts
                        <Dropdown.Submenu position='right'>
                          <Dropdown.Item onClick={() => handleClickWithField('discount.code')}>Code</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleClickWithField('discount.amount')}>Amount</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleClickWithField('discount.type')}>Type</Dropdown.Item>
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Additional Fields
                        <Dropdown.Submenu position='right'>
                          {additionalFields.map(af => {
                            return (
                              <Dropdown.Item
                                key={af.id}
                                onClick={() => handleClickWithField(`form.${af.internalName}`)}
                              >
                                {af.displayName}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                    </Dropdown>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
      {loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner animation='border' variant='primary' />
        </div>
      )}
      {dataMap && (
        <div>
          {destMappingId
            ? (
              <button className='btn btn-primary float-right' onClick={() => updateMapping()}>
                Update
              </button>
              )
            : (
              <button className='btn btn-primary float-right' onClick={() => createMapping()}>
                Create
              </button>
              )}
        </div>
      )}
    </Col>
  )
}

export { Sheets }
