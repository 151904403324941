import React from 'react'

const Calendar = ({ backgroundColor, iconColor }) => {
  return (
    <svg className='triggerIcon' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="6.4" fill={backgroundColor} />
      <path d="M23.7267 36.0003H14.6667C13.9594 36.0003 13.2811 35.7194 12.781 35.2193C12.281 34.7192 12 34.0409 12 33.3337V17.3337C12 16.6264 12.281 15.9481 12.781 15.448C13.2811 14.9479 13.9594 14.667 14.6667 14.667H30.6667C31.3739 14.667 32.0522 14.9479 32.5523 15.448C33.0524 15.9481 33.3333 16.6264 33.3333 17.3337V22.667" stroke={iconColor} stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M31.9998 37.3337C34.9454 37.3337 37.3332 34.9458 37.3332 32.0003C37.3332 29.0548 34.9454 26.667 31.9998 26.667C29.0543 26.667 26.6665 29.0548 26.6665 32.0003C26.6665 34.9458 29.0543 37.3337 31.9998 37.3337Z" stroke={iconColor}  stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28 12V17.3333M17.3333 12V17.3333M12 22.6667H33.3333M32 29.9947V32L33.3333 33.3333" stroke={iconColor} stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Calendar
