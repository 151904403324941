import React from 'react'

const ConsultMethods = ({ icon, type, subtitle, consultType, setConsultType }) => {
  return (
    <div className={`consultType ${consultType === type ? 'selectedConsultType' : ''}`} onClick={() => setConsultType(type)} >
      <img src={icon} className='icon' />
      <div className='type'>{type}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  )
}

export default ConsultMethods