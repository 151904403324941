import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import pageLeft from '../../../assets/icons/pageLeft.svg'
import pageRight from '../../../assets/icons/pageRight.svg'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { loadBillingDetails } from '../../../services/billing.services'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import filterByDate from '../../../assets/icons/filterByDate.svg'

const Billing = () => {
  const { isLoading, transactionDetails } = useSelector(state => state.billing)
  const { userData } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [before, setBefore] = useState(undefined)
  const [after, setAfter] = useState(undefined)
  const [filterDates, setFilterDates] = useState(null)
  const [dateFilter, showDateFilter] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const viewItemsCount = [10, 20, 30, 90]

  const formattedDate = timeStamp => {
    if (!timeStamp) return ''
    const date = new Date(timeStamp * 1000)
    return `${month[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  const getPageSize = viewItemsCount.map((t, i) => {
    return (
      <option key={t} value={t}>
        {t}
      </option>
    )
  })

  const onSelectFilterDate = dates => { 
    setFilterDates(dates)
  }

  const filterClickHandler = () => { 
    const startDate = new Date(filterDates?.start)
    const endDate = new Date(filterDates?.end)
    
    setStartDate(`${month[startDate.getMonth()]} ${startDate.getDate()}, ${startDate.getFullYear()}`)
    setEndDate(`${month[endDate.getMonth()]} ${endDate.getDate()}, ${endDate.getFullYear()}`)

    showDateFilter(false)
  }

  useEffect(() => {
    if (userData) {
      dispatch(loadBillingDetails(userData.account.accountId, userData.token, pageSize, after, before))
    }
  }, [before, after, pageSize, userData])

  return (
    <>
      <div className="billingPage">
        <PageTitle title="Billing" subtitle="See your transaction history" />
        <div className="billingHistoryContainer">
          <div className="filterByDateArea">
            <div className="filterInputArea" onClick={() => showDateFilter(true)}>
              Filter Transaction by Date
              <img src={filterByDate} alt="" className="icon" />
            </div>
            {(startDate && endDate) ? (
              <div className="filteredDataArea">
                <div className='dateText'>
                  From: <span>{startDate}</span>
                </div>
                <div className='dateText'>
                  To: <span>{endDate}</span>
                </div>
                <button
                  className='secondaryBorderedButton'
                  style={{ marginTop: '14px', width: '150px' }}
                  onClick={() => { 
                    setFilterDates(null)
                    setStartDate('')
                    setEndDate('')
                  }}
                >
                  Clear Filter
                </button>
              </div>
            ) : null}

          </div>
          {dateFilter && (
            <div className="dateFilterArea">
              <DateRangePicker
                onSelect={onSelectFilterDate}
                value={filterDates}
                maximumDate={new Date()}
              />
              <button
                className="primaryButton"
                style={{ margin: '20px' }}
                onClick={() => filterClickHandler()}
              >
                Filter
              </button>
            </div>
          )}
          <div className={`tableContainer ${isLoading && 'loadingTable'}`}>
            {isLoading && (
              <div className="loader">
                <div className="spinner" />
              </div>
            )}
            <div className="tableHeading">
              <div className="head one">Transaction Id</div>
              <div className="head two">Amount</div>
              <div className="head three">Date & Time</div>
            </div>
            <div className="tableBody">
              {transactionDetails?.data?.length > 0 ? (
                transactionDetails.data.map(t => (
                  <div key={t?.id} className="tableRow">
                    <div className="row one">{t?.id || ''}</div>
                    <div className="row two">$
                      {t?.total < 0
                      ? `- ${Math.abs((t?.total / 100).toFixed(2))}`
                      : `${(t?.total / 100).toFixed(2) || '0'}`}
                    </div>
                    <div className="row three">{formattedDate(t?.created)}</div>
                  </div>
                ))
              ) : (
                <div className="emptyContainer">
                  <div className="emptyText">{isLoading ? "Please wait while loading..." : "You don't have transaction history."}</div>
                </div>
              )}
            </div>
          </div>
          {transactionDetails && (
            <div className="filterAndPaginationContainer">
              <div className="filter">
                <p>View items by:</p>
                <div className="select">
                  <select
                  className=""
                    value={pageSize}
                    name="pageSize"
                    onChange={e => setPageSize(Number(e.target.value))}
                  >
                    {getPageSize}
                  </select>
                </div>
              </div>
              <div className="pagination">
                {page > 1 && !isLoading && (
                  <img 
                    src={pageLeft} 
                    className="leftArrow" 
                    alt="previous page" 
                    onClick={() => {
                      setPage(page => page - 1)
                      setBefore(transactionDetails.data?.[0].id)
                      setAfter(undefined)
                    }}
                  />
                )}
                {page > 1 && !isLoading && (
                  <div
                    className="previousPageNumber"
                    onClick={() => {
                      setPage(page => page - 1)
                      setBefore(transactionDetails.data?.[0].id)
                      setAfter(undefined)
                    }}
                  >
                    {page - 1}
                  </div>
                )}
                <div className="pageNumber">{page}</div>
                {(before || transactionDetails?.has_more) && !isLoading && (
                  <div
                    className="nextPageNumber"
                    onClick={() => { 
                      setPage(page => page + 1)
                      setAfter(transactionDetails.data?.[transactionDetails.data.length - 1].id)
                      setBefore(undefined)
                    }}
                  >
                    {page + 1}
                  </div>
                )}
                {(before || transactionDetails?.has_more) && !isLoading && (
                  <img
                    src={pageRight}
                    className="rightArrow"
                    alt="next page"
                    onClick={() => {
                      setPage(page => page + 1)
                      setAfter(transactionDetails.data?.[transactionDetails.data.length - 1].id)
                      setBefore(undefined)
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {dateFilter && <div className="backdrop" onClick={() => showDateFilter(false)} />}
    </>
  )
}

export { Billing }
