import React from 'react'

const SubmitEditing = ({ theme }) => {
  return (
    <div className='submit'>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.52495 17.6568L4.57495 12.7068L5.98895 11.2928L9.52645 14.8263L9.52495 14.8278L18.01 6.34277L19.424 7.75677L10.939 16.2428L9.52595 17.6558L9.52495 17.6568Z" fill={theme === 'dark' ? "#81b9f9" : "#1F90FF"}/>
      </svg>
    </div>
  )
}

export default SubmitEditing