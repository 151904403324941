import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

const PodsoftRouter = ({ authObj }) => {
  const history = useHistory()

  useEffect(() => {
    if (authObj && authObj.account) {
      if (authObj.account.planTitle === 'mafia_city_club') {
        history.push('mc-accounts')
      } else {
        history.push('orders')
      }
    }
  }, [authObj])

  return <div>Securely processing your request...</div>
}

export { PodsoftRouter }
