import moment from 'moment'
import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
  InputGroup,
  InputGroupAddon
} from 'reactstrap'

// RuleModal should take care of one Rule for a particular day
const RuleModal = ({
  authObj,
  modalOpen,
  setModalOpen,
  // function that returns the rule when it's changed
  onRuleChange,
  // available users coming from the parent
  availableUsers,
  // date of the rule in a moment object
  ruleDate,
  // the initial rule to initialize the modal if one already exists
  initialRule
}) => {
  // @todo use loader
  const [loader] = useState(false)

  // fields
  const [search, setSearch] = useState('')
  /*
  Fields in rules
  {
    accountId: uuid, // fill by parent
    locationId: uuid, // fill by parent
    type: string = wday or day
    wday: string = "mon,tue,wed,thu,fri",
    date: string = "1980-06-17",
    status: string,
    userIds: array[uuid],
    intervals: array[string] - i.e. ["12:00,1:00", "HH:mm,HH:mm"],
    repeats: array[string] - i.e. ["every1Week", "every2Weeks"]
  }
   */
  const [rule, setRule] = useState(
    initialRule || {
      userIds: [],
      intervals: [],
      repeats: []
    }
  )

  const [errMsg, setErrMsg] = useState('')

  // set intervals, wdays and repeats on rules when we submit
  const [intervals, setIntervals] = useState([{ startTime: '', endTime: '' }])
  const [repeats, setRepeats] = useState([])
  const [wdays, setWdays] = useState([])

  const availableRepeats = [
    'every1stWeek',
    'every2ndWeek',
    'every3rdWeek',
    'every4thWeek',
    'every5thWeek',
    'every2Weeks',
    'every3Weeks',
    'every4Weeks'
  ]
  const availableWdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

  const [repeatString, setRepeatString] = useState('')

  const addInterval = () => {
    setIntervals([...intervals, { startTime: '', endTime: '' }])
  }

  const removeInterval = index => {
    setIntervals(intervals.filter((_, i) => i !== index))
  }

  const updateStartTime = (e, index) => {
    const newIntervals = intervals.map((interval, i) => {
      if (i !== index) return interval
      interval.startTime = e.target.value
      return interval
    })
    setIntervals(newIntervals)
  }

  const updateEndTime = (e, index) => {
    const newIntervals = intervals.map((interval, i) => {
      if (i !== index) return interval
      interval.endTime = e.target.value
      return interval
    })
    setIntervals(newIntervals)
  }

  // helper method for rules
  const setUserIds = userIds => {
    setRule({
      ...rule,
      userIds
    })
  }

  const toggleUser = (e, userId) => {
    if (e.target.checked) {
      setUserIds([...rule.userIds, userId])
    } else {
      setUserIds(rule.userIds.filter(u => u !== userId))
    }
  }

  const toggleRepeats = repeat => {
    let tempRepeats = repeats
    const repeatPos = tempRepeats.indexOf(repeat)
    // if checked, uncheck it
    if (repeatPos > -1) {
      tempRepeats.splice(repeatPos, 1)
    } else {
      if (availableRepeats.indexOf(repeat) >= 5) {
        tempRepeats = [repeat]
      } else {
        tempRepeats.push(repeat)
        // remove everything at and over index 5
        tempRepeats = tempRepeats.filter(t => {
          return availableRepeats.indexOf(t) < 5
        })
      }
    }
    setRepeats([...tempRepeats])
  }

  const toggleDays = day => {
    const dayPos = wdays.indexOf(day)
    if (dayPos > -1) {
      wdays.splice(dayPos, 1)
    } else {
      wdays.push(day)
    }
    setWdays([...wdays])
  }

  // time for display only
  const formatTime = timestr => {
    return moment(timestr, 'LT').format('hh:mm a')
  }

  // wrapper for on rule change to make the right conversions
  const processOnRuleChange = type => {
    rule.type = type
    rule.date = moment(ruleDate).format('YYYY-MM-DD')
    // if repeats
    if (rule.type === 'wday') {
      rule.wday = wdays.join(',')
      rule.repeats = repeats
    }
    rule.status = 'active'

    // format intervals
    const formattedIntervals = []
    intervals.forEach(i => {
      if (i.startTime && i.endTime) {
        formattedIntervals.push(
          `${moment(i.startTime, 'LT').format('HH:mm')},${moment(i.endTime, 'LT').format('HH:mm')}`
        )
      }
    })
    rule.intervals = formattedIntervals

    setRule({ ...rule })
    onRuleChange(rule)
  }

  useEffect(() => {
    setErrMsg('')
    if (initialRule) {
      if (initialRule.intervals) {
        setIntervals(
          initialRule.intervals.map(i => ({
            startTime: i.split(',')[0],
            endTime: i.split(',')[1]
          }))
        )
      }
      if (initialRule.userIds) {
        setUserIds(initialRule.userIds)
      }
      if (initialRule.repeats) {
        setRepeats(initialRule.repeats)
      }
      if (initialRule.wday) {
        setWdays(initialRule.wday.split(','))
      }
      return
    }
    setRule({
      userIds: [],
      intervals: [],
      repeats: []
    })
    setIntervals([{ startTime: '', endTime: '' }])
  }, [ruleDate, initialRule])

  useEffect(() => {
    let newRepeatString = ''
    availableRepeats.forEach(a => {
      if (repeats.indexOf(a) > -1) {
        newRepeatString += a + ', '
      }
    })

    let newDayString = ''
    availableWdays.forEach(a => {
      if (wdays.indexOf(a) > -1) {
        newDayString += a + ', '
      }
    })

    if (newDayString.length > 0 && newRepeatString.length > 0) setRepeatString(newRepeatString + ' ' + newDayString)
    else setRepeatString('')
  }, [repeats, wdays])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
      size='lg'
    >
      <ModalHeader>Edit Event Availability ({moment(ruleDate).format('YYYY-MM-DD')})</ModalHeader>
      {(loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner color='primary' />
        </div>
      )) || (
        <ModalBody>
          <Row form style={{ padding: '15px', border: '1px solid' }}>
            {intervals.map((interval, index) => (
              <React.Fragment key={index}>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label for='startTime'>
                      <strong>From</strong>
                    </Label>
                    <Input
                      type='text'
                      value={interval.startTime}
                      onChange={e => updateStartTime(e, index)}
                      onBlur={e => {
                        e.target.value = formatTime(e.target.value)
                        updateStartTime(e, index)
                      }}
                      placeholder='HH:MM'
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label for='endTime'>
                      <strong>To</strong>
                    </Label>
                    <Input
                      type='text'
                      onChange={e => updateEndTime(e, index)}
                      onBlur={e => {
                        e.target.value = formatTime(e.target.value)
                        updateEndTime(e, index)
                      }}
                      value={interval.endTime}
                      placeholder='HH:MM'
                    />
                  </FormGroup>
                </Col>
                <div style={{ width: '100%' }}>
                  <Button
                    style={{ float: 'right' }}
                    size='sm'
                    color='danger'
                    onClick={() => {
                      removeInterval(index)
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </React.Fragment>
            ))}
            <Col sm={12}>
              <div>
                <Button size='sm' color='success' onClick={addInterval}>
                  + New rule
                </Button>
              </div>
              <div>
                <Button
                  size='sm'
                  color='primary'
                  style={{ marginTop: '25px' }}
                  onClick={() => {
                    setIntervals([{ startTime: '', endTime: '' }])
                  }}
                >
                  Unavailable today
                </Button>
              </div>
            </Col>
            <Col xs={12} md={12} style={{ padding: '20px' }}>
              <section className='box' style={{ padding: '30px' }}>
                <h4>Team Members</h4>
                <p>Select who is involved this day or series of days</p>
                <InputGroup xs={12} style={{ marginBottom: '20px' }}>
                  <Input
                    placeholder='Find Team Members...'
                    value={search}
                    onChange={e => setSearch(e.target.value.toLowerCase())}
                  />
                  <InputGroupAddon addonType='append'>
                    <Button size='xs' color='success'>
                      {' '}
                      Search{' '}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                  {availableUsers.map((p, i) => {
                    const ind = rule.userIds.findIndex(u => u === p.id)
                    const isChecked = ind >= 0
                    if (
                      search === '' ||
                      (p.firstName && p.firstName.toLowerCase().includes(search)) ||
                      (p.lastName && p.lastName.toLowerCase().includes(search)) ||
                      (p.role && p.role.toLowerCase().includes(search))
                    ) {
                      return (
                        <div key={p.id}>
                          <Label check htmlFor={`users_${p.id}`}>
                            <Input
                              style={{ margin: 0 }}
                              type='checkbox'
                              id={`users_${p.id}`}
                              name={`users_${p.id}`}
                              checked={isChecked}
                              onChange={e => toggleUser(e, p.id)}
                            />{' '}
                            {p.firstName || ''} {p.lastName || ''} -{' ' + p.role || ''}
                          </Label>
                        </div>
                      )
                    } else return null
                  })}
                </div>
              </section>
            </Col>
            <Col xs={12}>
              <Button
                size='sm'
                color='success'
                style={{ width: '100%' }}
                onClick={() => {
                  processOnRuleChange('day')
                  setModalOpen(false)
                }}
              >
                Apply to {moment(ruleDate).format('YYYY-MM-DD')} only
              </Button>
              {availableRepeats.map(repeat => {
                // let isChecked = repeats.indexOf(i) >= 0
                return (
                  <Label key={repeat} xs={12} sm={3} m={3} htmlFor={`repeat_${repeat}`} style={{ margin: '10px' }}>
                    <Input
                      type='checkbox'
                      name={`repeat_${repeat}`}
                      checked={repeats.indexOf(repeat) > -1}
                      onChange={() => toggleRepeats(repeat)}
                    />
                    {repeat}
                  </Label>
                )
              })}
              <div style={{ borderTop: '1px solid #ccc ' }} />
            </Col>
            <Col xs={12}>
              {availableWdays.map(day => {
                return (
                  <Label key={day} xs={12} sm={3} m={3} htmlFor={`wdays_${day}`} style={{ margin: '10px' }}>
                    <Input
                      type='checkbox'
                      name={`wdays_${day}`}
                      checked={wdays.indexOf(day) > -1}
                      onChange={() => toggleDays(day)}
                    />
                    {day}
                  </Label>
                )
              })}
              {repeatString.length === 0
                ? (
                  <h5>
                    <center>Select a week to enable repeat</center>
                  </h5>
                  )
                : (
                  <Button
                    style={{ width: '100%' }}
                    color='success'
                    size='sm'
                    onClick={() => {
                      processOnRuleChange('wday')
                      setModalOpen(false)
                    }}
                  >
                    Repeat for {repeatString}
                  </Button>
                  )}
            </Col>
          </Row>
        </ModalBody>
      )}

      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>
        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { RuleModal }
