import { setLoading, setOrders } from '../redux/slice/order.slice'
import { API } from '../api/API'

export const getOrders =
  (accountId, token, loading = false) =>
  async dispatch => {
    try {
      loading && dispatch(setLoading(true))
      const res = await API.getOrders(accountId, token)
      if (res?.data) {
        dispatch(setOrders(res?.data))
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

export const createOrder =
  (accountId, token, body, loading = false) =>
  async dispatch => {
    try {
      loading && dispatch(setLoading(true))
      const res = await API.createOrder(accountId, token, body)
      if (res?.data?.id) {
        dispatch(getOrders(accountId, token, true))
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
export const updateOrder =
  (accountId, token, body, loading = false) =>
  async dispatch => {
    try {
      loading && dispatch(setLoading(true))
      const res = await API.updateOrder(accountId, token, body)
      if (res?.data?.msg === 'success') {
        dispatch(getOrders(accountId, token, false))
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
