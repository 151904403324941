import moment from 'moment'
import React, { useState, useEffect } from 'react'
// used to hook datatables into jquery
// eslint-disable-next-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Button } from 'reactstrap'

import './Calendar.scss'
import { CalendarHelper } from './CalendarHelper'

// monthYearChangeFunc takes a func that will return the new year and month when clicked
// dayClickedFunc will return the dateStr of the day clicked in format YYYY-MM-DD

// events is a map of events based on days in the format YYYY-MM-DD
// it has an array of events for that day [{title, onClickFunc}]
const Calendar = ({
  month,
  year,
  monthYearChangeFunc,
  dayClickedFunc,
  events,
  isCalendarEvent,
  getLocations,
  goToReservations
}) => {
  const [currentMonthYear, setCurrentMonthYear] = useState(
    moment()
      .year(year || moment().year())
      .month(month || moment().month())
  )

  // first date we are going to start dealing with
  const [indexDate, setIndexDate] = useState()

  const [errMsg] = useState('')

  const [calendarEvents, setCalendarEvents] = useState(events || {})

  const getWeeks = () => {
    return (
      <>
        {getWeek(0)}
        {getWeek(1)}
        {getWeek(2)}
        {getWeek(3)}
        {getWeek(4)}
      </>
    )
  }

  const getWeek = weekNumber => {
    return (
      <tr style={{ display: 'flex', justifyContent: 'space-around' }}>
        {getDay(weekNumber, 0)}
        {getDay(weekNumber, 1)}
        {getDay(weekNumber, 2)}
        {getDay(weekNumber, 3)}
        {getDay(weekNumber, 4)}
        {getDay(weekNumber, 5)}
        {getDay(weekNumber, 6)}
      </tr>
    )
  }

  const getDay = (weekNumber, dayNumber) => {
    const dateStr = moment(indexDate).add(weekNumber, 'weeks').add(dayNumber, 'days').format('YYYY-MM-DD')

    // array to render/display events
    const disp = []

    // helper function to match day
    const isMatchingDay = w => {
      return (
        moment(dateStr) > moment() &&
        ((w === 'sun' && dayNumber === 0) ||
          (w === 'mon' && dayNumber === 1) ||
          (w === 'tue' && dayNumber === 2) ||
          (w === 'wed' && dayNumber === 3) ||
          (w === 'thu' && dayNumber === 4) ||
          (w === 'fri' && dayNumber === 5) ||
          (w === 'sat' && dayNumber === 6))
      )
    }

    // helper function to find repeat w.r.t weekday
    const findMatchingWeekDay = (e, i) => {
      e.wday.split(',').forEach((w, j) => {
        if (isMatchingDay(w) && disp.findIndex(d => d.ruleId === e.ruleId) === -1) disp.push(e)
      })
    }

    // helper function to find repeat w.r.t after every "n" weeks
    const findMatchingWeekDayAfterEvery = (e, i, date, weekGap) => {
      const ruleRepeatWeekNumber = moment(date, 'YYYY-MM-DD').week()
      const currentDayWeekNumber = moment(dateStr, 'YYYY-MM-DD').week()
      const difference = currentDayWeekNumber - ruleRepeatWeekNumber
      e.wday.split(',').forEach((w, j) => {
        if (difference % weekGap === 0 && isMatchingDay(w) && disp.findIndex(d => d.ruleId === e.ruleId) === -1) {
          disp.push(e)
        }
      })
    }

    // helper function to find matching repeat
    // e -> event, r -> repeat, i -> repeat interation
    const findMatchingRepeat = (e, r, i, date) => {
      if (r === 'every2Weeks') findMatchingWeekDayAfterEvery(e, i, date, 2)
      else if (r === 'every3Weeks') findMatchingWeekDayAfterEvery(e, i, date, 3)
      else if (r === 'every4Weeks') findMatchingWeekDayAfterEvery(e, i, date, 4)
      else {
        const week = CalendarHelper.getWeekOfMonth(
          moment(dateStr, 'YYYY-MM-DD'),
          moment(dateStr, 'YYYY-MM-DD').format('ddd').toLowerCase()
        )
        let weekStr
        if (week === 1) weekStr = 'every1stWeek'
        else if (week === 2) weekStr = 'every2ndWeek'
        else if (week === 3) weekStr = 'every3rdWeek'
        else if (week === 4) weekStr = 'every4thWeek'
        else if (week === 5) weekStr = 'every5thWeek'
        if (weekStr === r) findMatchingWeekDay(e, i)
      }
    }

    // checking the event type
    if (calendarEvents && calendarEvents[dateStr] && calendarEvents[dateStr].findIndex(r => r.type === 'day') > -1) {
      calendarEvents[dateStr].forEach((e, i) => {
        if (e.type === 'day') {
          disp.push(e)
        }
      })
    } else {
      // looping to find a matching repeat for this day
      for (const date in calendarEvents) {
        const event = calendarEvents[date]
        event.forEach(e => {
          if (e.type === 'wday') {
            e.repeats.forEach((r, i) => {
              findMatchingRepeat(e, r, i, date)
            })
          }
        })
      }
    }

    return (
      <td className='dayBox'>
        <button
          id={'date_' + dateStr}
          className='calendar-box'
          onClick={e => {
            e.preventDefault()
            if (dayClickedFunc) dayClickedFunc(dateStr)
          }}
        >
          <div className='dayStr' id={'date_content_' + dateStr} style={{ width: '100%' }}>
            {moment(dateStr).date()}
            {disp.length > 0 &&
              (isCalendarEvent
                ? (
                    disp.length === 1
                      ? (
                        <Button
                          size='sm'
                          style={{ display: 'block', width: '100%', overflow: 'hidden', maxHeight: '30px' }}
                          onClick={() => goToReservations(disp[0].locationId, disp[0].ruleId, dateStr)}
                        >
                          {disp[0].title}
                        </Button>
                        )
                      : (
                        <UncontrolledDropdown size='sm' style={{ display: 'block', width: '100%' }}>
                          <DropdownToggle caret>Events</DropdownToggle>
                          <DropdownMenu>
                            {disp.map((d, i) => (
                              <DropdownItem key={i} onClick={() => goToReservations(d.locationId, d.ruleId, dateStr)}>
                                {d.title}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        )
                  )
                : (
                    disp.map((d, i) => <div key={i}>{d.title}</div>)
                  ))}
          </div>
        </button>
      </td>
    )
  }

  useEffect(() => {
    if (currentMonthYear) {
      // the first week of the month --day
      const firstDay = moment(currentMonthYear).startOf('month').startOf('week').startOf('day')
      const endDay = moment(currentMonthYear).endOf('month').endOf('week').endOf('day')

      const firstDate = moment()
        .month(firstDay.month())
        .year(firstDay.year())
        .date(firstDay.date())
        .format('YYYY-MM-DD')
      const endDate = moment().month(endDay.month()).year(endDay.year()).date(endDay.date()).format('YYYY-MM-DD')
      if (getLocations) getLocations(firstDate, endDate)

      const indexDate = moment(firstDate)
      setIndexDate(indexDate)

      if (monthYearChangeFunc) {
        monthYearChangeFunc(
          moment(currentMonthYear).month(),
          moment(currentMonthYear).year(),
          moment(firstDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD')
        )
      }
    }
  }, [currentMonthYear])

  useEffect(() => {
    if (events && events.length > 0) {
      getWeeks()
    }
  }, [events])

  useEffect(() => {
    if (events) setCalendarEvents(events)
  }, [events])

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        <Col xs={12} md={12}>
          <div className='col-md-12'>
            <div className='col-md-12 row'>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <div className='calendarHeader'>
                <button
                  onClick={e => {
                    e.preventDefault()
                    setCurrentMonthYear(moment(currentMonthYear).subtract(1, 'month'))
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <div className='font-weight-bold'>
                  {moment(currentMonthYear).format('MMMM')} {moment(currentMonthYear).year()}
                </div>
                <button
                  onClick={e => {
                    e.preventDefault()
                    setCurrentMonthYear(moment(currentMonthYear).add(1, 'month'))
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
            <table id='edbridge-calendar' style={{ display: 'flex', flexDirection: 'column' }}>
              <thead>
                <tr style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <th className='calendar-header-box'>Sun</th>
                  <th className='calendar-header-box'>Mon</th>
                  <th className='calendar-header-box'>Tue</th>
                  <th className='calendar-header-box'>Wed</th>
                  <th className='calendar-header-box'>Thu</th>
                  <th className='calendar-header-box'>Fri</th>
                  <th className='calendar-header-box'>Sat</th>
                </tr>
              </thead>
              <tbody>{getWeeks()}</tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export { Calendar }
