import moment from 'moment-timezone'
import { nanoid } from 'nanoid'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import DatePicker from 'react-datepicker'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap'

import { Option } from './Option'

const EditSessionModal = ({
  authObj,
  sessionId,
  programId,
  programSessionId,
  modalOpen,
  setModalOpen,
  programView,
  refreshTable
}) => {
  const [loader, setLoader] = useState(true)

  // fields
  const [title, setTitle] = useState('')
  const [price, setPrice] = useState(undefined)
  const [startTime, setStartTime] = useState(undefined)
  const [endTime, setEndTime] = useState(undefined)
  const [hideDate, setHideDate] = useState(false)
  const [hideTime, setHideTime] = useState(false)
  const [status, setStatus] = useState('active')
  const [defaultHours, setDefaultHours] = useState(undefined)
  const [extraCharge, setExtraCharge] = useState(undefined)
  const [withinDays, setWithinDays] = useState(undefined)

  // options
  const [options, setOptions] = useState([])
  const [maxAttendees, setMaxAttendees] = useState('')
  const [dateTimeToggle, setDateTimeToggle] = useState(false)
  const [dateTimeMode, setDateTimeMode] = useState('datetime')

  const [errMsg, setErrMsg] = useState('')
  const showFields = programView === 'calendar'

  const setTimeZone = date => {
    const dateWithoutZone = moment.tz(date, 'America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss.SSS')
    const timeZone = moment(dateWithoutZone).format('Z')
    const dateWithTimezone = `${dateWithoutZone}${timeZone}`

    return new Date(dateWithTimezone)
  }

  const editSession = async () => {
    setErrMsg('Updating Session...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const updateObj = {
        programId,
        programSessionId,
        title,
        status
      }

      if (price) {
        updateObj.price = price
      }

      if (defaultHours) {
        updateObj.defaultHours = Number(defaultHours)
      }

      if (extraCharge) {
        updateObj.extraCharge = Number(extraCharge)
      }

      if (withinDays) {
        updateObj.withinDays = Number(withinDays)
      }
      updateObj.startTime = startTime ? moment(startTime).tz('America/Los_Angeles').unix() : null
      updateObj.endTime = endTime ? moment(endTime).tz('America/Los_Angeles').unix() : null
      if (maxAttendees) {
        updateObj.maxAttendees = Number(maxAttendees)
      } else {
        updateObj.maxAttendees = null
      }
      updateObj.hideDate = dateTimeToggle ? dateTimeMode === 'time' : true
      updateObj.hideTime = dateTimeToggle ? dateTimeMode === 'date' : true

      const optionsToSubmit = []
      if (options && options.length > 0) {
        // only attach options that have a title
        options.forEach((o, i) => {
          if (o && o.title) {
            optionsToSubmit.push({
              id: o.id || undefined,
              title: o.title,
              status: o.status,
              price: o.price || undefined,
              startTime: o.startTime || undefined,
              endTime: o.endTime || undefined,
              order: i,
              action: o.action
            })
          }
        })
      }
      updateObj.options = optionsToSubmit

      const res = await axios.put(`${config.apiUrl}/app/programs/sessions`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Session Updated Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to update session (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update session (2)')
    }
  }

  const getSession = async () => {
    setErrMsg('Fetching Session...')
    try {
      const res = await axios.get(
        `${config.apiUrl}/app/programs/sessions/by-id?programSessionId=${sessionId}&options=true`,
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res && res.data) {
        setErrMsg('')

        setTitle(res.data.title)
        setStatus(res.data.status)
        if (res.data.price) {
          setPrice(res.data.price)
        }

        if (res.data.defaultHours) {
          setDefaultHours(res.data.defaultHours)
        }

        if (res.data.extraCharge) {
          setExtraCharge(res.data.extraCharge)
        }

        if (res.data.withinDays) {
          setWithinDays(res.data.withinDays)
        }

        if (res.data.startTime) {
          setStartTime(moment.unix(res.data.startTime).tz('America/Los_Angeles').toDate())
        }
        if (res.data.endTime) {
          setEndTime(moment.unix(res.data.endTime).tz('America/Los_Angeles').toDate())
        }
        if (res.data.maxAttendees) {
          setMaxAttendees(res.data.maxAttendees)
        }
        setHideDate(res.data.hideDate || false)
        setHideTime(res.data.hideTime || false)

        // options
        if (res.data.options && res.data.options.length > 0) {
          setOptions(res.data.options)
        } else {
          setOptions([])
        }

        setLoader(false)
      } else {
        setErrMsg('Unable to fetch session (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch session (2)')
    }
  }

  const reorder = (targetId, sourceId) => {
    if (targetId === sourceId) {
      return
    }
    setOptions(currOptions => {
      const sourceInd = currOptions.findIndex(o => o.id === sourceId)
      if (sourceInd === -1) {
        return currOptions
      }
      const source = currOptions[sourceInd]
      const newOptions = currOptions.filter(o => o.id !== sourceId)
      const targetInd = newOptions.findIndex(o => o.id === targetId)
      if (targetInd === -1) {
        return currOptions
      }
      newOptions.splice(sourceInd <= targetInd ? targetInd + 1 : targetInd, 0, source)
      return newOptions
    })
  }

  const addOption = () => {
    setOptions([...options, { id: nanoid() }])
  }
  const updateOption = (option, index) => {
    const newOptions = [...options]
    newOptions[index] = option
    setOptions(newOptions)
  }
  const removeOption = index => {
    setOptions(
      options.filter((_, i) => {
        return i !== index
      })
    )
  }

  useEffect(() => {
    if (startTime || endTime) {
      setDateTimeToggle(true)
    }
  }, [startTime, endTime])

  useEffect(() => {
    if (hideDate && hideTime) {
      setDateTimeToggle(false)
    } else if (hideDate) {
      setDateTimeMode('time')
    } else if (hideTime) {
      setDateTimeMode('date')
    }
  }, [hideDate, hideTime])

  useEffect(() => {
    setErrMsg('')
  }, [])

  useEffect(() => {
    if (sessionId) {
      // if a new id is set, clean up modal for the new id
      setTitle('')
      setPrice(undefined)
      setDefaultHours(undefined)
      setExtraCharge(undefined)
      setWithinDays(undefined)
      setStartTime(undefined)
      setEndTime(undefined)
      setMaxAttendees('')
      setHideDate(false)
      setHideTime(false)
      setStatus('active')
      setOptions([])
      setDateTimeToggle(false)
      setDateTimeMode('datetime')

      getSession(sessionId)
    }
  }, [sessionId])

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center' }}
      size='lg'
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Update Session</ModalHeader>
      {(loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner color='primary' />
        </div>
      )) || (
        <ModalBody>
          <Form>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for='title'>
                    <strong className='required-field'>Title</strong>
                  </Label>
                  <Input
                    type='text'
                    name='title'
                    placeholder='Title'
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label for='price'>
                    <strong>Price (00.00)</strong>
                  </Label>
                  <Input
                    type='text'
                    name='price'
                    value={price || ''}
                    onChange={e => {
                      setPrice(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              {showFields && (
                <Col md={12}>
                  <FormGroup>
                    <Label for='defaultHours'>
                      <strong>Default Hours</strong>
                    </Label>
                    <Input
                      type='text'
                      name='defaultHours'
                      value={defaultHours || ''}
                      onChange={e => {
                        setDefaultHours(e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              {showFields && (
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label for='extraCharge'>
                      <strong>Extra Charge</strong>
                    </Label>
                    <Input
                      type='text'
                      name='extraCharge'
                      value={extraCharge || ''}
                      placeholder='$0'
                      onChange={e => {
                        setExtraCharge(e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              {showFields && (
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label for='withinDays'>
                      <strong>Within</strong>
                    </Label>
                    <Input
                      type='text'
                      name='withinDays'
                      placeholder='00 days'
                      value={withinDays || ''}
                      onChange={e => {
                        setWithinDays(e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col sm={12}>
                <label htmlFor='timeToggle'>
                  {' '}
                  <input
                    type='checkbox'
                    id='timeToggle'
                    checked={dateTimeToggle}
                    onChange={() => setDateTimeToggle(!dateTimeToggle)}
                  />
                  This session has a start/end date/time
                </label>
              </Col>
              {dateTimeToggle && (
                <>
                  <Col sm={12}>
                    <FormGroup check inline className='form-check-radio'>
                      <Label check>
                        <input
                          type='radio'
                          name='datetimemode'
                          onClick={() => setDateTimeMode('datetime')}
                          checked={dateTimeMode === 'datetime'}
                          ref={e => {
                            if (e && e.style) {
                              e.style.setProperty('position', 'relative', 'important')
                            }
                          }}
                        />
                        <span className='form-check-sign' />
                        Date Time (mm/dd/yyyy HH:mm)
                      </Label>
                    </FormGroup>
                    <FormGroup check inline className='form-check-radio'>
                      <Label check>
                        <input
                          type='radio'
                          name='datetimemode'
                          onClick={() => setDateTimeMode('date')}
                          checked={dateTimeMode === 'date'}
                          ref={e => {
                            if (e && e.style) {
                              e.style.setProperty('position', 'relative', 'important')
                            }
                          }}
                        />
                        <span className='form-check-sign' />
                        Date (mm/dd/yyyy)
                      </Label>
                    </FormGroup>
                    <FormGroup check inline className='form-check-radio'>
                      <Label check>
                        <input
                          type='radio'
                          name='datetimemode'
                          onClick={() => setDateTimeMode('time')}
                          checked={dateTimeMode === 'time'}
                          ref={e => {
                            if (e && e.style) {
                              e.style.setProperty('position', 'relative', 'important')
                            }
                          }}
                        />
                        <span className='form-check-sign' />
                        Time (HH:mm)
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <Label for='startTime'>
                      <strong>Start Time</strong>
                    </Label>
                    <FormGroup>
                      <DatePicker
                        selected={startTime ? setTimeZone(startTime) : undefined}
                        onChange={date => setStartTime(date ? setTimeZone(date) : undefined)}
                        timeInputLabel='Time:'
                        dateFormat={
                          dateTimeMode === 'datetime'
                            ? 'MM/dd/yyyy hh:mm a'
                            : dateTimeMode === 'date'
                              ? 'MM/dd/yyyy'
                              : 'hh:mm a'
                        }
                        showTimeInput
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label for='endTime'>
                        <strong>End Time</strong>
                      </Label>
                      <DatePicker
                        selected={endTime ? setTimeZone(endTime) : undefined}
                        onChange={date => setEndTime(date ? setTimeZone(date) : undefined)}
                        timeInputLabel='Time:'
                        dateFormat={
                          dateTimeMode === 'datetime'
                            ? 'MM/dd/yyyy hh:mm a'
                            : dateTimeMode === 'date'
                              ? 'MM/dd/yyyy'
                              : 'hh:mm a'
                        }
                        showTimeInput
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for='maxAttendees'>
                    <strong>Max Attendees</strong>
                  </Label>
                  <Input
                    type='text'
                    name='maxAttendees'
                    value={maxAttendees}
                    onChange={e => {
                      setMaxAttendees(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='status'>
                    <strong>Status</strong>
                  </Label>
                  <Input
                    type='select'
                    name='status'
                    value={status}
                    onChange={e => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value='active'>Active</option>
                    <option value='inactive'>Inactive</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label for='options'>
                    <strong>Options</strong>
                  </Label>
                  <Row>
                    {options.map((o, i) => {
                      return (
                        <Option
                          key={o.id || i}
                          index={i}
                          option={o}
                          authObj={authObj}
                          updateFunc={option => {
                            updateOption(option, i)
                          }}
                          removeFunc={removeOption}
                          reorder={reorder}
                          dateTimeMode={dateTimeMode}
                          dateTimeToggle={dateTimeToggle}
                        />
                      )
                    })}
                  </Row>
                  <Row>
                    <Button
                      color='primary'
                      onClick={() => {
                        addOption()
                      }}
                    >
                      Add Option
                    </Button>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      )}

      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>

        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            editSession()
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { EditSessionModal }
