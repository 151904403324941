import React from 'react'
import closeIcon from '../../../assets/icons/closeIcon.svg'
import userIcon from '../../../assets/icons/userIcon.svg'
// import errorIcon from '../../../assets/icons/status/errorIcon.svg'
import EmptyContainer from './EmptyContainer'

const ClientDetailsModal = props => {
  return (
    <div className='appDetailsModal'>
      <div className='header'>
        <h3 className='titleText'>Client Information</h3>
        <div
          className='iconArea'
          onClick={() => {
            props.setSelectedClient(null)
            props.showClientDetails(false)
          }}
        >
          <img src={closeIcon} className='icon' />
        </div>
      </div>
      <div className='scrollableContainer'>
        <div className='generalInformation'>
          <img src={userIcon} className='icon' alt='' />
          <div className='textArea'>
            <h3 className='clientName'>{props.selectedClient.email}</h3>
            <p className='clientUsername'>{props.selectedClient?.userId}</p>
          </div>
        </div>
        <div className='allAppsArea'>
          {props.selectedClient.apps.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>
                    <div className='tableHead'>Apps Integrated</div>
                  </th>
                  <th>
                    <div className='tableHead'>Email Used</div>
                  </th>
                  <th>
                    <div className='tableHead'>Username</div>
                  </th>
                  <th>
                    <div className='tableHead'>Status</div>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {props.selectedClient.apps.map(app => (
                  <tr key={app.id}>
                    <td>
                      <div className='appDetail'>
                        <div className='iconArea'>
                          <img src={props.allApps.find(a => a.slug === app?.app?.slug)?.icon} className='icon' />
                        </div>
                        <div className='appName'>{props.allApps.find(a => a.slug === app?.app?.slug).name}</div>
                      </div>
                    </td>
                    <td>{props.selectedClient?.email}</td>
                    <td>{props.selectedClient?.userId || '-'}</td>
                    {/* <td>
                      <div className="statusContainer">
                        <img src={errorIcon} className="icon" alt="" />
                        <div className="statusText">Error</div>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <EmptyContainer text='No Apps Integrated yet.' />
          )}
        </div>
      </div>
    </div>
  )
}

export default ClientDetailsModal
