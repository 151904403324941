import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'

const AddServiceModal = ({ authObj, modalOpen, setModalOpen, refreshTable }) => {
  // fields
  const [categoryId, setCategoryId] = useState(undefined)
  const [title, setTitle] = useState('')
  const [summary, setSummary] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [price, setPrice] = useState(0)
  const [timespan, setTimespan] = useState(0)
  const [status, setStatus] = useState('active')

  // variables from api
  const [categories, setCategories] = useState([])

  const [errMsg, setErrMsg] = useState('')

  const addService = async () => {
    setErrMsg('Adding Service...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const createObj = {
        title,
        categoryId: categoryId || null,
        summary,
        description,
        imageUrl,
        price,
        timespan,
        status
      }

      const res = await axios.post(`${config.apiUrl}/app/services`, createObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Service Added Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to add service (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to add service (2)')
    }
  }

  const getCategories = async () => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/categories`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setCategories(res.data)
      } else {
        setErrMsg('Unable to fetch categories (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch categories (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
    getCategories()
  }, [])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Add A New Service</ModalHeader>
      <ModalBody>
        <Form>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for='title'>
                  <strong className='required-field'>Title</strong>
                </Label>
                <Input
                  type='text'
                  name='title'
                  placeholder='Title'
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='category'>
                  <strong>Category</strong>
                </Label>
                <Input
                  type='select'
                  name='category'
                  value={categoryId}
                  onChange={e => {
                    setCategoryId(e.target.value)
                  }}
                >
                  <option value=''>No Category</option>
                  {categories.map(c => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.title}
                      </option>
                    )
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='summary'>
                  <strong>Summary</strong>
                </Label>
                <Input
                  type='text'
                  name='summary'
                  value={summary}
                  onChange={e => {
                    setSummary(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='description'>
                  <strong>Description</strong>
                </Label>
                <br />
                <textarea
                  name='description'
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value)
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='imageUrl'>
                  <strong>Image Url</strong>
                </Label>
                <Input
                  type='text'
                  name='imageUrl'
                  value={imageUrl}
                  onChange={e => {
                    setImageUrl(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for='price'>
                  <strong>Price (00.00)</strong>
                </Label>
                <Input
                  type='text'
                  name='price'
                  value={price}
                  onChange={e => {
                    setPrice(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for='timespan'>
                  <strong>Timespan (mins)</strong>
                </Label>
                <Input
                  type='text'
                  name='timespan'
                  value={timespan}
                  onChange={e => {
                    setTimespan(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='status'>
                  <strong>Status</strong>
                </Label>
                <Input
                  type='select'
                  name='status'
                  value={status}
                  onChange={e => {
                    setStatus(e.target.value)
                  }}
                >
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>
        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            addService()
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { AddServiceModal }
