import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import myAppsTitleIcon from '../../../assets/icons/myAppsTitleIcon.svg'
import { apps, deepCopy } from './OfferedApps'
import greenTickIcon from '../../../assets/icons/greenTick.svg'
import closeIcon from '../../../assets/icons/closeIcon.svg'
import campminderIcon from '../../../assets/icons/campminderIcon.svg'
import slackIcon from '../../../assets/icons/slackIcon.svg'
import googleSheetsIcon from '../../../assets/icons/googleSheetsIcon.svg'
import googleFormsIcon from '../../../assets/icons/googleFormsIcon.svg'
import hubspotIcon from '../../../assets/icons/hubspotIcon.svg'
import mailchimpIcon from '../../../assets/icons/mailchimpIcon.svg'
import googleDriveIcon from '../../../assets/icons/googleDriveIcon.svg'
import googleCalendarIcon from '../../../assets/icons/googleCalendarIcon.svg'
import gmailIcon from '../../../assets/icons/gmailIcon.svg'
import salesforceIcon from '../../../assets/icons/salesforceIcon.svg'
import shopifyIcon from '../../../assets/icons/shopifyIcon.svg'
import folderIcon from '../../../assets/icons/folderIcon.svg'
import PageTitle from '../../../components/PageTitle/PageTitle'
import sharpspringIcon from '../../../assets/icons/sharpspringIcon.svg'
import bigQueryIcon from '../../../assets/icons/bigQueryIcon.svg'
import campBrainIcon from '../../../assets/icons/campBrainIcon.svg'
import campSiteIcon from '../../../assets/icons/campSiteIcon.svg'
import campSpotIcon from '../../../assets/icons/campSpotIcon.svg'
import shipCalmIcon from '../../../assets/icons/shipCalmIcon.svg'
import { API } from '../../../api/API'
import { useSelector } from 'react-redux'

const AppsOffered = () => {
  const { userData } = useSelector(state => state.auth)
  const { theme } = useSelector(state => state.themes)
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('offered')
  const [, showAppModal] = useState(false)
  const [appModalId, setAppModalId] = useState('')
  const [selectedApp, setSelectedApp] = useState(null)
  const [connectedApps, setConnectedApps] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [isLoading, setLoading] = useState(false)

  const allApps = deepCopy(apps).map((r, i) => {
    r.icon = [
      campminderIcon,
      gmailIcon,
      googleSheetsIcon,
      sharpspringIcon,
      slackIcon,
      googleFormsIcon,
      hubspotIcon,
      mailchimpIcon,
      googleDriveIcon,
      googleCalendarIcon,
      salesforceIcon,
      shopifyIcon,
      bigQueryIcon,
      campBrainIcon,
      campSiteIcon,
      campSpotIcon,
      shipCalmIcon
    ][i]
    r.isAdded = !!connectedApps?.find(ca => ca?.slug === r.slug)
    r.settings = connectedApps?.find(ca => ca?.slug === r.slug)?.settings
    return r
  })

  const offeredApps = allApps.filter(app => !!connectedApps?.find(ca => ca?.slug === app.slug))
  const notOfferedApps = allApps.filter(app => !connectedApps?.find(ca => ca?.slug === app.slug))

  const getAppsOffered = async () => {
    setLoading(true)
    const res = await API.getAppsOffered(userData.account.accountId, userData.token)
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to get apps offered.')
    }
    setConnectedApps(res.data)
  }

  const removeAppsOffered = async () => {
    setLoading(true)
    const res = await API.updateAppsOffered(userData.account.accountId, userData.token, {
      slug: selectedApp.slug,
      status: 'archived'
    })
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to remove apps offered.')
    }
    getAppsOffered()
  }

  const showModal = app => {
    showAppModal(true)
    setAppModalId(app.name)
    setSelectedApp(app)
  }

  const hideModal = () => {
    showAppModal(false)
    setAppModalId('')
    setSelectedApp(null)
  }

  useEffect(() => {
    if (userData?.token) {
      getAppsOffered()
    }
  }, [userData])

  if (isLoading) {
    return (
      <div className='appsOfferedPage'>
        <div className='loader'>
          <div className='spinner' />
          <div className='text'>Loading, please wait</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='appsOfferedPage'>
        {isLoading ? (
          <div className='loader'>
            <div className='spinner' />
            <div className='text'>Loading, please wait</div>
          </div>
        ) : (
          <>
            <PageTitle page="appsOffered" theme={theme} title='Apps Offered' subtitle='For your clients to integrate' />
            {errMsg && <p style={{ color: 'red' }}>{errMsg}</p>}
            <div className='tabSection'>
              <div className='tabsArea'>
                <div className={`tab ${activeTab === 'offered' && 'activeTab'}`} onClick={() => setActiveTab('offered')}>
                  Apps Offered
                </div>
                <div
                  className={`tab ${activeTab === 'notOffered' && 'activeTab'}`}
                  onClick={() => setActiveTab('notOffered')}
                >
                  Apps Not Offered
                </div>
                <div className={`tab ${activeTab === 'all' && 'activeTab'}`} onClick={() => setActiveTab('all')}>
                  All
                </div>
              </div>
              <div className='appsCountArea'>{offeredApps.length} app(s) offered</div>
            </div>

            {activeTab === 'offered' ? (
              offeredApps.length > 0
                  ? (
                  <div className={`appsSection ${offeredApps.length < 6 && 'appsSectionFlex'}`}>
                    {offeredApps.map(app => (
                    <div className='app' key={app.slug} onClick={() => showModal(app)}>
                      <img src={app.icon} alt='' />
                      <h4 className='appName'>{app.name}</h4>
                      {app.isAdded && <img src={greenTickIcon} className='isActive' />}
                    </div>
                    ))}
                </div>
                )
                : (
                <div className='emptyApp'>
                  <img src={folderIcon} className='folderIcon' />
                  <h4 className='emptyAppText'>No apps offered.</h4>
                  <p className='subtitle'>
                    Go to the “Apps Not Offered” tab to start offering integrations to your Clients.
                  </p>
                  <button className='button' onClick={() => setActiveTab('notOffered')}>
                    Offer your first Integration
                  </button>
                </div>
              )
            ): null}
            
            <div className={`appsSection ${allApps.length < 6 && 'appsSectionFlex'}`}>
              {(activeTab === 'all' && allApps.length > 0) ?
                allApps.map(app => (
                  <div key={app.name} className='app' onClick={() => showModal(app)}>
                    <img src={app.icon} alt='' />
                    <h4 className='appName'>{app.name}</h4>
                    {app.isAdded && <img src={greenTickIcon} className='isActive' />}
                  </div>
                )): null}
            </div>

            

            <div className={`appsSection ${notOfferedApps.length < 6 && 'appsSectionFlex'}`}>
              {activeTab === 'notOffered' && notOfferedApps.length > 0
                ? notOfferedApps.map(app => (
                  <div key={app.name} className='app' onClick={() => showModal(app)}>
                    <img src={app.icon} alt='' />
                    <h4 className='appName'>{app.name}</h4>
                    {app.isAdded && <img src={greenTickIcon} className='isActive' />}
                  </div>
                  ))
                : null}
            </div>
          </>
        )}
        
      </div>

      {showAppModal && appModalId.length ? <div className='appOverlay' onClick={() => hideModal()} /> : null}
      {showAppModal && appModalId.length
        ? (
          <div className='appModal'>
            <div className='titleArea' style={{ backgroundColor: `${selectedApp.primaryColor}` }}>
              <div className='left'>
                <div
                  className='whiteBackground'
                  style={{ width: `${selectedApp.slug === 'shipcalm' ? '120px' : '70px'}` }}
                >
                  <img
                    src={selectedApp.icon}
                    className='icon'
                    style={{ width: `${selectedApp.slug === 'shipcalm' ? '80px' : '40px'}` }}
                    alt=''
                  />
                </div>
                <div className='textArea'>
                  <div className='titleText'>{selectedApp.name}</div>
                  <div className='subtitleText'>Allow your clients to integrate {selectedApp.name} with your app.</div>
                </div>
              </div>
              <div className='right' onClick={() => hideModal()}>
                <img src={closeIcon} className='icon' alt='' />
              </div>
            </div>
            <div className='contentArea'>
              <p className='contentText'>
                Your clients will now have the ability to connect their {selectedApp.name} account with your software and
                turn on workflows that you offer them.
                <br />
                <br />
                Are you sure you want to offer {selectedApp.name} as an integration for your clients.
              </p>
            </div>
            <div className='buttonArea'>
              {selectedApp.isAdded
                ? (
                  <>
                    <button
                      className='callToAction add'
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        history.push(`/apps/${selectedApp.slug}`)
                      }}
                    >
                      Update
                    </button>{' '}
                    <button
                      className='callToAction remove'
                      onClick={() => {
                        hideModal()
                        removeAppsOffered()
                      }}
                    >
                      Disconnect
                    </button>
                  </>
                  )
                : (
                  <button className='callToAction add' onClick={() => history.push(`/apps/${selectedApp.slug}`)}>
                    + Add Integration
                  </button>
                  )}
            </div>
          </div>
          )
        : null}
    </>
  )
}

export { AppsOffered }
