import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import { Row, Col, Button, Spinner, Input, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

import { AddCategoryModal } from './AddCategoryModal'
import { EditCategoryModal } from './EditCategoryModal'

const Categories = ({ authObj }) => {
  const [categories, setCategories] = useState([])

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(null)

  const [loader, setLoader] = useState(true)

  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editCategoryId, setEditCategoryId] = useState(null)
  const [errMsg, setErrMsg] = useState('')

  const getCategories = async () => {
    setErrMsg('Fetching categories...')
    try {
      let url = `${config.apiUrl}/app/categories`
      if (search) {
        url += `?search=${search}`
      }
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setCategories(res.data)
        setLoader(false)
      } else {
        setErrMsg('Unable to get categories (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get categories (2) : ${JSON.stringify(err)}`)
    }
  }

  const remove = async categoryId => {
    setErrMsg('Deleting categories...')
    try {
      const res = await axios.delete(`${config.apiUrl}/app/categories?categoryId=${categoryId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        // refresh table
        getCategories()
      } else {
        setErrMsg('Unable to remove category (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove category (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    if (search) {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        setErrMsg('Searching...')
        getCategories()
      }, 1000)
      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box '>
                <header className='panel_header'>
                  <h2 className='title float-left'>Categories</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    <Button
                      color='primary'
                      onClick={() => {
                        setModalOpen(true)
                      }}
                    >
                      Create Category
                    </Button>
                  </div>
                </header>
                <Col md={12} style={{ zIndex: '999' }}>
                  <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
                  <div
                    style={{
                      width: '250px',
                      paddingRight: '30px',
                      marginLeft: 'auto',
                      marginBottom: '20px'
                    }}
                  >
                    Search:
                    <Input
                      type='text'
                      placeholder=''
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value)
                      }}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categories &&
                            categories.map(c => {
                              return (
                                <tr key={c.id}>
                                  <td>{c.title}</td>
                                  <td>{c.status}</td>
                                  <td>
                                    <span
                                      style={{ marginRight: '10px', cursor: 'pointer' }}
                                      onClick={() => {
                                        setEditModalOpen(true)
                                        setEditCategoryId(c.id)
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} size='lg' />
                                    </span>
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const r = window.confirm(`Are you sure you want to remove ${c.title || c.id}`)
                                        if (r === true) {
                                          remove(c.id)
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} size='lg' />
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
      <AddCategoryModal
        authObj={authObj}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        refreshTable={getCategories}
      />
      <EditCategoryModal
        authObj={authObj}
        categoryId={editCategoryId}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        refreshTable={getCategories}
      />
    </div>
  )
}

export { Categories }
