import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'

import { Row, Col, Button, FormGroup, Label, Input, UncontrolledTooltip } from 'reactstrap'
import { useDrag, useDrop } from 'react-dnd'

const Option = ({ option, index, authObj, removeFunc, updateFunc, reorder, dateTimeToggle, dateTimeMode }) => {
  const ref = useRef(null)

  // fields
  const [id] = useState(option && option.id ? option.id : undefined)
  const [title, setTitle] = useState(option && option.title ? option.title : '')
  const [price, setPrice] = useState(option && option.price ? option.price : undefined)
  // in javascript date format
  const [startTime, setStartTime] = useState(
    option && option.startTime ? moment.unix(option.startTime).toDate() : undefined
  )
  const [endTime, setEndTime] = useState(option && option.endTime ? moment.unix(option.endTime).toDate() : undefined)
  const [status] = useState(option && option.status ? option.status : 'active')
  const [overwriteTime, setOverwriteTime] = useState(false)

  const [isMouseIn, setIsMouseIn] = useState(false)
  const [action, setAction] = useState(option && option.action ? option.action : 'overwritePrice')

  const [{ isDragging }, draggable] = useDrag({
    item: { id: option.id, type: 'option' },
    type: 'option',
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  })

  const [{ isOver }, drop] = useDrop(
    {
      accept: ['option'],
      canDrop: () => {
        return true
      },
      drop: (obj, monitor) => {
        reorder(option.id, obj.id)
      },
      collect: monitor => {
        return {
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop()
        }
      }
    },
    [option] // dependencies
  )

  useEffect(() => {
    if (!dateTimeToggle) {
      setOverwriteTime(false)
    }
  }, [dateTimeToggle])

  useEffect(() => {
    if (!overwriteTime) {
      setStartTime(undefined)
      setEndTime(undefined)
    }
  }, [overwriteTime])

  useEffect(() => {
    const updateObj = {
      id,
      title,
      price,
      status,
      action
    }

    if (startTime) {
      updateObj.startTime = moment(startTime).unix()
    }
    if (endTime) {
      updateObj.endTime = moment(endTime).unix()
    }
    updateFunc(updateObj)
  }, [title, price, startTime, endTime, action])

  return (
    <div ref={draggable(drop(ref))}>
      <Row
        form
        style={{
          padding: '15px',
          border: '1px solid',
          opacity: isDragging ? 0.5 : 1,
          outline: isMouseIn || isDragging ? '1px dashed black' : 'none',
          backgroundColor: isOver ? '#f2f2f2' : '#fff',
          cursor: 'move'
        }}
        onMouseEnter={() => setIsMouseIn(true)}
        onMouseLeave={() => setIsMouseIn(false)}
      >
        <Col sm={12} md={6}>
          <FormGroup>
            <Label for='title'>
              <strong className='required-field'>Title </strong>
            </Label>
            <Input
              type='text'
              name='title'
              placeholder='Title'
              value={title}
              onChange={e => {
                setTitle(e.target.value)
              }}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={6}>
          <FormGroup>
            <Label for='price'>
              <strong>Price (00.00)</strong>
            </Label>
            <Input
              type='text'
              name='price'
              value={price || ''}
              onChange={e => {
                setPrice(e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup check inline className='form-check-radio'>
            <Label check>
              <input
                type='radio'
                name={`${option.id}_action`}
                checked={action === 'overwritePrice'}
                onClick={() => setAction('overwritePrice')}
                ref={e => {
                  if (e && e.style) {
                    e.style.setProperty('position', 'relative', 'important')
                  }
                }}
              />
              <span className='form-check-sign' />
              Overwrite Price
            </Label>
          </FormGroup>
          <span className='tooltipSpan' id='toolTip_overwrite'>
            <i className='fas fa-info' />
          </span>
          <UncontrolledTooltip placement='right' target='toolTip_overwrite'>
            This will replace the session price when this option is selected
          </UncontrolledTooltip>
          <FormGroup check inline className='form-check-radio'>
            <Label check>
              <input
                type='radio'
                name={`${option.id}_action`}
                checked={action === 'adjustPrice'}
                onClick={() => setAction('adjustPrice')}
                ref={e => {
                  if (e && e.style) {
                    e.style.setProperty('position', 'relative', 'important')
                  }
                }}
              />
              <span className='form-check-sign' />
              Adjust Price
            </Label>
          </FormGroup>
          <span className='tooltipSpan' id='toolTip_adjust'>
            <i className='fas fa-info' />
          </span>
          <UncontrolledTooltip placement='right' target='toolTip_adjust'>
            This will increase or decrease the session price when this option is selected
          </UncontrolledTooltip>
        </Col>
        {dateTimeToggle && (
          <Col sm={12}>
            <label htmlFor={`${option.id}_timeToggle`}>
              {' '}
              <input
                type='checkbox'
                id={`${option.id}_timeToggle`}
                checked={overwriteTime}
                onChange={() => setOverwriteTime(!overwriteTime)}
              />
              Overwrite time
            </label>
          </Col>
        )}
        {dateTimeToggle && overwriteTime && (
          <>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for='startTime'>
                  <strong>Start Time</strong>
                </Label>
                <DatePicker
                  selected={startTime}
                  onChange={setStartTime}
                  timeInputLabel='Time:'
                  dateFormat={
                    dateTimeMode === 'datetime'
                      ? 'MM/dd/yyyy hh:mm a'
                      : dateTimeMode === 'date'
                        ? 'MM/dd/yyyy'
                        : 'hh:mm a'
                  }
                  showTimeInput
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for='endTime'>
                  <strong>End Time</strong>
                </Label>
                <DatePicker
                  selected={endTime}
                  onChange={setEndTime}
                  timeInputLabel='Time:'
                  dateFormat={
                    dateTimeMode === 'datetime'
                      ? 'MM/dd/yyyy hh:mm a'
                      : dateTimeMode === 'date'
                        ? 'MM/dd/yyyy'
                        : 'hh:mm a'
                  }
                  showTimeInput
                />
              </FormGroup>
            </Col>
          </>
        )}
        <div style={{ width: '100%' }}>
          <Button
            style={{ float: 'right' }}
            size='sm'
            color='danger'
            onClick={() => {
              removeFunc(index)
            }}
          >
            Remove
          </Button>
        </div>
      </Row>
      <br />
    </div>
  )
}

export { Option }
