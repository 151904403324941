/* eslint-disable operator-linebreak */
import React, { useState } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import inviteUserIcon from '../../../assets/img2/inviteUser.svg'
import moreOptions from '../../../assets/img2/moreOptions.svg'
import edit from '../../../assets/img2/edit.svg'
import deactivate from '../../../assets/img2/deactivate.svg'

import { AddUserModal } from './AddUserModal'
import { EditUserModal } from './EditUserModal'
import { Col, Row } from 'react-bootstrap'
import { DeactivateUserModal } from './DeactivateUserModal'

import { useSelector, useDispatch } from 'react-redux'
import { loadTeamUsers } from '../../../services/team.service'

const Users = () => {
  const { isLoading, users } = useSelector(state => state.team)
  const { userData } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [editUserId, setEditUserId] = useState(null)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [moreOption, showMoreOptions] = useState(false)
  const [updatingObject, setUpdatingObject] = useState(null)
  const [deactivateAccountObject, setDeactivateAccountObject] = useState(null)
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  const removeUser = async uId => {
    try {
      const response = await axios.delete(`${config.apiUrl}/app/users?userId=${uId}`, {
        headers: {
          accountid: userData.account.accountId,
          Authorization: `Bearer ${userData.token}`
        }
      })

      if (response && response.data) {
        setErrMsg('User removed successfully')
        setTimeout(() => {
          setErrMsg('')
        }, 3000)
        setDeactivateModalOpen(false)
        setEditModalOpen(false)
        dispatch(loadTeamUsers(userData.account.accountId, userData.token))
      } else {
        setErrMsg('Unable to remove users (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to remove users (2)')
    }
  }

  return (
    <>
      <div
        className='users-container'
        style={{ position: 'relative' }}
        onClick={() => {
          if (moreOption && editUserId) {
            showMoreOptions(false)
            setEditUserId(null)
          }
        }}
      >
        <div className='content'>
          <div className='header'>
            <h2 className='titleText'>Teams</h2>
            <div className='actionArea'>
              <div style={{ margin: '30px 0' }}>
                <button
                  className='inviteUser'
                  onClick={() => {
                    setAddModalOpen(true)
                  }}
                >
                  <img src={inviteUserIcon} alt='invite icon' />
                  <span>Invite User</span>
                </button>
              </div>
            </div>
          </div>
          {isLoading ?
            <div className='loadingContainer'>
              <div className='loader'>
                <div className='spinner' />
                <div className='text'>Loading, please wait</div>
              </div>
            </div>
            :
            <div className='mainArea'>
              <div className='content-body'>
                {users?.length > 0 && (
                  <div>
                    <div className='mainAreaTitle'>
                      <div style={{ width: '100%' }}>{`${users.length} ${users.length > 1 ? 'users' : 'user'
                  }`}
                      </div>
                    </div>
                    <div className='mainAreaSubtitle'>
                      <div style={{ width: '100%', color: '#ADAEB6' }}>Updated on March 12, 2022</div>
                    </div>
                  </div>
                )}
                {errMsg && (
                  <div className='errorArea'>
                    <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
                  </div>
                )}
              </div>
              <Row>
                <Col xs={12} md={12}>
                  <div className='scrollableContainer'>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className='tableHead'>Name</div>
                          </th>
                          <th>
                            <div className='tableHead'>Email</div>
                          </th>
                          <th>
                            <div className='tableHead'>Role</div>
                          </th>
                          <th>
                            <div className='tableHead'>Status</div>
                          </th>
                          <th />
                        </tr>
                      </thead>

                      <tbody>
                        {users?.length > 0 &&
                          users.map(u => {
                            return (
                              <tr key={u.id}>
                                <td>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                      className='circularAvatarContainer'
                                      style={{ marginRight: '10px' }}
                                      height='48'
                                      width='48'
                                      src={u.imgUrl || ''}
                                    />
                                    {u.firstName || ''} {u.lastName || ''}
                                  </div>
                                </td>
                                <td>{u.email || ''}</td>
                                {/* Administrator role for testing because there was no role field in response */}
                                <td>{u.role || 'Administrator'}</td>
                                <td>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                      className='statusCircle'
                                      style={{ backgroundColor: `${u.status === 'active' ? '#8CE28B' : '#FD7062'}` }}
                                    />
                                    {u.status === 'active' ? 'Active' : u.status || ''}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className='moreOptions'
                                    onClick={() => {
                                      showMoreOptions(true)
                                      setEditUserId(u.id)
                                    }}
                                  >
                                    <img width='18' src={moreOptions} />
                                    {moreOption && editUserId === u.id && (
                                      <div className='moreOptionPanel'>
                                        <div
                                          onClick={() => {
                                            setUpdatingObject(users.find(u => u.id === editUserId))
                                            setEditModalOpen(true)
                                          }}
                                          className='menuItem'
                                          style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <img style={{ marginRight: '10px' }} height='20' src={edit} alt='' />
                                          Update User
                                        </div>
                                        <div
                                          onClick={() => {
                                            setDeactivateAccountObject(users.find(u => u.id === editUserId))
                                            setDeactivateModalOpen(true)
                                          }}
                                          className='menuItem'
                                          style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <img style={{ marginRight: '10px' }} height='20' src={deactivate} alt='' />
                                          Deactivate User
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>}
        </div>
      </div>
      {addModalOpen && (
        <AddUserModal
          modalOpen={addModalOpen}
          setModalOpen={setAddModalOpen}
        />
      )}
      {editModalOpen && (
        <EditUserModal
          userId={editUserId}
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          setDeactivateModalOpen={setDeactivateModalOpen}
          deactivateModalOpen={deactivateModalOpen}
          setDeactivateAccountObject={setDeactivateAccountObject}
          removeUser={removeUser}
          updatingObject={updatingObject}
        />
      )}
      {deactivateModalOpen && (
        <DeactivateUserModal
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          modalOpen={deactivateModalOpen}
          setModalOpen={setDeactivateModalOpen}
          deactivateAccountObject={deactivateAccountObject}
          removeUser={removeUser}
        />
      )}
    </>
  )
}

export { Users }
