import React, { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Button } from 'reactstrap'
import { FieldType } from '../FieldTypes'
import Settings from './Settings'

const Step = ({
  step,
  activeStep,
  setActiveStep,
  removeStep,
  setStepOrder,
  updateCurrentSetting,
  setFieldSettingsModalOpen,
  updateFieldSettings
}) => {
  const iconRef = useRef()

  const [{ isDragging }, draggable] = useDrag({
    item: { id: step.id, type: FieldType.STEP },
    type: FieldType.STEP,
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      }
    }
  })

  const [{ isOver }, drop] = useDrop(
    {
      accept: [FieldType.STEP],
      canDrop: () => {
        return true
      },
      drop: (obj, monitor) => {
        setStepOrder(obj.id, step.order)
      },
      collect: monitor => {
        return {
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop()
        }
      }
    },
    [step]
  )

  useEffect(() => {
    iconRef.current.innerHTML = `<i class="${step.icon}"></i>`
  }, [step.icon])

  return (
    <span ref={drop} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <li
        ref={draggable}
        className={activeStep === step.id ? 'active' : ''}
        style={{ position: 'relative', opacity: isOver ? 0.5 : 1 }}
        onClick={() => setActiveStep(step.id)}
      >
        <div style={{ display: 'inline', position: 'absolute', right: '-10px', top: '-20px' }}>
          <Button
            size='xs'
            color='primary'
            title='Settings'
            onClick={() => {
              updateCurrentSetting(
                <Settings step={step} updateFieldSettings={updateFieldSettings} setStepOrder={setStepOrder} />
              )
              setFieldSettingsModalOpen(true)
            }}
          >
            <i className='fas fa-tools action-icon' />
          </Button>
          <Button
            size='xs'
            color='danger'
            title='Remove'
            onClick={() => {
              removeStep(step.id)
            }}
          >
            <i className='fas fa-times action-icon' />
          </Button>
        </div>
        <span ref={iconRef} />
        <strong>
          {Number(step.order) + 1}. {step.displayName}
        </strong>
      </li>
    </span>
  )
}

export default Step
