import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import BackIcon from '../../../components/Icons/BackIcon'
import ConsultMethods from './components/ConsultMethods'

import zoom from '../../../assets/icons/ConsultTypes/zoom.svg'
import call from '../../../assets/icons/ConsultTypes/call.svg'
import message from '../../../assets/icons/ConsultTypes/message.svg'

import { countryCodes } from './data/countryCodes'
import { updateOrder } from '../../../services/order.services'
import { setSelectedOrder } from '../../../redux/slice/order.slice'

const Consult = () => {
  const dispatch = useDispatch()
  const { theme } = useSelector(state => state.themes)
  const { isLoading, orders, selectedOrder } = useSelector(state => state?.order)
  const { userData } = useSelector(state => state?.auth)
  const history = useHistory()
  const [orderId ] = useState(history?.location?.pathname.split('/')[2])
  const themeColor = theme === 'dark' ? '#ffffff' : '#3e3d40'
  const [consultType, setConsultType] = useState('Zoom Call')
  const [countryCode, setCountryCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [contactNote, setContactNote] = useState('')
  const [msg, setMsg] = useState('')

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js")
    head.appendChild(script)
  }, [])

  useEffect(() => {
    if (!isLoading && orders?.length) {
      if (orders?.filter(o => o?.id === history?.location?.pathname.split('/')[2])?.length === 0) {
        history.push('/orders')
      } else { 
        dispatch(setSelectedOrder(orders?.find(o => o?.id === history?.location?.pathname.split('/')[2])))
      }
    }
  }, [orders, isLoading, selectedOrder])

  useEffect(() => { 
    if (selectedOrder) { 
      dispatch(setSelectedOrder(orders?.find(o => o?.id === orderId)))
    }
  }, [orders, selectedOrder])

  useEffect(() => { 
    setMsg('')
  }, [consultType, countryCode, phoneNumber, contactNote])
  
  useEffect(() => { 
    selectedOrder?.contactNote?.length ? setContactNote(selectedOrder?.contactNote) : setContactNote('')
    if (selectedOrder?.phone?.length) {
      setCountryCode(selectedOrder?.phone.split(' ')[0])
      setPhoneNumber(selectedOrder?.phone.split(' ')[1])
    } else {
      setCountryCode(countryCode[0]?.dial_code)
      setPhoneNumber('')
    }
  }, [selectedOrder])

  const onSubmitContactDetails = () => {
    if (contactNote?.length === 0 || phoneNumber?.length === 0) {
      return  setMsg('Please fill in all fields.')
    }
    const data = {
      id: orderId,
      contactNote,
      contactVia: consultType,
      phone: countryCode + " " + phoneNumber
    }
    dispatch(updateOrder(userData?.account?.accountId, userData?.token, data, false))
      .then(() => { 
        setMsg('Sucessfully updated contact information.')
        setTimeout(() => setMsg(''), 3000)
      })
  }

  return (
    <div className='consultPage'>
      <div className="backContainer">
        <div className="backButton" onClick={() => history.push('/orders')}>
          <BackIcon color={themeColor} />
          <div className="backText">Back</div>
        </div>
      </div>
      <div className="titleArea">
        <div className="title">
          Talk to us
        </div>
        <div className="subtitle">
          How do you wish we communicate?
        </div>
      </div>
      <div className="methods">
        <ConsultMethods
          icon={zoom}
          type="Zoom Call"
          subtitle="Let’s say hi on zoom!"
          consultType={consultType}
          setConsultType={setConsultType}
        />
        <ConsultMethods
          icon={call}
          type="Phone Call"
          subtitle="Let’s chat over a call"
          consultType={consultType}
          setConsultType={setConsultType}
        />
        <ConsultMethods
          icon={message}
          type="Via Text"
          subtitle="Let’s chat via text"
          consultType={consultType}
          setConsultType={setConsultType}
        />
      </div>
      <div className="optionArea" >
        <div className={`zoomContainer ${consultType === 'Zoom Call' ? 'showZoomContainer' : ''}`}>
          <div className="title">Book a Time with us via Zoom</div>
          <div
            className="calendly-inline-widget calendly-widget"
            data-url="https://calendly.com/carl333/30min"
            style={{ width: '100%', height: "700px" }}
          />
        </div>
        <div className={`phoneContainer ${consultType === 'Phone Call' || consultType === 'Via Text' ? 'showPhoneContainer' : ''}`}>
          <div className="title">
            When’s the best time to {consultType === 'Phone Call' ? 'give you a call?' : 'send you a text message?'}
          </div>
          <input
            type="text"
            className="secondaryInputField"
            placeholder='Enter your answer...'
            value={contactNote}
            onChange={e => setContactNote(e.target.value)}
          />
          <div className="title">
            Share with us your Phone number
          </div>
          <div className="phoneNumberArea">
            <div className="selectInputArea">
              <div
                className="countryCodeValue"
              >
                {countryCode}
              </div>
              <select
                name="countryCodes"
                className='select'
                id=""
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value.toString())}
              >
                {countryCodes?.map((c) => (
                  <option
                    key={`${c?.name} ${c?.dial_code}`}
                    value={c?.dial_code}
                  >
                    {c?.name} {c?.dial_code}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="number"
              className="phoneNumberField"
              placeholder='0000000000'
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </div>
          <p className="err">{msg}</p>
          <button
            className="submitButton"
            onClick={() => onSubmitContactDetails()}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  )
}

export default Consult