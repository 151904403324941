import axios from 'axios'
import processSlice from '../redux/slice/process.slice'
import { formatResponse } from './common.service'

import { config } from '../config'

export const loadProcesses = (accountId, token, loader) => dispatch => {
  loader && dispatch(processSlice.actions.setLoading(true))
  return axios
    .get(`${config.apiUrl}/app/processes`, {
      headers: {
        accountid: accountId,
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(processSlice.actions.setProcesses(res.data))
      dispatch(processSlice.actions.setLoading(false))
    })
    .catch(err => {
      dispatch(processSlice.actions.setLoading(false))
      return formatResponse(null, err)
    })
}
