import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'

const SettingForm = ({ title, description, children }) => {
  return (
    <Row>
      <Col xs={12} lg={4} md={8}>
        <h4>{title}</h4>
        <p>{description}</p>
      </Col>
      <Col xs={12} lg={8} md={8}>
        <Row>
          <Card>
            <Card.Body>{children}</Card.Body>
          </Card>
        </Row>
      </Col>
    </Row>
  )
}

export default SettingForm
