import React, { useState, useEffect } from 'react'
import myAppsIcon from '../../../assets/icons/myAppsIcon.svg'
import PageTitle from '../../../components/PageTitle/PageTitle'
import AppCard from './AppCard'
import { apps, deepCopy } from '../AppsOffered/OfferedApps'
import campminderIcon from '../../../assets/icons/campminderIcon.svg'
import slackIcon from '../../../assets/icons/slackIcon.svg'
import googleSheetsIcon from '../../../assets/icons/googleSheetsIcon.svg'
import googleFormsIcon from '../../../assets/icons/googleFormsIcon.svg'
import hubspotIcon from '../../../assets/icons/hubspotIcon.svg'
import mailchimpIcon from '../../../assets/icons/mailchimpIcon.svg'
import googleDriveIcon from '../../../assets/icons/googleDriveIcon.svg'
import googleCalendarIcon from '../../../assets/icons/googleCalendarIcon.svg'
import gmailIcon from '../../../assets/icons/gmailIcon.svg'
import salesforceIcon from '../../../assets/icons/salesforceIcon.svg'
import shopifyIcon from '../../../assets/icons/shopifyIcon.svg'
import folderIcon from '../../../assets/icons/folderIcon.svg'
import sharpspringIcon from '../../../assets/icons/sharpspringIcon.svg'
import bigQueryIcon from '../../../assets/icons/bigQueryIcon.svg'
import campBrainIcon from '../../../assets/icons/campBrainIcon.svg'
import campSiteIcon from '../../../assets/icons/campSiteIcon.svg'
import campSpotIcon from '../../../assets/icons/campSpotIcon.svg'
import shipCalmIcon from '../../../assets/icons/shipCalmIcon.svg'
import { useSelector } from 'react-redux'

const MyApps = () => {
  const [tab, setTab] = useState('connectedApps')
  const [filteredApps, setFilteredApps] = useState([])
  const [labelTitle, setLabelTitle] = useState('All Apps')
  const { settings } = useSelector(state => state.profile)
  const { isLoading, apps: myApps, allApps: availableApps, authUrlMap } = useSelector(state => state.myApps)

  const allApps = deepCopy(apps).map((r, i) => {
    r.icon = [
      campminderIcon,
      gmailIcon,
      googleSheetsIcon,
      sharpspringIcon,
      slackIcon,
      googleFormsIcon,
      hubspotIcon,
      mailchimpIcon,
      googleDriveIcon,
      googleCalendarIcon,
      salesforceIcon,
      shopifyIcon,
      bigQueryIcon,
      campBrainIcon,
      campSiteIcon,
      campSpotIcon,
      shipCalmIcon
    ][i]
    r.id = myApps.find(a => a.app?.slug === r.slug)?.id
    r.app = availableApps.find(a => a?.slug === r.slug)
    r.isAdded = !!myApps.find(a => a.app?.slug === r.slug && !a.clientId)
    return r
  })
  const connectedApps = allApps.filter(app => app.isAdded === true)
  const notConnectedApps = allApps.filter(app => app.isAdded === false)

  useEffect(() => {
    if (tab === 'all') {
      setFilteredApps(allApps)
      setLabelTitle('All Apps')
    } else if (tab === 'connectedApps') {
      setFilteredApps(connectedApps)
      setLabelTitle('Connected Apps')
    } else if (tab === 'notConnectedApps') {
      setFilteredApps(notConnectedApps)
      setLabelTitle('Not Connected Apps')
    }
  }, [tab, connectedApps, notConnectedApps])

  return (
    <div className="myAppsPage">
      {isLoading ? (
        <div className="loader">
          <div className="spinner" />
          <div className="text">Loading, please wait</div>
        </div>
      ) : (
        <>
          <PageTitle icon={myAppsIcon} title="My Apps" subtitle="Integrate apps you own internally" />
          <div className="horizontalLine" />
          <div className="row">
            <div className="leftMenu">
              <div className="connected">
                <p
                  className={`menuItem ${tab === 'connectedApps' && 'active'}`}
                  onClick={() => setTab('connectedApps')}
                >
                  Connected Apps
                </p>
                <p
                  className={`menuItem ${tab === 'notConnectedApps' && 'active'}`}
                  onClick={() => setTab('notConnectedApps')}
                >
                  Not Connected Apps
                </p>
                <p className={`menuItem ${tab === 'all' && 'active'}`} onClick={() => setTab('all')}>
                  All Apps
                </p>
              </div>
            </div>
            <div className="rightContent">
              <div className="category">
                <h4 className="label">{labelTitle}</h4>
                {filteredApps.length > 0 ? (
                  <div className="appsContainer">
                    {filteredApps.map((app, i) => (
                      <AppCard
                        key={i}
                        myAppId={app?.id}
                        appId={app?.app?.id}
                        appIcon={app?.icon}
                        appName={app?.name}
                        appSlug={app?.slug}
                        authUrl={authUrlMap[app?.slug]}
                        customUrl={app?.authUrl}
                        buttonType={app?.isAdded ? 'Disconnect' : 'Integrate'}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="emptyApp">
                    <img src={folderIcon} className="folderIcon" />
                    <h4 className="emptyAppText">No apps connected.</h4>
                    <p className="emptyAppSubtitleText">
                      Go to the “Not Connected” tab to start connecting your own app.
                    </p>
                    <button className="button" onClick={() => setTab('notConnectedApps')}>
                      Connect your first app
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MyApps
