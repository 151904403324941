import { configureStore } from '@reduxjs/toolkit'
import billingSlice from './slice/billing.slice'
import myClientsSlice from './slice/clients.slice'
import myAppsSlice from './slice/my-apps.slice'
import processSlice from './slice/process.slice'
import profileSlice from './slice/profile.slice'
import teamSlice from './slice/team.slice'
import userSlice from './slice/user.slice'
import themeSlice from './slice/theme.slice'
import orderSlice from './slice/order.slice'

export const store = configureStore({
  reducer: {
    auth: userSlice.reducer,
    billing: billingSlice.reducer,
    myApps: myAppsSlice.reducer,
    myClients: myClientsSlice.reducer,
    order: orderSlice.reducer,
    process: processSlice.reducer,
    profile: profileSlice.reducer,
    team: teamSlice.reducer,
    themes: themeSlice.reducer
  }
})
