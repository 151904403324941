import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../../config'
import { Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table } from 'reactstrap'

import { EditSessionModal } from './EditSessionModal'
import { AddSessionModal } from './AddSessionModal'
import { Session } from './Session'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Manager = ({ authObj }) => {
  const query = useQuery()
  const [programId] = useState(query.get('programId'))
  const [programName, setProgramName] = useState('')
  const [programView, setProgramView] = useState('')

  // tabs
  const [currentTab, setCurrentTab] = useState(1)

  // sessions -- tab 1
  const [sessions, setSessions] = useState([])
  const [orderChanged, setOrderChanged] = useState(false)

  const [search] = useState('')
  const [searching, setSearching] = useState(null)

  // tab 2 - tbd

  // general

  const [editSessionModalOpen, setEditSessionModalOpen] = useState(false)
  const [editSessionId, setEditSessionId] = useState(null)

  const [modalOpen, setModalOpen] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const getProgram = async () => {
    setErrMsg('Fetching program...')
    try {
      const url = `${config.apiUrl}/app/programs/by-id?programId=${programId}`
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setProgramName(res.data.title)
        setProgramView(res.data.view)
      } else {
        setErrMsg('Unable to get programs (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get programs (2) : ${JSON.stringify(err)}`)
    }
  }

  // get sessions for this program
  const getSessions = async () => {
    try {
      let url = `${config.apiUrl}/app/programs/sessions`
      if (search) {
        url += `?search=${search}`
      }
      if (programId) {
        url += `?programId=${programId}`
      }
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setSessions(res.data)
      } else {
        setErrMsg('Unable to get sessions (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get sessions (2) : ${JSON.stringify(err)}`)
    }
  }

  const removeSession = async sId => {
    try {
      const response = await axios.delete(`${config.apiUrl}/app/programs/sessions?programSessionId=${sId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (response && response.data) {
        setErrMsg('Session successfully removed')
        getSessions()
      } else {
        setErrMsg('Unable to remove session (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove session (2) : ${JSON.stringify(err)}`)
    }
  }

  const duplicate = async cId => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/app/programs/sessions/duplicate`,
        {
          programSessionId: cId
        },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (response && response.data) {
        setErrMsg('Session duplicated successfully')
        getSessions()
      } else {
        setErrMsg('Unable to duplicate session (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to duplicate session (2) : ${JSON.stringify(err)}`)
    }
  }

  const saveOrder = async () => {
    try {
      const sessionsArr = sessions.map((s, index) => {
        return { id: s.id, accountId: s.accountId, programId: s.programId, order: index }
      })
      const res = await axios.put(
        `${config.apiUrl}/app/programs/sessions/order`,
        { sessions: sessionsArr },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (!res || !res.data) {
        setErrMsg('Unable to update session order (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update sessions order (2)')
    }
  }

  const reorder = (targetId, sourceId) => {
    if (targetId === sourceId) {
      return
    }
    setOrderChanged(true)
    setSessions(currSessions => {
      const sourceInd = currSessions.findIndex(s => s.id === sourceId)
      if (sourceInd === -1) {
        return currSessions
      }
      const source = currSessions[sourceInd]
      const newSessions = currSessions.filter(s => s.id !== sourceId)
      const targetInd = newSessions.findIndex(s => s.id === targetId)
      if (targetInd === -1) {
        return currSessions
      }
      newSessions.splice(sourceInd <= targetInd ? targetInd + 1 : targetInd, 0, source)
      return newSessions
    })
  }

  useEffect(() => {
    if (orderChanged) {
      saveOrder()
    }
  }, [sessions])

  useEffect(() => {
    if (search) {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        setErrMsg('Searching...')
        getSessions()
      }, 1000)
      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    getProgram()
    getSessions()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col md={12}>
            <h3>Manage {programName}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className='row'>
              <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
            </div>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box '>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={currentTab === 1 ? 'active' : ''}
                      onClick={() => {
                        setCurrentTab(1)
                      }}
                    >
                      Sessions & Options
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={currentTab === 2 ? 'active' : ''}
                      onClick={() => {
                        setCurrentTab(2)
                      }}
                    >
                      TBD
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={currentTab}>
                  <TabPane tabId={1}>
                    <Row>
                      <Col sm='12'>
                        <header className='panel_header'>
                          <div
                            style={{
                              padding: '30px',
                              float: 'right',
                              display: 'flex'
                            }}
                          >
                            <Button
                              color='primary'
                              onClick={() => {
                                setModalOpen(true)
                              }}
                            >
                              Add Session
                            </Button>
                          </div>
                        </header>

                        <div className='content-body'>
                          <div className='row'>
                            <div className='col-lg-12 dt-disp'>
                              <Table bordered hover>
                                <thead>
                                  <tr>
                                    <th>Title</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sessions &&
                                    sessions.map(s => {
                                      return (
                                        <Session
                                          key={s.id}
                                          session={s}
                                          duplicate={duplicate}
                                          setEditSessionModalOpen={setEditSessionModalOpen}
                                          setEditSessionId={setEditSessionId}
                                          removeSession={removeSession}
                                          reorder={reorder}
                                        />
                                      )
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Row>
                      <Col md='12'>
                        <div className='content-body'>
                          <div className='row'>
                            <div className='col-lg-12 dt-disp'>
                              <h4>TBD</h4>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </section>
            </div>
          </Col>
        </Row>
      </div>
      <AddSessionModal
        authObj={authObj}
        programId={programId}
        modalOpen={modalOpen}
        programView={programView}
        setModalOpen={setModalOpen}
        refreshTable={getSessions}
      />
      <EditSessionModal
        authObj={authObj}
        sessionId={editSessionId}
        programId={programId}
        programSessionId={editSessionId}
        programView={programView}
        modalOpen={editSessionModalOpen}
        setModalOpen={setEditSessionModalOpen}
        refreshTable={getSessions}
      />
    </div>
  )
}

export { Manager }
