import moment from 'moment-timezone'
const CalendarHelper = {}

// rules in opticor format
CalendarHelper.rules = []

// set rules in opticor format
CalendarHelper.setRules = rules => {
  CalendarHelper.rules = rules
}

CalendarHelper.getRules = () => {
  return CalendarHelper.rules
}

// given a set of rules in opticor format, merge it with our current set of rules
CalendarHelper.updateRules = rules => {
  rules.forEach(rule => {
    if (rule.type === 'day') {
      // replace day in rules if it exists
      const rulePos = CalendarHelper.rules
        .map(r => {
          if (r.type === 'day') {
            return r.date
          }
          return null
        })
        .indexOf(rule.date)
      if (rulePos > -1) {
        CalendarHelper.rules[rulePos].intervals = rule.intervals
      } else {
        CalendarHelper.rules.push(rule)
      }
    }
    if (rule.type === 'wday') {
      // replace day in rules if it exists
      const rulePos = CalendarHelper.rules
        .map(r => {
          if (r.type === 'wday') {
            return r.wday
          }
          return null
        })
        .indexOf(rule.wday)
      if (rulePos > -1) {
        CalendarHelper.rules[rulePos].date = rule.date
        CalendarHelper.rules[rulePos].intervals = rule.intervals
        CalendarHelper.rules[rulePos].repeat = rule.repeat
      } else {
        CalendarHelper.rules.push(rule)
      }
    }
  })
  // console.log("updaterules ", CalendarHelper.rules)
}

// get a list of events that's ready for clndr
CalendarHelper.getCalendarDisp = (intervalStart, intervalEnd) => {
  const { startDay, endDay } = CalendarHelper.getCalendarStartEndDates(intervalStart, intervalEnd)

  const calendarDisp = []
  for (let currentDay = moment(startDay); currentDay.isBefore(moment(endDay)); currentDay.add(1, 'day')) {
    // get the rule on this day
    const ruleOnCurrentDay = CalendarHelper.getRuleOnDay(currentDay, CalendarHelper.rules)

    if (ruleOnCurrentDay && ruleOnCurrentDay.intervals) {
      let str = CalendarHelper.getAvailabilities(ruleOnCurrentDay.intervals)
      if (ruleOnCurrentDay.intervals.length === 0) {
        str = 'Unavailable'
      }

      let date
      if (ruleOnCurrentDay.type === 'day') {
        date = ruleOnCurrentDay.date
      } else if (ruleOnCurrentDay.type === 'wday') {
        date = moment(currentDay, 'YYYY-MM-DD').format('YYYY-MM-DD')
      }

      calendarDisp.push({
        date,
        title: str
      })
    }

    // console.log("getEvents ", moment(currentDay).format("YYYY-MM-DD"), {
    //   date,
    //   title: str
    // })
  }
  // console.log("events ", events)
  return calendarDisp
}

// get a list calendar disp events ready for clndr from events
CalendarHelper.getCalendarDispFromEvents = (intervalStart, intervalEnd, events) => {
  const { startDay, endDay } = CalendarHelper.getCalendarStartEndDates(intervalStart, intervalEnd)

  const calendarDisp = []
  for (let currentDay = moment(startDay); currentDay.isBefore(moment(endDay)); currentDay.add(1, 'day')) {
    // for every event in the list of events
    for (let i = 0; i < events.length; i += 1) {
      const event = events[i]
      // get the rule on this day
      const ruleOnCurrentDay = CalendarHelper.getRuleOnDay(currentDay, event.rules)
      if (ruleOnCurrentDay && ruleOnCurrentDay.intervals && ruleOnCurrentDay.intervals.length > 0) {
        let date
        if (ruleOnCurrentDay.type === 'day') {
          date = ruleOnCurrentDay.date
        } else if (ruleOnCurrentDay.type === 'wday') {
          date = moment(currentDay, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }

        calendarDisp.push({
          id: event.id,
          date,
          title: event.title,
          rule: ruleOnCurrentDay
        })
      }
    }
  }
  // console.log("calendarDisp ", calendarDisp)
  return calendarDisp
}

// get availabilities as an html string from intervals
CalendarHelper.getAvailabilities = intervals => {
  let str = ''
  intervals.forEach((i, index) => {
    if (index !== 0) {
      str += '</br>'
    }
    str += `${moment(i.from, 'HH:mm').format('LT')}-${moment(i.to, 'HH:mm').format('LT')}`
  })
  return str
}

// get the rule that applies to a specific day
// day takes priority over wday
// currentDay is a moment object YYYY-MM-DD format
CalendarHelper.getRuleOnDay = (currentDay, rules) => {
  let lowPriorityRule
  for (let i = 0; i < rules.length; i += 1) {
    const rule = rules[i]
    if (rule.type === 'day' && moment(currentDay, 'YYYY-MM-DD').isSame(moment(rule.date, 'YYYY-MM-DD'))) {
      return rule
    }

    if (rule.type === 'wday') {
      // check if it's the same day
      const sameDayCheck = rule.wday.split(',').indexOf(moment(currentDay).format('ddd').toLowerCase()) > -1

      if (sameDayCheck) {
        // if not date is set on wday, then repeat is indefinite
        // if date is set, then only repeat after date
        const dateCheck = rule.date
          ? moment(currentDay, 'YYYY-MM-DD').isSameOrAfter(moment(rule.date, 'YYYY-MM-DD'))
          : true

        // check to make sure the current day is a day we repeat on
        // if it contains, -2 , that means we repeat every 2 weeks
        let repeatCheck = false
        if (rule.repeats.findIndex(r => r.repeat === 'every2Weeks') > -1) {
          const ruleRepeatWeekNumber = moment(rule.date, 'YYYY-MM-DD').week()
          const currentDayWeekNumber = moment(currentDay, 'YYYY-MM-DD').week()
          repeatCheck = CalendarHelper.isEven(ruleRepeatWeekNumber) === CalendarHelper.isEven(currentDayWeekNumber)
        } else if (rule.repeats.findIndex(r => r.repeat === 'every3Weeks') > -1) {
          // every 3 weeks
          const ruleRepeatWeekNumber = moment(rule.date, 'YYYY-MM-DD').week()
          const currentDayWeekNumber = moment(currentDay, 'YYYY-MM-DD').week()
          repeatCheck = Math.abs(ruleRepeatWeekNumber - currentDayWeekNumber) % 3 === 0
        } else if (rule.repeats.findIndex(r => r.repeat === 'every4Weeks') > -1) {
          // every 4 weeks
          const ruleRepeatWeekNumber = moment(rule.date, 'YYYY-MM-DD').week()
          const currentDayWeekNumber = moment(currentDay, 'YYYY-MM-DD').week()
          repeatCheck = Math.abs(ruleRepeatWeekNumber - currentDayWeekNumber) % 4 === 0
        } else {
          const week = CalendarHelper.getWeekOfMonth(currentDay, moment(currentDay).format('ddd').toLowerCase())
          let weekStr
          if (week === 1) weekStr = 'every1stWeek'
          else if (week === 2) weekStr = 'every2ndWeek'
          else if (week === 3) weekStr = 'every3rdWeek'
          else if (week === 4) weekStr = 'every4thWeek'
          else if (week === 5) weekStr = 'every5thWeek'

          repeatCheck = rule.repeats.findIndex(r => r.repeat === weekStr) > -1
        }

        if (dateCheck && repeatCheck) {
          lowPriorityRule = rule
        }
      }
    }
  }
  // console.log(
  //   "rulesOnDay ",
  //   moment(currentDay).format("YYYY-MM-DD"),
  //   rulesOnDay
  // )
  return lowPriorityRule
}

// get the week of the month relative to the day of the week
CalendarHelper.getWeekOfMonth = (currentDay, dayOfWeekStr) => {
  // get week of month, this is relative to the day of week
  let weekOfMonth = 1
  // make this relative to the month of the current day
  const firstDayOfMonthDay = moment(currentDay).startOf('month').day()
  let firstDayOfMonthWeek = moment(currentDay).startOf('month').week()
  const dayOfWeekNum = moment(dayOfWeekStr, 'ddd').day()

  if (firstDayOfMonthDay > dayOfWeekNum) {
    // if first day of the month is in the previous week, move forward 1 week
    firstDayOfMonthWeek += 1
  }
  while (firstDayOfMonthWeek < currentDay.week()) {
    firstDayOfMonthWeek += 1
    weekOfMonth += 1
  }
  return weekOfMonth
}

// getCalendarStartEndDates -- returned in moment format
CalendarHelper.getCalendarStartEndDates = (intervalStart, intervalEnd) => {
  // get first calendar day
  let startDay
  let endDay
  if (moment(intervalStart).day() === 0) {
    startDay = moment(intervalStart)
  } else {
    startDay = moment(intervalStart).subtract(moment(intervalStart).day(), 'days')
  }

  if (moment(intervalEnd).day() === 6) {
    endDay = moment(intervalEnd)
  } else {
    endDay = moment(intervalEnd).add(6 - moment(intervalEnd).day(), 'days')
  }
  return { startDay, endDay }
}

// isEven
CalendarHelper.isEven = n => {
  return n % 2 === 0
}

// isOdd
CalendarHelper.isOdd = n => {
  return Math.abs(n % 2) === 1
}

export { CalendarHelper }
