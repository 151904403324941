import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap'

const EditCategoryModal = ({ authObj, categoryId, modalOpen, setModalOpen, refreshTable }) => {
  const [loader, setLoader] = useState(true)

  // fields
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [status, setStatus] = useState('active')

  const [errMsg, setErrMsg] = useState('')

  const editCategory = async () => {
    setErrMsg('Updating Category...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const updateObj = {
        categoryId,
        title,
        description,
        imageUrl,
        status
      }

      const res = await axios.put(`${config.apiUrl}/app/categories`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Category Updated Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to update category (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update category (2)')
    }
  }

  const getCategory = async cId => {
    setErrMsg('Fetching Category...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/categories/by-id?categoryId=${cId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setTitle(res.data.title)
        setDescription(res.data.description)
        setImageUrl(res.data.imageUrl)
        setStatus(res.data.status)

        setLoader(false)
      } else {
        setErrMsg('Unable to fetch category (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch category (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  useEffect(() => {
    if (categoryId) {
      getCategory(categoryId)
    }
  }, [categoryId])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Update Category</ModalHeader>
      {(loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner color='primary' />
        </div>
      )) || (
        <ModalBody>
          <Form>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for='title'>
                    <strong className='required-field'>Title</strong>
                  </Label>
                  <Input
                    type='text'
                    name='title'
                    placeholder='Title'
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='description'>
                    <strong>Description</strong>
                  </Label>
                  <br />
                  <textarea
                    name='description'
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value)
                    }}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='imageUrl'>
                    <strong>Image Url</strong>
                  </Label>
                  <Input
                    type='text'
                    name='imageUrl'
                    value={imageUrl}
                    onChange={e => {
                      setImageUrl(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='status'>
                    <strong>Status</strong>
                  </Label>
                  <Input
                    type='select'
                    name='status'
                    value={status}
                    onChange={e => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value='active'>Active</option>
                    <option value='inactive'>Inactive</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      )}

      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>
        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            editCategory()
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { EditCategoryModal }
