import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { API } from '../../../api/API'
import { FieldTag } from '../FormBuilder/FieldTypes'
import { Sheets } from './Sheets'
import { Gmail } from './Gmail'
import { Campminder } from './Campminder'

const useQuery = search => {
  return new URLSearchParams(useLocation().search).get(search)
}

const Step2 = ({ authObj }) => {
  const selectedDestination = useQuery('dest')
  const destMappingId = useQuery('id')
  const [form, setForm] = useState({})
  const [attendeeFields, setAttendeeFields] = useState([])
  const [guardianFields, setGuardianFields] = useState([])
  const [additionalFields, setAdditionalFields] = useState([])

  const getForm = async () => {
    const res = await API.getForms(authObj.account.accountId, authObj.token)
    if (res && res.data && res.data.length > 0) {
      const { fieldGroups, formFields } = res.data[0]
      setForm({ fieldGroups: fieldGroups || [], formFields: formFields || [] })
    }
  }

  useEffect(() => {
    if (form) {
      const fieldGroups = form.fieldGroups || []
      const formFields = form.formFields || []
      const fieldGroupMap = {}
      const attendeeFieldsTemp = []
      const guardianFieldsTemp = []
      const additionalFieldsTemp = []
      fieldGroups.forEach(fg => {
        fieldGroupMap[fg.id] = fg
      })
      formFields.forEach(ff => {
        if (
          [
            FieldTag.SINGLELINEINPUT,
            FieldTag.CHECKBOXES,
            FieldTag.DATE,
            FieldTag.DROPDOWN,
            FieldTag.MULTIPLECHOICE,
            FieldTag.PARAGRAPH
          ].includes(ff.type)
        ) {
          const fg = fieldGroupMap[ff.fieldGroupId] || {}
          if (fg.repeat === 'attendees') {
            attendeeFieldsTemp.push(ff)
          } else if (fg.repeat === 'guardians') {
            guardianFieldsTemp.push(ff)
          } else {
            additionalFieldsTemp.push(ff)
          }
        }
      })
      setAttendeeFields(attendeeFieldsTemp)
      setGuardianFields(guardianFieldsTemp)
      setAdditionalFields(additionalFieldsTemp)
    }
  }, [form])

  useEffect(() => {
    getForm()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12}>
            <Card body>
              <Row>
                <Col xs={12}>
                  <h4>Step2: Integrate and Map the fields</h4>
                  <hr />
                </Col>
                {selectedDestination === 'sheets'
                  ? (
                    <Sheets
                      authObj={authObj}
                      attendeeFields={attendeeFields}
                      guardianFields={guardianFields}
                      additionalFields={additionalFields}
                      destMappingId={destMappingId}
                    />
                    )
                  : selectedDestination === 'gmail'
                    ? (
                      <Gmail authObj={authObj} destMappingId={destMappingId} />
                      )
                    : selectedDestination === 'campminder'
                      ? (
                        <Campminder authObj={authObj} destMappingId={destMappingId} />
                        )
                      : null}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Step2 }
