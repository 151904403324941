import React from 'react'
import emptyWorkflow from '../../../../assets/icons/robots/emptyWorkflow.svg'

const EmptyWorkflow = () => {
  return (
        <div className='emptyWorkflowComponent'>
      <img
        className='emptyIcon'
        src={emptyWorkflow}
        alt='empty'
      />
      <div className="emptyTitle">
        You don’t have any workflows
      </div>
      <div className="emptySubtitle">
        Make a Request first to see any workflows
      </div>
    </div>
  )
}

export default EmptyWorkflow