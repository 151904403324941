import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API } from '../../../api/API'
import { config } from '../../../config'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap'

const EditProgramModal = ({ authObj, programId, modalOpen, setModalOpen, refreshTable }) => {
  const [loader, setLoader] = useState(true)

  // fields
  const [categoryId, setCategoryId] = useState(undefined)
  const [locationId, setLocationId] = useState(undefined)
  const [formId] = useState(undefined)
  const [title, setTitle] = useState('')
  const [view, setView] = useState('table')
  const [price, setPrice] = useState(undefined)
  const [imgUrl, setImgUrl] = useState('')
  const [description, setDescription] = useState('')
  const [minAttendees, setMinAttendees] = useState(undefined)
  const [maxAttendees, setMaxAttendees] = useState(undefined)
  const [minSessions, setMinSessions] = useState(undefined)
  const [maxSessions, setMaxSessions] = useState(undefined)
  const [sessionDurationType, setSessionDurationType] = useState('')
  const [sessionDurationValue, setSessionDurationValue] = useState('')
  const [sessionType, setSessionType] = useState('')
  const [status, setStatus] = useState('active')

  // variables from api
  const [, setCategories] = useState([])
  const [, setLocations] = useState([])
  const [, setForms] = useState([])

  const [errMsg, setErrMsg] = useState('')

  const editProgram = async () => {
    setErrMsg('Updating Program...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const updateObj = {
        programId,
        title,
        view,
        imgUrl,
        description,
        sessionDurationType,
        sessionDurationValue,
        sessionType,
        status
      }

      if (categoryId) {
        updateObj.categoryId = categoryId
      }
      if (locationId) {
        updateObj.locationId = locationId
      }
      if (formId) {
        updateObj.formId = formId
      }
      if (price) {
        updateObj.price = price
      }
      if (minAttendees) {
        updateObj.minAttendees = minAttendees
      }
      if (maxAttendees) {
        updateObj.maxAttendees = maxAttendees
      }
      if (minSessions) {
        updateObj.minSessions = minSessions
      }
      if (maxSessions) {
        updateObj.maxSessions = maxSessions
      }

      const res = await axios.put(`${config.apiUrl}/app/programs`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Program Updated Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to update program (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update program (2)')
    }
  }

  const getProgram = async cId => {
    setErrMsg('Fetching Program...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/programs/by-id?programId=${cId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setTitle(res.data.title)
        setCategoryId(res.data.categoryId)
        setLocationId(res.data.locationId)
        setView(res.data.view)
        setImgUrl(res.data.imgUrl)
        setDescription(res.data.description)
        setSessionDurationType(res.data.sessionDurationType)
        setSessionDurationValue(res.data.sessionDurationValue)
        setSessionType(res.data.sessionType)
        setStatus(res.data.status)
        if (res.data.price) {
          setPrice(res.data.price)
        }
        if (res.data.minAttendees) {
          setMinAttendees(res.data.minAttendees)
        }
        if (res.data.maxAttendees) {
          setMaxAttendees(res.data.maxAttendees)
        }
        if (res.data.minSessions) {
          setMinSessions(res.data.minSessions)
        }
        if (res.data.maxSessions) {
          setMaxSessions(res.data.maxSessions)
        }

        setLoader(false)
      } else {
        setErrMsg('Unable to fetch program (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch program (2)')
    }
  }

  const getCategories = async () => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/categories`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data && res.data.data) {
        setErrMsg('')

        setCategories(res.data)
      } else {
        setErrMsg('Unable to fetch categories (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch categories (2)')
    }
  }
  const getLocations = async () => {
    try {
      const res = await API.getLocations(authObj.account.accountId, authObj.token)
      if (res && res.data.data) {
        setErrMsg('')

        setLocations(res.data.data)
      } else {
        setErrMsg('Unable to fetch locations (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch locations (2)')
    }
  }
  const getForms = async () => {
    try {
      const res = await API.getForms(authObj.account.accountId, authObj.token)

      if (res && res.data) {
        setErrMsg('')

        setForms(res.data)
      } else {
        setErrMsg('Unable to fetch forms (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch forms (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
    getCategories()
    getLocations()
    getForms()
  }, [])

  useEffect(() => {
    if (programId) {
      getProgram(programId)
    }
  }, [programId])

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center' }}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Update Program</ModalHeader>
      {(loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner color='primary' />
        </div>
      )) || (
        <ModalBody>
          <Form>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for='title'>
                    <strong className='required-field'>Title</strong>
                  </Label>
                  <Input
                    type='text'
                    name='title'
                    placeholder='Title'
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              {/* <Col md={12}>
                <FormGroup>
                  <Label for="category">
                    <strong>Category</strong>
                  </Label>
                  <Input
                    type="select"
                    name="category"
                    value={categoryId}
                    onChange={e => {
                      setCategoryId(e.target.value)
                    }}
                  >
                    <option value="">No Category</option>
                    {categories.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.title}
                        </option>
                      )
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="location">
                    <strong>Location</strong>
                  </Label>
                  <Input
                    type="select"
                    name="location"
                    value={locationId}
                    onChange={e => {
                      setLocationId(e.target.value)
                    }}
                  >
                    <option value="">No Location</option>
                    {locations.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.title}
                        </option>
                      )
                    })}
                  </Input>
                </FormGroup>
              </Col> */}
              <Col md={12}>
                <FormGroup>
                  <Label for='view'>
                    <strong>View</strong>
                  </Label>
                  <Input
                    type='select'
                    name='status'
                    value={view}
                    onChange={e => {
                      setView(e.target.value)
                    }}
                  >
                    <option value='table'>Table</option>
                    <option value='calendar'>Calendar</option>
                  </Input>
                </FormGroup>
              </Col>
              {/* <Col md={12}>
                <FormGroup>
                  <Label for="form">
                    <strong>Forms</strong>
                  </Label>
                  <Input
                    type="select"
                    name="form"
                    value={formId}
                    onChange={e => {
                      setFormId(e.target.value)
                    }}
                  >
                    <option value="">No Form</option>
                    {forms.map(f => {
                      return (
                        <option key={f.id} value={f.id}>
                          {f.title}
                        </option>
                      )
                    })}
                  </Input>
                </FormGroup>
              </Col> */}
              <Col md={12}>
                <FormGroup>
                  <Label for='description'>
                    <strong>Description</strong>
                  </Label>
                  <br />
                  <textarea
                    name='description'
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value)
                    }}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='imgUrl'>
                    <strong>Image Url</strong>
                  </Label>
                  <Input
                    type='text'
                    name='imgUrl'
                    value={imgUrl}
                    onChange={e => {
                      setImgUrl(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              {/* <Col md={12}>
                <FormGroup>
                  <Label for="price">
                    <strong>Price (00.00)</strong>
                  </Label>
                  <Input
                    type="text"
                    name="price"
                    value={price || ""}
                    onChange={e => {
                      setPrice(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="minAttendees">
                    <strong>Min Attendees</strong>
                  </Label>
                  <Input
                    type="text"
                    name="minAttendees"
                    value={minAttendees || ""}
                    onChange={e => {
                      setMinAttendees(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="maxAttendees">
                    <strong>Max Attendees</strong>
                  </Label>
                  <Input
                    type="text"
                    name="maxAttendees"
                    value={maxAttendees || ""}
                    onChange={e => {
                      setMaxAttendees(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="minSessions">
                    <strong>Min Sessions</strong>
                  </Label>
                  <Input
                    type="text"
                    name="minSessions"
                    value={minSessions || ""}
                    onChange={e => {
                      setMinSessions(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="maxSessions">
                    <strong>Max Sessions</strong>
                  </Label>
                  <Input
                    type="text"
                    name="maxSessions"
                    value={maxSessions || ""}
                    onChange={e => {
                      setMaxSessions(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col> */}
              <Col md={12}>
                <FormGroup>
                  <Label for='status'>
                    <strong>Visible To Public</strong>
                  </Label>
                  <Input
                    type='select'
                    name='status'
                    value={status}
                    onChange={e => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value='active'>Yes</option>
                    <option value='inactive'>No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      )}

      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>

        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            editProgram()
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { EditProgramModal }
