import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { API } from '../../../api/API'
import sharpspringLogo from '../../../assets/icons/sharpspringPortalLogo.svg'

const Sharpspring = () => {
  const { userData } = useSelector(state => state.auth)
  const appId = new URLSearchParams(window.location.search).get('id')
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const integrateApp = async () => {
    if (apiKey.length === 0 || apiSecret.length === 0) {
      return setErrMsg('Fields cannot be empty')
    }
    setLoading(true)
    const res = await API.createMyApp(userData.account.accountId, userData.token, appId, {
      apiKey,
      apiSecret
    })
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to integrate app. Please try again.')
    }
    window.close()
  }

  if (isLoading) {
    return (
      <div className='loader'>
        <div className='spinner' />
        <div className='text'>Loading, please wait</div>
      </div>
    )
  }

  return (
    <div className='sharpspringLoginPage'>
      <div className='container'>
        <div className='mainArea'>
          <div className='logoArea'>
            <img className='logo' src={sharpspringLogo} alt='Sharpspring' />
          </div>
          <div className='horizontalLine' />
          <p className='welcomeText'>Welcome Back!</p>
          <div className='inputArea'>
            <input
              type='text'
              id='username'
              placeholder='Username'
              autoFocus
              value={apiKey}
              onChange={e => setApiKey(e.target.value)}
            />
          </div>
          <div className='inputArea'>
            <input
              type='text'
              id='password'
              placeholder='Password'
              value={apiSecret}
              onChange={e => setApiSecret(e.target.value)}
            />
          </div>
          <button className='submit' type='submit' onClick={() => integrateApp()}>
            Log In
          </button>
          <p className='errorMessage'>{errMsg}</p>
        </div>
      </div>
    </div>
  )
}

export { Sharpspring }
