import React from 'react'
import { FormGroup, Label, Input, Row, Col, Form } from 'reactstrap'

const Settings = ({ field, updateFieldSettings, setFieldOrderFunc }) => {
  return (
    <Row key={field.id}>
      <Col md='12'>
        <Form>
          <div>Field type: Button</div>
          <FormGroup>
            <Label htmlFor={field.id + '_label'}>Label</Label>
            <Input
              type='text'
              key={field.id + '_label'}
              id={field.id + '_label'}
              name={field.id + '_label'}
              placeholder=''
              value={field.displayName}
              onChange={async e => {
                updateFieldSettings(field.id, { displayName: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_instructions'}>Instructions</Label>
            <Input
              type='textarea'
              name={field.id + '_instructions'}
              id={field.id + '_required'}
              value={field.instructions}
              onChange={e => {
                updateFieldSettings(field.id, { instructions: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_size'}>Size</Label>
            <select
              name={field.id + '_size'}
              id={field.id + '_size'}
              value={field.width}
              onChange={e => {
                updateFieldSettings(field.id, { width: e.target.value })
              }}
            >
              <option value={33}>small</option>
              <option value={50}>medium</option>
              <option value={100}>large</option>
            </select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_order'}>Order</Label>
            <Input
              type='number'
              id={field.id + '_order'}
              name={field.id + '_order'}
              value={field.order}
              onChange={e => {
                setFieldOrderFunc(field.id, Number(e.target.value))
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_action'}>Action</Label>
            <select
              className='form-control'
              onChange={e => updateFieldSettings(field.id, { action: e.target.value })}
              value={field.action}
            >
              <option value='none'>None</option>
              <option value='next_step'>Next step</option>
              <option value='prev_step'>Prev step</option>
              <option value='calculate_total'>Calculate Total</option>
              <option value='submit_payment'>Submit Payment</option>
            </select>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

export default Settings
