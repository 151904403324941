import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import editIcon from '../../../assets/icons/editIcon.svg'
import { config } from '../../../config'
import { getMyClients } from '../../../services/clients.services'

const ClientRow = props => {
  const dispatch = useDispatch()
  const { client } = props
  const [userIdHover, setUserIdHover] = useState(false)
  const [isCopied, setCopied] = useState(false)
  

  const getApps = () => {
    const filteredApps = props?.apps.map(app => props?.allApps?.find(a => a?.slug === app?.app?.slug))
    if (filteredApps.length > 6) {
      return (
        <>
          {filteredApps.slice(0, 5).map((app, i) => (
            <div className='imageContainer' key={`${app?.slug}${i}`}>
              <img src={app.icon} className='icon' />
            </div>
          ))}
          <div className='imageContainer'>
            <div className='moreApp'>{`+${props.apps?.length - 5}`}</div>
          </div>
        </>
      )
    } else {
      return filteredApps.map((app, i) => (
        <div className='imageContainer' key={`${app?.slug}${i}`}>
          <img src={app?.icon} className='icon' />
        </div>
      ))
    }
  }

  const copyToClipboard = (e, id) => {
    e.stopPropagation()
    navigator.clipboard.writeText(id)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const setEditing = (e, client) => {
    if (props.editableClient) {
      props.setEditableClient(null)
    } else {
      e.stopPropagation()
      props.setEditableClient(client)
    }
  }

  const updateUserIdHandler = (e) => {
    e.preventDefault()
    if (props.editableClient.userId !== props.newUserId) {
      const data = {
        id: props.editableClient?.id,
        email: props.editableClient?.email,
        userId: props.newUserId
      }
      axios.put(`${config.apiUrl}/app/workflow-clients`, data, {
        headers: {
          accountid: props.editableClient?.accountId,
          Authorization: `Bearer ${props.token}`
        }
      }).then((res) => {
        if (res?.data?.msg === 'success') {
          props.setEditableClient(null)
          props.setNewUserId('')
          dispatch(getMyClients(props.editableClient?.accountId, props?.token, false))
        }
      }).catch(err => console.log(err))
    } else { 
      props.setEditableClient(null)
    }    
  }

  return (
    <tr
      key={`${client.email}${client.userId}`}
      onClick={() => {
        if (props.editableClient) {
          if (client.email !== props.editableClient.email) { 
            props.setEditableClient(null)
            props.setNewUserId('')
          }
        } else {
          props.showClientDetails(true)
          props.setSelectedClient(props.client)
        }
      }}
      className={`${props.editableClient && props.editableClient.email !== client.email ? 'unselectedRow' : 'editableRow'}`}
    >
      <td>{client.email}</td>
      <td>
        <div className='editableArea'>
          {props.editableClient?.email === client.email
            ? (
              <>
                <input
                  type='text'
                  value={props.newUserId}
                  onChange={(e) => props.setNewUserId(e.target.value)}
                  className='editUsername'
                  placeholder='Enter User ID'
                  autoFocus
                />
                <button
                  className='transparentButton'
                  onClick={(e) => updateUserIdHandler(e)}
                >
                  Save
                </button>
              </>
              )
            : (
              <>
                <img src={editIcon} className='icon' alt='' onClick={e => setEditing(e, client)} />
                <div
                  className='text'
                  onClick={e => copyToClipboard(e, client.email)}
                  onMouseEnter={() => setUserIdHover(true)}
                  onMouseLeave={() => setUserIdHover(false)}
                >
                  {client.userId}
                </div>
              </>
              )}
          {userIdHover && <div className='tooltip'>{isCopied ? 'Copied' : 'Copy'}</div>}
        </div>
      </td>
      <td>{client.apps.length}</td>
      <td>
        <div className='appsContainer'>{getApps()}</div>
      </td>
      <td>{client.error > 0 ? <div className='errorContainer'>{client.error}</div> : ''}</td>
    </tr>
  )
}

export default ClientRow
