import React from 'react'

const ProfileIcon = ({ color }) => {
  return (
    <svg className='icon' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke={ color } stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 17.333C18.2091 17.333 20 15.5421 20 13.333C20 11.1239 18.2091 9.33301 16 9.33301C13.7909 9.33301 12 11.1239 12 13.333C12 15.5421 13.7909 17.333 16 17.333Z" stroke={ color } stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.224 25.1317C8.55401 24.0333 9.22929 23.0706 10.1497 22.3863C11.07 21.702 12.1865 21.3327 13.3333 21.333H18.6667C19.815 21.3326 20.9328 21.7029 21.8538 22.3887C22.7749 23.0746 23.45 24.0394 23.7787 25.1397" stroke={ color } stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default ProfileIcon