/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React from 'react'
import retrainPricing from '../../../assets/img2/retrainPricing.svg'
import callIcon from '../../../assets/img2/greyCall.svg'
import messageIcon from '../../../assets/img2/greyMessage.svg'

const RetrainAI = () => {
  return (
    <div className='deleteModal'>
      <div className='title'>
        Re-train your AI
      </div>
      <img src={retrainPricing} alt='' />
      <div className='subtitle'>
        Please schedule a call with us using this link: <span><a href='https://calendly.com/carl333' target='_blank' rel='noreferrer'>https://calendly.com/carl333</a></span>
      </div>
      <div className='contactDetails'>Got questions, contact us at:</div>
      <div className='row'>
        <img src={callIcon} className='icon' alt='' />
        <div className='contactDetails'>646-466-6473 (Text only)</div>
      </div>
      <div className='row'>
        <img src={messageIcon} className='icon' alt='' />
        <div className='contactDetails'>support@champselect.ai</div>
      </div>
    </div>
  )
}

export { RetrainAI }
