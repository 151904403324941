import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import { Row, Col, Button, Spinner, Input, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

import { AddDiscountModal } from './AddDiscountModal'
import { EditDiscountModal } from './EditDiscountModal'

const Discounts = ({ authObj }) => {
  const [discounts, setDiscounts] = useState([])

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(null)

  const [loader, setLoader] = useState(true)

  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editDiscountId, setEditDiscountId] = useState(null)
  const [errMsg, setErrMsg] = useState('')

  const getDiscounts = async () => {
    setErrMsg('Fetching discounts...')
    try {
      let url = `${config.apiUrl}/app/discounts`
      if (search) {
        url += `?search=${search}`
      }
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setDiscounts(res.data)
        setLoader(false)
      } else {
        setErrMsg('Unable to get discounts (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get discounts (2) : ${JSON.stringify(err)}`)
    }
  }

  const remove = async discountId => {
    setErrMsg('Deleting discounts...')
    try {
      const res = await axios.delete(`${config.apiUrl}/app/discounts?discountId=${discountId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        // refresh table
        getDiscounts()
      } else {
        setErrMsg('Unable to remove discount (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove discount (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    if (search) {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        setErrMsg('Searching...')
        getDiscounts()
      }, 1000)
      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    getDiscounts()
  }, [])

  return (
    <div data-testid='discount-component'>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box '>
                <header className='panel_header'>
                  <h2 className='title float-left'>Discounts</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    <Button
                      data-testid='create-discount-button'
                      color='primary'
                      onClick={() => {
                        setModalOpen(true)
                      }}
                    >
                      Create Discounts
                    </Button>
                  </div>
                </header>
                <Col md={12} style={{ zIndex: '999' }}>
                  <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
                  <div
                    style={{
                      width: '250px',
                      paddingRight: '30px',
                      marginLeft: 'auto',
                      marginBottom: '20px'
                    }}
                  >
                    Search:
                    <Input
                      data-testid='app-discount-search-input-box'
                      type='text'
                      placeholder=''
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value)
                      }}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {discounts &&
                            discounts.map(d => {
                              return (
                                <tr key={d.id}>
                                  <td>{d.title}</td>
                                  <td>{d.type}</td>
                                  <td>{d.amount}</td>
                                  <td>{d.status}</td>
                                  <td>{d.actions}</td>
                                  <td>
                                    <span
                                      data-testid={`discount-${d.id}-edit`}
                                      style={{ marginRight: '10px', cursor: 'pointer' }}
                                      onClick={() => {
                                        setEditModalOpen(true)
                                        setEditDiscountId(d.id)
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} size='lg' />
                                    </span>
                                    <span
                                      data-testid={`discount-${d.id}-delete`}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const r = window.confirm(`Are you sure you want to remove ${d.title || d.id}`)
                                        if (r === true) {
                                          remove(d.id)
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} size='lg' />
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
      <AddDiscountModal
        authObj={authObj}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        refreshTable={getDiscounts}
      />
      <EditDiscountModal
        authObj={authObj}
        discountId={editDiscountId}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        refreshTable={getDiscounts}
      />
    </div>
  )
}

export { Discounts }
