import React from 'react'
import { Table } from 'reactstrap'
import moment from 'moment'
import styles from './Reservation.module.scss'

function ReservationExpandedInfo(props) {
  const { reservation } = props

  return (
    <>
      <tr className={styles.tableRow}>
        <td className={styles.tableCol}>
          <Table>
            <tbody>
              {reservation &&
                reservation.programs &&
                reservation.programs.map((program, i) => {
                  return (
                    <tr key={`${program?.id}_${i}`}>
                      <td>
                        <td className={styles.tableCell} component="th" scope="tr">
                          <a href={`reservations/${reservation.reservationId}`}>{program?.title}</a>
                        </td>
                      </td>
                    </tr>
                  )
                })}
              <tr />
            </tbody>
          </Table>
        </td>
        <td className={styles.tableCol}>
          <Table>
            <tbody>
              {reservation &&
                reservation.sessions &&
                reservation.sessions.map((session, i) => {
                  return (
                    <tr key={`${session?.id}_${i}`}>
                      <td className={styles.tableCell} component="th" scope="tr">
                        {session?.title}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </td>
        <td className={styles.tableCol}>
          <Table>
            <tbody>
              {reservation &&
                reservation.attendees &&
                reservation.attendees.map((attendee, i) => (
                  <tr key={`${attendee.id}_${i}`}>
                    <td className={styles.tableCell} component="th" scope="tr">
                      {`${attendee.firstName} ${attendee.lastName}`}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </td>
        <td className={styles.tableCol}>
          <Table>
            <tbody>
              {reservation &&
                reservation.guardians &&
                reservation.guardians.map((guardian, i) => (
                  <tr key={`${guardian.id}_${i}`}>
                    <td className={styles.tableCell} component="th" scope="tr">
                      {`${guardian.firstName} ${guardian.lastName}`}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </td>
        <td className={styles.tableCol}>
          <Table>
            <tbody>
              <tr>
                <td className={styles.tableCell} component="th" scope="tr">
                  <Table>
                    <tbody>{reservation && reservation.paidBy && <td>{reservation.paidBy.name}</td>}</tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </td>
        <td className={styles.tableCol}>
          <Table>
            <tbody>
              <tr>
                <td className={styles.tableCell} component="th" scope="tr">
                  $ {reservation.total}
                </td>
              </tr>
            </tbody>
          </Table>
        </td>
        <td className={styles.tableCol}>
          <Table>
            <tbody>
              <tr>
                <td className={styles.tableCell} component="th" scope="tr">
                  {moment(reservation.createdAt).format('MM-DD-YYYY HH:mm a')}
                </td>
              </tr>
            </tbody>
          </Table>
        </td>
      </tr>
    </>
  )
}

export default ReservationExpandedInfo
