import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Route, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Layout } from '../../layouts/Layout2.jsx'
import { config } from '../../config'
import userSlice from '../../redux/slice/user.slice'
import { useDispatch } from 'react-redux'
import { loadGeneralSettings, loadProfileData } from '../../services/profile.service.js'
import { loadProcesses } from '../../services/process.service.js'
import { getAllApps, getAuthUrls, getMyApps } from '../../services/my-apps.service.js'
import { getMyClients } from '../../services/clients.services.js'
import { loadBillingDetails } from '../../services/billing.services.js'
import { getOrders } from '../../services/order.services.js'

const ProtectedRoute = ({ path, component: Component, exact, dnd, withoutWrapper }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [cookies, setCookie] = useCookies(['champselectToken', 'champselectActiveAccount'])

  // Example:
  // const authObj = {
  //   token: "",
  //   user: {},
  //   account: {}
  // }
  const [authObj, setAuthObj] = useState(undefined)

  // accounts available to this user
  const [accounts, setAccounts] = useState([])

  const [, setErrMsg] = useState('')

  const login = () => {
    history.push('/login')
  }

  const changeProfile = accountId => {
    const changeTo = accounts.filter(user => {
      return user.accountId === accountId
    })
    if (changeTo.length < 1) return
    setCookie('champselectActiveAccount', changeTo[0].accountId, { domain: document.location.hostname, path: '/' })
    // eslint-disable-next-line no-undef
    window && location.reload()
  }

  // try to validate token and get meta data
  useEffect(async () => {
    if (cookies) {
      const token = cookies.champselectToken
      const activeAccountId = cookies.champselectActiveAccount
      let activeAccount
      if (!token) {
        return login()
      }
      try {
        const response = await axios.post(`${config.apiUrl}/public/auth/validate`, { token })
        if (response && response.data && response.data.user && response.data.accounts) {
          response.data.accounts.forEach(item => {
            if (item.accountId === activeAccountId) activeAccount = item
          })
          setAuthObj({
            token,
            user: response.data.user,
            account: activeAccount || response.data.accounts[0]
          })
          dispatch(
            userSlice.actions.setUser({
              token,
              user: response.data.user,
              account: activeAccount || response.data.accounts[0]
            })
          )
          // dispatch(loadTeamUsers(activeAccountId, token))
          dispatch(loadGeneralSettings(activeAccountId, token))
          dispatch(loadProfileData(activeAccountId, token))
          dispatch(loadProcesses(activeAccountId, token, true))
          dispatch(getMyApps(activeAccountId, token))
          dispatch(getAllApps(activeAccountId, token))
          dispatch(getAuthUrls(activeAccountId))
          dispatch(getMyClients(activeAccountId, token))
          dispatch(getOrders(activeAccountId, token, true))
          setAccounts(response.data.accounts)
          setErrMsg('')
        } else {
          login()
        }
      } catch (err) {
        console.log('Unable to login. Please contact support for help! Code 2', err)
        login()
      }
    } else {
      login()
    }
  }, [cookies])

  return (
    <>
      {authObj && (
        <Route
          path={path}
          exact={exact}
          render={props => {
            return (
              <>
                {/* <div style={{ color: 'red' }}>{errMsg}</div>
                <Layout
                  component={Component}
                  authObj={authObj}
                  dnd={dnd || false}
                  accounts={accounts}
                  withoutWrapper={withoutWrapper}
                  changeProfile={changeProfile}
                /> */}
                <Layout
                  component={Component}
                  authObj={authObj}
                  accounts={accounts}
                  dnd={dnd || false}
                  changeProfile={changeProfile}
                />
              </>
            )
          }}
        />
      )}
    </>
  )
}

export { ProtectedRoute }
