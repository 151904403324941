import { faCalendarAlt, faListOl, faProjectDiagram, faTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const SideBar = ({ companyLogo }) => {
  return (
    <div className='container' id='sidebar' style={{ display: 'inline-block', width: '100%' }}>
      <img
        className='rounded mx-auto d-block img-fluid'
        src={companyLogo}
        style={{ width: '150px', height: '100px' }}
      />
      <hr />
      <div>
        <span className='icon-stack'>
          <FontAwesomeIcon
            icon={faListOl}
            style={{
              marginTop: '7px',
              marginRight: '10px',
              color: '#2f6f64'
            }}
          />
          Number of Attendee(s): 2
        </span>
      </div>
      <div>
        <span className='icon-stack'>
          <FontAwesomeIcon
            icon={faProjectDiagram}
            style={{
              marginTop: '7px',
              marginRight: '9px',
              color: '#00BCD4'
            }}
          />
          Summer Camp
        </span>
      </div>
      <hr />
      <div>
        <span className='icon-stack'>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{
              marginTop: '7px',
              marginRight: '5px',
              color: '#FF5722'
            }}
          />
          Slots
        </span>
      </div>
      <div className='row'>
        <div className='row col-12'>
          <div className='col-6' style={{ fontSize: '0.8rem' }}>
            <div>
              Day Session
              <br />
              Premium
            </div>
          </div>
          <span className='col-6' style={{ fontSize: '15px' }}>
            $100 x 2 = $200
          </span>
        </div>
      </div>
      <span>
        <hr />
        <div>
          <div className='row md-12'>
            <div className='col-8' style={{ fontSize: '0.8rem' }}>
              <span className='icon-stack'>
                <FontAwesomeIcon
                  icon={faTag}
                  style={{
                    marginTop: '7px',
                    marginRight: '10px',
                    color: '#673ab7'
                  }}
                />
                Discount(50):
              </span>
            </div>
            <div className='col-4' style={{ fontSize: '0.8rem' }}>
              Multi-session discount
            </div>
          </div>
        </div>
      </span>
      <hr />
      <div className='row md-12'>
        <div className='col-5'>Promo Code:</div>
        <div className='col-4'>
          <input style={{ width: '100%', border: '1px solid #000' }} disabled />
        </div>
        <div className='col-3'>
          <button className='btn btn-edbridge btn-sm'>Add</button>
        </div>
      </div>
      <br />
      <br />
      <div className='row md-12'>
        <div className='col-8'>Total:</div>
        <div className='col-4'>${150}</div>
      </div>
    </div>
  )
}

export default SideBar
