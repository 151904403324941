import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import moment from 'moment-timezone'

const Session = ({ session, duplicate, setEditSessionModalOpen, setEditSessionId, removeSession, reorder }) => {
  const ref = useRef(null)

  const [isMouseIn, setIsMouseIn] = useState(false)

  const [{ isDragging }, draggable] = useDrag({
    item: { id: session.id, type: 'session' },
    type: 'session',
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  })

  const [{ isOver }, drop] = useDrop(
    {
      accept: ['session'],
      canDrop: () => {
        return true
      },
      drop: (obj, monitor) => {
        reorder(session.id, obj.id)
      },
      collect: monitor => {
        return {
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop()
        }
      }
    },
    [session] // dependencies
  )

  return (
    <tr
      ref={draggable(drop(ref))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        outline: isMouseIn || isDragging ? '1px dashed black' : 'none',
        backgroundColor: isOver ? '#f2f2f2' : '#fff'
      }}
      onMouseEnter={() => setIsMouseIn(true)}
      onMouseLeave={() => setIsMouseIn(false)}
    >
      <td>{session.title}</td>
      <td>
        {!(session.hideDate && session.hideTime) &&
          session.startTime &&
          moment
            .unix(session.startTime)
            .tz('America/Los_Angeles')
            .format(session.hideDate ? 'HH:mm a' : session.hideTime ? 'MM/DD/yyyy' : 'MM/DD/yyyy HH:mm a')}
      </td>
      <td>
        {!(session.hideDate && session.hideTime) &&
          session.endTime &&
          moment
            .unix(session.endTime)
            .tz('America/Los_Angeles')
            .format(session.hideDate ? 'HH:mm a' : session.hideTime ? 'MM/DD/yyyy' : 'MM/DD/yyyy HH:mm a')}
      </td>
      <td>{session.status}</td>
      <td>
        <button
          className='btn btn-primary btn-xs'
          style={{ marginRight: '10px' }}
          onClick={() => {
            const r = window.confirm(`Are you sure you want to duplicate ${session.title || session.id}`)
            if (r === true) {
              duplicate(session.id)
            }
          }}
        >
          Duplicate
        </button>
        <span
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => {
            setEditSessionModalOpen(true)
            setEditSessionId(session.id)
          }}
        >
          <FontAwesomeIcon icon={faEdit} size='lg' />
        </span>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const r = window.confirm(`Are you sure you want to remove ${session.title || session.id}`)
            if (r === true) {
              removeSession(session.id)
            }
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} size='lg' />
        </span>
      </td>
    </tr>
  )
}

export { Session }
