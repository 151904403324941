import React, { useEffect, useState } from 'react'
import { Col, Spinner, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { API } from '../../../api/API'
import googleLogo from '../Settings/googleLogo.svg'

const Gmail = ({ authObj, destMappingId }) => {
  const history = useHistory()

  const [isGmailIntegrated, setIsGmailIntegrated] = useState(false)
  const [oauthUrl, setOauthUrl] = useState(undefined)
  const [loader, setLoader] = useState(false)
  const [templates, setTemplates] = useState(undefined)
  const [selectedTemplate, setSelectedTemplate] = useState(undefined)

  const validateGmailIntegration = async () => {
    setLoader(true)
    const res = await API.getSettings(authObj.account.accountId, authObj.token)
    if (res && res.data) {
      if (res.data.isGoogleIntegrated) {
        setIsGmailIntegrated(true)
      } else {
        setIsGmailIntegrated(false)
        setOauthUrl(res.data.generatedUrl)
      }
    } else {
      setIsGmailIntegrated(false)
    }
    setLoader(false)
  }

  const getTemplates = async () => {
    setLoader(true)
    const res = await API.getTemplates(authObj.account.accountId, authObj.token)
    if (res && res.data) {
      setTemplates(res.data)
    }
    setLoader(false)
  }

  const getMapping = async () => {
    const res = await API.getMappings(authObj.account.accountId, authObj.token, destMappingId)
    if (res && res.data && res.data.length > 0) {
      const { templateId } = res.data[0]
      setSelectedTemplate(templateId)
    }
  }

  const createMapping = async () => {
    setLoader(true)
    const templateTitle = templates.find(t => t.id === selectedTemplate).title
    const res = await API.createMapping(authObj.account.accountId, authObj.token, {
      title: `GMail-${templateTitle}`,
      destination: 'gmail',
      templateId: selectedTemplate,
      status: 'active'
    })
    if (res && res.data) {
      history.push('/data-mappings')
    }
    setLoader(false)
  }

  const updateMapping = async () => {
    setLoader(true)
    const templateTitle = templates.find(t => t.id === selectedTemplate).title
    const res = await API.updateMapping(authObj.account.accountId, authObj.token, {
      destMappingId,
      title: `GMail-${templateTitle}`,
      destination: 'gmail',
      templateId: selectedTemplate,
      status: 'active'
    })
    if (res && res.data) {
      history.push('/data-mappings')
    }
    setLoader(false)
  }

  useEffect(() => {
    if (destMappingId && destMappingId !== '') {
      getMapping()
    }
  }, [destMappingId])

  useEffect(() => {
    if (isGmailIntegrated) {
      getTemplates()
    }
  }, [isGmailIntegrated])

  useEffect(() => {
    validateGmailIntegration()
  }, [])

  return (
    <Col xs={12}>
      {!isGmailIntegrated && oauthUrl && (
        <button
          className='btn'
          style={{ border: 'none', background: '#f2f2f2', borderRadius: '5px', padding: '5px' }}
          onClick={() => {
            const googleAuthWindow = window.open(oauthUrl, '', 'width=500,height=500')
            const checkWindowClose = setInterval(() => {
              if (googleAuthWindow.closed) {
                clearInterval(checkWindowClose)
                validateGmailIntegration()
              }
            }, 500)
          }}
        >
          <img src={googleLogo} />
          Sign in to Gmail
        </button>
      )}
      {templates && (
        <Row style={{ alignItems: 'flex-end' }}>
          <Col xs={12} md={9}>
            <label htmlFor='selectTemplate'>Select an Email Template</label>
            <select
              id='selectTemplate'
              className='form-control'
              onChange={e => setSelectedTemplate(e.target.value)}
              value={selectedTemplate}
            >
              <option>Select</option>
              {templates.map(t => (
                <option key={t.id} value={t.id}>
                  {t.title}
                </option>
              ))}
            </select>
          </Col>
          <Col xs={12} md={3}>
            <button className='btn btn-light' onClick={() => history.push('/templates/create')}>
              New template
            </button>
          </Col>
        </Row>
      )}
      {loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner animation='border' variant='primary' />
        </div>
      )}
      {selectedTemplate && (
        <div style={{ marginTop: '10px' }}>
          {destMappingId
            ? (
              <button className='btn btn-primary float-right' onClick={() => updateMapping()}>
                Update
              </button>
              )
            : (
              <button className='btn btn-primary float-right' onClick={() => createMapping()}>
                Create
              </button>
              )}
        </div>
      )}
    </Col>
  )
}

export { Gmail }
