import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Calendar } from '../../../components/Calendar/Calendar'
import { API } from '../../../api/API'

function CalendarPage ({ authObj }) {
  const [calendarEvents, setCalendarEvents] = useState({})
  const [errMsg, setErrMsg] = useState('')
  const history = useHistory()

  const setCalendarEventsHelper = locations => {
    const tempEvents = {}
    locations.forEach(location => {
      const rules = location.rules
      if (rules) {
        rules.forEach(rule => {
          if (!tempEvents[rule.date]) tempEvents[rule.date] = []
          tempEvents[rule.date].push({
            locationId: location.id,
            ruleId: rule.id,
            title: location.title,
            type: rule.type,
            repeats: rule.repeats.map(r => r.repeat),
            wday: rule.wday,
            date: rule.date
          })
        })
      }
    })
    setCalendarEvents(tempEvents)
  }

  // startDate and endDate in YYYY-MM-DD
  const getLocations = async (startDate, endDate) => {
    setErrMsg('Fetching locations...')
    try {
      const res = await API.getLocations(
        authObj.account.accountId,
        authObj.token,
        false,
        true,
        true,
        startDate,
        endDate
      )
      if (res && res.data) {
        setErrMsg('')
        setCalendarEventsHelper(res.data.data)
      } else {
        setErrMsg('Unable to get locations (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get locations (2) : ${JSON.stringify(err)}`)
    }
  }

  const goToReservations = (locationId, ruleId, date) => {
    history.push({
      pathname: '/locations/reservations',
      search: `locationId=${locationId}&ruleId=${ruleId}&date=${date}`
    })
  }

  return (
    <div>
      <div className='content'>
        <p style={{ width: '100%', color: 'red' }}>{errMsg}</p>
        <Calendar
          events={calendarEvents}
          isCalendarEvent
          getLocations={getLocations}
          goToReservations={goToReservations}
        />
      </div>
    </div>
  )
}

export { CalendarPage }
