import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { config } from '../../config'
import ReactPixel from 'react-facebook-pixel'
import { Link, useHistory } from 'react-router-dom'
import champSelectLogo from '../../assets/img2/champselect-logo-with-text.svg'

const Signup = () => {
  const history = useHistory()
  const [, setCookie] = useCookies(['champselectToken', 'champselectActiveAccount', 'champselectUserId'])

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const signup = async (e) => {
    e.preventDefault()
    setErrMsg('Signing up...')
    if (!firstName) {
      return setErrMsg('First Name is required.')
    }
    if (!lastName) {
      return setErrMsg('Last Name is required.')
    }
    if (!email) {
      return setErrMsg('Email Name is required.')
    }
    if (!password) {
      return setErrMsg('Password is required.')
    }
    if (password !== confirmPassword) {
      return setErrMsg("Passwords don't match.")
    }
    try {
      const response = await axios.post(
        `${config.apiUrl}/public/auth/signup`,
        {
          firstName,
          lastName,
          email,
          password,
          companyName,
          timeZone: moment.tz.guess(),
          plan: 'free'
        },
        {}
      )

      if (response && response.data) {
        // set token
        setCookie('champselectToken', response.data.token, {
          domain: document.location.hostname,
          path: '/',
          expires: new Date(moment.unix(response.data.expires).toDate())
        })
        setCookie('champselectActiveAccount', response.data.accounts[0].accountId, { domain: document.location.hostname, path: '/' })
        setCookie('champselectUserId', response.data.userId, { domain: document.location.hostname, path: '/' })
        setErrMsg('')
        ReactPixel.track('SignedUp', {
          firstName,
          lastName,
          email
        })
        history.push('/router')
      } else {
        return setErrMsg('Unable to signup. Please contact support for help! Code 1')
      }
    } catch (err) {
      if (err && err.response && err.response.data && (err.response.data.msg || err.response.data.err)) {
        return setErrMsg(`${err.response.data.msg || err.response.data.err}`)
      }
      return setErrMsg('Unable to signup. Please contact support for help! Code 2')
    }
  }

  return (
    <div className='signup'>
      <div className='left-container'>
        <div className='transparent'>
          <p>
            <strong>Allowing your clients to integrate your app with the world!</strong>
          </p>
        </div>
      </div>
      <div className='right-container'>
        <form className='form-container' onSubmit={signup}>
          <img src={champSelectLogo} className='logo' alt='champselect-logo' style={{ width: '220px', marginBottom: '16px', paddingTop: '70px' }} />
          <div className='title'>Sign up - ChampSelect.ai</div>
          <div className='basic-details-container'>
            <div className='inputArea'>
              <label htmlFor='firstName'>First Name</label>
              <input
                id='firstName'
                type='text'
                placeholder='Your First Name'
                required
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div className='inputArea'>
              <label htmlFor='lastName'>Last Name</label>
              <input
                id='lastName'
                type='text'
                placeholder='Your Last Name'
                required
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className='basic-details-container'>
            <div className='inputArea'>
              <label htmlFor='email'>Email</label>
              <input
                id='email'
                type='email'
                placeholder='Your Email'
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className='basic-details-container borderLine'>
            <div className='inputArea'>
              <label htmlFor='password'>Password</label>
              <input
                id='password'
                type='password'
                placeholder='Your Password'
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className='inputArea'>
              <label htmlFor='confirm-password'>Confirm Password</label>
              <input
                id='confirm-password'
                type='password'
                placeholder='Re-type Your Password'
                required
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div className='basic-details-container'>
            <div className='inputArea'>
              <label htmlFor='business-name'>Business Name</label>
              <input
                id='business-name'
                type='text'
                placeholder='Your Business Name'
                required
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
            </div>
          </div>
          <span className='err'>{errMsg}</span>
          <button
            type='submit'
            onClick={(e) => signup(e)}
          >
            Sign Up
          </button>
          <p className='signup-link'>
            Already have an account?{' '}
            <span>
              <Link to='/login'>Login</Link>
            </span>
          </p>
          <div className='copyrightContainer'>
            <p className='copyright-msg'>Copyright © 2022 ChampSelect.ai. All rights reserved</p>
          </div>
        </form>
      </div>
    </div>
  )
}

export { Signup }
