import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  settings: null,
  profileData: null
}

const setLoading = (state = initialState, action) => {
  return {
    ...state,
    isLoading: action.payload
  }
}

const setSettings = (state = initialState, action) => {
  return {
    ...state,
    settings: action.payload
  }
}
const setProfileData = (state = initialState, action) => {
  return {
    ...state,
    profileData: action.payload
  }
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading,
    setSettings,
    setProfileData
  }
})

export default profileSlice
