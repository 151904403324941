import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import { Row, Col, Button, Spinner, Input, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

import { AddServiceModal } from './AddServiceModal'
import { EditServiceModal } from './EditServiceModal'

const Services = ({ authObj }) => {
  const [services, setServices] = useState([])

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(null)

  const [loader, setLoader] = useState(true)

  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editServiceId, setEditServiceId] = useState(null)
  const [errMsg, setErrMsg] = useState('')

  const getServices = async () => {
    setErrMsg('Fetching services...')
    try {
      let url = `${config.apiUrl}/app/services`
      if (search) {
        url += `?search=${search}`
      }
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setServices(res.data)
        setLoader(false)
      } else {
        setErrMsg('Unable to get services (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get services (2) : ${JSON.stringify(err)}`)
    }
  }

  const remove = async serviceId => {
    setErrMsg('Deleting services...')
    try {
      const res = await axios.delete(`${config.apiUrl}/app/services?serviceId=${serviceId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        // refresh table
        getServices()
      } else {
        setErrMsg('Unable to remove service (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove service (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    if (search) {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        setErrMsg('Searching...')
        getServices()
      }, 1000)
      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    getServices()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box '>
                <header className='panel_header'>
                  <h2 className='title float-left'>Services</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    <Button
                      color='primary'
                      onClick={() => {
                        setModalOpen(true)
                      }}
                    >
                      Create Service
                    </Button>
                  </div>
                </header>
                <Col md={12} style={{ zIndex: '999' }}>
                  <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
                  <div
                    style={{
                      width: '250px',
                      paddingRight: '30px',
                      marginLeft: 'auto',
                      marginBottom: '20px'
                    }}
                  >
                    Search:
                    <Input
                      type='text'
                      placeholder=''
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value)
                      }}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {services &&
                            services.map(s => {
                              return (
                                <tr key={s.id}>
                                  <td>{s.title}</td>
                                  <td>{s.category}</td>
                                  <td>{s.price}</td>
                                  <td>{s.status}</td>
                                  <td>
                                    <span
                                      style={{ marginRight: '10px', cursor: 'pointer' }}
                                      onClick={() => {
                                        setEditModalOpen(true)
                                        setEditServiceId(s.id)
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} size='lg' />
                                    </span>
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const r = window.confirm(`Are you sure you want to remove ${s.title || s.id}`)
                                        if (r === true) {
                                          remove(s.id)
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} size='lg' />
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
      <AddServiceModal authObj={authObj} modalOpen={modalOpen} setModalOpen={setModalOpen} refreshTable={getServices} />
      <EditServiceModal
        authObj={authObj}
        serviceId={editServiceId}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        refreshTable={getServices}
      />
    </div>
  )
}

export { Services }
