import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { config } from '../../../config'
import { useSelector, useDispatch } from 'react-redux'
import { loadProcesses } from '../../../services/process.service'
import { UpdateBillingCard } from '../../../components/UpdateBillingCard/UpdateBillingCard'
import greenCheckmark from '../../../assets/img2/greenCheckmark.svg'
import howItWorks from '../../../assets/img2/howItWorks.svg'
import leftArrow from '../../../assets/img2/arrowLeft.svg'

import amexLogo from '../../../assets/img2/americanExpressCard.svg'
import masterLogo from '../../../assets/img2/masterCard.svg'
import visaLogo from '../../../assets/img2/visaCard.svg'
import add from '../../../assets/img2/add-blue-circle-fill.svg'
import previousPriceWithText from '../../../assets/img2/previousPriceWithText.svg'
import callIcon from '../../../assets/img2/greyCall.svg'
import messageIcon from '../../../assets/img2/greyMessage.svg'

import axios from 'axios'

const CreateWorkflowLegacy = ({ authObj }) => {
  const history = useHistory()
  const { cardData } = useSelector(state => state.billing)
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  const [videoLink] = useState('null')
  const [aiName, setAiName] = useState('')
  const [remarksModal, showRemarksModal] = useState(false)
  const [updatePaymentInformationModal, showUpdatePaymentInformationModal] = useState(false)

  const onNameSubmit = async e => {
    e.preventDefault()
    if (aiName.length < 3) {
      setErrMsg('AI Name length should be greater than 3')
    } else {
      const postingData = {
        title: aiName,
        videoUrl: videoLink
      }

      try {
        const response = await axios.post(`${config.apiUrl}/app/processes`, postingData, {
          headers: {
            Authorization: `Bearer ${authObj.token}`,
            accountid: authObj.account.accountId
          }
        })

        if (response && response.data) {
          setAiName('')
          setErrMsg('')
          setErrMsg('')
          dispatch(loadProcesses(authObj.account.accountId, authObj.token))
          showRemarksModal(true)
        } else {
          setErrMsg('Unable to get processes information')
        }
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.msg) {
          return setErrMsg(`${err.response.data.msg}`)
        }
        return setErrMsg('Unable to get processes information. Please contact support for help!')
      }
    }
  }

  return (
    <>
      <div className='hirePage'>
        <div className='leftContainer'>
          <div className='headerContainer'>
            <div className='backContainer' onClick={() => history.push('/workflow')}>
              <img src={leftArrow} className='icon' alt='' />
            </div>
            <div className='title'>Create Workflow</div>
          </div>
          <div className='bodyContainer'>
            <form onSubmit={onNameSubmit}>
              <div className='label'>1. Name your Workflow</div>
              <input
                placeholder='Enter Your AI Name'
                value={aiName}
                type='text'
                className='inputField'
                onChange={e => setAiName(e.target.value)}
              />
              <div className='horizontalLine' />
              <div className='label'>
                2. Set Credit Card
                <img src={greenCheckmark} className='icon' alt='' />
              </div>
              {!cardData?.msg && (
                <div className='changePaymentOption' onClick={() => showUpdatePaymentInformationModal(true)}>
                  Change payment option
                </div>
              )}
              {cardData?.msg
                ? (
                  <div className='addPaymentMethod' onClick={() => showUpdatePaymentInformationModal(true)}>
                    <img src={add} className='icon' />
                    Add Payment Method
                  </div>
                  )
                : (
                  <div className='cc-row'>
                    {cardData?.brand === 'Visa' && (
                      <div className='leftRow'>
                        <img src={visaLogo} alt='Visa' />
                        <p className='name'>Visa Debit Card</p>
                      </div>
                    )}
                    {cardData?.brand === 'Mastercard' && (
                      <div className='leftRow'>
                        <img src={masterLogo} alt='MasterCard' />
                        <div className='name'>Master Debit Card</div>
                      </div>
                    )}
                    {cardData?.brand === 'American Express' && (
                      <div className='leftRow'>
                        <img src={amexLogo} alt='American Express' />
                        <div className='name'>American Express Card</div>
                      </div>
                    )}
                    <span className='number'>**** **** **** {cardData?.last4}</span>
                  </div>
                  )}
              <div className='horizontalLine' />
              <div className='label gray'>Quotation</div>
              <div className='subtitle gray'>Here’s an estimate of the quotation for your AI</div>
              <div className='quotationContainer'>
                <div className='column'>
                  <div className='greyText'>Estimated Price</div>
                  <div className='price'>
                    $ 99 <span>per month</span>
                  </div>
                </div>
                <img src={previousPriceWithText} alt='' />
              </div>
              {errMsg && <p className='errorMessage'>{errMsg}</p>}
              <div className='row'>
                <button
                  type='submit'
                  disabled={cardData?.msg}
                  className={`submitButton ${cardData?.msg && 'disabled'}`}
                  onClick={onNameSubmit}
                >
                  Submit Request
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='rightContainer'>
          <div className='verticalLine' />
          <div className='emptyContainer'>
            <p className='howItWorks'>How It Works</p>
            <img src={howItWorks} className='howItWorksImage' alt='' />
          </div>
        </div>
      </div>

      {updatePaymentInformationModal && (
        <div className='showUpdatePaymentInformationModal' onClick={() => showUpdatePaymentInformationModal(false)} />
      )}
      {updatePaymentInformationModal && (
        <UpdateBillingCard showUpdatePaymentInformationModal={showUpdatePaymentInformationModal} />
      )}

      {remarksModal && <div className='backdropOverlay' onClick={() => showRemarksModal(false)} />}
      {remarksModal && (
        <div className='remarksModal'>
          <div className='title'>Almost There!</div>
          <div className='subtitle'>
            Our team will be working hard to develop your AI. We’ll be in touch with you within 24-48 hours.
          </div>
          <div className='title'>AI Request Received!</div>
          <div className='row'>
            <img src={greenCheckmark} className='icon' alt='' />
            Your setup fee of $50 has been waived!
          </div>
          <div className='contactDetails'>Got questions, contact us at:</div>
          <div className='contactRow'>
            <img src={callIcon} className='icon' alt='' />
            <div className='contactDetails'>646-466-6473 (Text only)</div>
          </div>
          <div className='contactRow'>
            <img src={messageIcon} className='icon' alt='' />
            <div className='contactDetails'>support@champselect.ai</div>
          </div>
          <button
            className='backButton'
            onClick={() => {
              showRemarksModal(false)
              history.push('/workflow')
            }}
          >
            Back to Home
          </button>
        </div>
      )}
    </>
  )
}

export { CreateWorkflowLegacy }
