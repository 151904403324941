import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { config } from '../../config'
import champSelectLogo from '../../assets/img2/champselect-logo-with-text.svg'
import emailIcon from '../../assets/img2/email-icon.svg'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [disabledResetButton, setDisabledResetButton] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  const resetPassword = async (e) => {
    e.preventDefault()
    setErrMsg('Creating Reset Link...')
    try {
      // validation
      if (!email) {
        return setErrMsg('Email cannot be blank.')
      }

      const response = await axios.post(
        `${config.apiUrl}/public/auth/forgot-password`,
        {
          email
        },
        {}
      )

      if (response && response.data) {
        setDisabledResetButton(true)
        return setErrMsg(`${response.data.msg}`)
      } else {
        return setErrMsg('Unable to reset password. Please contact support for help! Code 1')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to reset password. Please contact support for help! Code 2')
    }
  }

  return (
    <div className='forgot-password'>
      <div className='left-container'>
        <div className='transparent'>
          <p>
            <strong>Allowing your clients to integrate your app with the world!</strong>
          </p>
        </div>
      </div>
      <div className='right-container'>
        <div className='form-container'>
          <img src={champSelectLogo} alt='champselect-logo' style={{ width: '220px', marginBottom: '16px' }} />
          <div className='title'>Forgot Password</div>
          <div className='subtitle'>We will email you a link to change your password</div>
          <form onSubmit={resetPassword}>
            <div className='inputArea'>
              <img src={emailIcon} className='inputIcon' alt='' />
              <input
                type='text'
                className='form-control'
                id='email'
                placeholder='Your Email Address'
                required
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <span className='err'>{errMsg}</span>
            <button
              className='btn login_btn'
              type='submit'
              style={{ marginTop: '24px' }}
              onClick={resetPassword}
              disabled={disabledResetButton}
            >
              Send Reset Password Link
            </button>
          </form>
          <p className='signup-link'>
            Don’t have an account?{' '}
            <span>
              <Link to='/signup'>Sign Up now</Link>
            </span>
          </p>
          <p className='copyright-msg'>Copyright © 2022 ChampSelect.ai. All rights reserved</p>
        </div>
      </div>
    </div>
  )
}

export { ForgotPassword }
