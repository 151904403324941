import React from 'react'

const ConfirmationModal = ({ hideModal, saveHandler }) => {
  return (
    <div className="confirmationModal">
      <div className="title">Save Changes?</div>
      <div className="subtitle">
        Do you want to save your changes before you exit? This will automatically turn into your Draft Orders.
      </div>
      <div className="buttonsArea">
        <div className="secondaryBorderedButton" onClick={() => hideModal()}>Back to Editing</div>
        <div className="horizontalSpace" />
        <div className="primaryButton" onClick={() => saveHandler()}>Save Changes</div>
      </div>
    </div>
  )
}

export default ConfirmationModal