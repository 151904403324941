/* eslint-disable no-tabs */
import { useState } from 'react'
import { config } from '../../config'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import { loadBillingDetails } from '../../services/billing.services'
import cardGray from '../../assets/img2/card-gray.svg'
import axios from 'axios'

const UpdateBillingCard = ({ authObj, showUpdatePaymentInformationModal, isUpdate }) => {
  const dispatch = useDispatch()

  const [cookie] = useCookies(['champselectToken', 'champselectActiveAccount', 'champselectUserId'])
  const [cardErrorMessage, setCardErrorMessage] = useState('')

  const [name, setName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [cvv, setCvv] = useState('')
  const [expDate, setExpDate] = useState('')
  const [zip, setZip] = useState('')

  const dateToday = new Date()

  const updatePaymentDetails = async e => {
    e.preventDefault()
    let expMon, expYear, yearAndMonth

    if (expDate.includes('/') || expDate.includes('-')) {
      if (expDate.includes('/')) {
        yearAndMonth = expDate.split('/')
      } else if (expDate.includes('-')) {
        yearAndMonth = expDate.split('/')
      }
      expYear = yearAndMonth[1]
      expMon = yearAndMonth[0]
      if (
        parseInt(expYear) > parseInt(dateToday.getFullYear().toString().slice(-2)) ||
        (expYear.length === 2 && expMon.length === 2) ||
        (parseInt(expMon) > 12 && parseInt(expMon) < 1)
      ) {
        expYear = parseInt(`${dateToday.getFullYear().toString().substring(0, 2)}${expYear}`)
        expMon = parseInt(expMon)
      } else {
        return setCardErrorMessage('Please insert valid expiry date as month/year')
      }
    } else {
      return setCardErrorMessage('Please insert expiry date as month/year')
    }

    if (!cardNumber || !cvv || !expYear || !expMon || !zip) {
      return setCardErrorMessage('Fill all Fields')
    }
    setCardErrorMessage('Updating Card Information...')
    const card = {
      cvc: cvv,
      number: cardNumber.trim().toString(),
      exp_month: expMon,
      exp_year: expYear,
      address_zip: zip,
      object: 'card'
    }

    try {
      const response = await axios.put(
        `${config.apiUrl}/app/subscribe/stripe-card`,
        {
          card: card
        },
        {
          headers: {
            accountid: cookie.champselectActiveAccount,
            Authorization: `Bearer ${cookie.champselectToken}`
          }
        }
      )

      if (response && response.data) {
        setCardErrorMessage('')
        dispatch(loadBillingDetails(cookie.champselectActiveAccount, cookie.champselectToken))
        showUpdatePaymentInformationModal(false)
      } else {
        return setCardErrorMessage('Unable to subscribe. Please contact support for help! Code 1')
      }
    } catch (err) {
      if (err && err.response && err.response.data && (err.response.data.msg || err.response.data.err)) {
        return setCardErrorMessage(`${err.response.data.msg || err.response.data.err}`)
      }
      return setCardErrorMessage('Unable to subscribe. Please contact support for help! Code 2')
    }
  }

  return (
    <div className="updatePaymentContainer">
      <div className="titleArea">
        <div className="title">{isUpdate ? 'Update' : 'Add'} Payment Method</div>
        <span>{isUpdate ? 'Update' : 'Add'} your desired payment method</span>
      </div>
      <form onSubmit={updatePaymentDetails}>
        <div className="row" style={{ justifyContent: 'space-between' }}>
          <div className="inputGroup" style={{ width: '100%' }}>
            <label htmlFor="validationDefaultCN">Name on Card</label>
            <input
              type="text"
              className="form-control"
              id="validationDefaultCN"
              placeholder="Enter your Name on Card"
              required
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ width: '100%' }}>
          <div className="inputGroup" style={{ width: '50%', marginRight: '8px', position: 'relative' }}>
            <label htmlFor="validationDefaultCN">Card Number</label>
            <input
              type="text"
              className="form-control"
              id="validationDefaultCN"
              placeholder="XXXX XXXX XXXX XXXX"
              required
              value={cardNumber}
              onChange={e => setCardNumber(e.target.value)}
              style={{ paddingLeft: '40px' }}
            />
            <img src={cardGray} className="inputFieldIcon" />
          </div>
          <div className="inputGroup" style={{ width: '25%', marginRight: '8px' }}>
            <label htmlFor="validationDefaultEM">Expiry</label>
            <input
              type="text"
              className="form-control"
              id="validationDefaultEM"
              placeholder="XX / XX"
              required
              value={expDate}
              onChange={e => setExpDate(e.target.value)}
            />
          </div>
          <div className="inputGroup" style={{ width: '25%' }}>
            <label htmlFor="validationDefaultCVV">CVV</label>
            <input
              type="text"
              className="form-control"
              id="validationDefaultCVV"
              placeholder="XXX"
              required
              value={cvv}
              onChange={e => setCvv(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="inputGroup" style={{ width: '30%' }}>
            <label htmlFor="validationDefaultCN">Zip</label>
            <input
              type="text"
              className="form-control"
              id="validationDefaultCN"
              placeholder="XXXXX"
              required
              value={zip}
              onChange={e => setZip(e.target.value)}
            />
          </div>
        </div>
        <div className="errorText" style={{ flex: 1 }}>
          <p style={{ color: '#fd7062' }}>{cardErrorMessage}</p>
        </div>
        <button type="submit" onSubmit={updatePaymentDetails} className="submit">
          {isUpdate ? 'Update' : 'Add'} Payment Method
        </button>
        <button onClick={() => showUpdatePaymentInformationModal(false)} className="cancel">
          Cancel
        </button>
      </form>
    </div>
  )
}

export { UpdateBillingCard }
