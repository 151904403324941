import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { FormGroup, Label, Button } from 'reactstrap'

import Settings from './Settings'
import { FieldType } from '../../FieldTypes'

import amexLogo from './amex.jpg'
import masterLogo from './mastercard.jpg'
import visaLogo from './visa.jpg'

const Payment = ({
  field,
  removeFunc,
  setFieldOrderFunc,
  updateCurrentSetting,
  setFieldSettingsModalOpen,
  updateFieldSettings
}) => {
  const [isMouseIn, setIsMouseIn] = useState(false)

  // draggable
  const [{ isDragging }, draggable] = useDrag({
    item: { id: field.id, parentId: field.fieldGroupId, type: FieldType.PAYMENT },
    type: FieldType.PAYMENT,
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      }
    }
  })

  return (
    <div
      ref={draggable}
      style={{
        width: '100%',
        border: isMouseIn || isDragging ? '1px dashed black' : 'none',
        cursor: 'move',
        backgroundColor: isDragging ? 'lightblue' : 'white'
      }}
    >
      <FormGroup
        onMouseEnter={() => {
          setIsMouseIn(true)
        }}
        onMouseLeave={() => {
          setIsMouseIn(false)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Label>
            {field.required ? '* ' : ''}
            {field.displayName}
          </Label>
          <div style={{ display: 'flex' }}>
            <Button
              size='xs'
              color='primary'
              title='Settings'
              onClick={() => {
                updateCurrentSetting(
                  <Settings
                    field={field}
                    setFieldOrderFunc={setFieldOrderFunc}
                    updateFieldSettings={updateFieldSettings}
                  />
                )
                setFieldSettingsModalOpen(true)
              }}
            >
              <i className='fas fa-tools' />
            </Button>
            <Button
              size='xs'
              color='danger'
              title='Remove'
              onClick={() => {
                removeFunc(field.id)
              }}
            >
              <i className='fas fa-times' />
            </Button>
          </div>
        </div>
        <div className='row' id='creditCardContainer' style={{ margin: '20px 0' }}>
          <div id='creditCardID'>
            <div className='creditCardForm'>
              <div className='payment'>
                <form>
                  <div className='form-group owner'>
                    <label htmlFor='owner'>Full Name</label>
                    <input type='text' className='form-control' disabled />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='cvv'>CVV</label>
                    <input type='text' className='form-control' disabled />
                  </div>
                  <div className='form-group' id='card-number-field'>
                    <label htmlFor='cardNumber'>Card Number</label>
                    <input id='cardNumber' type='text' className='form-control' disabled />
                  </div>
                  <div className='form-group' id='expiration-date'>
                    <label>Expiration Date</label>
                    <select id='expMon' style={{ fontSize: '18px' }} disabled>
                      <option value='01'>January</option>
                      <option value='02'>February</option>
                      <option value='03'>March</option>
                      <option value='04'>April</option>
                      <option value='05'>May</option>
                      <option value='06'>June</option>
                      <option value='07'>July</option>
                      <option value='08'>August</option>
                      <option value='09'>September</option>
                      <option value='10'>October</option>
                      <option value='11'>November</option>
                      <option value='12'>December</option>
                    </select>
                    <select id='expYear' style={{ fontSize: '18px' }} disabled>
                      <option value='20'> 2020</option>
                      <option value='21'> 2021</option>
                      <option value='22'> 2022</option>
                      <option value='23'> 2023</option>
                      <option value='24'> 2024</option>
                      <option value='25'> 2025</option>
                      <option value='26'> 2026</option>
                      <option value='27'> 2027</option>
                      <option value='28'> 2028</option>
                      <option value='29'> 2029</option>
                      <option value='30'> 2030</option>
                    </select>
                  </div>
                  <div className='form-group' id='zip-field'>
                    <label htmlFor='zip'>Zip</label>
                    <input type='text' className='form-control' id='zip' disabled />
                  </div>
                  <div className='form-group' id='credit_cards'>
                    <img src={visaLogo} id='visa' />
                    <img src={masterLogo} id='mastercard' />
                    <img src={amexLogo} id='amex' />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <span>{field.instructions}</span>
      </FormGroup>
    </div>
  )
}

export default Payment
