import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../../config'

import { Calendar } from '../../../components/Calendar/Calendar'

import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap'
import { RuleModal } from './RuleModal'

const EditLocation = () => {
  const location = useLocation()
  const history = useHistory()

  // to be filled when location hook updated
  const [authObj, setAuthObj] = useState(undefined)
  const [locationId, setLocationId] = useState(undefined)

  const [, setLoader] = useState(true)

  const [ruleModalOpen, setRuleModalOpen] = useState(false)

  // fields
  const [title, setTitle] = useState('')
  const [area, setArea] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [notes, setNotes] = useState('')
  const [status, setStatus] = useState('active')
  const [userIds, setUserIds] = useState([])
  const [rules, setRules] = useState([])
  const [initialRule, setInitialRule] = useState(undefined)
  const [ruleDate, setRuleDate] = useState(moment())
  const [availableUsers, setAvailableUsers] = useState([])

  const [errMsg, setErrMsg] = useState('')

  // calendar events
  const [calendarEvents, setCalendarEvents] = useState({})

  const editLocation = async () => {
    setErrMsg('Updating Location...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const updateObj = {
        locationId,
        title,
        area,
        phone,
        address,
        city,
        state,
        zip,
        country,
        description,
        imageUrl,
        notes,
        status,
        rules,
        userIds
      }

      const res = await axios.put(`${config.apiUrl}/app/locations`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Location Updated Successfully.')
        // refreshTable()
      } else {
        setErrMsg('Unable to update location (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update location (2)')
    }
  }

  // helper function for setting the rules returned by the api
  const setRulesHelper = rules => {
    // setting the rules
    const tempRules = []
    if (rules) {
      rules.forEach(rule => {
        const tempRule = {}
        tempRule.type = rule.type
        tempRule.date = rule.date
        if (rule.intervals) {
          tempRule.intervals = rule.intervals.map(i => i.interval)
        }
        if (rule.userIds) {
          tempRule.userIds = rule.userIds.map(u => u.userId)
        }
        if (rule.repeats) {
          tempRule.repeats = rule.repeats.map(r => r.repeat)
        }
        if (rule.wday) {
          tempRule.wday = rule.wday
        }
        tempRules.push(tempRule)
      })
    }
    return tempRules
  }

  const getLocation = async (locationId, startDate, endDate) => {
    setErrMsg('Fetching Location...')
    try {
      const res = await axios.get(
        `${config.apiUrl}/app/locations/by-id?locationId=${locationId}&includeRules=true&includeUsers=true&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res && res.data) {
        setTitle(res.data.title || '')
        setArea(res.data.area || '')
        setPhone(res.data.phone || '')
        setAddress(res.data.address || '')
        setCity(res.data.city || '')
        setState(res.data.state || '')
        setZip(res.data.zip || '')
        setCountry(res.data.country || '')
        setDescription(res.data.description || '')
        setImageUrl(res.data.imageUrl || '')
        setNotes(res.data.notes || '')
        setStatus(res.data.status || '')
        setRules(setRulesHelper(res.data.rules))
        if (res.data.users) {
          setUserIds(res.data.users.map(u => u.userId))
        }

        setErrMsg('')
        setLoader(false)
      } else {
        setErrMsg('Unable to fetch location (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch location (2)')
    }
  }

  const getUsers = async () => {
    setErrMsg('Fetching Staff...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/users?active=true`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setAvailableUsers(res.data)
      } else {
        setErrMsg('Unable to fetch staff (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch staff (2)')
    }
  }

  // handles rule changes from the RuleModal
  const onRuleChange = rule => {
    const tempRules = rules
    // set userIds on new rule
    setUserIds([...rule.userIds])

    // @todo refactor this or move to helper class
    // if type day rule exists for this date, remove it
    tempRules.forEach((r, i) => {
      if (r.type === 'day' && r.date === rule.date) {
        tempRules.splice(i, 1)
      }
    })
    rule.accountId = authObj.accountId
    tempRules.push(rule)
    setRules([...tempRules])
  }

  useEffect(() => {
    setErrMsg('')
    if (authObj) getUsers()
  }, [authObj])

  useEffect(() => {
    setErrMsg('')
    if (locationId) {
      const startDate = moment().startOf('month').startOf('week').startOf('day').format('YYYY-MM-DD')
      const endDate = moment().endOf('month').endOf('week').endOf('day').format('YYYY-MM-DD')
      getLocation(locationId, startDate, endDate)
    }
  }, [locationId])

  useEffect(() => {
    setAuthObj(location.state.authObj)
    setLocationId(location.state.locationId)
  }, [location])

  // finding a rule on a particular date
  useEffect(() => {
    if (rules) {
      const ruleOnThisDate = rules.find(r => moment(ruleDate).isSame(r.date))
      setInitialRule(ruleOnThisDate)
    }
  }, [ruleDate])

  // set calendar events when rules are updated
  useEffect(() => {
    if (calendarEvents) {
      rules.forEach(rule => {
        if (!rule.intervals || rule.intervals.length < 1) {
          const event = { title: 'Unavailable', type: rule.type }
          // add repeats if type is wday
          if (rule.type === 'wday') {
            event.repeats = rule.repeats
            event.wday = rule.wday
          }
          calendarEvents[rule.date] = [event]
        } else {
          calendarEvents[rule.date] = rule.intervals.map(i => {
            const interval = i.split(',')
            const event = {
              title: `${interval[0]} - ${interval[1]}`,
              type: rule.type
            }
            // add repeats if type is wday
            if (rule.type === 'wday') {
              event.repeats = rule.repeats
              event.wday = rule.wday
            }
            return event
          })
        }
      })
      setCalendarEvents({ ...calendarEvents })
    }
  }, [rules])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <header className='panel_header'>
              <h2 className='title float-left'>Update Location</h2>
              <div style={{ padding: '30px', float: 'right' }}>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => {
                    history.push('/locations')
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size='sm'
                  color='primary'
                  onClick={() => {
                    editLocation()
                  }}
                >
                  Update
                </Button>
              </div>
            </header>
          </Col>
          <Col xs={12} md={12} style={{ padding: '30px' }}>
            <section className='box' style={{ padding: '30px' }}>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <Col md={12}>
                <FormGroup>
                  <Label for='title'>
                    <strong className='required-field'>Title</strong>
                  </Label>
                  <Input
                    type='text'
                    name='title'
                    placeholder='Title'
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='area'>
                    <strong>Area</strong>
                  </Label>
                  <Input
                    type='text'
                    name='area'
                    value={area}
                    onChange={e => {
                      setArea(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='phone'>
                    <strong>Phone</strong>
                  </Label>
                  <Input
                    type='text'
                    name='phone'
                    value={phone}
                    onChange={e => {
                      setPhone(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='address'>
                    <strong>Address</strong>
                  </Label>
                  <Input
                    type='text'
                    name='address'
                    value={address}
                    onChange={e => {
                      setAddress(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for='state'>
                    <strong>State</strong>
                  </Label>
                  <Input
                    type='text'
                    name='state'
                    value={state}
                    onChange={e => {
                      setState(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for='zip'>
                    <strong>Zip</strong>
                  </Label>
                  <Input
                    type='text'
                    name='zip'
                    value={zip}
                    onChange={e => {
                      setZip(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='country'>
                    <strong>Country</strong>
                  </Label>
                  <Input
                    type='text'
                    name='country'
                    value={country}
                    onChange={e => {
                      setCountry(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='description'>
                    <strong>Description</strong>
                  </Label>
                  <br />
                  <textarea
                    name='description'
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value)
                    }}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='imageUrl'>
                    <strong>Image Url</strong>
                  </Label>
                  <Input
                    type='text'
                    name='imageUrl'
                    value={imageUrl}
                    onChange={e => {
                      setImageUrl(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='notes'>
                    <strong>Notes</strong>
                  </Label>
                  <br />
                  <textarea
                    name='notes'
                    value={notes}
                    onChange={e => {
                      setNotes(e.target.value)
                    }}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='status'>
                    <strong>Status</strong>
                  </Label>
                  <Input
                    type='select'
                    name='status'
                    value={status}
                    onChange={e => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value='active'>Active</option>
                    <option value='inactive'>Inactive</option>
                  </Input>
                </FormGroup>
              </Col>
            </section>
          </Col>
          <Col xs={12} md={12} style={{ padding: '30px' }}>
            <section className='box' style={{ padding: '30px' }}>
              <h4>Staff Members</h4>
              <div style={{ height: '200px', overflow: 'scroll' }}>
                {availableUsers.map(p => {
                  return (
                    <div key={p.id}>
                      <Label check htmlFor={`users_${p.id}`}>
                        <Input
                          style={{ margin: 0 }}
                          type='checkbox'
                          id={`users_${p.id}`}
                          name={`users_${p.id}`}
                          checked={userIds.indexOf(p.id) > -1}
                          onChange={e => {
                            const programPos = userIds.indexOf(p.id)
                            if (e.target.checked) {
                              // on check
                              if (programPos === -1) {
                                setUserIds(programs => [...programs, p.id])
                              }
                            } else {
                              // on uncheck
                              if (programPos > -1) {
                                const pId = userIds[programPos]
                                setUserIds(userIds.filter(programId => programId !== pId))
                              }
                            }
                          }}
                        />{' '}
                        {p.firstName || ''} {p.lastName || ''} - {p.email || ''}
                      </Label>
                    </div>
                  )
                })}
              </div>
            </section>
          </Col>
          <Col xs={12} md={12} style={{ padding: '30px' }}>
            <section className='box' style={{ padding: '30px' }}>
              <Calendar
                dayClickedFunc={dateStr => {
                  setRuleDate(moment(dateStr, 'YYYY-MM-DD'))
                  setRuleModalOpen(true)
                }}
                events={calendarEvents}
                monthYearChangeFunc={(month, year, startDate, endDate) => {
                  if (locationId) {
                    getLocation(locationId, startDate, endDate)
                  }
                }}
              />
            </section>
          </Col>
        </Row>
        <Row>
          <div className='panel_header' style={{ width: '100%' }}>
            <div style={{ padding: '30px', float: 'right' }}>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <Button
                size='sm'
                color='danger'
                onClick={() => {
                  history.push('/locations')
                }}
              >
                Cancel
              </Button>
              <Button
                size='sm'
                color='primary'
                onClick={() => {
                  editLocation()
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Row>
      </div>
      <RuleModal
        authObj={authObj}
        modalOpen={ruleModalOpen}
        setModalOpen={setRuleModalOpen}
        availableUsers={availableUsers}
        onRuleChange={onRuleChange}
        ruleDate={ruleDate}
        initialRule={initialRule}
      />
    </div>
  )
}

export { EditLocation }
