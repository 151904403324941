import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const UpdateRelationModal = ({
  isUpdateRelationModalOpen,
  setIsUpdateRelationModalOpen,
  allRelations,
  sourceName,
  targetName,
  updateRelation
}) => {
  const [errMsg] = useState('')
  const [targetToSourceRelation, setTargetToSourceRelation] = useState('')
  const [sourceToTargetRelation, setSourceToTargetRelation] = useState('')

  useEffect(() => {
    if (allRelations.length > 0) {
      setTargetToSourceRelation(allRelations[0])
      setSourceToTargetRelation(allRelations[0])
    }
  }, [allRelations])

  return (
    <Modal style={{ height: '100%', display: 'flex', alignItems: 'center' }} show={isUpdateRelationModalOpen} onHide={() => setIsUpdateRelationModalOpen(false)}>
      <Modal.Header>
        <Modal.Title>Update Relation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errMsg}</p>
        <div>
          <span>
            {sourceName} is {targetName}'s{' '}
          </span>
          <select value={sourceToTargetRelation} onChange={e => setSourceToTargetRelation(e.target.value)}>
            {allRelations.map(r => (
              <option key={r} value={r}>
                {r}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginTop: '10px' }}>
          <span>
            {targetName} is {sourceName}'s{' '}
          </span>
          <select value={targetToSourceRelation} onChange={e => setTargetToSourceRelation(e.target.value)}>
            {allRelations.map(r => (
              <option key={r} value={r}>
                {r}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='success'
          size='sm'
          onClick={() => updateRelation(targetToSourceRelation, sourceToTargetRelation)}
        >
          Update
        </Button>
        <Button variant='primary' size='sm' onClick={() => setIsUpdateRelationModalOpen(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { UpdateRelationModal }
