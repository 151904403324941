import React from 'react'
import AppsOfferedIcon from '../Icons/AppsOffered'

const PageTitle = props => {

  const getIconComponent = pageName => { 
    switch (pageName) { 
      case 'appsOffered': 
        return <AppsOfferedIcon color={props?.theme === 'dark' ? '#ffffff' : '#424451'} />
      default: 
        return null
    }
  }

  return (
    <div className='pageTitleArea'>
      <div className='leftArea'>
        {props?.page ? (
          <div className='titleIcon' >
          {getIconComponent(props?.page)}
        </div>
        ) : (
          <img src={props?.icon} className='titleIcon' alt='' />
        )}
        <div className='column'>
          <h2 className='titleText'>{props?.title}</h2>
          <p className='subtitleText'>{props.subtitle}</p>
        </div>
      </div>
      <div className='rightArea'>
        {props.children}
      </div>
    </div>
  )
}

export default PageTitle
