import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useHistory } from 'react-router-dom'
import { config } from '../../config'
import champSelectLogo from '../../assets/img2/champselect-logo-with-text.svg'
import emailIcon from '../../assets/img2/email-icon.svg'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ResetPassword = () => {
  const history = useHistory()

  const query = useQuery()
  const code = query.get('code')

  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newConfirmPassword, setNewConfirmPassword] = useState('')

  const [disabledResetButton, setDisabledResetButton] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  const resetPassword = async (e) => {
    e.preventDefault()
    setErrMsg('Resetting Password...')
    try {
      // validation
      if (!email) {
        return setErrMsg('Email cannot be blank.')
      }
      if (!newPassword) {
        return setErrMsg('New Password cannot be blank.')
      }
      if (!newConfirmPassword) {
        return setErrMsg('Please re-type your new password.')
      }
      if (newPassword !== newConfirmPassword) {
        return setErrMsg('Confirm password cannot be different.')
      }

      const response = await axios.post(
        `${config.apiUrl}/public/auth/reset-password`,
        {
          code,
          email,
          newPassword
        },
        {}
      )

      if (response && response.data && response.data.msg === 'success') {
        history.push('/login?reset=success')
      } else {
        return setErrMsg('Unable to reset password. Please contact support for help! Code 1')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to reset password. Please contact support for help! Code 2')
    }
  }

  const validateCode = async () => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/public/auth/validate-reset-code`,
        {
          code
        },
        {}
      )

      if (response && response.data && response.data.msg === 'success') {
        return
      } else {
        setDisabledResetButton(true)
        return setErrMsg(`${response.data.msg}`)
      }
    } catch (err) {
      setDisabledResetButton(true)
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to reset password. Please contact support for help! Code 2')
    }
  }

  // initial load -- check if code is valid with the backend
  useEffect(() => {
    validateCode()
  }, [])

  return (
    <div className='forgot-password'>
      <div className='left-container'>
        <div className='transparent'>
          <p>
            <strong>Allowing your clients to integrate your app with the world!</strong>
          </p>
        </div>
      </div>
      <div className='right-container'>
        <div className='form-container'>
          <img src={champSelectLogo} alt='champselect-logo' style={{ width: '220px', marginBottom: '16px' }} />
          <div className='title'>Update your Password</div>
          <div className='subtitle'>Enter new password for your account.</div>
          <form onSubmit={resetPassword}>
            <div className='input-group' style={{ marginBottom: '18px' }}>
              <div className='iconArea'>
                <img src={emailIcon} alt='' />
              </div>
              <input
                type='text'
                className='form-control'
                id='email'
                placeholder='Your Email Address'
                required
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div className='input-group' style={{ marginRight: '12px', width: '100%' }}>
                <label htmlFor='password' style={{ color: '#fff' }}>Password</label>
                <input
                  type='password'
                  className='form-control'
                  id='password'
                  placeholder='Your new password'
                  required
                  style={{ paddingLeft: '12px', marginTop: '8px' }}
                  onChange={e => setNewPassword(e.target.value)}
                />
              </div>
              <div className='input-group' style={{ width: '100%' }}>
                <label htmlFor='confirmPassword' style={{ color: '#fff' }}>Confirm Password</label>
                <input
                  type='password'
                  className='form-control'
                  id='confirmPassword'
                  placeholder='Re-type new password'
                  required
                  style={{ paddingLeft: '12px', marginTop: '8px' }}
                  onChange={e => setNewConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <span className='err'>{errMsg}</span>
            <button
              className='btn login_btn'
              type='submit'
              style={{ marginTop: '24px' }}
              onSubmit={resetPassword}
              disabled={disabledResetButton}
            >
              Update New Password
            </button>
          </form>
          <p className='copyright-msg'>Copyright © 2022 ChampSelect.ai. All rights reserved</p>
        </div>
      </div>
    </div>
  )
}

export { ResetPassword }
