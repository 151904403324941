// this is for the tag / label of the field
const FieldTag = {
  SINGLELINEINPUT: 'fieldTagSingleLineInput',
  PARAGRAPH: 'fieldTagParagraph',
  DROPDOWN: 'fieldTagDropdown',
  MULTIPLECHOICE: 'fieldTagMultipleChoice',
  CHECKBOXES: 'fieldTagCheckbox',
  BUTTON: 'fieldTagButton',
  DATE: 'fieldTagDate',
  PAYMENT: 'fieldTagPayment',
  SPACER: 'fieldTagSpacer',
  TEXT: 'fieldTagText',
  PROGRAMS: 'fieldTagPrograms',
  SESSIONS: 'fieldTagSessions',
  CALENDAR_VIEW: 'fieldTagCalendarView',
  TERMS_AND_CONDITIONS: 'fieldTagTermsAndConditions'
}

// this is the actual field itself with preview
const FieldType = {
  SINGLELINEINPUT: 'fieldTypeSingleLineInput',
  PARAGRAPH: 'fieldTypeParagraph',
  DROPDOWN: 'fieldTypeDropdown',
  MULTIPLECHOICE: 'fieldTypeMultipleChoice',
  CHECKBOXES: 'fieldTypeCheckbox',
  BUTTON: 'fieldTypeButton',
  DATE: 'fieldTypeDate',
  PAYMENT: 'fieldTypePayment',
  SPACER: 'fieldTypeSpacer',
  TEXT: 'fieldTypeText',
  PROGRAMS: 'fieldTypePrograms',
  SESSIONS: 'fieldTypeSessions',
  CALENDAR_VIEW: 'fieldTypeCalendarView',
  TERMS_AND_CONDITIONS: 'fieldTypeTermsAndConditions',
  FORMFIELD: 'fieldTypeFormField',
  STEP: 'fieldTypeStep'
}

export { FieldTag, FieldType }
