import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap'

const EditClientModal = ({ authObj, clientId, modalOpen, setModalOpen, refreshTable }) => {
  const [loader, setLoader] = useState(true)

  // fields
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [gender, setGender] = useState('')
  const [phone, setPhone] = useState('')
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [day, setDay] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [status, setStatus] = useState('active')
  const [textNotifications, setTextNotification] = useState(true)

  const [errMsg, setErrMsg] = useState('')

  // create array from 1 to 31
  const days = [...Array(32).keys()].slice(1)

  const editClient = async () => {
    setErrMsg('Updating Client...')
    try {
      // validation
      if (!firstName) {
        return setErrMsg('First name cannot be blank.')
      }
      if (!lastName) {
        return setErrMsg('Last name cannot be blank.')
      }
      if (!email) {
        return setErrMsg('Email cannot be blank.')
      }

      const updateObj = {
        clientId,
        firstName,
        lastName,
        gender: gender || undefined,
        phone: phone || undefined,
        dob: month + '/' + day + '/' + year,
        email,
        password: password || undefined,
        status,
        textNotifications: _.isBoolean(textNotifications) ? textNotifications : undefined
      }

      const res = await axios.put(`${config.apiUrl}/app/clients`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Client Updated Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to update client (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update client (2)')
    }
  }

  const getClient = async cId => {
    setErrMsg('Fetching Client...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/clients/by-id?clientId=${cId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        const date = res.data.dob ? res.data.dob.split('/') : ''

        setFirstName(res.data.firstName)
        setLastName(res.data.lastName)
        setGender(res.data.gender)
        setPhone(res.data.phone)
        setYear(date[2])
        setMonth(date[0])
        setDay(date[1])
        setEmail(res.data.email)
        setStatus(res.data.status)
        setTextNotification(res.data.textNotifications)

        setLoader(false)
      } else {
        setErrMsg('Unable to fetch client (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch client (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  useEffect(() => {
    if (clientId) {
      getClient(clientId)
    }
  }, [clientId])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Update Client</ModalHeader>
      {(loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner color='primary' />
        </div>
      )) || (
        <ModalBody>
          <Form>
            <Row form>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for='firstName'>
                    <strong className='required-field'>First Name</strong>
                  </Label>
                  <Input
                    type='text'
                    name='firstName'
                    id='firstName'
                    placeholder='Jane'
                    value={firstName}
                    onChange={e => {
                      setFirstName(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for='lastName'>
                    <strong className='required-field'>Last Name</strong>
                  </Label>
                  <Input
                    type='text'
                    name='lastName'
                    id='lastName'
                    placeholder='Doe'
                    value={lastName}
                    onChange={e => {
                      setLastName(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for='Gender'>
                    <strong>Gender</strong>
                  </Label>
                  <Input
                    type='select'
                    name='gender'
                    id='gender'
                    value={gender}
                    onChange={e => {
                      setGender(e.target.value)
                    }}
                  >
                    <option value='' disabled>
                      Gender
                    </option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for='Phone'>
                    <strong>Phone</strong>
                  </Label>
                  <Input
                    type='text'
                    name='phone'
                    id='phone'
                    placeholder='(123) 456-7890'
                    value={phone}
                    onChange={e => {
                      setPhone(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label for='Year'>
                    <strong>Year</strong>
                  </Label>
                  <Input
                    type='number'
                    name='year'
                    id='year'
                    placeholder='1990'
                    value={year}
                    onChange={e => {
                      setYear(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label for='month'>
                    <strong>Month</strong>
                  </Label>
                  <Input
                    type='select'
                    name='month'
                    id='month'
                    value={month}
                    onChange={e => {
                      setMonth(e.target.value)
                    }}
                  >
                    <option value='' disabled>
                      Month
                    </option>
                    <option label='January' value='1'>
                      January
                    </option>
                    <option label='February' value='2'>
                      February
                    </option>
                    <option label='March' value='3'>
                      March
                    </option>
                    <option label='April' value='4'>
                      April
                    </option>
                    <option label='May' value='5'>
                      May
                    </option>
                    <option label='June' value='6'>
                      June
                    </option>
                    <option label='July' value='7'>
                      July
                    </option>
                    <option label='August' value='8'>
                      August
                    </option>
                    <option label='September' value='9'>
                      September
                    </option>
                    <option label='October' value='10'>
                      October
                    </option>
                    <option label='November' value='11'>
                      November
                    </option>
                    <option label='December' value='12'>
                      December
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label for='Day'>
                    <strong>Day</strong>
                  </Label>
                  <Input
                    type='select'
                    name='day'
                    id='day'
                    value={day}
                    onChange={e => {
                      setDay(e.target.value)
                    }}
                  >
                    <option value='' disabled>
                      Day
                    </option>
                    {days.map(d => {
                      return <option key={d} value={`"${d}"`}>{`${d}`}</option>
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for='email'>
                <strong className='required-field'>Email</strong>
              </Label>
              <Input
                type='email'
                name='email'
                id='email'
                placeholder='janedoe@gmail.com'
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for='password'>
                <strong>Password</strong>
              </Label>
              <Input
                type='password'
                name='password'
                id='password'
                placeholder='Password'
                value={password}
                onChange={e => {
                  setPassword(e.target.value)
                }}
              />
            </FormGroup>
            <FormGroup style={{ maxWidth: '200px' }}>
              <Label for='status'>
                <strong>Status</strong>
              </Label>
              <Input
                type='select'
                name='status'
                id='status'
                value={status}
                onChange={e => {
                  setStatus(e.target.value)
                }}
              >
                <option value='active'>Active</option>
                <option value='inactive'>Inactive</option>
              </Input>
            </FormGroup>
            <FormGroup tag='fieldset'>
              <FormGroup check>
                <Label check>
                  <Input
                    type='radio'
                    name='radio1'
                    checked={textNotifications}
                    onChange={e => {
                      setTextNotification(e.target.checked)
                    }}
                  />
                  I want to receive text notifications.
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type='radio'
                    name='radio1'
                    checked={!textNotifications}
                    onChange={e => {
                      setTextNotification(!e.target.checked)
                    }}
                  />
                  I do not want to receive text notifications.
                </Label>
              </FormGroup>
            </FormGroup>
          </Form>
        </ModalBody>
      )}

      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>

        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            editClient()
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { EditClientModal }
