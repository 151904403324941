import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { config } from '../../config'

const GoogleCallback = ({ authObj }) => {
  const [errMsg, setErrMsg] = useState('')
  const location = useLocation()

  const integrateGoogle = async googleCode => {
    setErrMsg('Integrating google...')
    try {
      const url = `${config.apiUrl}/app/settings/integration/google`
      const res = await axios.put(
        url,
        { googleCode },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res.status === 200) {
        setErrMsg('')
      } else {
        setErrMsg('Unable to integrate google (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to integrate google (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(async () => {
    const code = new URLSearchParams(location.search).get('code')
    if (code) {
      await integrateGoogle(code)
      window.close()
    }
  }, [])

  return <>{errMsg}</>
}

export { GoogleCallback }
