import moment from 'moment'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import DatePicker from 'react-date-picker'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'

const EditDiscountModal = ({ authObj, discountId, modalOpen, setModalOpen, refreshTable }) => {
  const [loader, setLoader] = useState(true)

  // initialize
  const [availablePrograms, setAvailablePrograms] = useState([])

  // fields
  const [title, setTitle] = useState('')
  const [type, setType] = useState('percentage')
  const [code, setCode] = useState('')
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState('')
  const [programIds, setProgramIds] = useState([])
  const [appliedOn, setAppliedOn] = useState(0)
  const [expDate, setExpDate] = useState(0)
  const [status, setStatus] = useState('active')

  const [errMsg, setErrMsg] = useState('')

  const editDiscount = async () => {
    setErrMsg('Updating Discount...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      // validating if code is required
      if (!code && (type === 'percentage' || type === 'fixed')) {
        return setErrMsg('Code cannot be blank.')
      }

      const newExpDate = moment(expDate).unix()

      const updateObj = {
        discountId,
        title,
        type,
        code,
        amount,
        description,
        programIds,
        appliedOn,
        expDate: newExpDate,
        status
      }

      const res = await axios.put(`${config.apiUrl}/app/discounts`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Discount Updated Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to update discount (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update discount (2)')
    }
  }

  const getDiscount = async cId => {
    setErrMsg('Fetching Discount...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/discounts/by-id?discountId=${cId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setTitle(res.data.title)
        setType(res.data.type)
        if (res.data.code) {
          setCode(res.data.code)
        }
        if (res.data.amount) {
          setAmount(res.data.amount)
        }
        if (res.data.expDate > 0) {
          setExpDate(moment.unix(res.data.expDate).toDate())
        }
        if (res.data.description) {
          setDescription(res.data.description)
        }
        if (res.data.programIds) {
          setProgramIds(res.data.programIds)
        }
        setStatus(res.data.status)

        setLoader(false)
      } else {
        setErrMsg('Unable to fetch discount (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch discount (2)')
    }
  }

  const getPrograms = async () => {
    setErrMsg('Fetching Programs...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/programs?active=true`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setAvailablePrograms(res.data)
      } else {
        setErrMsg('Unable to fetch programs (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch programs (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  useEffect(() => {
    if (discountId) {
      getDiscount(discountId)
      getPrograms()
    }
  }, [discountId])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      data-testid={`discount-edit-modal-${discountId}`}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Update Discount</ModalHeader>
      {(loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner color='primary' />
        </div>
      )) || (
        <ModalBody>
          <Form>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for='title'>
                    <strong className='required-field'>Title</strong>
                  </Label>
                  <Input
                    type='text'
                    data-testid='add-discount-title-textbox'
                    name='title'
                    placeholder='Title'
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='type'>
                    <strong>Type</strong>
                  </Label>
                  <Input
                    type='select'
                    data-testid='add-discount-type-textbox'
                    name='type'
                    value={type}
                    onChange={e => {
                      setType(e.target.value)
                    }}
                  >
                    <option value='percentage'>Percentage (Promo Code)</option>
                    <option value='fixed'>Fixed Amount (Promo Code)</option>
                    <option value='sibling_percentage'>Sibling (% Automatic)</option>
                    <option value='sibling_fixed'>Sibling (Fixed Automatic)</option>
                    <option value='multi_percentage'>Multi Session (% Automatic)</option>
                    <option value='multi_fixed'>Multi Session (Fixed Automatic)</option>
                  </Input>
                </FormGroup>
              </Col>
              {(type === 'percentage' || type === 'fixed') && (
                <Col md={12}>
                  <FormGroup>
                    <Label for='code'>
                      <strong>Discount Code</strong>
                    </Label>
                    <Input
                      type='text'
                      data-testid='add-discount-discountCode-textbox'
                      name='code'
                      value={code}
                      onChange={e => {
                        setCode(e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              {(type === 'sibling_percentage' ||
                type === 'multi_percentage' ||
                type === 'multi_fixed' ||
                type === 'sibling_fixed') && (
                  <Col md={12}>
                    <FormGroup>
                      <Label for='code'>
                        <strong>Applied On</strong>
                      </Label>
                      <Input
                        data-testid='add-discount-appliedOn-textbox'
                        type='number'
                        name='appliedOn'
                        value={appliedOn}
                        onChange={e => {
                          setAppliedOn(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
              )}
              <Col md={12}>
                <FormGroup>
                  <Label for='amount'>
                    <strong>Discount Amount</strong>
                  </Label>
                  <InputGroup>
                    {type === 'percentage' ||
                      (type === 'fixed' && (
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>{type === 'percentage' ? '%' : '$'}</InputGroupText>
                        </InputGroupAddon>
                      ))}
                    <Input
                      type='text'
                      data-testid='add-discount-amount-textbox'
                      name='amount'
                      value={amount}
                      placeholder='0'
                      onChange={e => {
                        setAmount(e.target.value)
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {(type === 'percentage' || type === 'fixed') && (
                <Col md={12}>
                  <Label for='startTime'>
                    <strong>Expiry Date</strong>
                  </Label>
                  <FormGroup>
                    <DatePicker
                      value={expDate}
                      onChange={e => {
                        setExpDate(e)
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col md={12}>
                <FormGroup>
                  <Label for='description'>
                    <strong>Description</strong>
                  </Label>
                  <br />
                  <textarea
                    name='description'
                    data-testid='add-discount-description-textbox'
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value)
                    }}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for='programs'>
                    <strong>Programs</strong>
                  </Label>
                  <div style={{ height: '200px', overflow: 'scroll' }}>
                    {availablePrograms.map(p => {
                      return (
                        <div key={p.id}>
                          <Label check htmlFor={`programs_${p.id}`}>
                            <Input
                              style={{ margin: 0 }}
                              type='checkbox'
                              id={`programs_${p.id}`}
                              name={`programs_${p.id}`}
                              data-testid={`add-discount-availablePrograms-${p.id}-textbox`}
                              checked={programIds.indexOf(p.id) > -1}
                              onChange={e => {
                                const programPos = programIds.indexOf(p.id)
                                if (e.target.checked) {
                                  // on check
                                  if (programPos === -1) {
                                    setProgramIds(programs => [...programs, p.id])
                                  }
                                } else {
                                  // on uncheck
                                  if (programPos > -1) {
                                    const pId = programIds[programPos]
                                    setProgramIds(programIds.filter(programId => programId !== pId))
                                  }
                                }
                              }}
                            />{' '}
                            {p.title}
                          </Label>
                        </div>
                      )
                    })}
                  </div>
                </FormGroup>
              </Col>
              {/* @TODO implement appliedOn and expDate */}
              <Col md={12}>
                <FormGroup>
                  <Label for='status'>
                    <strong>Status</strong>
                  </Label>
                  <Input
                    type='select'
                    name='status'
                    data-testid='add-discount-status-textbox'
                    value={status}
                    onChange={e => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value='active'>Active</option>
                    <option value='inactive'>Inactive</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      )}

      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>

        <Button
          data-testid='edit-discount-model-cancel-button'
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid='edit-discount-model-add-button'
          color='primary'
          onClick={() => {
            editDiscount()
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { EditDiscountModal }
