import axios from 'axios'
import profileSlice from '../redux/slice/profile.slice'
import { formatResponse } from './common.service'

import { config } from '../config'

export const loadGeneralSettings =
  (accountId, token, loader = true) =>
    dispatch => {
      loader && dispatch(profileSlice.actions.setLoading(true))
      return axios
        .get(`${config.apiUrl}/app/settings`, {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
          dispatch(profileSlice.actions.setSettings(res.data))
          dispatch(profileSlice.actions.setLoading(false))
        })
        .catch(err => {
          dispatch(profileSlice.actions.setLoading(false))
          return formatResponse(null, err)
        })
    }

export const loadProfileData =
  (accountId, token, loader = true) =>
    dispatch => {
      loader && dispatch(profileSlice.actions.setLoading(true))
      return axios
        .get(`${config.apiUrl}/app/profile`, {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
          dispatch(profileSlice.actions.setProfileData(res.data))
          dispatch(profileSlice.actions.setLoading(false))
        })
        .catch(err => {
          dispatch(profileSlice.actions.setLoading(false))
          return formatResponse(null, err)
        })
    }
