import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../../config'
import ReactPaginate from 'react-paginate'
import { Row, Col, Button, Spinner, Input, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

import { AddClientModal } from './AddClientModal'
import { EditClientModal } from './EditClientModal'

const Clients = ({ authObj }) => {
  const history = useHistory()

  const [clients, setClients] = useState([])

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(null)

  const [loader, setLoader] = useState(true)

  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editClientId, setEditClientId] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [totalClients, setTotalClients] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit] = useState(10)
  const [initialLoad, setInitialLoad] = useState(true)

  const getClients = async () => {
    setErrMsg('Fetching clients...')
    setLoader(true)
    try {
      let url = `${config.apiUrl}/app/clients`
      if (search) {
        url += `?search=${search}`
      }
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        },
        params: {
          page: currentPage + 1,
          pageSize: limit
        }
      })

      setLoader(false)
      if (res && res.data) {
        setErrMsg('')
        setTotalClients(res.data.count)
        setClients(res.data.clients)
        setLoader(false)
      } else {
        setErrMsg('Unable to get clients (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get clients (2) : ${JSON.stringify(err)}`)
    }
  }

  const remove = async clientId => {
    setErrMsg('Deleting client...')
    try {
      const res = await axios.delete(`${config.apiUrl}/app/clients?clientId=${clientId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        // refresh table
        getClients()
      } else {
        setErrMsg('Unable to remove client (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove client (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    if (search || (search === '' && !initialLoad)) {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        setErrMsg('Searching...')
        currentPage !== 0 ? setCurrentPage(0) : getClients()
      }, 1000)

      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    if (!initialLoad) getClients()
  }, [currentPage])

  useEffect(() => {
    getClients()
    setInitialLoad(false)
  }, [])

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div
              style={{
                padding: '16px',
                marginTop: '16px',
                // boxShadow: "3px 2px 2px 2px #ccc"
                borderColor: '#302a52',
                borderStyle: 'solid',
                borderRadius: '8px',
                borderWidth: '2px'
              }}
            >
              <Input
                type='text'
                placeholder='Search for clients here'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box'>
                <header className='panel_header'>
                  <h2 className='title float-left'>Clients</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    <Button
                      color='primary'
                      onClick={() => {
                        setModalOpen(true)
                      }}
                    >
                      Create Client
                    </Button>
                  </div>
                </header>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clients &&
                            clients.map(c => {
                              return (
                                <tr key={c.id}>
                                  <td style={{ cursor: 'pointer' }} onClick={() => history.push(`clients/${c.id}`)}>
                                    {c.firstName || ''} {c.lastName || ''}
                                  </td>
                                  <td>{c.email || ''}</td>
                                  <td>{c.phone || ''}</td>
                                  <td>
                                    <span
                                      style={{ marginRight: '10px', cursor: 'pointer' }}
                                      onClick={() => {
                                        setEditModalOpen(true)
                                        setEditClientId(c.id)
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} size='lg' />
                                    </span>
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const r = window.confirm(`Are you sure you want to remove ${c.name || c.id}`)
                                        if (r === true) {
                                          remove(c.id)
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} size='lg' />
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                      <div style={{ float: 'right', padding: '16px' }}>
                        <ReactPaginate
                          breakClassName='page-item'
                          breakLinkClassName='page-link'
                          containerClassName='pagination'
                          pageClassName='page-item'
                          pageLinkClassName='page-link'
                          previousClassName='page-item'
                          previousLinkClassName='page-link'
                          nextClassName='page-item'
                          nextLinkClassName='page-link'
                          activeClassName='active'
                          className='pagination'
                          pageCount={Math.ceil(totalClients / limit)}
                          initialPage={currentPage}
                          onPageChange={page => handlePageChange(page.selected)}
                        />
                      </div>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
      <AddClientModal authObj={authObj} modalOpen={modalOpen} setModalOpen={setModalOpen} refreshTable={getClients} />
      <EditClientModal
        authObj={authObj}
        clientId={editClientId}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        refreshTable={getClients}
      />
    </div>
  )
}

export { Clients }
