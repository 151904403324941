import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: 'dark'
}

const setTheme = (state = initialState, action) => {
  return {
    ...state,
    theme: action.payload
  }
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme
  }
})

export default themeSlice
