import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import { config } from '../../config'
import greyLock from '../../assets/img2/greyLock.svg'
import { useSelector, useDispatch } from 'react-redux'
import { loadProfileData, loadGeneralSettings } from '../../services/profile.service'
import cameraIcon from '../../assets/icons/cameraIcon.svg'

const UserProfile = () => {
  const { userData } = useSelector(state => state.auth)
  const { isLoading, profileData } = useSelector(state => state.profile)
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setEmail] = useState('')
  const [imgUrl, setImgUrl] = useState(
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80'
  )
  const [imgFile, setImgFile] = useState(undefined)
  const [errMsg, setErrMsg] = useState('')
  const [isEditable, setEditable] = useState(false)

  const profileImgRef = useRef()

  const saveUserProfile = async (e) => {
    e.preventDefault()
    setErrMsg('Saving User Profile...')
    try {
      // validation
      if (!firstName) {
        return setErrMsg('First Name cannot be blank.')
      }
      if (!lastName) {
        return setErrMsg('Last Name cannot be blank.')
      }

      const formData = new FormData() // eslint-disable-line

      formData.append('firstName', firstName)
      formData.append('lastName', lastName)

      if (imgFile) {
        formData.append('imageProfile', imgFile[0])
      }

      const response = await axios.put(`${config.apiUrl}/app/profile`, formData, {
        headers: {
          accountid: userData.account.accountId,
          Authorization: `Bearer ${userData.token}`,
          'Content-Type': 'multipart/form-data'
        }
      })

      if (response && response.data) {
        setErrMsg('Profile Saved Successfully.')
        setTimeout(() => {
          setErrMsg('')
        }, 3000)
        dispatch(loadGeneralSettings(userData.account.accountId, userData.token, false))
        dispatch(loadProfileData(userData.account.accountId, userData.token, false))
      } else {
        setErrMsg('Unable to save profile')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to save profile. Please contact support for help!')
    }
  }

  const changeProfileImg = e => {
    const files = e.target.files
    if (files && files[0]) {
      setImgFile(files)
      const reader = new FileReader() // eslint-disable-line
      reader.onload = e => {
        setImgUrl(e.target.result)
      }
      reader.readAsDataURL(files[0])
    }
  }

  const cancelClickHandler = (e) => {
    e.preventDefault()
    setFirstName(profileData?.firstName)
    setlastName(profileData?.lastName)
    setEditable(false)
  }

  useEffect(() => {
    if (profileData) {
      setFirstName(profileData?.firstName)
      setlastName(profileData?.lastName)
      setEmail(profileData?.email)
      setImgUrl(profileData?.imgUrl)
    }
  }, [profileData])

  return (
    <div className={`content ${isEditable && 'biggerHeight'}`}>
      {isLoading
        ? (
          <div className='loadingContainer'>
            <div className='loader'>
              <div className='spinner' />
              <div className='text'>Loading, please wait</div>
            </div>
          </div>
          )
        : (
          <form onSubmit={saveUserProfile}>
            <div className="titleArea">
              <div className="titleText">{isEditable ? 'Edit My Profile' : 'My Profile' }</div>
              <div className='profileMainActions'>
                {isEditable
                  ? <>
                      <button type='button' className='cancelButton' onClick={cancelClickHandler}>Cancel</button>
                      <button type='submit' className='saveButton' disabled={!(profileData?.firstName !== firstName || profileData?.lastName !== lastName)} onClick={saveUserProfile}>Save</button>
                    </>
                  : <button type='button' className='editButton' onClick={() => setEditable(true)}>Edit Profile</button>}
              </div>
            </div>
            <div className='mainArea'>
              <div className='ImageContainer'>
                <img src={imgUrl} className='image' alt='' />
                {isEditable && (
                  <div
                    onClick={() => profileImgRef.current.click()}
                    className={`profileCamera ${!isEditable && 'disabled'}`}
                  >
                    <img src={cameraIcon} alt='' />
                  </div>
                )}
                <input
                  type='file'
                  accept='image/*'
                  ref={profileImgRef}
                  multiple={false}
                  onChange={e => {
                    changeProfileImg(e)
                  }}
                  disabled={!isEditable}
                  hidden
                />
              </div>
              <div className='textContainer'>
                <div className='userName'>{userData?.user?.firstName} {userData?.user?.lastName}</div>
                <p className="emailText">{userData?.user?.email}</p>
              </div>
            </div>
            {isEditable && (
              <div className='profileBody'>
                <div className='inputGroup'>
                  <div className='labelTitle'>First Name</div>
                  <div className='inputArea'>
                    <input
                      type='text'
                      id='firstName'
                      value={firstName}
                      onChange={e => {
                        setFirstName(e.target.value)
                      }}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
                <div className='inputGroup'>
                  <div className='labelTitle'>Last Name</div>
                  <div className='inputArea'>
                    <input
                      type='text'
                      id='lastName'
                      value={lastName}
                      onChange={e => {
                        setlastName(e.target.value)
                      }}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
                <div className='emailInputGroup'>
                  <div className='labelTitle'>Email</div>
                  <div className='inputArea'>
                    <input
                      type='email'
                      id='emailInput'
                      className='disabled emailInput'
                      value={email}
                      placeholder='johndoe@gmail.com'
                      disabled
                    />
                    <p className='text-secondary'>* Please contact support to change your email.</p>
                    <img src={greyLock} className='icon' alt='' />
                  </div>
                </div>
                <div style={{ color: 'red', marginLeft: '30px' }}>{errMsg}</div>
              </div>
            )}
          </form>
          )}
    </div>
  )
}

export { UserProfile }
