import { createSlice } from '@reduxjs/toolkit'

const myAppsSlice = createSlice({
  name: 'myApps',
  initialState: {
    apps: [],
    allApps: [],
    authUrlMap: {},
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload
      }
    },
    setApps: (state, action) => {
      return {
        ...state,
        apps: action.payload
      }
    },
    setAllApps: (state, action) => {
      return {
        ...state,
        allApps: action.payload
      }
    },
    setAuthUrlMap: (state, action) => {
      return {
        ...state,
        authUrlMap: action.payload
      }
    }
  }
})

export default myAppsSlice

export const { setApps, setAllApps, setLoading, setAuthUrlMap } = myAppsSlice.actions
