import React from 'react'
import { FormGroup, Label, Input, Row, Col, Form } from 'reactstrap'

const Settings = ({ field, updateFieldSettings, setFieldOrderFunc }) => {
  return (
    <Row key={field.id}>
      <Col md='12'>
        <Form>
          <div>Field type: Sessions</div>
          <FormGroup>
            <Label htmlFor={field.id + '_displayName'}>Display Name</Label>
            <Input
              type='text'
              key={field.id + '_displayName'}
              id={field.id + '_displayName'}
              name={field.id + '_displayName'}
              placeholder=''
              value={field.displayName}
              onChange={async e => {
                updateFieldSettings(field.id, { displayName: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_internalName'}>Internal Name</Label>
            <Input
              type='text'
              key={field.id + '_internalName'}
              id={field.id + '_internalName'}
              name={field.id + '_internalName'}
              placeholder=''
              value={field.internalName}
              onChange={async e => {
                updateFieldSettings(field.id, { internalName: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_instructions'}>Instructions</Label>
            <Input
              type='textarea'
              name={field.id + '_instructions'}
              id={field.id + '_instructions'}
              value={field.instructions}
              onChange={e => {
                updateFieldSettings(field.id, { instructions: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_order'}>Order</Label>
            <Input
              type='number'
              id={field.id + '_order'}
              name={field.id + '_order'}
              value={field.order}
              onChange={e => {
                setFieldOrderFunc(field.id, Number(e.target.value))
              }}
            />
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

export default Settings
