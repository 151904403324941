import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../../config'
import { API } from '../../../api/API'

import { Row, Col, Button, Spinner, Input, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

const Locations = ({ authObj }) => {
  const history = useHistory()

  const [locations, setLocations] = useState([])

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(null)

  const [loader, setLoader] = useState(true)

  const [, setEditLocationId] = useState(null)
  const [errMsg, setErrMsg] = useState('')

  const getLocations = async () => {
    setErrMsg('Fetching locations...')
    try {
      const res = await API.getLocations(authObj.account.accountId, authObj.token, false, false, false)

      if (res && res.data) {
        setErrMsg('')
        setLocations(res.data.data)
        setLoader(false)
      } else {
        setErrMsg('Unable to get locations (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get locations (2) : ${JSON.stringify(err)}`)
    }
  }

  const remove = async locationId => {
    setErrMsg('Deleting locations...')
    try {
      const res = await axios.delete(`${config.apiUrl}/app/locations?locationId=${locationId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        // refresh table
        getLocations()
      } else {
        setErrMsg('Unable to remove location (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove location (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    if (search) {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        setErrMsg('Searching...')
        getLocations()
      }, 1000)
      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    getLocations()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box '>
                <header className='panel_header'>
                  <h2 className='title float-left'>Locations</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    <Button
                      color='primary'
                      onClick={() => {
                        history.push('/locations/create')
                      }}
                    >
                      Create Location
                    </Button>
                  </div>
                </header>
                <Col md={12} style={{ zIndex: '999' }}>
                  <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
                  <div
                    style={{
                      width: '250px',
                      paddingRight: '30px',
                      marginLeft: 'auto',
                      marginBottom: '20px'
                    }}
                  >
                    Search:
                    <Input
                      type='text'
                      placeholder=''
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value)
                      }}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {locations &&
                            locations.map(l => {
                              return (
                                <tr key={l.id}>
                                  <td>{l.title}</td>
                                  <td>{l.status}</td>
                                  <td>
                                    <span
                                      style={{ marginRight: '10px', cursor: 'pointer' }}
                                      onClick={() => {
                                        setEditLocationId(l.id)
                                        history.push({
                                          pathname: '/locations/edit',
                                          state: {
                                            authObj: authObj,
                                            locationId: l.id
                                          }
                                        })
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} size='lg' />
                                    </span>
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const r = window.confirm(`Are you sure you want to remove ${l.title || l.id}`)
                                        if (r === true) {
                                          remove(l.id)
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} size='lg' />
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Locations }
