import React from 'react'

const EmptyContainer = props => {
  return (
    <div className='emptyContainer'>
      <span>{props.text}</span>
    </div>
  )
}

export default EmptyContainer
