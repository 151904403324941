import React from 'react'
import WorkflowIcon from '../../../assets/img2/workflow-icon.svg'
import AddIcon from '../../../assets/img2/add-blue-circle-fill.svg'
import ArrowRightIcon from '../../../assets/img2/arrowRight.svg'
import greenLightning from '../../../assets/img2/greenLightning.svg'
import redCross from '../../../assets/img2/redCross.svg'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PageTitle from '../../../components/PageTitle/PageTitle'
import axios from 'axios'
import EmptyWorkflow from './Components/EmptyWorkflow'

import blue from '../../../assets/icons/robots/blue.svg'
import green from '../../../assets/icons/robots/green.svg'
import red from '../../../assets/icons/robots/red.svg'
import yellow from '../../../assets/icons/robots/yellow.svg'


const Workflows = () => {
  const history = useHistory()
  const { theme } = useSelector(state => state?.themes)
  const { isLoading, processes: workflows } = useSelector(state => state.process)

  const images = [blue, green, red, yellow]

  return (
    <div className="workflow-container">
      {isLoading ? (
        <div className="loader">
          <div className="spinner" />
          <div className="text">Loading, please wait</div>
        </div>
        ) : (
          <>
            <PageTitle page="workflows" theme={theme} title='Your Workflows' subtitle='Manage and configure all of your workflows here'>
              <button className='primaryButton' onClick={() => history.push('/workflows/create')}>
                {workflows?.length > 0 ? "Add Workflow" : 'Add new Workflow' }
              </button>
            </PageTitle>
            {workflows?.length > 0 ? (
              <div className="workflowContainer">
                {workflows?.map((workflow, i) => (
                  <div
                    className='workflow'
                    key={workflow?.id}
                    onClick={() => { 
                      history.push(`/workflows/${workflow.id}`)
                    }}
                  >
                    <div className="left">
                      <img
                        className='icon'
                        src={images[Math.floor(Math.random() * 4)]}
                        alt='icon'
                      />
                      <div className="textArea">
                        <div className="title">
                          {workflow.title}
                        </div>
                      </div>
                    </div>
                    <div className="rightContainer">
                      <div className="column">
                        <div className="row">
                          <img src={greenLightning} className="icon" alt="" />
                          <div className="title green">{workflow.successfulRuns}</div>
                        </div>
                        <div className="subtitle">
                          <div>Successful Runs</div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="row">
                          <img src={redCross} className="icon" alt="" />
                          <div className="title red">{workflow.failedRuns}</div>
                        </div>
                        <div className="subtitle">
                          <div>Failed Runs</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="emptyWorkflowContainer">
                  <EmptyWorkflow />
              </div>
            )}
          </>
      )}
    </div>
  )
}

export { Workflows }
