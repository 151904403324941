import moment from 'moment'
import 'date-fns'
import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { Row, Col, Button, Table, Spinner } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { API } from '../../../api/API'
import styles from './Reservation.module.scss'

const IncompleteReservations = ({ authObj }) => {
  const [reservations, setReservations] = useState([])
  const [totalReservations, setTotalReservations] = useState(0)
  const [search] = useState('')
  const [searching, setSearching] = useState(null)
  const [searchType] = useState('')
  const [loader, setLoader] = useState(true)
  const [errMsg, setErrMsg] = useState('')
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate())
  const [initialLoad] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit] = useState(10)

  const getIncompleteReservations = async () => {
    setErrMsg('Fetching reservations...')
    setLoader(true)
    try {
      const res = await API.getIncompleteReservationsList(
        authObj.account.accountId,
        authObj.token,
        moment(startDate).unix(),
        moment(endDate).unix(),
        currentPage + 1,
        limit
      )
      setLoader(false)
      if (res && res.data && res.data.reservations) {
        setErrMsg('')
        setReservations(res.data.reservations.data)
        setTotalReservations(res.data.reservations.count)
      } else {
        setErrMsg('Unable to get reservations (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get reservations (2) : ${JSON.stringify(err)}`)
    }
  }

  const submitDate = () => {
    if (startDate && endDate) {
      getIncompleteReservations()
    }
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (search || (search === '' && !initialLoad)) {
      if (searchType && searchType !== '') {
        if (searching) {
          clearTimeout(searching)
        }
        const s = setTimeout(() => {
          setErrMsg('Searching...')
          currentPage !== 0 ? setCurrentPage(0) : getIncompleteReservations()
        }, 1000)

        setSearching(s)
      }
    }
  }, [search, searchType])

  useEffect(() => {
    getIncompleteReservations()
  }, [currentPage])

  return (
    <div>
      <div className='content'>
        <Row>
          <Row style={{ marginTop: '1%', marginLeft: '1%' }}>
            <Col>
              <label>start date</label>
              <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
            </Col>
            <Col>
              <label>end date</label>
              <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
            </Col>
            <Col>
              <Button color='primary' style={{ marginTop: '14%' }} onClick={submitDate}>
                submit
              </Button>
            </Col>
          </Row>

          <Col xs={12} md={12}>
            <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box'>
                <header className='panel_header'>
                  <h2 className='title float-left'>Incomplete Reservations</h2>
                </header>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table>
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Progress</th>
                            <th>Names</th>
                            <th>Emails</th>
                            <th>Logs</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservations &&
                            reservations.map((reservation, i) => {
                              return (
                                <tr className={styles.tableRow} key={reservation.reservationId}>
                                  <td className={styles.tableCell}>{reservation.reservationId}</td>
                                  <td className={styles.tableCell}>{reservation.progress}</td>
                                  <td className={styles.tableCell}>{reservation.names}</td>
                                  <td className={styles.tableCell}>{reservation.emails}</td>
                                  <td className={styles.tableCell}>
                                    {reservation.raw && reservation.raw.slots && reservation.raw.slots.length !== 0 && (
                                      <div className={styles.scroll}>
                                        <p>{JSON.stringify(reservation.raw.slots)}</p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                      <div style={{ float: 'right', padding: '16px' }}>
                        <ReactPaginate
                          breakClassName='page-item'
                          breakLinkClassName='page-link'
                          containerClassName='pagination'
                          pageClassName='page-item'
                          pageLinkClassName='page-link'
                          previousClassName='page-item'
                          previousLinkClassName='page-link'
                          nextClassName='page-item'
                          nextLinkClassName='page-link'
                          activeClassName='active'
                          className='pagination'
                          pageCount={Math.ceil(totalReservations / limit)}
                          initialPage={currentPage}
                          onPageChange={page => handlePageChange(page.selected)}
                        />
                      </div>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { IncompleteReservations }
