import { createSlice } from '@reduxjs/toolkit'

const myClientsSlice = createSlice({
  name: 'myClients',
  initialState: {
    myClients: [],
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload
      }
    },
    setMyClients: (state, action) => {
      return {
        ...state,
        myClients: action.payload
      }
    }
  }
})

export default myClientsSlice

export const { setMyClients, setLoading } = myClientsSlice.actions
