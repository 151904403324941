import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  users: []
}

const setLoading = (state = initialState, action) => {
  return {
    ...state,
    isLoading: action.payload
  }
}

const setUsers = (state = initialState, action) => {
  return {
    ...state,
    users: action.payload
  }
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setLoading,
    setUsers
  }
})

export default teamSlice
