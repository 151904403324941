import React from 'react'
import { FormGroup, Label, Input, Row, Col, Form, Button, Table } from 'reactstrap'

const Settings = ({ field, updateFieldSettings, setFieldOrderFunc }) => {
  const addOption = () => {
    updateFieldSettings(field.id, {
      options: [...field.options, { option: 'I use pen', value: 'pen', order: field.options.length }]
    })
  }

  const removeOption = index => {
    updateFieldSettings(field.id, { options: field.options.filter((o, i) => i !== index) })
  }

  const updateOption = (value, key, index) => {
    const newOptions = [...field.options]
    newOptions[index][key] = value
    updateFieldSettings(field.id, { options: newOptions })
  }

  return (
    <Row key={field.id}>
      <Col md='12'>
        <Form>
          <div>Field type: Dropdown</div>
          <FormGroup>
            <Label htmlFor={field.id + '_displayName'}>Display Name</Label>
            <Input
              type='text'
              key={field.id + '_displayName'}
              id={field.id + '_displayName'}
              name={field.id + '_displayName'}
              placeholder=''
              value={field.displayName}
              onChange={async e => {
                updateFieldSettings(field.id, { displayName: e.target.value })
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor={field.id + '_internalName'}>Internal Name</Label>
            <Input
              type='text'
              key={field.id + '_internalName'}
              id={field.id + '_internalName'}
              name={field.id + '_internalName'}
              placeholder=''
              value={field.internalName}
              onChange={async e => {
                updateFieldSettings(field.id, { internalName: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup check>
            <Label check htmlFor={field.id + '_required'}>
              <Input
                type='checkbox'
                id={field.id + '_required'}
                name={field.id + '_required'}
                checked={field.required}
                onChange={e => {
                  updateFieldSettings(field.id, { required: e.target.checked })
                }}
              />{' '}
              Required?
            </Label>
          </FormGroup>
          <br />

          <FormGroup>
            <Label htmlFor={field.id + '_placeholder'}>Placeholder</Label>
            <Input
              type='text'
              id={field.id + '_placeholder'}
              name={field.id + '_placeholder'}
              placeholder=''
              value={field.placeholder}
              onChange={e => {
                updateFieldSettings(field.id, { placeholder: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_instructions'}>Instructions</Label>
            <Input
              type='textarea'
              name={field.id + '_instructions'}
              id={field.id + '_instructions'}
              value={field.instructions}
              onChange={e => {
                updateFieldSettings(field.id, { instructions: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_size'}>Size</Label>
            <select
              name={field.id + '_size'}
              id={field.id + '_size'}
              value={field.width}
              onChange={e => {
                updateFieldSettings(field.id, { width: e.target.value })
              }}
            >
              <option value={33}>small</option>
              <option value={50}>medium</option>
              <option value={100}>large</option>
            </select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_order'}>Order</Label>
            <Input
              type='number'
              id={field.id + '_order'}
              name={field.id + '_order'}
              value={field.order}
              onChange={e => {
                setFieldOrderFunc(field.id, Number(e.target.value))
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_options'}>Options</Label>
            <Table responsive size='sm' id={field.id + '_options'} name={field.id + '_options'}>
              <thead>
                <tr>
                  <th>Option</th>
                  <th>Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {field.options.map((o, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <input
                          disabled={field.action !== 'none'}
                          type='text'
                          value={o.option}
                          onChange={e => updateOption(e.target.value, 'option', i)}
                        />
                      </td>
                      <td>
                        <input
                          disabled={field.action !== 'none'}
                          type='text'
                          value={o.value}
                          onChange={e => updateOption(e.target.value, 'value', i)}
                        />
                      </td>
                      <td>
                        <Button
                          disabled={field.action !== 'none'}
                          color='danger'
                          size='sm'
                          onClick={() => removeOption(i)}
                        >
                          X
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Button
              disabled={field.action !== 'none'}
              outline
              color='secondary'
              size='sm'
              style={{ width: '100%' }}
              onClick={() => addOption()}
            >
              Add an option
            </Button>
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_action'}>Action</Label>
            <select
              className='form-control'
              onChange={e => {
                if (e.target.value === 'attendees') {
                  updateFieldSettings(field.id, {
                    action: e.target.value,
                    options: [
                      { option: 'Number of attendee(s)', value: 0, order: 0 },
                      { option: 1, value: 1, order: 1 },
                      { option: 2, value: 2, order: 2 },
                      { option: 3, value: 3, order: 3 }
                    ]
                  })
                } else {
                  updateFieldSettings(field.id, { action: e.target.value })
                }
              }}
              value={field.action}
            >
              <option value='none'>None</option>
              <option value='attendees'>Set no. of attendees</option>
            </select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_showWhen'}>Show when</Label>
            <select
              className='form-control'
              onChange={e => {
                updateFieldSettings(field.id, { showWhen: e.target.value })
              }}
              value={field.showWhen}
            >
              <option value='always'>Always</option>
              <option value='calendar_view'>Calendar View</option>
              <option value='table_view'>Table View</option>
            </select>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

export default Settings
