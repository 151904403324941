import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'

const Step1 = () => {
  const history = useHistory()

  const [selectedDestination, setSelectedDestination] = useState(undefined)

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12}>
            <Card body>
              <Row>
                <Col xs={12}>
                  <h4>Step1: Choose a destination</h4>
                  <hr />
                </Col>
                <Col xs={12} style={{ textAlign: 'center' }}>
                  <Card
                    style={{
                      maxWidth: 'max-content',
                      margin: '10px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      border: selectedDestination === 'sheets' ? '2px solid lightgreen' : 'none'
                    }}
                    onClick={() => setSelectedDestination('sheets')}
                  >
                    <Card.Body>
                      <img
                        height='100px'
                        src='https://lh3.googleusercontent.com/yCF7mTvXRF_EhDf7Kun5_-LMYTbD2IL-stx_D97EzpACfhpGjY_Frx8NZw63rSn2dME0v8-Im49Mh16htvPAGmEOMhiTxDZzo6rB7MY'
                      />
                      <h4>Sheets</h4>
                    </Card.Body>
                  </Card>
                  <Card
                    style={{
                      maxWidth: 'max-content',
                      margin: '10px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      border: selectedDestination === 'gmail' ? '2px solid lightgreen' : 'none'
                    }}
                    onClick={() => setSelectedDestination('gmail')}
                  >
                    <Card.Body>
                      <img
                        height='100px'
                        src='https://lh3.googleusercontent.com/0rpHlrX8IG77awQMuUZpQ0zGWT7HRYtpncsuRnFo6V3c8Lh2hPjXnEuhDDd-OsLz1vua4ld2rlUYFAaBYk-rZCODmi2eJlwUEVsZgg'
                      />
                      <h4>Gmail</h4>
                    </Card.Body>
                  </Card>
                  <Card
                    style={{
                      maxWidth: 'max-content',
                      margin: '10px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      border: selectedDestination === 'campminder' ? '2px solid lightgreen' : 'none'
                    }}
                    onClick={() => setSelectedDestination('campminder')}
                  >
                    <Card.Body>
                      <img
                        height='100px'
                        src='https://cdn.dribbble.com/users/32820/screenshots/6722534/campminder01_4x.png?compress=1&resize=1600x1200'
                      />
                      <h4>Campminder</h4>
                    </Card.Body>
                  </Card>
                  <div>
                    <button
                      className='btn btn-primary float-right'
                      onClick={() => history.push(`step2?dest=${selectedDestination}`)}
                    >
                      Next
                    </button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Step1 }
