import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Col, Row } from 'reactstrap'
import { config } from '../../../config'

function Export({ authObj }) {
  const [fields, setFields] = useState([])
  const [selectedFieldIds, setSelectedFieldIds] = useState([])
  const [exportData, setExportData] = useState([])

  const [errMsg, setErrMsg] = useState('')
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  const csvHeaders = selectedFieldIds.map(fId => {
    const field = fields.find(f => f.id === fId)
    return { label: field.displayName, key: field.displayName }
  })

  const csvReport = {
    data: exportData,
    headers: csvHeaders,
    filename: 'data.csv'
  }

  const getExportData = async () => {
    setErrMsg('Fetching export data...')
    try {
      const url = `${config.apiUrl}/app/dashboard/reservations?startTime=${moment(
        startDate,
        'YYYY-MM-DD'
      ).unix()}&endTime=${moment(endDate, 'YYYY-MM-DD').unix()}`
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        // eslint-disable-next-line no-undef
        const url = URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }))
        const a = document.createElement('a')
        a.href = url
        a.download = 'report.csv'
        a.click()
      } else {
        setErrMsg('Unable to get export data (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get export data (2) : ${JSON.stringify(err)}`)
    }
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12}>
            <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
            <div className="col-12" style={{ paddingTop: '30px' }}>
              <section className="box">
                <header className="panel_header">
                  <h2 className="title float-left">Export</h2>
                </header>
                <Col>
                  <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Filter by:</span>{' '}
                  <label htmlFor="startDate">
                    Start Date
                    <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                  </label>
                  <label htmlFor="endDate">
                    End Date
                    <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                  </label>
                </Col>
                <Col>
                  <br />
                  <br />
                  <button className="btn btn-success btn-sm" onClick={() => getExportData()}>
                    Go
                  </button>
                  {exportData.length > 0 && (
                    <button className="btn btn-primary btn-sm">
                      <span>
                        <CSVLink {...csvReport} style={{ color: '#fff' }}>
                          Export
                        </CSVLink>{' '}
                        <FontAwesomeIcon icon={faFileDownload} />
                      </span>
                    </button>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Export }
