import React from 'react'
import clockIcon from '../../../../assets/icons/clockGrey.svg'

const CreatedTime = ({ time, isEditing }) => {
  return (
    <div className={`createdTime ${isEditing && 'isEditing'}`}>
      <img className='clockIcon' src={clockIcon} alt="" />
      <div className="createdText">{time}</div>
    </div>
  )
}

export default CreatedTime