import axios from 'axios'
import teamSlice from '../redux/slice/team.slice'
import { formatResponse } from './common.service'

import { config } from '../config'

export const loadTeamUsers = (accountId, token) => dispatch => {
  dispatch(teamSlice.actions.setLoading(true))
  return axios
    .get(`${config.apiUrl}/app/users`, {
      headers: {
        accountid: accountId,
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(teamSlice.actions.setUsers(res.data))
      dispatch(teamSlice.actions.setLoading(false))
    })
    .catch(err => {
      dispatch(teamSlice.actions.setLoading(false))
      return formatResponse(null, err)
    })
}
