import { Card, CardBody, Table, CardText, CardTitle, Button } from 'reactstrap'
import moment from 'moment'
import React, { useState } from 'react'
import styles from './Reservation.module.scss'

const ReservationSlots = ({ authObj, slots }) => {
  const [toggle, setToggle] = useState({ indexValue: undefined, isExpanded: false })
  return (
    <Card>
      <CardBody>
        <Table striped>
          <thead>
            <th>Program</th>
            <th>Session</th>
            <th>Option</th>
            <th>Location</th>
            <th>Price</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Attendees</th>
            <th>Guardians</th>
          </thead>
          <tbody>
            {slots
              ? slots.map((slot, i) => (
                <React.Fragment key={i}>
                  <tr key={slot.id}>
                    <td>{slot.programTitle}</td>
                    <td>{slot.sessionTitle}</td>
                    <td>{slot.optionTitle}</td>
                    <td>{slot.location && slot.location.title}</td>
                    <td>$ {slot.price}</td>
                    <td>{moment.unix(slot.startTime).format('MM-DD-YYYY HH:mm a')}</td>
                    <td>{moment.unix(slot.endTime).format('MM-DD-YYYY HH:mm a')}</td>
                    <td>
                      <tbody>
                        {slot.attendees.map((a, j) => (
                          <tr key={a.id}>
                            <td>{`${a.firstName} ${a.lastName}`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </td>
                    <td>
                      <tbody>
                        {slot.guardians.map(g => (
                          <tr key={g.id}>
                            <td>{`${g.firstName} ${g.lastName}`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </td>
                    <td>
                      <Button
                        className='btn-sm'
                        onClick={() =>
                          setToggle(prevState => ({
                            ...prevState,
                            indexValue: i,
                            isExpanded: !toggle.isExpanded
                          }))}
                      >
                        <span className='caret' />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    {toggle.indexValue === i && toggle.isExpanded && (
                      <td colSpan='8'>
                        <div className='row'>
                          <div className='col-12'>
                            <Card className={styles.card}>
                              <CardBody className={styles.cardBody}>
                                <CardTitle tag='h5'>Additional Information</CardTitle>
                                <CardText>
                                  <p>Emergency Contact Name:&nbsp; {slots[i].emergencyContactName}</p>
                                  <p>Emergency Contact Phone:&nbsp; {slots[i].emergencyContactPhone}</p>
                                </CardText>
                              </CardBody>
                            </Card>
                          </div>
                          {slots[i].attendees.map(att => (
                            <div key={att.id} className='col-3'>
                              <Card className={styles.card}>
                                <CardBody className={styles.cardBody}>
                                  <CardTitle tag='h5'> {`${att.firstName} ${att.lastName}`}</CardTitle>
                                  <CardText>
                                    <p>Dob:&nbsp; {att.dob}</p>
                                    <p>Grade: &nbsp; {att.grade}</p>
                                    <p>School Name:&nbsp; {att.schoolName}</p>
                                    <p>Teacher Name: {att.homeroomTeacher}</p>
                                    <p>Notes: {att.notes}</p>
                                  </CardText>
                                </CardBody>
                              </Card>
                            </div>
                          ))}
                          {slots[i].guardians.map(g => (
                            <div key={g.id} className='col-3'>
                              <Card className={styles.card}>
                                <CardBody className={styles.cardBody}>
                                  <CardTitle tag='h5'>{`${g.firstName} ${g.lastName}`}</CardTitle>
                                  <CardText>
                                    <p>Email:&nbsp; {g.email}</p>
                                    <p>Phone:&nbsp; {g.phone}</p>
                                    <p>Dob:&nbsp; {g.dob}</p>
                                    <p>Address:&nbsp; {g.address}</p>
                                    <p>City:&nbsp; {g.city}</p>
                                    <p>State:&nbsp; {g.state}</p>
                                    <p>Zip:&nbsp; {g.zip}</p>
                                    <p>Notes: {g.notes}</p>
                                  </CardText>
                                </CardBody>
                              </Card>
                            </div>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
                ))
              : ''}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export { ReservationSlots }
