export const deploymentTypes = [
  {
    id: 1,
    type: 'pause',
    title: 'Pause Deployment',
    subtitle1: '$0 per month',
    subtitle2: 'no workflow runs'
  },
  {
    id: 2,
    type: 'small',
    title: 'Small Deployment',
    subtitle1: '$100 per month',
    subtitle2: '100k workflow runs'
  },
  {
    id: 3,
    type: 'medium',
    title: 'Medium Deployment',
    subtitle1: '$250 per month',
    subtitle2: '500k workflow runs'
  },
  {
    id: 4,
    type: 'Large',
    title: 'Large Deployment',
    subtitle1: '$397 per month',
    subtitle2: '1m workflow runs'
  }
]
