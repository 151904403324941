export const formatResponse = (res, err, defaultErrStr) => {
  const formattedRes = {
    data: undefined,
    err: undefined
  }
  if (res && res.data) {
    formattedRes.data = res.data
  } else if (err && err.response && err.response.data && err.response.data.msg) {
    formattedRes.err = `${err.response.data.msg}`
  } else if (defaultErrStr) {
    formattedRes.err = defaultErrStr
  } else {
    formattedRes.err = 'Unable to fetch from API'
  }
  return formattedRes
}
