import { API } from '../api/API'
import { setLoading, setMyClients } from '../redux/slice/clients.slice'

export const getMyClients =
  (accountId, token, loading = true) =>
  async dispatch => {
    try {
      loading && dispatch(setLoading(true))
      const res = await API.getMyClients(accountId, token)
      if (res.data) {
        dispatch(setMyClients(res.data))
      }
    } catch (err) {
      console.error(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
