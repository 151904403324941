import moment from 'moment'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-date-picker'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'

const AddDiscountModal = ({ authObj, modalOpen, setModalOpen, refreshTable }) => {
  // initialize
  const [availablePrograms, setAvailablePrograms] = useState([])

  // fields
  const [title, setTitle] = useState('')
  const [type, setType] = useState('percentage')
  const [code, setCode] = useState('')
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState('')
  const [programIds, setProgramIds] = useState([])
  const [appliedOn, setAppliedOn] = useState(0)
  const [expDate, setExpDate] = useState(0)
  const [status, setStatus] = useState('active')

  const [errMsg, setErrMsg] = useState('')

  const addDiscount = async () => {
    setErrMsg('Adding Discount...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }
      // validating if code is required
      if (!code && (type === 'percentage' || type === 'fixed')) {
        return setErrMsg('Code cannot be blank.')
      }

      const createObj = {
        title,
        type,
        code,
        appliedOn,
        amount,
        description,
        programIds,
        expDate,
        status
      }
      if (expDate) {
        createObj.expDate = moment(expDate).unix()
      } else {
        createObj.expDate = 0
      }

      const res = await axios.post(`${config.apiUrl}/app/discounts`, createObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Discount Added Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to add discount (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to add discount (2)')
    }
  }

  const getPrograms = async () => {
    setErrMsg('Fetching Programs...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/programs?active=true`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setAvailablePrograms(res.data)
      } else {
        setErrMsg('Unable to fetch programs (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch programs (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  useEffect(() => {
    if (modalOpen) {
      getPrograms()
    }
  }, [modalOpen])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      data-testid='create-discount-modal'
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Add A New Discount</ModalHeader>
      <ModalBody>
        <Form>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for='title'>
                  <strong className='required-field'>Title</strong>
                </Label>
                <Input
                  type='text'
                  data-testid='add-discount-title-textbox'
                  name='title'
                  placeholder='Title'
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='type'>
                  <strong>Type</strong>
                </Label>
                <Input
                  data-testid='add-discount-type-textbox'
                  type='select'
                  name='type'
                  value={type}
                  onChange={e => {
                    setType(e.target.value)
                  }}
                >
                  <option value='percentage'>Percentage (Promo Code)</option>
                  <option value='fixed'>Fixed Amount (Promo Code)</option>
                  <option value='sibling_percentage'>Sibling (% Automatic)</option>
                  <option value='sibling_fixed'>Sibling (Fixed Automatic)</option>
                  <option value='multi_percentage'>Multi Session (% Automatic)</option>
                  <option value='multi_fixed'>Multi Session (Fixed Automatic)</option>
                </Input>
              </FormGroup>
            </Col>
            {(type === 'percentage' || type === 'fixed') && (
              <Col md={12}>
                <FormGroup>
                  <Label for='code'>
                    <strong>Discount Code</strong>
                  </Label>
                  <Input
                    type='text'
                    name='code'
                    data-testid='add-discount-discountCode-textbox'
                    value={code}
                    onChange={e => {
                      setCode(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col>
            )}
            {(type === 'sibling_percentage' ||
              type === 'multi_percentage' ||
              type === 'multi_fixed' ||
              type === 'sibling_fixed') && (
                <Col md={12}>
                  <FormGroup>
                    <Label for='code'>
                      <strong>Applied On</strong>
                    </Label>
                    <Input
                      type='number'
                      data-testid='add-discount-appliedOn-textbox'
                      name='appliedOn'
                      value={appliedOn}
                      onChange={e => {
                        setAppliedOn(e.target.value)
                      }}
                    />
                  </FormGroup>
                </Col>
            )}
            <Col md={12}>
              <FormGroup>
                <Label for='amount'>
                  <strong>Discount Amount</strong>
                </Label>
                <InputGroup>
                  {type === 'percentage' ||
                    (type === 'fixed' && (
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>{type === 'percentage' ? '%' : '$'}</InputGroupText>
                      </InputGroupAddon>
                    ))}
                  <Input
                    data-testid='add-discount-amount-textbox'
                    type='text'
                    name='amount'
                    value={amount}
                    placeholder='0'
                    onChange={e => {
                      setAmount(e.target.value)
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            {(type === 'percentage' || type === 'fixed') && (
              <Col md={12}>
                <Label for='startTime'>
                  <strong>Expiry Date</strong>
                </Label>
                <FormGroup>
                  <DatePicker onChange={setExpDate} value={expDate} />
                </FormGroup>
              </Col>
            )}
            <Col md={12}>
              <FormGroup>
                <Label for='description'>
                  <strong>Description</strong>
                </Label>
                <br />
                <textarea
                  data-testid='add-discount-description-textbox'
                  name='description'
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value)
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='programs'>
                  <strong>Programs</strong>
                </Label>
                <div style={{ height: '200px', overflow: 'scroll' }}>
                  {availablePrograms.map(p => {
                    return (
                      <div key={p.id}>
                        <Label check htmlFor={`programs_${p.id}`}>
                          <Input
                            data-testid={`add-discount-availablePrograms-${p.id}-textbox`}
                            style={{ margin: 0 }}
                            type='checkbox'
                            id={`programs_${p.id}`}
                            name={`programs_${p.id}`}
                            checked={programIds.indexOf(p.id) > -1}
                            onChange={e => {
                              const programPos = programIds.indexOf(p.id)
                              if (e.target.checked) {
                                // on check
                                if (programPos === -1) {
                                  setProgramIds(programs => [...programs, p.id])
                                }
                              } else {
                                // on uncheck
                                if (programPos > -1) {
                                  const pId = programIds[programPos]
                                  setProgramIds(programIds.filter(programId => programId !== pId))
                                }
                              }
                            }}
                          />{' '}
                          {p.title}
                        </Label>
                      </div>
                    )
                  })}
                </div>
              </FormGroup>
            </Col>
            {/* @TODO implement appliedOn and expDate */}
            <Col md={12}>
              <FormGroup>
                <Label for='status'>
                  <strong>Status</strong>
                </Label>
                <Input
                  type='select'
                  data-testid='add-discount-status-textbox'
                  name='status'
                  value={status}
                  onChange={e => {
                    setStatus(e.target.value)
                  }}
                >
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>
        <Button
          data-testid='add-discount-model-cancel-button'
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid='add-discount-model-add-button'
          color='primary'
          onClick={() => {
            addDiscount()
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { AddDiscountModal }
