import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    },
    tableCell: {
      maxWidth: 100,
      wordBreak: 'break-word',
      borderBottom: 'none'
    }
  })
)

function RosterExpandedInfo (props) {
  const classes = useStyles()
  const { slot } = props

  return (
    <>
      <TableRow>
        <TableCell className={classes.container}>
          <Table className={classes.table} size='small' aria-label='purchases'>
            <TableBody>
              {slot.attendees.map(singleAttendee => (
                <TableRow key={singleAttendee.id}>
                  <TableCell className={classes.tableCell} component='th' scope='row'>
                    {`${singleAttendee.firstName} ${singleAttendee.lastName}`}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{singleAttendee.dob}</TableCell>
                  <TableCell className={classes.tableCell}>{singleAttendee.notes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
        <TableCell className={classes.container}>
          <Table className={classes.table} size='small' aria-label='purchases'>
            <TableBody>
              {slot.guardians.map(oneGuardian => (
                <TableRow key={oneGuardian.id}>
                  <TableCell className={classes.tableCell} component='th' scope='row'>
                    {`${oneGuardian.firstName} ${oneGuardian.lastName}`}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{oneGuardian.email}</TableCell>
                  <TableCell className={classes.tableCell}>{oneGuardian.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
        <TableCell className={classes.container}>
          <Table className={classes.table} size='small' aria-label='purchases'>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell} component='th' scope='row'>
                  {slot.program_session?.title}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        <TableCell className={classes.container}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell} component='th' scope='row'>
                  {slot.program_session_option?.title}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  )
}

export default RosterExpandedInfo
