import React from 'react'

const DeactivateUserModal = ({ modalOpen, editModalOpen, setModalOpen, deactivateAccountObject, removeUser }) => {
  return (
    <div className='overlay'>
      <div className='backDrop' style={{ zIndex: editModalOpen ? 6 : 1 }} onClick={() => setModalOpen(!modalOpen)} />
      <div className='userModalContainer' style={{ zIndex: editModalOpen ? 10 : 5 }}>
        <div className='userModal'>
          <div className='heading'>Deactivate User?</div>
          <div className='subHeading'>
            Do you want to deactivate "{`${deactivateAccountObject.firstName} ${deactivateAccountObject.lastName}`}" to
            the team? This process cannot be undone.
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <button className='cancel' onClick={() => setModalOpen(!modalOpen)}>
              Cancel
            </button>
            <button className='remove' onClick={() => removeUser(deactivateAccountObject.id)}>
              Remove User
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { DeactivateUserModal }
