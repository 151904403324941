import React from 'react'

const BackdropOverlay = props => {
  return (
    <div
      className='appOverlay'
      onClick={() => props.hideBackdrop(false)}
    />
  )
}

export default BackdropOverlay
