import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Container, Row, Col, Button, Spinner, InputGroup, Form, FormControl, Image } from 'react-bootstrap'

import { loadStripe } from '@stripe/stripe-js'

import moment from 'moment-timezone'
import axios from 'axios'
import { config } from '../../../config'
import SettingForm from './SettingForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

import googleLogo from './googleLogo.svg'

loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY)

const Settings = ({ authObj }) => {
  const defaultTimeZone = moment.tz.guess()
  const timeZones = moment.tz.names()
  const [errMsg, setErrMsg] = useState('')
  const [loader, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)
  const [onSaveLoader, setOnSaveLoader] = useState(false)
  const [formStatus, setFormStatus] = useState(true)
  const [googleUrl, setGoogleUrl] = useState(undefined)
  const [integrateGoogle, setIntegrateGoogle] = useState(false)
  const [imgDisplayed, setImgDisplayed] = useState(undefined)
  const [imgFile, setImgFile] = useState(undefined)

  const [settings, setSettings] = useState({
    name: '',
    domain: '',
    emailConfirmations: false,
    acceptPayments: false,
    emailNightBeforeAt9Pm: false,
    emailNotifications: false,
    textNotifications: false,
    googleDisplayName: '',
    googleEmail: '',
    emails: '',
    // googlePassword: "",
    status: '',
    companyLogo: '',
    timeInterval: '',
    timeZone: defaultTimeZone,
    twilioAccountId: '',
    twilioAuthToken: '',
    twilioFromNumber: '',
    anetLoginId: '',
    stripeAccountKey: '',
    stripeAccountSecret: '',
    anetTransactionKey: '',
    isGoogleIntegrated: false
  })
  useEffect(() => {
    getSettings()
  }, [])

  const changeProfileImg = e => {
    const files = e.target.files
    if (files && files[0]) {
      setImgFile(files)
      const reader = new FileReader() // eslint-disable-line
      reader.onload = e => {
        setImgDisplayed(e.target.result)
      }
      reader.readAsDataURL(files[0])
      setFormStatus(false)
    }
  }

  const timeZonesWithTime = timeZones.map((t, i) => {
    return (
      <option key={t} value={t}>
        (GMT{moment.tz(timeZones[i]).format('Z')}) {timeZones[i]}
      </option>
    )
  })

  const handleChange = (key, value) => {
    setFormStatus(false)
    setSettings(prevSettings => {
      const newSettings = { ...prevSettings }
      newSettings[key] = value
      return newSettings
    })
  }

  const toggleGoogleIntegration = checked => {
    if (checked === false && settings.isGoogleIntegrated) {
      const res = window.confirm('Are you sure you want to remove Google integration?')
      if (res) {
        setIntegrateGoogle(checked)
        removeGoogleIntegration()
      }
    } else {
      setIntegrateGoogle(checked)
    }
  }

  const setResponseSettings = response => {
    setSettings({
      ...settings,
      name: response.data.account?.name,
      domain: response.data.accountSettings.domain ? response.data.accountSettings.domain : '',
      emailConfirmations: response.data.accountSettings.emailConfirmations,
      acceptPayments: response.data.accountSettings.acceptPayments,
      emailNightBeforeAt9Pm: response.data.accountSettings.emailNightBeforeAt9Pm,
      emailNotifications: response.data.accountSettings.emailNotifications,
      textNotifications: response.data.accountSettings.textNotifications,
      googleDisplayName: response.data.accountSettings.googleDisplayName
        ? response.data.accountSettings.googleDisplayName
        : '',
      googleEmail: response.data.accountSettings.googleEmail ? response.data.accountSettings.googleEmail : '',
      emails: response.data.accountSettings.emails ? response.data.accountSettings.emails : '',
      // googlePassword: response.data.accountSettings["googlePassword"]
      //   ? response.data.accountSettings["googlePassword"]
      //   : "",
      status: response.data.accountSettings.status ? response.data.accountSettings.status : '',
      companyLogo: response.data.accountSettings.companyLogo ? response.data.accountSettings.companyLogo : '',
      timeInterval: response.data.accountSettings.timeInterval ? response.data.accountSettings.timeInterval : '',
      timeZone: response.data.accountSettings.timeZone ? response.data.accountSettings.timeZone : '',
      twilioAccountId: response.data.accountSettings.twilioAccountId
        ? response.data.accountSettings.twilioAccountId
        : '',
      stripeAccountKey: response.data.accountSettings.stripeAccountKey
        ? response.data.accountSettings.stripeAccountKey
        : '',
      stripeAccountSecret: response.data.accountSettings.stripeAccountSecret
        ? response.data.accountSettings.stripeAccountSecret
        : '',
      twilioAuthToken: response.data.accountSettings.twilioAuthToken
        ? response.data.accountSettings.twilioAuthToken
        : '',
      twilioFromNumber: response.data.accountSettings.twilioFromNumber
        ? response.data.accountSettings.twilioFromNumber
        : '',
      anetLoginId: response.data.accountSettings.anetLoginId ? response.data.accountSettings.anetLoginId : '',
      anetTransactionKey: response.data.accountSettings.anetTransactionKey
        ? response.data.accountSettings.anetTransactionKey
        : '',
      isGoogleIntegrated: response.data.isGoogleIntegrated
    })
    if (response.data.generatedUrl) {
      setGoogleUrl(response.data.generatedUrl)
    }
    if (response.data.isGoogleIntegrated === true) {
      setIntegrateGoogle(true)
    }
  }
  const getSettings = async () => {
    setErrMsg('Fetching settings...')
    setLoading(true)
    try {
      const url = `${config.apiUrl}/app/settings`
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setResponseSettings(res)
      } else {
        setErrMsg('Unable to get settings (1)')
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setErrMsg(`Unable to get settings (2) : ${JSON.stringify(err)}`)
    }
  }

  const updateAccountSettings = async () => {
    // setErrMsg("Updating Settings...")
    setOnSaveLoader(true)
    setSaved(false)
    try {
      const formData = new FormData() // eslint-disable-line
      formData.append('name', settings.name)
      formData.append('domain', settings.domain)
      formData.append('emailConfirmations', settings.emailConfirmations)
      formData.append('acceptPayments', settings.acceptPayments)
      formData.append('emailNightBeforeAt9Pm', settings.emailNightBeforeAt9Pm)
      formData.append('emailNotifications', settings.emailNotifications)
      formData.append('textNotifications', settings.textNotifications)
      formData.append('googleDisplayName', settings.googleDisplayName)
      formData.append('googleEmail', settings.googleEmail)
      formData.append('emails', settings.emails)
      formData.append('status', settings.status)
      formData.append('companyLogo', settings.companyLogo)
      formData.append('timeInterval', settings.timeInterval)
      formData.append('timeZone', settings.timeZone)
      formData.append('twilioAccountId', settings.twilioAccountId)
      formData.append('twilioAuthToken', settings.twilioAuthToken)
      formData.append('twilioFromNumber', settings.twilioFromNumber)
      formData.append('anetLoginId', settings.anetLoginId)
      formData.append('anetTransactionKey', settings.anetTransactionKey)
      formData.append('stripeAccountKey', settings.stripeAccountKey)
      formData.append('stripeAccountSecret', settings.stripeAccountSecret)
      formData.append('isGoogleIntegrated', settings.isGoogleIntegrated)
      if (imgFile) formData.append('imgFile', imgFile[0])

      const url = `${config.apiUrl}/app/settings`
      const res = await axios.put(url, formData, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`,
          'Content-Type': 'multipart/form-data'
        }
      })

      if (res.status === 200) {
        setErrMsg('')
        setFormStatus(true)
        setSaved(true)
      } else {
        setErrMsg('Unable to update settings (1)')
      }
      setOnSaveLoader(false)
    } catch (err) {
      setOnSaveLoader(false)
      setErrMsg(`Unable to update settings (2) : ${JSON.stringify(err)}`)
    }
  }

  // const syncSheet = async () => {
  //   const url = `${config.apiUrl}/app/settings/integration/sheet`
  //   await axios.post(
  //     url,
  //     {},
  //     {
  //       headers: {
  //         accountid: authObj.account.accountId,
  //         Authorization: `Bearer ${authObj.token}`
  //       }
  //     }
  //   )
  // }

  const removeGoogleIntegration = async () => {
    setErrMsg('Removing google integration...')
    try {
      const url = `${config.apiUrl}/app/settings/integration/google`
      const res = await axios.delete(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res.status === 200) {
        setErrMsg('')
        getSettings()
      } else {
        setErrMsg('Unable to remove google integration (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove google integration (2) : ${JSON.stringify(err)}`)
    }
  }

  if (loader) {
    return (
      <div>
        <div className='content'>
          <Container fluid>
            <Row className='d-flex mt-5 justify-content-center'>
              <Spinner animation='border' role='status' />
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        style={{ height: '100%', display: 'flex', alignItems: 'center' }}
        show={errMsg || saved}
        onHide={() => {
          setSaved(false)
          setErrMsg('')
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{saved ? 'Saved' : 'Failed'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{saved ? 'Your changes are saved!' : errMsg || 'Something went wrong'}</Modal.Body>
      </Modal>
      <div className='content'>
        <Container fluid>
          {!formStatus && (
            <Row className='bg-dark text-light py-2 '>
              <Col className='d-flex align-items-center'>
                {errMsg
                  ? (
                    <span>{errMsg}</span>
                    )
                  : (
                    <span>{formStatus ? 'Settings are up to date' : 'You have unsaved changes!'}</span>
                    )}
              </Col>
              <Col className='d-flex justify-content-end'>
                <Button disabled={onSaveLoader} variant='success' onClick={updateAccountSettings} size='sm'>
                  {onSaveLoader ? <Spinner size='sm' animation='border' role='status' /> : 'Save'}
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Container fluid>
                <hr style={{ width: '100%' }} />
                <SettingForm title='General Settings' description='Basic settings for your account'>
                  <Form.Label htmlFor='setting-companyLogo'>Company Logo</Form.Label>
                  <InputGroup className='mb-3' style={{ position: 'relative', display: 'flex' }}>
                    {!imgDisplayed && settings.companyLogo === ''
                      ? (
                        <div
                          style={{
                            height: '200px',
                            width: '200px',
                            margin: '0 auto',
                            border: '2px dashed #ccc'
                          }}
                        />
                        )
                      : (
                        <Image
                          alt=''
                          src={imgDisplayed || settings.companyLogo}
                          style={{
                            height: '200px',
                            width: '200px',
                            margin: '0 auto'
                          }}
                          fluid
                        />
                        )}
                    <div
                      style={{
                        position: 'absolute',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        left: '50%',
                        transform: 'translate(-50%, 0)'
                      }}
                    >
                      <label htmlFor='upload' style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faCamera} size='3x' color='#ccc' />
                      </label>
                      <input
                        id='upload'
                        type='file'
                        accept='image/*'
                        onChange={e => {
                          changeProfileImg(e)
                        }}
                        style={{
                          display: 'none'
                        }}
                      />
                    </div>
                  </InputGroup>
                  <Form.Label htmlFor='setting-name'>Company name</Form.Label>
                  <InputGroup className='mb-3'>
                    <FormControl
                      name='name'
                      id='setting-name'
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      className='max-width'
                      label='Name'
                      value={settings.name}
                      variant='outlined'
                    />
                  </InputGroup>

                  <Form.Group controlId='timeZone'>
                    <Form.Label>Timezone</Form.Label>
                    <Form.Control
                      required
                      value={settings.timeZone}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      type='text'
                      as='select'
                      name='timeZone'
                    >
                      {timeZonesWithTime}
                    </Form.Control>
                    <Form.Text className='text-muted'>
                      If you change time zone, be sure to refresh the page with Ctrl+R on windows or Cmd+R on Mac
                    </Form.Text>
                  </Form.Group>

                  <Form.Label htmlFor='setting-domain'>Domain/Website</Form.Label>
                  <InputGroup className='mb-3'>
                    <FormControl
                      name='domain'
                      id='setting-domain'
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      className='max-width'
                      label='Domain'
                      value={settings.domain}
                      variant='outlined'
                    />
                  </InputGroup>
                  {/* <Form.Group controlId="timeInterval">
                    <Form.Label>Time interval</Form.Label>
                    <Form.Control
                      required
                      value={settings.timeInterval}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      type="text"
                      as="select"
                      name="timeInterval"
                    >
                      <option value="">None</option>
                      <option value="15m">15 Min</option>
                      <option value="30m">30 Min</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="status">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      required
                      value={settings.status}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      type="text"
                      as="select"
                      name="status"
                    >
                      <option value="">None</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Form.Control>
                  </Form.Group> */}
                </SettingForm>
                <hr style={{ width: '100%' }} />
                <SettingForm
                  title='Admin emails'
                  description='These email address(es) will receive alerts and communication'
                >
                  <Form>
                    <Form.Group controlId='emails'>
                      <Form.Label>Email(s)</Form.Label>
                      <FormControl
                        name='emails'
                        id='emails'
                        onChange={e => handleChange(e.target.name, e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        className='max-width'
                        value={settings.emails}
                        variant='outlined'
                      />
                      <Form.Text className='text-muted'>
                        Multiple emails can be added by seperating with commas
                      </Form.Text>
                    </Form.Group>
                  </Form>
                </SettingForm>
                <hr style={{ width: '100%' }} />
                <SettingForm title='Connect your card' description='we will connect your card to the stripe'>
                  <Form.Check
                    type='switch'
                    id='acceptPayments'
                    name='acceptPayments'
                    onChange={e => handleChange(e.target.name, !settings[e.target.name])}
                    checked={settings.acceptPayments}
                    label='Do you want to accept payments?'
                  />
                  {settings.acceptPayments && (
                    <>
                      <Form.Label htmlFor='stripeAccountKey'>Stripe account key</Form.Label>
                      <InputGroup className='mb-3'>
                        <FormControl
                          name='stripeAccountKey'
                          id='stripeAccountKey'
                          onChange={e => handleChange(e.target.name, e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          className='max-width'
                          value={settings.stripeAccountKey}
                          variant='outlined'
                        />
                      </InputGroup>
                      <Form.Label htmlFor='stripeAccountSecret'>Stripe account secret</Form.Label>
                      <InputGroup className='mb-3'>
                        <FormControl
                          name='stripeAccountSecret'
                          id='stripeAccountSecret'
                          onChange={e => handleChange(e.target.name, e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          className='max-width'
                          value={settings.stripeAccountSecret}
                          variant='outlined'
                        />
                      </InputGroup>
                      <p>
                        Login/Signup to your Stripe account <a href='https://dashboard.stripe.com/login'>here</a> and
                        find your Stripe keys <a href='https://dashboard.stripe.com/dashboard'>here</a> in the "for
                        developers" section
                      </p>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <hr style={{ width: '100%' }} />
                        <span> OR </span>
                        <hr style={{ width: '100%' }} />
                      </div>
                      <Form.Label htmlFor='anetLoginId'>Anet Login ID</Form.Label>
                      <InputGroup className='mb-3'>
                        <FormControl
                          name='anetLoginId'
                          id='anetLoginId'
                          onChange={e => handleChange(e.target.name, e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          className='max-width'
                          value={settings.anetLoginId}
                          variant='outlined'
                        />
                      </InputGroup>
                      <Form.Label htmlFor='anetTransactionKey'>Anet Transaction Key</Form.Label>
                      <InputGroup className='mb-3'>
                        <FormControl
                          name='anetTransactionKey'
                          id='anetTransactionKey'
                          onChange={e => handleChange(e.target.name, e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          className='max-width'
                          value={settings.anetTransactionKey}
                          variant='outlined'
                        />
                      </InputGroup>
                      <p>
                        Login/Signup to your Anet account{' '}
                        <a href='https://www.authorize.net/en-us/sign-up.html'>here</a> and find your Anet keys by
                        following{' '}
                        <a href='https://support.authorize.net/s/article/How-do-I-obtain-my-API-Login-ID-and-Transaction-Key'>
                          these
                        </a>{' '}
                        steps
                      </p>
                    </>
                  )}
                </SettingForm>

                <hr style={{ width: '100%' }} />
                <SettingForm
                  title='Google integration'
                  description='By default when your customers register, we send them confirmation emails from support@champselect.ai, if you want this coming from your Gmail, please integrate it here'
                >
                  <Form.Check
                    type='switch'
                    id='integrateGoogle'
                    name='integrateGoogle'
                    onChange={e => toggleGoogleIntegration(e.target.checked)}
                    checked={integrateGoogle}
                    label='Do you want to send emails from your own Gmail??'
                  />
                  {integrateGoogle && (
                    <>
                      <Form.Label htmlFor='setting-google-name'>Google Email</Form.Label>
                      <InputGroup className='mb-3'>
                        <FormControl
                          name='googleEmail'
                          id='setting-google-name'
                          onChange={e => handleChange(e.target.name, e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          className='max-width'
                          label='Google Email'
                          value={settings.googleEmail}
                          variant='outlined'
                        />
                      </InputGroup>
                      <Form.Label htmlFor='setting-google-display-name'>Google Display Name</Form.Label>
                      <InputGroup className='mb-3'>
                        <FormControl
                          name='googleDisplayName'
                          id='setting-google-display-name'
                          onChange={e => handleChange(e.target.name, e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          className='max-width'
                          value={settings.googleDisplayName}
                          variant='outlined'
                        />
                      </InputGroup>

                      {!settings.isGoogleIntegrated && googleUrl
                        ? (
                          <button
                            style={{
                              backgroundColor: '#fff',
                              color: '#808080',
                              border: 'none',
                              boxShadow: '3px 3px 5px 1px #ccc',
                              borderRadius: '3px',
                              fontWeight: 'normal'
                            }}
                            onClick={() => {
                              const googleAuthWindow = window.open(googleUrl, '', 'width=500,height=500')
                              const checkWindowClose = setInterval(() => {
                                if (googleAuthWindow.closed) {
                                  clearInterval(checkWindowClose)
                                  getSettings()
                                }
                              }, 500)
                            }}
                          >
                            <img src={googleLogo} /> Sign in with Google
                          </button>
                          )
                        : (
                          <button
                            style={{
                              backgroundColor: '#fff',
                              color: '#808080',
                              border: 'none',
                              boxShadow: '3px 3px 5px 1px #ccc',
                              borderRadius: '3px',
                              padding: '12px',
                              fontWeight: '300'
                            }}
                            onClick={() => removeGoogleIntegration()}
                          >
                            Remove integration
                          </button>
                          )}
                    </>
                  )}
                </SettingForm>
                {/* <hr style={{ width: "100%" }} /> */}
                {/* <SettingForm
                  title="Twillio integration"
                  description="Integrate twilio for sending texts on your behalf"
                >
                  <Form.Label htmlFor="twilioAccountId">Twillio Account Id</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      name="twilioAccountId"
                      id="twilioAccountId"
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      className="max-width"
                      value={settings.twilioAccountId}
                      variant="outlined"
                    />
                  </InputGroup>
                  <Form.Label htmlFor="twilioAuthToken">Twillio Auth Token</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      name="twilioAuthToken"
                      id="twilioAuthToken"
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      className="max-width"
                      value={settings.twilioAuthToken}
                      variant="outlined"
                    />
                  </InputGroup>
                  <Form.Label htmlFor="twilioFromNumber">Twillio From Number</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      name="twilioFromNumber"
                      id="twilioFromNumber"
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      className="max-width"
                      value={settings.twilioFromNumber}
                      variant="outlined"
                    />
                  </InputGroup>
                </SettingForm>
                <hr style={{ width: "100%" }} /> */}
                {/* <SettingForm title="Notifications" description="Manage notifications for your account">
                  <Form>
                    <Form.Check
                      type="switch"
                      onChange={e => handleChange(e.target.name, !settings[e.target.name])}
                      id="textNotifications"
                      name="textNotifications"
                      checked={settings.textNotifications}
                      label="Text Notifications"
                    />
                    <Form.Check
                      type="switch"
                      id="emailConfirmations"
                      name="emailConfirmations"
                      onChange={e => handleChange(e.target.name, !settings[e.target.name])}
                      checked={settings.emailConfirmations}
                      label="Email Confirmations"
                    />
                    <Form.Check
                      type="switch"
                      id="emailNotifications"
                      name="emailNotifications"
                      onChange={e => handleChange(e.target.name, !settings[e.target.name])}
                      checked={settings.emailNotifications}
                      label="Email Notifications"
                    />
                    <Form.Check
                      type="switch"
                      id="emailNightBeforeAt9Pm"
                      name="emailNightBeforeAt9Pm"
                      onChange={e => handleChange(e.target.name, !settings[e.target.name])}
                      checked={settings.emailNightBeforeAt9Pm}
                      label="Email Night Before 9pm"
                    />
                  </Form>
                </SettingForm> */}
              </Container>
            </Col>
          </Row>
          <Row>
            <hr style={{ width: '100%' }} />
            <Col className='d-flex justify-content-end'>
              <Button disabled={onSaveLoader} variant='success' onClick={updateAccountSettings} size='sm'>
                {onSaveLoader ? <Spinner size='sm' animation='border' role='status' /> : 'Save'}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default Settings
