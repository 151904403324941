import React, { useState, useEffect } from 'react'
import { config } from '../../../config'
import axios from 'axios'
import { useSelector } from 'react-redux'
import moment from 'moment'
import leftArrow from '../../../assets/img2/arrowLeft.svg'
import warningIcon from '../../../assets/img2/warning-circle.svg'
import ignoreRed from '../../../assets/img2/ignoreRed.svg'
import rightPage from '../../../assets/img2/arrowRight.svg'
import redCross from '../../../assets/img2/redCross.svg'
import HistoryDetail from '../../../components/Modals/HistoryDetail/HistoryDetail'
import GreenCircularTick from '../../../components/Icons/GreenCircularTick'
import GreenLightning from '../../../components/Icons/GreenLightning'
import ReRun from '../../../components/Icons/ReRun'
import viewIcon from '../../../assets/icons/view_eye.svg'

const WorkflowHistory = ({ authObj, workflowId, theme, isEditing }) => {
  const { isLoading } = useSelector(state => state.process)
  const [historyDetailModal, showHistoryDetailModal] = useState(false)
  const [historyID, setHistoryID] = useState('')

  const [showFailedOnly, setShowFailedOnly] = useState(false)
  const [workflowsList, setWorkflowsList] = useState(null)
  const [failedWorkflowsList, setFailedWorkflowsList] = useState([])
  const [workflowHistory, setWorkflowHistory] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [message, setMessage] = useState('Currently, There are no process runs in this workflow.')
  const viewItemsCount = [10, 20, 30]
  const [historyLoading, setHistoryLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    showFailedWorkflowsOnly(showFailedOnly)
  }, [showFailedOnly])

  useEffect(() => {
    if (workflowId) {
      getWorkflowsList()
    }
  }, [page, pageSize])

  useEffect(() => {
    if (workflowId) {
      getWorkflowsList()
    }
  }, [workflowId])

  const getWorkflowsList = async (loading = true) => {
    loading && setHistoryLoading(true)
    await Promise.all([
      axios
        .get(
          `${config.apiUrl}/app/process-runs?processId=${workflowId}&page=${page}&pageSize=${pageSize}&includeProcess=true`,
          {
            headers: {
              Authorization: `Bearer ${authObj.token}`,
              accountid: authObj.account.accountId
            }
          }
        )
        .then(response => {
          if (response && response.data) {
            if (!showFailedOnly) {
              setWorkflowHistory(response.data?.history)
            }
            setWorkflowsList(response.data)
            setErrMsg('')
          } else {
            setErrMsg('Unable to get processes information')
          }
          loading && setHistoryLoading(false)
        })
        .catch(err => {
          loading && setHistoryLoading(false)
          if (err && err.response && err.response.data && err.response.data.msg) {
            return setErrMsg(`${err.response.data.msg}`)
          }
          return setErrMsg('Unable to get processes information. Please contact support for help!')
        }),
      axios
        .get(
          `${config.apiUrl}/app/process-runs?processId=${workflowId}&page=${page}&pageSize=${pageSize}&includeProcess=true&failed=true`,
          {
            headers: {
              Authorization: `Bearer ${authObj.token}`,
              accountid: authObj.account.accountId
            }
          }
        )
        .then(response => {
          if (response && response.data) {
            if (showFailedOnly) {
              setWorkflowHistory(response.data?.history)
            }
            setFailedWorkflowsList(response.data?.history)
          } else {
            setErrMsg('Unable to get processes information')
          }
          loading && setHistoryLoading(false)
        })
        .catch(err => {
          loading && setHistoryLoading(false)
          if (err && err.response && err.response.data && err.response.data.msg) {
            return setErrMsg(`${err.response.data.msg}`)
          }
          return setErrMsg('Unable to get processes information. Please contact support for help!')
        })
    ])
  }

  const showFailedWorkflowsOnly = isChecked => {
    if (isChecked) {
      setWorkflowHistory(failedWorkflowsList)
      setMessage('Currently, There are no failed runs in this process.')
    } else {
      setWorkflowHistory(workflowsList?.history || workflowHistory)
      setMessage('Currently, There are no process runs in this process.')
    }
  }

  const reRunProcess = id => {
    axios
      .post(
        `${config.apiUrl}/app/processes/rerun?processRunId=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authObj.token}`,
            accountid: authObj.account.accountId
          }
        }
      )
      .then(response => {
        if (response.data && response.data.msg === 'success') {
          getWorkflowsList()
        }
      })
      .catch(err => {
        if (err && err.response && err.response.data && err.response.data.msg) {
          return setErrMsg(`${err.response.data.msg}`)
        }
        return setErrMsg('Unable to re-run processes information. Please contact support for help!')
      })
  }

  const ignoreFailedRun = id => {
    axios
      .put(
        `${config.apiUrl}/app/process-runs`,
        {
          id,
          status: 'success'
        },
        {
          headers: {
            Authorization: `Bearer ${authObj.token}`,
            accountid: authObj.account.accountId
          }
        }
      )
      .then(response => {
        if (response.data && response.data.msg === 'success') {
          getWorkflowsList()
        }
      })
      .catch(err => {
        if (err && err.response && err.response.data && err.response.data.msg) {
          return setErrMsg(`${err.response.data.msg}`)
        }
        return setErrMsg('Unable to ignore processes information. Please contact support for help!')
      })
  }

  const getPageSize = viewItemsCount.map((t, i) => {
    return (
      <option key={t} value={t}>
        {t}
      </option>
    )
  })

  const showDetailInformation = id => {
    setHistoryID(id)
    showHistoryDetailModal(true)
  }

  return (
    <>
      {workflowsList ? (
        <div className={`workflowHistoryContainer ${isEditing && "hideHistory"}`}>
          <div className="titleRow">
            <div className="historyTitle">Workflow History</div>
            <div className="historySubtitle">
              {`${workflowsList?.successfulRuns + workflowsList?.failedRuns} Total Run${(workflowsList?.successfulRuns + workflowsList?.failedRuns) > 1 ? 's' : ''}`}
            </div>
          </div>
          <div className="mainRow">
            <div className="column">
              <div className="row">
                <GreenLightning theme={theme} />
                <div className="title green">{workflowsList?.successfulRuns}</div>
              </div>
              <div className="subtitle">
                Successful Runs
              </div>
            </div>
            <div className="column">
              <div className="row">
                <img src={redCross} className="icon" alt="" />
                <div className="title red">{workflowsList?.failedRuns}</div>
              </div>
              <div className="subtitle">
                Failed Runs
              </div>
            </div>
          </div>
          <div className="failedProcessOnly">
            <div className="switch-container">
              <label htmlFor="failedProcess">
                <input
                  id="failedProcess"
                  type="checkbox"
                  checked={showFailedOnly}
                  onChange={e => {
                    setShowFailedOnly(e.target.checked)
                  }}
                />
                <span />
              </label>
              <span>Failed process only</span>
            </div>
          </div>
          {errMsg && <p className='err'>{errMsg}</p>}
          <div className={`tableContainer ${historyLoading && 'loadingTable'}`}>
            {historyLoading && (
              <div className="loader">
                <div className="spinner" />
              </div>
            )}
            <div className="tableHeading">
              <div className="head one">Status</div>
              <div className="head two">Identifier</div>
              <div className="head three">Trigger</div>
              <div className="head four">Date & Time</div>
              <div className="head five">Action</div>
            </div>
            <div className="tableBody">
              {workflowHistory?.length ? (
                workflowHistory?.map(a => (
                  <div
                    key={a?.id}
                    className="tableRow"
                  >
                    <div className="row one">
                      {a?.status?.toLowerCase() === 'success'  ? (
                        <GreenCircularTick theme={theme} />
                      ) : (
                        <img src={warningIcon} alt="failed" />
                      )}
                    </div>
                    <div className="row two">{a?.identifier || a?.requestId}</div>
                    <div className="row three">Scheduled</div>
                    <div className="row four">{moment(a.createdAt).format('MMM D, YYYY @ HH:mm a')}</div>
                    <div className="row five">
                      <div
                        className="view"
                        onClick={e => {
                          e.stopPropagation()
                          showDetailInformation(a?.id)
                        }}
                      >
                        <img src={viewIcon} className='icon' alt="view" />
                        <span>View</span>
                      </div>
                      {a?.status?.toLowerCase() !== 'success' && (
                        <>
                          <div className="re-run" onClick={() => reRunProcess(a.id)}>
                            <ReRun theme={theme} />
                            <span>Re-run</span>
                          </div>
                          <div
                            className="ignore"
                            onClick={e => {
                              e.stopPropagation()
                              ignoreFailedRun(a.id)
                            }}
                          >
                            <img src={ignoreRed} className='icon' alt="ignore" />
                            <span>Ignore</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : <div className="emptyText">{message}</div>}
            </div>
          </div>
          <div className="filterAndPaginationContainer">
            <div className="filter">
              View items by:
              <div className="select">
                <select
                  className=""
                  value={pageSize}
                  name="pageSize"
                  onChange={e => {
                    setPage(1)
                    setPageSize(e.target.value)
                  }}
                >
                  {getPageSize}
                </select>
              </div>
            </div>
            <div className="pagination">
              {page > 1 && !isLoading && (
                <img src={leftArrow} className="leftArrow" onClick={() => setPage(page - 1)} />
              )}
              {page > 1 && !isLoading && (
                <div className="previousPageNumber" onClick={() => setPage(page - 1)}>
                  {page - 1}
                </div>
              )}
              <div className="pageNumber">{page}</div>
              {workflowHistory?.length >= pageSize && !isLoading && (
                <div className="nextPageNumber" onClick={() => setPage(page + 1)}>
                  {page + 1}
                </div>
              )}
              {workflowHistory?.length >= pageSize && !isLoading && (
                <img src={rightPage} className="rightArrow" onClick={() => setPage(page + 1)} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="emptyContainer">
          <p className="notSelectedText">Empty history for this workflow.</p>
        </div>
      )}

      {historyDetailModal && <div className="backdropOverlay" onClick={() => showHistoryDetailModal(false)} />}
      {historyDetailModal && (
        <HistoryDetail
          theme={theme}
          workflowsList={workflowsList}
          historyID={historyID}
          isLoading={isLoading}
          reRunProcess={reRunProcess}
          ignoreFailedRun={ignoreFailedRun}
        />
      )}
    </>
  )
}

export { WorkflowHistory }
