import React from 'react'

const BackIcon = ({ color }) => {
  return (
    <svg className='icon' width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.53505 0.514893L0.0500488 8.99989L8.53505 17.4849L9.95005 16.0709L2.87805 8.99989L9.95005 1.92889L8.53505 0.514893Z" fill={color} />
    </svg>
  )
}

export default BackIcon