import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import { Button, Col, Row, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const Templates = ({ authObj }) => {
  const history = useHistory()

  const [errMsg, setErrMsg] = useState('')
  const [templates, setTemplates] = useState([])

  const getTemplates = async () => {
    setErrMsg('Fetching templates...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/templates`, {
        headers: {
          Authorization: `Bearer ${authObj.token}`,
          accountid: authObj.account.accountId
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setTemplates(res.data)
      } else {
        setErrMsg('Unable to fetch templates (1)')
      }
    } catch (err) {
      setErrMsg('Unable to fetch templates (2)', err.toString())
    }
  }

  useEffect(() => {
    getTemplates()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12}>
            <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box'>
                <header className='panel_header'>
                  <h2 className='title float-left'>Templates</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    <Button color='primary' onClick={() => history.push('/templates/create')}>
                      Create Template
                    </Button>
                  </div>
                </header>
                <Col style={{ paddingBottom: '10px' }}>
                  <Table striped bordered hover style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {templates.map((template, i) => (
                        <tr key={template.id}>
                          <td>{i + 1}</td>
                          <td onClick={() => history.push(`/templates/${template.id}`)} style={{ cursor: 'pointer' }}>
                            {template.title}
                          </td>
                          <td>{template.description}</td>
                          <td>{template.type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Templates }
