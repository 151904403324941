import React, { useEffect, useState } from 'react'
import { API } from '../../../api/API'

const Dashboard = ({ authObj }) => {
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [name, setName] = useState('')
  const [website, setWebsite] = useState('')
  const [logo, setLogo] = useState('')
  const [credentials, setCredentials] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [apps, setApps] = useState([])
  const [selectedApp, setSelectedApp] = useState(undefined)

  const getApps = async () => {
    setLoading(true)
    const res = await API.getApps(authObj.account.accountId, authObj.token)
    if (res.data) {
      setApps(res.data)
    }
    setLoading(false)
  }

  const updateApp = async () => {
    setAddModalOpen(false)
    setLoading(true)
    const res = await API.updateApp(authObj.account.accountId, authObj.token, {
      id: selectedApp.id,
      name,
      website,
      logo,
      metaData: credentials
    })
    if (res.data) {
      getApps()
    }
    setLoading(false)
  }

  const createApp = async () => {
    setAddModalOpen(false)
    setLoading(true)
    const res = await API.createApp(authObj.account.accountId, authObj.token, {
      name,
      website,
      logo,
      metaData: credentials
    })
    if (res.data) {
      getApps()
    }
    setLoading(false)
  }

  useEffect(() => {
    if (selectedApp) {
      setName(selectedApp.name)
      setWebsite(selectedApp.website)
      setLogo(selectedApp.logo)
      setCredentials(selectedApp.metaData)
    } else {
      setName('')
      setWebsite('')
      setLogo('')
      setCredentials('')
    }
  }, [selectedApp])

  useEffect(() => {
    if (!addModalOpen) {
      setSelectedApp(undefined)
    }
  }, [addModalOpen])

  useEffect(() => {
    getApps()
  }, [])

  return (
    <>
      <div className='dashboardPageLayout'>
        <div className='header'>
          <div className='title'>Dashboard</div>
          <button className='add-btn' onClick={() => setAddModalOpen(true)}>
            <span>Add Your App</span>
          </button>
        </div>
        {isLoading
          ? (
            <div className='loadingContainer'>
              <div className='loader'>
                <div className='spinner' />
                <div className='text'>Loading, please wait</div>
              </div>
            </div>
            )
          : (
            <div className='body'>
              {apps.map(app => (
                <div
                  key={app.id}
                  className='app-card'
                  tabIndex={0}
                  onClick={() => {
                    setAddModalOpen(true)
                    setSelectedApp(app)
                  }}
                >
                  <div
                    tabIndex={0}
                    className='menu'
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    ...
                  </div>
                  <img src={app.logo} alt='app logo' />
                  <div className='app-title'>{app.name}</div>
                </div>
              ))}
            </div>
            )}
      </div>
      {addModalOpen && (
        <>
          <div className='showAddAppModal' onClick={() => setAddModalOpen(false)} />
          <div className='addAppContainer'>
            <div className='scrollable-container'>
              <div className='titleArea'>
                <div className='title'>{selectedApp ? 'Update app' : 'Add a new app'}</div>
              </div>
              <form
                onSubmit={e => {
                  e.preventDefault()
                  if (selectedApp) {
                    updateApp()
                  } else {
                    createApp()
                  }
                }}
              >
                <div className='row' style={{ justifyContent: 'space-between' }}>
                  <div className='inputGroup' style={{ width: '100%' }}>
                    <label htmlFor='name'>Name</label>
                    <input
                      type='text'
                      className='form-control'
                      id='name'
                      placeholder='Enter a name for your App'
                      required
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                  <div className='inputGroup' style={{ width: '100%' }}>
                    <label htmlFor='website'>Website</label>
                    <input
                      type='text'
                      className='form-control'
                      id='website'
                      placeholder='Enter app website'
                      required
                      value={website}
                      onChange={e => setWebsite(e.target.value)}
                    />
                  </div>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                  <div className='inputGroup' style={{ width: '100%' }}>
                    <label htmlFor='logo'>Logo Url</label>
                    <input
                      type='text'
                      className='form-control'
                      id='logo'
                      placeholder='Enter logo url'
                      required
                      value={logo}
                      onChange={e => setLogo(e.target.value)}
                    />
                  </div>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                  <div className='inputGroup' style={{ width: '100%' }}>
                    <label htmlFor='credentials'>Credentials</label>
                    <textarea
                      className='form-control'
                      id='credentials'
                      placeholder='Enter credentials'
                      required
                      value={credentials}
                      onChange={e => setCredentials(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  type='submit'
                  onSubmit={e => {
                    e.preventDefault()
                    if (selectedApp) {
                      updateApp()
                    } else {
                      createApp()
                    }
                  }}
                  className='submit'
                >
                  {selectedApp ? 'Update App' : 'Add App'}
                </button>
                <button onClick={() => setAddModalOpen(false)} className='cancel'>
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { Dashboard }
