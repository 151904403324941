import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import axios from 'axios'
import { config } from '../../../config'
import {
  Card,
  CardContent,
  FormControl,
  Select,
  InputLabel,
  Grid,
  Hidden,
  Typography,
  MenuItem,
  Button,
  CircularProgress,
  Container,
  Dialog
} from '@material-ui/core'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Col, Row } from 'reactstrap'
import DateFnsUtils from '@date-io/date-fns'
import './Transfers.scss'
import { DataGrid } from '@material-ui/data-grid'
import { API } from '../../../api/API'

const Transfers = ({ authObj }) => {
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const [errMsg, setErrMsg] = useState('')
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const [transferTo, setTransferTo] = useState()
  const [reservations, setReservations] = useState([])
  const [users, setUsers] = useState(undefined)
  const [selctedReservations, setSelectedReservations] = useState([])
  const [showReservations, setShowReservations] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const columns = [
    { field: 'clientName', headerName: 'Name', type: 'string', width: 160 },
    { field: 'clientEmail', headerName: 'Email', type: 'string', width: 200 },
    { field: 'clientPhone', headerName: 'Phone', type: 'string', width: 200 },
    { field: 'userReserved', headerName: 'Staff', type: 'string', width: 200 },
    { field: 'reservationLocation', headerName: 'Location', type: 'string', width: 200 },
    { field: 'startTime', headerName: 'Start Time', type: 'string', width: 220 },
    { field: 'endTime', headerName: 'End Time', type: 'string', width: 220 }
  ]

  useEffect(() => {
    getLocations()
    getUsers()
  }, [])

  const getLocations = async () => {
    setErrMsg('Fetching locations...')
    setLoading(true)
    try {
      const res = await API.getLocations(authObj.account.accountId, authObj.token, true, false, false)

      if (res && res.data) {
        setErrMsg('')
        setLoading(false)
        setLocations(res.data.data)
      } else {
        setLoading(false)
        setErrMsg('Unable to get locations (1)')
      }
    } catch (err) {
      setLoading(false)
      setErrMsg(`Unable to get locations (2) : ${JSON.stringify(err)}`)
    }
  }

  const getReservations = async () => {
    setErrMsg('Fetching reservations...')
    setLoading(true)
    try {
      const url = `${config.apiUrl}/app/reservation-services/admin`
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        },
        params: {
          startTime: moment(fromDate).unix(),
          endTime: moment(toDate).unix(),
          getClients: true,
          locationId: selectedLocation,
          reserved: true,
          userIds: selectedUser ? [selectedUser] : undefined
        }
      })

      if (res && res.data) {
        setReservations(
          res.data.map(singleReservation => {
            const firstService = singleReservation.reservation_services[0]
            return {
              id: singleReservation.id,
              startTime: moment.unix(firstService.startTime).tz('America/Los_Angeles').format('YYYY-MM-DD LT'),
              endTime: moment.unix(firstService.endTime).tz('America/Los_Angeles').format('YYYY-MM-DD LT'),
              clientName: firstService.client
                ? firstService.client.firstName + ' ' + firstService.client.lastName
                : 'No Client',
              clientEmail: firstService.client ? firstService.client.email : 'No Email',
              clientPhone: firstService.client ? firstService.client.phone : 'No Phone',
              clientAddress: firstService.client ? firstService.client.address : 'No Address',
              clientCity: firstService.client ? firstService.client.city : 'No City',
              reservationLocation: locations.filter(locationInfo => locationInfo.id === firstService.locationId)[0]
                .title,
              userReserved: users.filter(singleUser => singleUser.id === firstService.userId)[0]?.firstName,
              status: firstService.status
            }
          })
        )
        setShowReservations(true)
        setLoading(false)
        setErrMsg('')
      } else {
        setLoading(false)
        setErrMsg('Unable to get reservations (1)')
      }
    } catch (err) {
      setLoading(false)
      setErrMsg(`Unable to get reservations (2) : ${JSON.stringify(err)}`)
    }
  }

  const getUsers = async () => {
    setLoading(true)
    setErrMsg('Fetching users...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/users`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setUsers(res.data)
        setLoading(false)
        setErrMsg('')
      } else {
        setLoading(false)
        setErrMsg('Unable to fetch users (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        setLoading(false)
        return setErrMsg(`${err.response.data.msg}`)
      }
      setLoading(false)
      return setErrMsg('Unable to fetch users (2)')
    }
  }

  const handleDateChange = (origin, date) => {
    if (origin === 'from') setFromDate(date)
    else if (origin === 'to') setToDate(date)
  }

  const handleFiltration = () => {
    // Get the reservations from the backend
    getReservations()
  }

  const transferReservations = async reservationIds => {
    setLoading(true)
    setErrMsg('Transfering Reservations...')
    try {
      const res = await axios.put(
        `${config.apiUrl}/app/reservation-services/transfer/admin`,
        { reservationIds, userId: transferTo },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )
      if (res && res.data) {
        setDialogOpen(true)
        getReservations()
        setLoading(false)
        setErrMsg('')
      } else {
        setLoading(false)
        setErrMsg('Unable to fetch users (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        setLoading(false)
        return setErrMsg(`${err.response.data.msg}`)
      }
      setLoading(false)
      return setErrMsg('Unable to fetch users (2)')
    }
  }
  const handleTransfer = () => {
    const reservationIds = selctedReservations.map(singleReservation => singleReservation.id)
    transferReservations(reservationIds)
  }
  return (
    <div>
      <div className='content'>
        <Row style={{ marginTop: '30px' }}>
          <Col xs={12} md={12}>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <Card>
                <CardContent>
                  <Typography variant='h6'>Reservations Transferred Successfully</Typography>
                </CardContent>
              </Card>
            </Dialog>
            <Typography color='error'> {errMsg}</Typography>
            <Card className='material-reset'>
              <CardContent>
                <Typography variant='h6'>Transfer Reservations</Typography>
                <Grid container item xs={12} md={12} spacing={2} style={{ paddingTop: '30px' }}>
                  {/* Empty Space on side */}
                  <Hidden mdDown>
                    <Grid item md={1} />
                  </Hidden>
                  {/* End of Empty Space on side */}
                  {/* Filters */}

                  <Grid item container direction='column' xs={12} md={10} spacing={2}>
                    <Grid item container xs={12} md={12}>
                      <Card className='max-width'>
                        <CardContent>
                          <Typography variant='caption'>Filter Reservations</Typography>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item container spacing={1} md={12}>
                              <Grid item md={3} xs={12}>
                                <KeyboardDatePicker
                                  margin='normal'
                                  variant='inline'
                                  id='date-picker-inline'
                                  label='From'
                                  format='MM/dd/yyyy'
                                  value={fromDate}
                                  onChange={date => handleDateChange('from', date)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <KeyboardDatePicker
                                  margin='normal'
                                  variant='inline'
                                  id='date-picker-inline'
                                  label='To'
                                  format='MM/dd/yyyy'
                                  value={toDate}
                                  onChange={date => handleDateChange('to', date)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <FormControl variant='outlined' className='max-width'>
                                  <InputLabel id='location-select'>Location</InputLabel>
                                  <Select
                                    labelId='location-select'
                                    id='location-select'
                                    defaultValue=''
                                    onChange={event => setSelectedLocation(event.target.value)}
                                    label='Location'
                                  >
                                    <MenuItem value=''>
                                      <em>None</em>
                                    </MenuItem>
                                    {locations &&
                                      locations.map(singleLocation => (
                                        <MenuItem key={singleLocation.id} value={singleLocation.id}>
                                          {singleLocation.title}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <FormControl variant='outlined' className='max-width'>
                                  <InputLabel id='user-select'>User</InputLabel>
                                  <Select
                                    labelId='user-select'
                                    id='user-select'
                                    defaultValue=''
                                    onChange={event => setSelectedUser(event.target.value)}
                                    label='User'
                                  >
                                    <MenuItem value=''>
                                      <em>None</em>
                                    </MenuItem>
                                    {users?.map(singleUser => (
                                      <MenuItem key={singleUser.id} value={singleUser.id}>
                                        {singleUser.firstName + ' ' + singleUser.lastName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={12}>
                                <Button
                                  className='max-width button-ui'
                                  variant='contained'
                                  onClick={handleFiltration}
                                  color='primary'
                                >
                                  Filter
                                </Button>
                              </Grid>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* End of Filters */}
                    {loading
                      ? (
                        <Container>
                          <Grid item container justify='center'>
                            <CircularProgress size='3rem' />
                          </Grid>
                        </Container>
                        )
                      : showReservations
                        ? (
                          <Grid item container xs={12} md={12} spacing={2}>
                            <Grid item xs={12} md={12}>
                              <Card>
                                <CardContent>
                                  <Typography variant='h6' gutterBottom>
                                    Select reservations
                                  </Typography>
                                  <div style={{ height: 400, width: '100%' }} className='material-reset'>
                                    <DataGrid
                                      checkboxSelection
                                      onSelectionModelChange={e => {
                                        const selectedIDs = new Set(e.selectionModel)
                                        setSelectedReservations(reservations.filter(row => selectedIDs.has(row.id)))
                                      }}
                                      rows={reservations}
                                      columns={columns}
                                      pageSize={10}
                                    />
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography color='primary'>Select the user to transfer the reservations</Typography>
                              <FormControl variant='outlined' className='max-width'>
                                <InputLabel id='transfer-to-user'>Transfer To</InputLabel>
                                <Select
                                  labelId='transfer-to-user'
                                  id='transfer-to-user'
                                  defaultValue=''
                                  onChange={event => setTransferTo(event.target.value)}
                                  label='Location'
                                >
                                  <MenuItem value=''>
                                    <em>None</em>
                                  </MenuItem>
                                  {users?.map(singleUser => (
                                    <MenuItem key={singleUser.id} value={singleUser.id}>
                                      {singleUser.firstName + ' ' + singleUser.lastName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Button
                                variant='contained'
                                color='primary'
                                style={{ marginTop: '1em' }}
                                onClick={handleTransfer}
                              >
                                Transfer Reservations
                              </Button>
                            </Grid>
                          </Grid>
                          )
                        : (
                            ''
                          )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Transfers }
