import { setAllApps, setApps, setAuthUrlMap, setLoading } from '../redux/slice/my-apps.slice'
import { API } from '../api/API'

export const getMyApps = (accountId, token) => async dispatch => {
  try {
    dispatch(setLoading(true))
    const res = await API.getMyApps(accountId, token)
    if (res.data) {
      dispatch(setApps(res.data))
    }
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setLoading(false))
  }
}

export const getAllApps = (accountId, token) => async dispatch => {
  try {
    dispatch(setLoading(true))
    const res = await API.getApps(accountId, token)
    if (res.data) {
      dispatch(setAllApps(res.data))
    }
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setLoading(false))
  }
}

export const getAuthUrls = accountId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const res = await API.getAuthUrls(accountId)
    if (res.data) {
      dispatch(setAuthUrlMap(res.data))
    }
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setLoading(false))
  }
}
