import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './redux'
import App from './App'
import { config } from './config'

import './assets/scss/theme.scss'
import './styles/root.scss'

if (config.alerts) {
  Sentry.init({
    dsn: 'https://ebef3f111de143b2be0448c16203e363@o457843.ingest.sentry.io/6001914',
    release: 'edbridge@' + config.version,
    environment: config.env,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
