import React, { useState } from 'react'
import accordionIcon from '../../../../assets/icons/accordionUp.svg'

const SlackAppSettings = ({ app, settings, setSettings }) => {
  const [settingsExpanded, setSettingsExpanded] = useState(true)

  return (
    <div className={`slackSettingsSection ${settingsExpanded ? '' : 'closeSettingsAccordion'}`}>
      <div className="settingsTitleArea">
        <div className="textArea">
          <h4 className="titleText">Settings</h4>
          <p className="desc">
            To integrate with {app?.name}, your clients will need to grant you permission to their {app?.name} Account.
            Input the required fields to allow {app?.name} to identify you.
          </p>
        </div>
        <div className="accordionArea" onClick={() => setSettingsExpanded(!settingsExpanded)}>
          <img src={accordionIcon} className={`accordionIcon ${settingsExpanded ? '' : 'closeAccordion'}`} />
        </div>
      </div>
      <div className="settingsBodyArea">
        <div className="row">
          <div className="column">
            <div className="label">Client ID</div>
            <input
              className="input"
              type="text"
              placeholder="Enter Client ID..."
              value={settings?.clientId ?? ''}
              onChange={e => {
                setSettings(settings => {
                  return { ...settings, clientId: e.target.value }
                })
              }}
            />
          </div>
          <div className="column">
            <div className="label">Client Secret</div>
            <input
              className="input"
              type="text"
              placeholder="Enter Secret..."
              value={settings?.clientSecret ?? ''}
              onChange={e => {
                setSettings(settings => {
                  return { ...settings, clientSecret: e.target.value }
                })
              }}
            />
          </div>
          <div className="column">
            <div className="label">Redirect URI (Add this to your OAuth settings)</div>
            <input
              className="input"
              type="text"
              placeholder="Enter Redirect URI..."
              value={settings?.redirectUri ?? ''}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="column" style={{ width: '100%' }}>
            <div className="label">Scope (Comma "," Separated)</div>
            <input
              className="input"
              type="text"
              placeholder="Enter Scope..."
              style={{ width: '80%' }}
              value={settings?.scopes ?? ''}
              onChange={e => {
                setSettings(settings => {
                  return { ...settings, scopes: e.target.value }
                })
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div className="label">
              State <span>(Optional)</span>
            </div>
            <input
              className="input"
              type="text"
              placeholder="Enter state string..."
              value={settings?.state ?? ''}
              onChange={e => {
                setSettings(settings => {
                  return { ...settings, state: e.target.value }
                })
              }}
            />
          </div>
          <div className="column">
            <div className="label">
              Team <span>(Optional)</span>
            </div>
            <input
              className="input"
              type="text"
              placeholder="Enter Team ID of a workspace..."
              value={settings?.team ?? ''}
              onChange={e => {
                setSettings(settings => {
                  return { ...settings, team: e.target.value }
                })
              }}
            />
          </div>
        </div>
        <a href={app?.getAuthLink} rel="noreferrer" target="_blank" className="helpText">
          Steps for creating a Slack app (contact our support if you need help) &#8594;
        </a>
      </div>
    </div>
  )
}

export default SlackAppSettings
