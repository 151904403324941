import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API } from '../../../api/API'
import { setLoading } from '../../../redux/slice/my-apps.slice'
import { getMyApps } from '../../../services/my-apps.service'
import integrateButtonIcon from '../../../assets/icons/integrateButtonIcon.svg'


const appCard = props => {
  const { userData } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const disconnect = async () => {
    dispatch(setLoading(true))
    await API.updateMyApp(userData.account.accountId, userData.token, {
      id: props.myAppId,
      status: 'archived'
    })
    dispatch(setLoading(false))
    dispatch(getMyApps(userData.account.accountId, userData.token))
  }

  return (
    <div className="appCard" key={props.key}>
      <div className="rowContainer">
        <img src={props.appIcon} alt="" className="icon" />
        <div className="appTitle">{props.appName}</div>
      </div>
      <button
        className={`button ${props.buttonType.toLowerCase()}`}
        onClick={() => {
          if (props.buttonType === 'Disconnect') {
            disconnect()
            return
          }
          const url = props.authUrl
            ? `${props.authUrl}&state=${JSON.stringify({ app: props.appSlug, accountId: userData.account.accountId })}`
            : `${props.customUrl}&appId=${props.appId}&accountId=${userData.account.accountId}&token=${userData.token}`
          const integrationWindow = window.open(url, props.appName)
          const interval = setInterval(() => {
            if (integrationWindow.closed) {
              clearInterval(interval)
              dispatch(getMyApps(userData.account.accountId, userData.token))
            }
          }, 1000)
        }}
      >
        {props.buttonType}
        {props.buttonType !== 'Disconnect' && <img src={integrateButtonIcon} className="buttonIcon" />}
      </button>
    </div>
  )
}

export default appCard
