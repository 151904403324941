import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { config } from '../../config'
import { useCookies } from 'react-cookie'
import champSelectLogo from '../../assets/img2/champselect-logo-with-text.svg'
import emailIcon from '../../assets/img2/email-icon.svg'
import lockIcon from '../../assets/img2/lock-icon.svg'
import userSlice from '../../redux/slice/user.slice'
import { useSelector, useDispatch } from 'react-redux'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Login = () => {
  const { theme } = useSelector(state => state?.themes)
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const [, setCookie] = useCookies(['champselectToken', 'champselectActiveAccount'])

  const [errMsg, setErrMsg] = useState('')
  const [, setWelcomeMsg] = useState('')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const login = async (e) => {
    e.preventDefault()
    setErrMsg('Logging in...')

    try {
      // validation
      if (!email) {
        return setErrMsg('Email cannot be blank.')
      }
      if (!password) {
        return setErrMsg('Password cannot be blank.')
      }

      const response = await axios.post(
        `${config.apiUrl}/public/auth/token`,
        {
          email,
          password
        },
        {}
      )

      if (response && response.data && response.data.token) {
        // set token
        setCookie('champselectToken', response.data.token, {
          domain: document.location.hostname,
          path: '/',
          expires: new Date(moment.unix(response.data.expires).toDate())
        })
        setCookie('champselectActiveAccount', response.data.accounts[0].accountId, { domain: document.location.hostname, path: '/' })
        setCookie('champselectUserId', response.data.userId, { domain: document.location.hostname, path: '/' })
        dispatch(userSlice.actions.setUser(response.data))
        // move on to the next page
        const returnTo = query.get('returnTo')
        if (returnTo) {
          history.push(returnTo)
        } else {
          history.push('/router')
        }
        setErrMsg('')
      } else {
        return setErrMsg('Unable to login. Please contact support for help! Code 1')
      }
    } catch (err) {
      if (err && err.response && err.response.data && (err.response.data.msg || err.response.data.err)) {
        return setErrMsg(`${err.response.data.msg || err.response.data.err}`)
      }
      return setErrMsg('Unable to login. Please contact support for help! Code 2')
    }
  }

  useEffect(() => {
    // if we are coming from signup success, display a welcome message
    const signup = query.get('signup')
    if (signup === 'success') {
      setWelcomeMsg(
        'Account Created Successfully. Please use your email and password to login. If you have an questions please contact us at support@champselect.ai. Welcome onboard!'
      )
    }

    // if we are coming from reset success, display a msg
    const reset = query.get('reset')
    if (reset === 'success') {
      setWelcomeMsg(
        'Password Reset Successful. Please use your email and password to login. If you have an questions please contact us at support@champselect.ai. Welcome back!'
      )
    }

    // if we are coming from invite success, display a msg
    const invite = query.get('invite')
    if (invite === 'success') {
      setWelcomeMsg(
        'Your have successfully completed the invitation. Please use your email and password to login. If you have an questions please contact us at support@champselect.ai. Welcome back!'
      )
    }
  }, [])

  return (
    <div className='login'>
      <div className='left-container'>
        <div className='transparent'>
          <p>
            <strong>Allowing your clients to integrate your app with the world!</strong>
          </p>
        </div>
      </div>
      <div className='right-container'>
        <form className='form-container' onSubmit={login}>
          <img src={champSelectLogo} alt='champselect-logo' style={{ width: '220px', marginBottom: '24px' }} />
          <div className='title'>Welcome back</div>
          <div className='inputArea'>
            <img src={emailIcon} className='inputIcon' alt='email-icon' />
            <input
              type='text'
              placeholder='Your Email Address'
              required
              onChange={e => setEmail(e.target.value)}
              autoFocus
            />
          </div>
          <div className='inputArea'>
            <img src={lockIcon} className='inputIcon' alt='lock-icon' />
            <input
              type='password'
              placeholder='Your Password'
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className='forgot-password-container'>
            <div>
              <input id='remember-me' className='login-checkbox' type='checkbox' />
            </div>
            <label htmlFor='remember-me'>Remember me</label>
            <span>
              <Link to='/forgot-password'>Forgot Password</Link>
            </span>
          </div>
          <span className='err'>{errMsg}</span>
          <button
            type='submit'
            onClick={(e) => login(e)}
          >
            Login
          </button>
          <p className='signup-link'>
            Don’t have an account?{' '}
            <span>
              <Link to='/signup'>Sign Up now</Link>
            </span>
          </p>
          <p className='copyright-msg'>Copyright © 2022 ChampSelect.ai. All rights reserved</p>
        </form>
      </div>
    </div>
  )
}

export { Login }
