import React from 'react'
import emptyOrders from '../../../../assets/icons/emptyOrders.svg'

const EmptyOrder = () => {
  return (
    <div className='emptyOrderComponent'>
      <img
        className='emptyIcon'
        src={emptyOrders}
        alt='empty'
      />
      <div className="emptyTitle">
        Send us your first Order
      </div>
      <div className="emptySubtitle">
        Click the "Create an Order" to create one
      </div>
    </div>
  )
}

export default EmptyOrder