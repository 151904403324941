import moment from 'moment'
import 'date-fns'
import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { Row, Col, Button, Table, Spinner, Input } from 'reactstrap'
import ReservationExpandedInfo from './ReservationExpandedInfo'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { API } from '../../../api/API'

const PER_PAGE = 10

const ReservationViewer = ({ authObj }) => {
  const [reservations, setReservations] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(null)
  const [searchType, setSearchType] = useState('')
  const [loader, setLoader] = useState(true)
  const [errMsg, setErrMsg] = useState('')
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate())
  const [initialLoad] = useState(true)
  useEffect(() => {
    getReservations()
  }, [])
  const getReservations = async () => {
    setErrMsg('Fetching reservations...')
    setLoader(true)
    try {
      const res = await API.getReservationsList(
        authObj.account.accountId,
        authObj.token,
        moment(startDate).unix(),
        moment(endDate).unix(),
        searchType,
        search
      )
      setLoader(false)
      if (res && res.data && res.data.reservations) {
        setErrMsg('')
        setReservations(
          res.data.reservations
          //  uncomment below to test more data with the pagination
          // .concat(res.data.reservations)
          // .concat(res.data.reservations)
          // .concat(res.data.reservations)
          // .concat(res.data.reservations)
          // .concat(res.data.reservations)
        )
      } else {
        setErrMsg('Unable to get reservations (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get reservations (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    if (search || (search === '' && !initialLoad)) {
      if (searchType && searchType !== '') {
        if (searching) {
          clearTimeout(searching)
        }
        const s = setTimeout(() => {
          setErrMsg('Searching...')
          getReservations()
        }, 1000)

        setSearching(s)
      }
    }
  }, [search, searchType])

  const submitDate = () => {
    if (startDate && endDate) {
      getReservations()
    }
  }
  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const offset = currentPage * PER_PAGE

  const currentPageData = reservations.slice(offset, offset + PER_PAGE)

  const pageCount = Math.ceil(reservations.length / PER_PAGE)

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={6} md={6}>
            <div
              style={{
                padding: '16px',
                marginTop: '16px',
                borderColor: '#302a52',
                borderStyle: 'solid',
                borderRadius: '8px',
                borderWidth: '2px'
              }}
            >
              <Input
                type='text'
                placeholder='Search for reservations here'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </Col>
          <Col xs={6} md={6}>
            <div style={{ padding: '16px', marginTop: '16px' }}>
              <Input
                type='select'
                name='searchType'
                value={searchType}
                onChange={e => {
                  setSearchType(e.target.value)
                }}
              >
                <option value=''>Select filter</option>
                <option value='reservationId'>Reservation Id</option>
                <option value='transactionId'>Tansaction Id</option>
                <option value='name'>Name</option>
              </Input>
            </div>
          </Col>

          <Row style={{ marginTop: '1%', marginLeft: '1%' }}>
            <Col>
              <label>start date</label>
              <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
            </Col>
            <Col>
              <label>end date</label>
              <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
            </Col>
            <Col>
              <Button color='primary' style={{ marginTop: '14%' }} onClick={submitDate}>
                submit
              </Button>
            </Col>
          </Row>

          <Col xs={12} md={12}>
            <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box'>
                <header className='panel_header'>
                  <h2 className='title float-left'>Reservations</h2>
                </header>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table>
                        <thead>
                          <tr>
                            <th>Programs</th>
                            <th>Sessions</th>
                            <th>Attendees</th>
                            <th>Guardians</th>
                            <th>Paid By</th>
                            <th>Total</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservations &&
                            currentPageData.map((reservation, i) => {
                              return (
                                <ReservationExpandedInfo key={`${reservation.id}_${i}`} reservation={reservation} />
                              )
                            })}
                        </tbody>
                      </Table>
                      <div style={{ float: 'right', padding: '16px' }}>
                        <ReactPaginate
                          pageCount={pageCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          breakClassName='page-item'
                          breakLinkClassName='page-link'
                          containerClassName='pagination'
                          pageClassName='page-item'
                          pageLinkClassName='page-link'
                          previousClassName='page-item'
                          previousLinkClassName='page-link'
                          nextClassName='page-item'
                          nextLinkClassName='page-link'
                          activeClassName='active'
                          className='pagination'
                          onPageChange={page => handlePageChange(page.selected)}
                        />
                      </div>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { ReservationViewer }
