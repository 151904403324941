import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ReactSortable } from 'react-sortablejs'
import { config } from '../../../config'

import './OrderingModal.scss'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'

const OrderingModal = ({ authObj, programs, modalOpen, setModalOpen, refreshTable }) => {
  const [loader, setLoader] = useState(true)

  // fields
  const [sortedPrograms, setSortedPrograms] = useState(programs)

  const [errMsg, setErrMsg] = useState('')

  const save = async () => {
    setErrMsg('Updating order of programs...')
    try {
      // validation
      if (!sortedPrograms) {
        return setErrMsg('Unable to save.')
      }

      const programIds = sortedPrograms.map(p => {
        return p.id
      })
      const res = await axios.put(
        `${config.apiUrl}/app/programs/order`,
        { programIds },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res && res.data) {
        setErrMsg('Program Ordering Updated Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to update program ordering (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update program ordering (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
    setLoader(false)
  }, [])

  useEffect(() => {
    // only sort active programs
    if (programs && programs.length > 0) {
      const activePrograms = programs.filter(p => {
        return p.status === 'active'
      })
      setSortedPrograms(activePrograms)
    }
  }, [programs])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Customize Ordering of "ACTIVE" Program</ModalHeader>
      {(loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner color='primary' />
        </div>
      )) || (
        <ModalBody>
          <ReactSortable list={sortedPrograms} setList={setSortedPrograms} ghostClass='sortableGhost'>
            {sortedPrograms.map(item => {
              return (
                <div
                  style={{
                    border: '1px solid black',
                    padding: '5px',
                    marginTop: '5px',
                    cursor: 'grab'
                  }}
                  key={item.id}
                >
                  {item.title}
                </div>
              )
            })}
          </ReactSortable>
        </ModalBody>
      )}

      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>

        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            save()
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { OrderingModal }
