/* eslint-disable no-undef */
import React, { useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import champSelectLogo from '../../assets/img2/champselect-logo.svg'
import { Link, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import themeSlice from '../../redux/slice/theme.slice'
import LogoutIcon from '../Icons/LogoutIcon'
import ProfileIcon from '../Icons/ProfileIcon'
import SettingsArrow from '../Icons/SettingsArrow'
import darkMoon from '../../assets/icons/dark-moon.svg'
import lightSun from '../../assets/icons/light-sun.svg'

const Navbar = ({ authObj, processes, automationCount, plan }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { settings } = useSelector(state => state.profile)
  const accountName = settings?.account?.name
  const [, , removeCookie] = useCookies(['champselectToken'])
  const { userData } = useSelector(state => state.auth)

  const [settingsModel, showSettingsModel] = useState(false)
  const { theme } = useSelector(state => state?.themes)
  const [permitEveryone, setPermitEveryone] = useState(false)

  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return history.location.pathname.indexOf(routeName) > -1 ? 'activeTab' : ''
  }

  const logout = () => {
    removeCookie('champselectToken', { domain: document.location.hostname, path: '/' })
    history.push('login')
  }

  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark'
    dispatch(themeSlice.actions.setTheme(isCurrentDark ? 'light' : 'dark'))
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark')
  }

  return (
    <div className="navbar" >
      <div className='logoArea'>
        <Link to="/orders">
          <div className='logoContainer'>
            <img src={champSelectLogo} alt='ChampSelect logo' className='logo' />
            <span className='title'>ChampSelect.ai</span>
          </div>
        </Link>
        {/* {accountName && (
          <>
            <div className='verticalSeparator' />
            <div className='org'>{accountName}</div>
          </>
        )} */}
      </div>
      <div className='menuContainer'>
        <div className={`orders ${activeRoute('orders')}`}>
          <Link to='/orders'>
            <div className='title'>Orders</div>
          </Link>
        </div>
        <div className={`workflows ${activeRoute('workflows')}`}>
          <Link to='/workflows'>
            <div className='title'>Workflows</div>
          </Link>
        </div>
        <div className={`billing ${activeRoute('billing')}`}>
          <Link to='/billing'>
            <div className='title'>Billing</div>
          </Link>
        </div>
        {/* <div className={`billing ${activeRoute('billing')}`}>
          <Link to='/billing'>
            <div className='title'>Billing</div>
          </Link>
        </div> */}
        {authObj.account.accountName.toLowerCase().includes('got game') && (
          <>
            <div className='programs'>
              <Link to='/programs'>
                <div className={`title ${activeRoute('programs')}`}>Programs</div>
              </Link>
            </div>
            <div className='discounts'>
              <Link to='/discounts'>
                <div className={`title ${activeRoute('discounts')}`}>Discounts</div>
              </Link>
            </div>
            <div className='forms'>
              <Link to='/forms/builder'>
                <div className={`title ${activeRoute('/forms/builder')}`}>Widget Builder</div>
              </Link>
            </div>
            <div className='reservations'>
              <Link to='/reservations'>
                <div className={`title ${activeRoute('/reservations')}`}>Registrations</div>
              </Link>
            </div>
            <div className='rosters'>
              <Link to='/rosters'>
                <div className={`title ${activeRoute('/rosters')}`}>Rosters</div>
              </Link>
            </div>
          </>
        )}
      </div>
      <div className='settingsArea'>
        <div
          className='clickableArea'
          onClick={(e) => {
            e.stopPropagation()
            showSettingsModel(!settingsModel)
          }}
        >
          <div className='imageContainer'>
            <img src={userData?.user?.imgUrl} className='image' alt='' />
          </div>
          <SettingsArrow color={ theme === 'dark' ? '#81b9f9' : '#2d2d31'}/>
          {settingsModel && (
            <div className='dropdownContainer'>
              <div
                onClick={(e) => { 
                  e.stopPropagation()
                  setPermitEveryone(!permitEveryone)
                  handleThemeChange()
                }}
                className='menuItem'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div className="darkmode-switch">
                  <div className="switch-container">
                    <label htmlFor="everyone">
                      <input
                        id="everyone"
                        type="radio"
                        name="darkmode-switch"
                        checked={theme === 'dark'}
                      />
                      <span />
                    </label>
                  </div>
                </div>
                <div className="menuText">
                  {theme === 'dark' ? 'Dark Mode' : 'Light Mode'}
                </div>
                <img src={theme === 'dark' ? darkMoon : lightSun} className='mode' alt='' />
              </div>
              <Link
                to='/profile'
                onClick={() => {
                  showSettingsModel(false)
                }}
                className='menuItem'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ProfileIcon color={ theme === 'dark' ? '#ffffff' : '#424451'}/>
                <div className="menuText">
                  Profile
                </div>
              </Link>
              <div
                onClick={() => logout()}
                className='menuItem'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <LogoutIcon color={theme === 'dark' ? '#ffffff' : '#424451'} />
                <div className="menuText">
                  Logout
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {settingsModel && (
        <div className="backdrop" onClick={() => showSettingsModel(false)} />
      )}
    </div>
  )
}

export { Navbar }
