/* eslint-disable no-tabs */
import React from 'react'

const WorkflowRules = ({ processesList }) => {
  return (
    <div className='workflowRuleModal'>
      <div className='processDetailsInfoPage'>
        <div className='titleText'>{processesList?.title}</div>
        <div className='InformationContainer'>
          <div className='InformationDetails'>
            <div className='informationTitle'>Workflow Rules</div>
            <div className='contractDetailsContainer'>
              <div className='contractText'>
                No Workflow Rules available at the moment.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkflowRules
