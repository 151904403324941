import React, { useState, useEffect, useReducer, useRef } from 'react'
import moment from 'moment-timezone'
import { ScheduledTrigger } from './ScheduledTrigger'
import { WebhookTrigger } from './WebhookTrigger'
import ArrowLeftIcon from '../../../assets/img2/arrowLeft.svg'
import arrowDown from '../../../assets/img2/arrowDown.svg'
import CalendarIcon from '../../../assets/img2/calendar-dark.svg'
import HttpIcon from '../../../assets/img2/http-dark.svg'
import CodeIcon from '../../../assets/img2/code-icon-blue.svg'
import { useHistory } from 'react-router-dom'

import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css'
import { API } from '../../../api/API'
import { useSelector } from 'react-redux'
import { config } from '../../../config'
import BackIcon from '../../../components/Icons/BackIcon'
import Webhook from '../../../components/Icons/Webhook'
import Calendar from '../../../components/Icons/Calendar'
import RadioButton from '../../../components/Icons/RadioButton'
import { deploymentTypes } from './data/DeploymentTypes'
import { getBackgroundColor, getIconColor } from './MiscFunctions/Miscellaneous'

const CreateWorkflow = ({ authObj }) => {
  const history = useHistory()
  const { theme } = useSelector(state => state?.themes)
  const themeColor = theme === 'dark' ? '#ffffff' : '#3e3d40'
  const { apps } = useSelector(state => state.myApps)
  const myApps = apps?.filter(app => !app.clientId)
  const clientApps = [...new Map(apps?.filter(app => !!app.clientId).map(item => [item?.app?.slug, item])).values()]
  const [isLoading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [title, setTitle] = useState('')
  const [selectedTrigger, setSelectedTrigger] = useState(undefined)
  const [expandableMyAppsTabs, setExpandableMyAppsTabs] = useState([])
  const [expandableClientTabs, setExpandableClientTabs] = useState([])
  const [codeSelector, setCodeSelector] = useState('MyApps')
  const [code, setCode] = useState(`
// import third-party library
const axios = require("axios")

// use your connected apps with \`MyApps\`
console.log(MyApps)

for(const Client of Clients) {
  // Operations using client
  console.log(Client.Email)
  console.log(Client.UserId)
  console.log(Client.Apps)
}

// use payload passed in the request body using \`Payload\`
console.log(Payload)

// use \`input\` for testing your code
console.log(input)

// return anything you want
return input
`)
  const [input, setInput] = useState(`{
  "input": {
    "a": 100,
    "b": 200
  }
}`)
  const [output, setOutput] = useState(`{
  "output": {
    "sum": 300
  }
}`)
  const [cursor, setCursor] = useState(0)
  const prevCodeAndCursor = useRef({ code, cursor })
  const [permitEveryone, setPermitEveryone] = useState(true)
  const [permissionEmails, setPermissionEmails] = useState([])
  const [workflowId, setWorkflowId] = useState('')
  const [deployOption, setDeployOption] = useState('small')

  // scheduled trigger
  const [scheduledTrigger, setScheduledTrigger] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'timeUnit':
          return { ...state, timeUnit: action.payload }
        case 'frequency':
          return { ...state, frequency: action.payload }
        case 'atTime':
          return { ...state, atTime: action.payload }
        case 'timeZone':
          return { ...state, timeZone: action.payload }
      }
    },
    { timeUnit: 'daily', frequency: 1, atTime: '', timeZone: moment.tz.guess() }
  )

  // webhook trigger
  const [webhookUrl, setWebhookUrl] = useState('')

  const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split('\n')
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join('\n')

  const createWorkflow = async () => {
    setErrMsg('')
    if (!workflowId) {
      return setErrMsg('Workflow id must be provided')
    }
    if (!title) {
      return setErrMsg('Workflow name must be provided')
    }
    if (!selectedTrigger) {
      return setErrMsg('Workflow trigger type must be provided')
    }
    if (selectedTrigger === 'webhook' && !webhookUrl) {
      return setErrMsg('Webhook URL must be provided')
    }
    if (!permitEveryone && permissionEmails.length === 0) {
      return setErrMsg('At least one email should be permitted')
    }
    const workflow = {
      id: workflowId,
      title: title,
      trigger: selectedTrigger,
      triggerMetadata: selectedTrigger === 'scheduled' ? scheduledTrigger : { webhookUrl },
      customCode: code,
      permittedEmails: permitEveryone ? [] : permissionEmails,
      status: 'active'
    }
    setLoading(true)
    const res = await API.createProcess(authObj.account.accountId, authObj.token, workflow)
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to create workflow. Please try again.')
    }
    if (res.data) {
      history.replace('/workflows')
    }
  }

  const runCode = async () => {
    setErrMsg('')
    setLoading(true)
    const res = await API.runCode(authObj.account.accountId, authObj.token, code, input)
    setLoading(false)
    if (res.data) {
      setOutput(JSON.stringify(res.data, undefined, 2))
    }
    if (res.err) {
      setErrMsg('Unable to run workflow id. Please try again.')
    }
  }

  const getUUID = async () => {
    const res = await API.getUUID(authObj.account.accountId)
    if (res.data?.uuid) {
      setWorkflowId(res.data.uuid)
    } else {
      setErrMsg('Unable to get workflow id. Please refresh the page.')
    }
  }

  const insertFieldsInCode = (e, newString) => {
    e.target.blur()
    const cursor = document.querySelector('#codeArea').selectionStart
    setCursor(cursor + newString.length)
    setCode(code => `${code.substring(0, cursor)}${newString}${code.substring(cursor)}`)
  }

  const onExpandMyAppsTab = slug => {
    if (!expandableMyAppsTabs.includes(slug)) {
      setExpandableMyAppsTabs([...expandableMyAppsTabs, slug])
    } else {
      setExpandableMyAppsTabs(expandableMyAppsTabs.filter(t => t !== slug))
    }
  }

  const onExpandClientTab = slug => {
    if (!expandableClientTabs.includes(slug)) {
      setExpandableClientTabs([...expandableClientTabs, slug])
    } else {
      setExpandableClientTabs(expandableClientTabs.filter(t => t !== slug))
    }
  }

  useEffect(() => {
    if (workflowId) {
      setWebhookUrl(`${config.apiUrl}/webhooks?id=${workflowId}&accountId=${authObj.account.accountId}`)
    }
  }, [workflowId])

  // effect to focus the cursor on the text area after code insertion
  useEffect(() => {
    if (code !== prevCodeAndCursor.current.code && cursor !== prevCodeAndCursor.current.cursor) {
      const codeArea = document.querySelector('#codeArea')
      if (codeArea.setSelectionRange) {
        codeArea.focus()
        codeArea.setSelectionRange(cursor, cursor)
      } else if (codeArea.createTextRange) {
        // for olders browsers
        const range = codeArea.createTextRange()
        range.collapse(true)
        range.moveEnd('character', cursor)
        range.moveStart('character', cursor)
        range.select()
      } else {
        codeArea.focus()
      }

      return () => {
        prevCodeAndCursor.current.code = code
        prevCodeAndCursor.current.cursor = cursor
      }
    }
  }, [code, cursor])

  useEffect(() => {
    getUUID()
  }, [])

  useEffect(() => {
    if (myApps.length === 0) {
      setCodeSelector('Client')
    }
  }, [myApps])

  return (
    <div className="create-workflow-container-new">
      {isLoading ? (
        <div className="loader">
          <div className="spinner" />
          <div className="text">Loading, please wait</div>
        </div>
      ) : (
        <>
          <div className="navigationArea">
            <div className="backContainer">
              <div className="backButton" onClick={() => history.push('/requests')}>
                <BackIcon color={themeColor} />
                <div className="backText">Back</div>
              </div>
            </div>
            <div className="buttonsArea">
                <button
                  className="secondaryBorderedButton"
                  style={{ marginRight: '16px' }}
                  onClick={() => history.replace('/workflows')}
                >
                Reset
              </button>
              <button className="primaryButton" onClick={() => createWorkflow()}>
                Save Workflow
              </button>
            </div>
          </div>
          {errMsg && <p className='err'>{errMsg}</p>}
          <div className="name-container">
            <span>Workflow Name</span>
            <input
              type="text"
              placeholder="Your Workflow Name"
              autoFocus
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div className="triggerType">
            <div className="titleArea">
              <span className="trigger-title">How should this Workflow be triggered?</span>
            </div>
            <div className="trigger-row">
              <div 
                className="card" 
                onClick={() =>  setSelectedTrigger('scheduled')}
              >
                <Calendar 
                  backgroundColor={getBackgroundColor(theme, 'scheduled', selectedTrigger)}
                  iconColor={getIconColor(theme, 'scheduled', selectedTrigger)}
                />
                <div className="column">
                  <span className="title">Scheduled</span>
                  <span className="subtitle">Run this workflow at a specific time or on a recurring basis</span>
                </div>
              </div>
              <div 
                className="card" 
                onClick={() => setSelectedTrigger('webhook')}
              >
                <Webhook 
                  backgroundColor={getBackgroundColor(theme, 'webhook', selectedTrigger)}
                  iconColor={getIconColor(theme, "webhook", selectedTrigger)}
                />
                <div className="column">
                  <span className="title">Webhook</span>
                  <span className="subtitle">Trigger this Workflow by sending an HTTP request to a Webhook</span>
                </div>
              </div>
            </div>
            {selectedTrigger === 'scheduled' && (
              <ScheduledTrigger
                isEditing
                scheduledTrigger={scheduledTrigger}
                setScheduledTrigger={setScheduledTrigger}
                setSelectedTrigger={setSelectedTrigger}
              />
            )}
            {selectedTrigger === 'webhook' && (
              <WebhookTrigger
                isEditing
                setSelectedTrigger={setSelectedTrigger}
                webhookUrl={webhookUrl}
              />
            )}  
          </div>
          
          <div className='deploymentOptionsCard'>
            <div className="deploymentTitle">Deployment Options</div>
            <p className="subtitle">Contact support to change deployment methods</p>
            <div className="cardsArea">
              {deploymentTypes?.map(dt => (
                <div key={dt?.id} className={`card disabledCard ${deployOption === dt?.type && 'selectedCard'}`} onClick={() => setDeployOption(dt?.type)}>
                  <RadioButton
                    selected={deployOption === dt?.type}
                    theme={theme}
                  />
                  <div className="columnText">
                    <div className="columnTitle">{dt?.title}</div>
                    <p className="subtitle">{dt?.subtitle1}</p>
                    <p className="subtitle">{dt?.subtitle2}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <span className="permission-title">Set Permissions</span>
          <span className="permission-subtitle">Who will this workflow run for?</span>
          <div className="permission-switch">
            <div className="switch-container">
              <label htmlFor="everyone">
                <input
                  id="everyone"
                  type="radio"
                  name="permission-switch"
                  checked={permitEveryone}
                  onChange={e => {
                    setPermitEveryone(e.target.checked)
                  }}
                />
                <span />
              </label>
            </div>
            <span>
              Enable access for <span>Everyone</span>
            </span>
          </div>
          <div className="permission-switch">
            <div className="switch-container">
              <label htmlFor="specific">
                <input
                  id="specific"
                  type="radio"
                  name="permission-switch"
                  checked={!permitEveryone}
                  onChange={e => {
                    setPermitEveryone(!e.target.checked)
                  }}
                />
                <span />
              </label>
            </div>
            <span>
              Enable access for <span>Specific People</span>
            </span>
          </div> */}
          {/* {!permitEveryone && (
            <>
              <div className="people">
                <div className="count">{permissionEmails.length}</div>
                {permissionEmails.map(email => {
                  return (
                    <div className="tab" key={email}>
                      <span>{email}</span>
                      <button
                        onClick={() => {
                          setPermissionEmails(emails => emails.filter(e => e !== email))
                        }}
                      >
                        x
                      </button>
                    </div>
                  )
                })}
                <input
                  type="text"
                  placeholder="Enter an email address"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      const email = e.target.value
                      setPermissionEmails(emails => {
                        if (!emails.includes(email)) {
                          return [...emails, email]
                        }
                        return emails
                      })
                      e.target.value = ''
                    }
                  }}
                />
              </div>
              <span className="people-msg">Press Enter after typing an email address</span>
            </>
          )} */}
          {/* <div className="code-header">
            <img src={CodeIcon} alt="Code" />
            <span>Custom Code</span>
            <button
              onClick={() => {
                window.open(
                  'https://docs.google.com/document/d/e/2PACX-1vTmKB0UbY6MO2NCts5OAG0zNx63JdlcbmqXeFbl2umEUd05297ffzfGcKpq-F5XyNh4b2Wqih4gqXP3/pub'
                )
              }}
            >
              Need Help?
            </button>
          </div>
          <div className="rowContainer">
            <div className="customCodeLibrary">
              <div className="titleArea">
                <div
                  className={`title ${codeSelector === 'MyApps' && 'activeTitle'}`}
                  onClick={() => setCodeSelector('MyApps')}
                >
                  My Apps
                </div>
                <div
                  className={`title ${codeSelector === 'Client' && 'activeTitle'}`}
                  onClick={() => setCodeSelector('Client')}
                >
                  Client
                </div>
              </div>
              <div className="bodyArea">
                {codeSelector === 'MyApps' ? (
                  <div className="dropdown-menu">
                    {myApps?.map(app => (
                      <div className="dropdown-item" key={app?.id}>
                        {Object.keys(app?.credentials).length && (
                          <img
                            src={ArrowLeftIcon}
                            alt=""
                            className={`icon ${expandableMyAppsTabs.includes(app?.app?.slug) && 'activeIcon'}`}
                            onClick={() => onExpandMyAppsTab(app?.app?.slug)}
                          />
                        )}
                        <button className="dropdown-btn" onClick={() => onExpandMyAppsTab(app?.app?.slug)}>
                          {app?.app?.slug}
                        </button>
                        {expandableMyAppsTabs.includes(app?.app?.slug) && (
                          <div className="dropdown-menu">
                            {Object.keys(app?.credentials).map(k => (
                              <div className="dropdown-item" key={k}>
                                <button
                                  className="dropdown-btn"
                                  onClick={e => insertFieldsInCode(e, ` MyApps.${app?.app?.slug}.${k} `)}
                                >
                                  {k}
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="dropdown-menu">
                    <div className="dropdown-item">
                      <button className="dropdown-btn" onClick={e => insertFieldsInCode(e, ' Client.Email ')}>
                        Email
                      </button>
                    </div>
                    <div className="dropdown-item">
                      <button className="dropdown-btn" onClick={e => insertFieldsInCode(e, ' Client.UserId ')}>
                        UserId
                      </button>
                    </div>
                    <div className="dropdown-item">
                      <img
                        src={ArrowLeftIcon}
                        alt=""
                        className={`icon ${expandableClientTabs.includes('Apps') && 'activeIcon'}`}
                        onClick={() => onExpandClientTab('Apps')}
                      />
                      <button className="dropdown-btn" onClick={() => onExpandClientTab('Apps')}>
                        Apps
                      </button>
                      {expandableClientTabs.includes('Apps') && (
                        <div className="dropdown-menu">
                          {clientApps?.map(app => (
                            <div className="dropdown-item" key={app?.id}>
                              <img
                                src={ArrowLeftIcon}
                                alt=""
                                className={`icon ${expandableClientTabs.includes(app?.app?.slug) && 'activeIcon'}`}
                                onClick={() => onExpandClientTab(app?.app?.slug)}
                              />
                              <button className="dropdown-btn" onClick={() => onExpandClientTab(app?.app?.slug)}>
                                {app?.app?.slug}
                              </button>
                              {expandableClientTabs.includes(app?.app?.slug) && (
                                <div className="dropdown-menu">
                                  {Object.keys(app?.credentials).map(k => (
                                    <div className="dropdown-item" key={k}>
                                      <button
                                        className="dropdown-btn"
                                        key={k}
                                        onClick={e => insertFieldsInCode(e, ` Client.Apps.${app?.app?.slug}.${k} `)}
                                      >
                                        {k}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="code-area" onClick={() => document.getElementsByTagName('textarea')[0].focus()}>
              <div className="codeContainer">
                <Editor
                  value={code}
                  onValueChange={code => setCode(code)}
                  highlight={code => hightlightWithLineNumbers(code, languages.js)}
                  textareaId="codeArea"
                  className="editor"
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 16,
                    color: '#ffffff'
                  }}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="testCodeArea">
            <div className="code-area">
              <div style={{ display: 'flex', height: '300px' }}>
                <textarea name="inputArea" id="inputArea" value={input} onChange={e => setInput(e.target.value)} />
                <textarea name="inputArea" id="inputArea" value={output} disabled />
              </div>
              <button onClick={() => runCode()}>Test Code</button>
            </div>
          </div> */}
        </>
      )}
    </div>
  )
}

export { CreateWorkflow }
