import React from 'react'
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import Sidebar3 from '../../components/Sidebar/Sidebar3'
import ChangePassword from './ChangePassword'
import GeneralSettings from './GeneralSettings'
import { UserProfile } from './UserProfile'
import { Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import PageTitle from '../../components/PageTitle/PageTitle'
import profileIcon from '../../assets/icons/profileIcon.svg'

const Profile = ({ authObj }) => {
  const { isLoading } = useSelector(state => state.profile)
  const { path, url } = useRouteMatch()
  const location = useLocation()

  return (
    <div className='profilePage'>
      {isLoading
        ? (
          <div className='loadingContainer'>
            <div className='loader'>
              <div className='spinner' />
              <div className='text'>Loading, please wait</div>
            </div>
          </div>
          )
        : (
          <>
            <PageTitle icon={profileIcon} title="Profile" subtitle="Account Information" />
            <div className="horizontalLine" />
            <div className='mainArea'>
              <Sidebar3 url={url} pathName={location.pathname} />
              <div className='mainForm'>
                <Switch>
                  <Route exact path={path}>
                    <UserProfile authObj={authObj} />
                  </Route>
                  <Route path={`${path}/change-password`}>
                    <ChangePassword authObj={authObj} />
                  </Route>
                  <Route path={`${path}/general-settings`}>
                    <GeneralSettings authObj={authObj} />
                  </Route>
                </Switch>
              </div>
            </div>
          </>
        )}
    </div>
  )
}

export default Profile
