import React from 'react'

const GreenLightning = ({ theme }) => {
  return (
    <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8752 8.42109H14.7119L19.3549 2.55469C19.451 2.43047 19.3643 2.25 19.2072 2.25H10.2189C10.1533 2.25 10.09 2.28516 10.0572 2.34375L3.98457 12.832C3.91192 12.9563 4.00098 13.1133 4.14629 13.1133H8.23379L6.13848 21.4945C6.09395 21.6773 6.31426 21.8062 6.4502 21.675L20.0041 8.74219C20.126 8.62734 20.0439 8.42109 19.8752 8.42109Z" fill={theme === 'dark' ? "#8ce28b" : "#51BF50"} />
    </svg>
  )
}

export default GreenLightning