import React from 'react'

const SettingsArrow = ({ color }) => {
  return (
    <svg className='dropdown' width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.178483 0.938335L4.44436 4.80157C4.51312 4.86381 4.59841 4.91376 4.69439 4.94801C4.79037 4.98227 4.89476 5 5.0004 5C5.10604 5 5.21043 4.98227 5.30641 4.94801C5.40239 4.91376 5.48769 4.86381 5.55645 4.80157L9.82232 0.938335C10.2294 0.569585 9.8919 0 9.26627 0H0.733309C0.10768 0 -0.229854 0.569585 0.178483 0.938335Z" fill={color} />
    </svg>
  )
}

export default SettingsArrow