import React from 'react'

const CodeBoxImage = ({ color, theme }) => {
  return (
    <svg className='backgroundBox'viewBox="0 0 575 323" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-34" transform="translate(-597.000000, -95.000000)">
          <g id="Group-40" transform="translate(597.000000, 95.000000)">
            <rect id="Rectangle" fill={ color } x="0" y="0" width="575" height="323" rx="10" />
            <g id="Group-31" transform="translate(23.000000, 43.000000)" stroke="#6c6c6e44" stroke-linecap="square">
              <line x1="532.5" y1="0.5" x2="0.5" y2="0.5" id="Line-2" />
            </g>
            <g id="Group-48" transform="translate(21.000000, 19.000000)">
              <circle id="Oval" fill={ theme === 'dark' ? "#6c6c6e" : "#6c6c6e44"} cx="6" cy="6" r="6" />
              <circle id="Oval-Copy-3" fill={ theme === 'dark' ? "#56565a" : "#56565a44"} cx="25" cy="6" r="6" />
              <circle id="Oval-Copy-4" fill={ theme === 'dark' ? "#424245" : "#42424544"} cx="44" cy="6" r="6" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CodeBoxImage