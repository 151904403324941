import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: null
}

const setUser = (state = initialState, action) => {
  return {
    ...state,
    userData: action.payload
  }
}

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser
  }
})

export default userSlice
