import React, { useState } from 'react'
import lockIcon from '../../assets/img2/password-lock.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Image } from 'react-bootstrap'
import axios from 'axios'
import { config } from '../../config'

const ChangePassword = ({ authObj }) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [length, setLength] = useState(false)
  const [capitalLetters, setCapitalLetters] = useState(false)
  const [symbols, setSymbols] = useState(false)
  const [showPasword, setShowPasword] = useState(false)
  const [isEditable, setEditable] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  const criterionPassword = e => {
    // eslint-disable-next-line no-useless-escape
    const symbolsRgx = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    const capitalLettersRgx = /[A-Z]/g

    e.target.value.match(capitalLettersRgx) ? setCapitalLetters(true) : setCapitalLetters(false)

    e.target.value.match(symbolsRgx) ? setSymbols(true) : setSymbols(false)

    e.target.value.length >= 8 ? setLength(true) : setLength(false)
  }

  const handleShowPassword = e => {
    const input = e.target.previousSibling
    const type = input.getAttribute('type') === 'password' ? 'text' : 'password'

    input.setAttribute('type', type)
  }

  const inputStatus = e => {
    e.target.value.length > 0 ? setShowPasword(true) : setShowPasword(false)
  }

  const saveUserPassword = async (e) => {
    e.preventDefault()
    setErrMsg('Saving User Password...')
    try {
      // validation
      if (newPassword && !oldPassword) {
        return setErrMsg(
          'Please enter your existing password to reset password. If you forgot your password, you can use your forgot-password function to get a link. Please contact support if you have any questions'
        )
      }

      const formData = new FormData() // eslint-disable-line

      if (newPassword) {
        formData.append('oldPassword', oldPassword)
        formData.append('newPassword', newPassword)
      }

      const response = await axios.put(`${config.apiUrl}/app/profile`, formData, {
        headers: {
          Authorization: `Bearer ${authObj.token}`,
          'Content-Type': 'multipart/form-data',
          accountid: authObj.account.accountId
        }
      })

      if (response && response.data) {
        setErrMsg('Password Saved Successfully.')
        setOldPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        setLength(false)
        setSymbols(false)
        setCapitalLetters(false)
      } else {
        setErrMsg('Unable to save password')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to save password. Please contact support for help!')
    }
  }

  const cancelClickHandler = (e) => {
    e.preventDefault()
    setOldPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
    setLength(false)
    setSymbols(false)
    setCapitalLetters(false)
    setEditable(false)
  }

  return (
    <div className={`content ${isEditable && 'biggerHeight'}`}>
      <div className="titleArea">
        <div className="titleText">{isEditable ? 'Update Password' : 'My Password' }</div>
        <div className='profileMainActions'>
          {isEditable ? (
            <>
              <button
                className='cancelButton'
                onClick={cancelClickHandler}
              >
                Cancel
              </button>
              <button
                type='submit'
                disabled={!(oldPassword.length > 0 && newPassword.length > 0 && confirmNewPassword.length > 0 && newPassword === confirmNewPassword)}
                onClick={saveUserPassword}
              >
                Update Password
              </button>
            </>
          ): <button className='editButton' onClick={() => setEditable(true)}>Change Password</button>}
        </div>
      </div>
      {!isEditable ? (
        <div className='row'>
          <div className='form-group'>
            <div className='oldPassword'>Current Password</div>
            <div style={{ width: '60%' }}>
              <div className='input-group'>
                <div className='lockIcon lowerTop'>
                  <Image src={lockIcon} alt='lock-icon' />
                </div>
                <div className='input' style={{ width: '100%' }}>
                  <input
                    value='********'
                    disabled
                    type='password'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        ) : (
        <Form onSubmit={saveUserPassword}>
          <div className='form-group'>
            <div className='oldPassword'>Old Password</div>
            <div style={{ width: '60%' }}>
              <div className='input-group'>
                <div className='lockIcon lowerTop'>
                  <Image src={lockIcon} alt='lock-icon' />
                </div>
                <div className='input' style={{ width: '100%' }}>
                  <input
                    value={oldPassword}
                    onChange={e => {
                      setOldPassword(e.target.value)
                      inputStatus(e)
                    }}
                    type='password'
                  />
                  <span
                    onClick={e => handleShowPassword(e)}
                    className={`showButton lowerTop ${oldPassword && showPasword ? '' : 'gray-span'}`}
                  >
                    Show
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='new-password'>
              <div className='newPassword'>New Password</div>
              <div style={{ width: '60%' }}>
                <div className='input-group'>
                  <div className='lockIcon'>
                    <Image src={lockIcon} alt='lock-icon' />
                  </div>
                  <div className='input' style={{ width: '100%' }}>
                    <input
                      onKeyUp={e => criterionPassword(e)}
                      value={newPassword}
                      onChange={e => {
                        setNewPassword(e.target.value)
                        inputStatus(e)
                      }}
                      className={`${newPassword.length === 0 || (length && capitalLetters && symbols) ? '' : 'red-border'}`}
                      type='password'
                    />
                    <span
                      onClick={e => handleShowPassword(e)}
                      className={`showButton ${newPassword && showPasword ? '' : 'gray-span'}`}
                    >
                      Show
                    </span>
                  </div>
                  <Form.Text className='w-100 mt-1 wrong-password'>
                    {!(newPassword.length === 0 || (length && capitalLetters && symbols)) && 'Password criteria is not met.'}
                  </Form.Text>
                </div>
                <div className='password-advice'>
                  <Form.Text style={{ margin: '6px' }} className=''>We advise that you use the following for a strong password:</Form.Text>
                  <div
                    className={`${capitalLetters ? 'green-advice' : 'red-advice'
                      }`}
                  >
                    <FontAwesomeIcon icon={capitalLetters ? 'check-circle' : 'exclamation-triangle'} />
                    <Form.Text style={{ marginLeft: '6px' }} className={`${capitalLetters ? 'green-advice' : 'red-advice'}`}>
                      1 Capital Letter
                    </Form.Text>
                  </div>
                  <div
                    className={`${length ? 'green-advice' : 'red-advice'
                      }`}
                  >
                    <FontAwesomeIcon icon={length ? 'check-circle' : 'exclamation-triangle'} />
                    <Form.Text className={`${length ? 'green-advice' : 'red-advice'}`}>
                      More than 8 Characters
                    </Form.Text>
                  </div>
                  <div
                    className={`${symbols ? 'green-advice' : 'red-advice'
                      }`}
                  >
                    <FontAwesomeIcon icon={symbols ? 'check-circle' : 'exclamation-triangle'} />
                    <Form.Text className={`${symbols ? 'green-advice' : 'red-advice'}`}>
                      At least 1 symbol
                    </Form.Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='form-group noBorder'>
            <div className='newConfirmPassword'>Confirm New Password</div>
            <div style={{ width: '60%' }}>
              <div className='input-group'>
                <div className='lockIcon'>
                  <Image src={lockIcon} alt='lock-icon' />
                </div>
                <div className='input' style={{ width: '100%' }}>
                  <input
                    value={confirmNewPassword}
                    onChange={e => {
                      setConfirmNewPassword(e.target.value)
                      inputStatus(e)
                    }}
                    className={`${confirmNewPassword.length === 0 || (newPassword === confirmNewPassword) ? '' : 'red-border'}`}
                    type='password'
                    placeholder='Confirm New Password'
                  />
                  <span
                    onClick={e => handleShowPassword(e)}
                    className={`showButton ${confirmNewPassword && showPasword ? '' : 'gray-span'}`}
                  >
                    Show
                  </span>
                </div>
                <Form.Text className='wrong-password'>
                  {confirmNewPassword.length === 0 || (newPassword === confirmNewPassword) ? <br /> : 'Passwords do not match.'}
                </Form.Text>
              </div>
            </div>
          </div>
          <div style={{ color: 'red' }}>{errMsg}</div>
        </Form>
      )}
    </div>
  )
}

export default ChangePassword
