import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Status from './Status'
import CloseIcon from '../../../../components/Icons/CloseIcon'
import CreatedTime from './CreatedTime'
import blueworkflowIcon from '../../../../assets/icons/robots/blue.svg'
import cancel from '../../../../assets/icons/cancel.svg'
import EditIcon from '../../../../components/Icons/EditIcon'
import SubmitEditing from '../../../../components/Icons/SubmitEditing'
import { getOrderIcon, formattedDate } from '../MiscFunctions/MiscFunction'
import { updateOrder } from '../../../../services/order.services'

const OrderSidebar = ({ theme, orderDetails, hideModal, selectedOrder, isEditing, setEditing }) => {
  const dispatch = useDispatch()
  const { userData } = useSelector(state => state?.auth)
  const { processes } = useSelector(state => state?.process)
  const history = useHistory()
  const [orderTitle, setOrderTitle] = useState(selectedOrder?.type)
  const [orderDescription, setOrderDescription] = useState(selectedOrder?.description)
  const [workflow, setWorkflow] = useState(null)

  const orderType = ['Build new automation', 'Fix existing automation', 'Improve exsiting automation']

  const onSubmitEditRequest = () => { 
    const data = {
      id: selectedOrder?.id,
      type: orderTitle,
      description: orderDescription
    }
    dispatch(updateOrder(userData?.account?.accountId, userData?.token, data, false))
      .then(() => setEditing(false))
      .catch(() => setEditing(false))
  }

  const onCancelEditing = () => { 
    setOrderTitle(selectedOrder?.type)
    setOrderDescription(selectedOrder?.description)
    setEditing(false)
  }

  useEffect(() => {
    setOrderTitle(selectedOrder?.type)
    setOrderDescription(selectedOrder?.description)
    setWorkflow(processes?.find(p => p?.id === selectedOrder?.workflowId) || null)
  }, [selectedOrder])

  return (
    <div className={`orderDetailsSidebar ${!orderDetails ? 'hideSidebar' : ''}`}>
      <div className="relativeContainer">
        <div className="flexRow">
          <Status status={selectedOrder?.status} />
          <div className="closeArea" onClick={() => hideModal()}>
            <CloseIcon color={theme === 'dark' ? '#FFFFFF' : '#3E3D40'} />
          </div>
        </div>
        <div className="headingArea">
          <div className="left">
            <img className='icon' src={getOrderIcon(selectedOrder?.type)} />
            <div className={`title ${isEditing && 'isEditing'}`}>
              {selectedOrder?.name || selectedOrder?.type}
            </div>
            <div
              className={`editIcon ${isEditing && 'isEditing'}`}
              onClick={() => setEditing(true)}
            >
              <EditIcon theme={theme} />
            </div>
            {isEditing && (
              <div className="editableContainer">
                <div className="selectInputArea">
                  <select
                    name="countryCodes"
                    className='select'
                    id=""
                    value={orderTitle}
                    onChange={(e) => setOrderTitle(e.target.value)}
                  >
                    {orderType?.map(o => (
                      <option key={o} value={o}>{o}</option>
                    ))}
                  </select>
                </div>
                <div onClick={() => onCancelEditing()}>
                  <img className='cancel' src={cancel} />
                </div>
                <div onClick={() => onSubmitEditRequest()}>
                  <SubmitEditing theme={theme} />
                </div>
              </div>
            )}
          </div>
          <CreatedTime isEditing={isEditing} time={formattedDate(selectedOrder?.updatedAt || selectedOrder?.createdAt)} />
        </div>
        <div className="descriptionArea">
          <div className="descriptionTitle">
            Order Description
          </div>
          <div className={`descriptionText ${isEditing && 'isEditing'}`}>
            {selectedOrder?.description}
          </div>
          {isEditing && (
            <input
              type="text"
              className="primaryInputField"
              placeholder='Your new order description'
              value={orderDescription}
              onChange={e => setOrderDescription(e.target.value)}
            />
          )}
        </div>
        <div className="workflowArea">
          <div className="workflowTitle">
            Workflow
          </div>
          {workflow?.id ? (
            <div className="workflowCard">
              <img src={blueworkflowIcon} className='icon' />
              <div className="workflowText">
                Enroll leads in CampMinder on Registration
              </div>
            </div>
          ) : (
            <div className="descriptionText">
              There aren't any Workflow for this order.
            </div>
          )}
        </div>
        <div className="priceBreakDownArea">
          <div className="titles">
            <div className="title">
              Price Breakdown
            </div>
            <div className="title">
              Amount
            </div>
          </div>
          <div className="subtitles">
            <div className="subtitle">
              New Workflow
            </div>
            <div className="subtitle">
              $1,000 - One time
            </div>
          </div>
          <div className="subtitles">
            <div className="subtitle">
              S Deployment - 100k runs/m
            </div>
            <div className="subtitle">
              $99 - Monthly
            </div>
          </div>
        </div>
        <div className="totalArea">
          <div className="titles">
            <div className="title">Total</div>
            <div className="title">$1,099.00</div>
          </div>
        </div>
        <div className="buttonsArea">
          <div className="primaryButton fullWidth" onClick={() => hideModal()}>
            Looks good to me!
          </div>
          <div className="borderedButton fullWidth" onClick={() => history.push(`/orders/${selectedOrder?.id}/consult`)}>
            I got questions
          </div>
          <div className="helpText">
            We’ll be reaching out to you to coordinate with payment
          </div>
        </div>
      </div>
      {isEditing && <div
        className="sidebarOverlay"
        onClick={() => onCancelEditing()}
      />}
    </div>
  )
}

export default OrderSidebar