import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { Navbar } from '../components/Navbar/Navbar'
import { Footer } from '../components/Footer/Footer'

const Layout = ({ authObj, accounts, component: Component, changeProfile, dnd }) => {
  const history = useHistory()
  const { processes } = useSelector(state => state.process)

  const oldTheme = ['program', 'discount', 'form', 'reservation', 'roster', 'settings'].some(s =>
    history.location.pathname.includes(s) && !history.location.pathname.includes('google_forms')
  )
  const [errMsg, setErrMsg] = useState('')

  let comp
  if (history.location.pathname === '/ai') {
    comp = (
      <Component
        authObj={authObj}
        changeProfile={changeProfile}
        processes={processes}
        errMsg={errMsg}
        setErrMsg={setErrMsg}
      />
    )
  } else if (
    history.location.pathname === '/history' ||
    (history.location.pathname.includes('/processes') && history.location.pathname.length >= 11)
  ) {
    comp = <Component authObj={authObj} changeProfile={changeProfile} processes={processes} errMsg={errMsg} />
  } else {
    comp = <Component authObj={authObj} changeProfile={changeProfile} />
  }

  return (
    <div className='layout'>
      <Navbar authObj={authObj} processes={processes} />
      <div
        className={`main-content ${
          oldTheme && history.location.pathname !== '/profile/general-settings' ? 'old-theme' : ''
        }`}
      >
        {dnd && <DndProvider backend={HTML5Backend}>{comp}</DndProvider>}
        {!dnd && comp}
        {/* <Footer /> */}
      </div>
    </div>
  )
}

export { Layout }
