import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { FormGroup, Label, Button } from 'reactstrap'

import Settings from './Settings'
import { FieldType } from '../../FieldTypes'

const SessionsTable = ({
  field,
  removeFunc,
  setFieldOrderFunc,
  updateCurrentSetting,
  setFieldSettingsModalOpen,
  updateFieldSettings
}) => {
  const [isMouseIn, setIsMouseIn] = useState(false)

  // draggable
  const [{ isDragging }, draggable] = useDrag({
    item: { id: field.id, parentId: field.fieldGroupId, type: FieldType.SESSIONS },
    type: FieldType.SESSIONS,
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      }
    }
  })

  return (
    <div
      ref={draggable}
      style={{
        border: isMouseIn || isDragging ? '1px dashed black' : 'none',
        cursor: 'move',
        backgroundColor: isDragging ? 'lightblue' : 'white',
        width: '100%',
        display: 'inline-block'
      }}
    >
      <FormGroup
        onMouseEnter={() => {
          setIsMouseIn(true)
        }}
        onMouseLeave={() => {
          setIsMouseIn(false)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Label>
            {field.required ? '* ' : ''}
            {field.displayName}
          </Label>
          <div style={{ display: 'flex' }}>
            <Button
              size='xs'
              color='primary'
              title='Settings'
              onClick={() => {
                updateCurrentSetting(
                  <Settings
                    field={field}
                    setFieldOrderFunc={setFieldOrderFunc}
                    updateFieldSettings={updateFieldSettings}
                  />
                )
                setFieldSettingsModalOpen(true)
              }}
            >
              <i className='fas fa-tools' />
            </Button>
            <Button
              size='xs'
              color='danger'
              title='Remove'
              onClick={() => {
                removeFunc(field.id)
              }}
            >
              <i className='fas fa-times' />
            </Button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <table className='table table-bordered table-hover' style={{ textAlign: 'center' }}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Start</th>
                  <th>End </th>
                  <th>Options</th>
                </tr>
                <tr>
                  <td>Day camp</td>
                  <td>01-01-01 01:01 pm</td>
                  <td>01-01-01 02:01 pm</td>
                  <td>
                    <select disabled>
                      <option>SELECT ONE</option>
                      <option>Premium</option>
                      <option>Vip</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Evening camp</td>
                  <td>01-01-01 05:01 pm</td>
                  <td>01-01-01 06:01 pm</td>
                  <td>
                    <select disabled>
                      <option>SELECT ONE</option>
                      <option>Premium</option>
                      <option>Vip</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <span>{field.instructions}</span>
      </FormGroup>
    </div>
  )
}

export default SessionsTable
