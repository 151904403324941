/* eslint-disable */
import React, { useEffect, useState } from "react"
import { Editor } from "@tinymce/tinymce-react"
import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { config } from "../../../config"
import axios from "axios"
import { useLocation, useParams } from "react-router-dom"
import { API } from "../../../api/API"
import { FieldTag } from "../FormBuilder/FieldTypes"

const TemplateEditor = ({ authObj }) => {
  const params = useParams()

  const [errMsg, setErrMsg] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [subject, setSubject] = useState("")
  const [status, setStatus] = useState("active")
  const [content, setContent] = useState("<p>Initial content</p>")
  const [templateId, setTemplateId] = useState("")
  const [isCreateMode, setIsCreateMode] = useState(true)

  const [form, setForm] = useState(undefined)
  const [attendeeFields, setAttendeeFields] = useState(undefined)
  const [guardianFields, setGuardianFields] = useState(undefined)
  const [additionalFields, setAdditionalFields] = useState(undefined)

  const getForm = async () => {
    const res = await API.getForms(authObj.account.accountId, authObj.token)
    if (res && res.data && res.data.length > 0) {
      const { fieldGroups, formFields } = res.data[0]
      setForm({ fieldGroups: fieldGroups || [], formFields: formFields || [] })
    }
  }

  const createTemplate = async () => {
    if (title === "") {
      setErrMsg("Title is required")
      return
    }
    try {
      setErrMsg("Creating template...")
      const templateObj = {
        title,
        description,
        // editor converts "<" to "&lt;", below code for restoring it
        content: content.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
        subject,
        status
      }
      const res = await axios.post(`${config.apiUrl}/app/templates`, templateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg("Template created successfully!")
      } else {
        setErrMsg("Unable to create template (1)")
      }
    } catch (err) {
      setErrMsg("Unable to create template (2)")
    }
  }

  const getTemplateById = async tId => {
    try {
      setErrMsg("Fetching template...")
      const res = await axios.get(`${config.apiUrl}/app/templates/byId?templateId=${tId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg("")
        if (res.data.title) setTitle(res.data.title)
        if (res.data.description) setDescription(res.data.description)
        if (res.data.subject) setSubject(res.data.subject)
        if (res.data.status) setStatus(res.data.status)
        if (res.data.content) setContent(res.data.content)
      } else {
        setErrMsg("Unable to fetch template (1)")
      }
    } catch (err) {
      setErrMsg("Unable to fetch template (2)")
    }
  }

  const updateTemplate = async () => {
    if (title === "") {
      setErrMsg("Title is required")
      return
    }
    try {
      setErrMsg("Updating template...")
      const templateObj = {
        templateId,
        title,
        description,
        content: content.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
        subject,
        status
      }
      const res = await axios.put(`${config.apiUrl}/app/templates`, templateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res) {
        setErrMsg("Template updated successfully!")
      } else {
        setErrMsg("Unable to update template (1)")
      }
    } catch (err) {
      setErrMsg("Unable to update template (2)")
    }
  }

  useEffect(() => {
    if (form) {
      const fieldGroups = form.fieldGroups || []
      const formFields = form.formFields || []
      const fieldGroupMap = {}
      const attendeeFieldsTemp = []
      const guardianFieldsTemp = []
      const additionalFieldsTemp = []
      fieldGroups.forEach(fg => {
        fieldGroupMap[fg.id] = fg
      })
      formFields.forEach(ff => {
        if (
          [
            FieldTag.SINGLELINEINPUT,
            FieldTag.CHECKBOXES,
            FieldTag.DATE,
            FieldTag.DROPDOWN,
            FieldTag.MULTIPLECHOICE,
            FieldTag.PARAGRAPH
          ].includes(ff.type)
        ) {
          const fg = fieldGroupMap[ff.fieldGroupId] || {}
          if (fg.repeat === "attendees") {
            attendeeFieldsTemp.push(ff)
          } else if (fg.repeat === "guardians") {
            guardianFieldsTemp.push(ff)
          } else {
            additionalFieldsTemp.push(ff)
          }
        }
      })
      setAttendeeFields(attendeeFieldsTemp)
      setGuardianFields(guardianFieldsTemp)
      setAdditionalFields(additionalFieldsTemp)
    }
  }, [form])

  useEffect(() => {
    if (templateId && templateId !== "") {
      getTemplateById(templateId)
    }
  }, [templateId])

  useEffect(() => {
    const tId = params.templateId
    if (tId && tId !== "create") {
      setIsCreateMode(false)
      setTemplateId(tId)
    }
  }, [params])

  useEffect(() => {
    getForm()
  }, [])

  return (
    <div>
      <div className="content">
        <Row>
          <Col>
            <div style={{ width: "100%", color: "red" }}>{errMsg}</div>
            <div className="col-12" style={{ paddingTop: "30px" }}>
              <section className="box">
                <header className="panel_header">
                  <h2 className="title float-left">Editor</h2>
                </header>
                <Col>
                  <Form.Label htmlFor="title">
                    Title <sup>*</sup>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl value={title} onChange={e => setTitle(e.target.value)} />
                  </InputGroup>
                </Col>
                <Col>
                  <Form.Label htmlFor="description">Description</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl value={description} onChange={e => setDescription(e.target.value)} />
                  </InputGroup>
                </Col>
                <Col>
                  <Form.Label htmlFor="status">Status</Form.Label>
                  <select
                    className="form-control"
                    value={status}
                    onChange={e => setStatus(e.target.value)}
                    style={{ marginBottom: "15px" }}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="archived">Archived</option>
                  </select>
                </Col>
                <Col>
                  <Form.Label htmlFor="subject">Subject</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl value={subject} onChange={e => setSubject(e.target.value)} />
                  </InputGroup>
                </Col>
                <Col>
                  <Form.Label htmlFor="content">Content</Form.Label>
                  {attendeeFields && guardianFields && additionalFields && (
                    <Editor
                      apiKey="ify5uxtuwx6vsei4q9gc7qvwpzeh3ikqv74wo5y8r5slr1z9"
                      value={content}
                      init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                          "advlist autolink lists link image",
                          "charmap print preview anchor",
                          "searchreplace visualblocks code",
                          "insertdatetime media table paste wordcount"
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent| \
            insertvariables",
                        setup: editor => {
                          editor.ui.registry.addMenuButton("insertvariables", {
                            text: "Insert Variables",
                            fetch: callback => {
                              callback([
                                {
                                  type: "nestedmenuitem",
                                  text: "Registration",
                                  getSubmenuItems: () => [
                                    {
                                      type: "menuitem",
                                      text: "Id",
                                      onAction: () => editor.insertContent("<%= reservation.id %>")
                                    },
                                    {
                                      type: "menuitem",
                                      text: "Total",
                                      onAction: () => editor.insertContent("<%= reservation.total %>")
                                    }
                                  ]
                                },
                                {
                                  type: "nestedmenuitem",
                                  text: "Transaction",
                                  getSubmenuItems: () => [
                                    {
                                      type: "menuitem",
                                      text: "Id",
                                      onAction: () => editor.insertContent("<%= transaction.id %>")
                                    },
                                    {
                                      type: "menuitem",
                                      text: "Log",
                                      onAction: () => editor.insertContent("<%= transaction.log %>")
                                    }
                                  ]
                                },
                                {
                                  type: "nestedmenuitem",
                                  text: "Slots",
                                  getSubmenuItems: () => [
                                    {
                                      type: "nestedmenuitem",
                                      text: "Program",
                                      getSubmenuItems: () => [
                                        {
                                          type: "menuitem",
                                          text: "Title",
                                          onAction: () => editor.insertContent("<%= slot.program.title %>")
                                        },
                                        {
                                          type: "menuitem",
                                          text: "Status",
                                          onAction: () => editor.insertContent("<%= slot.program.status %>")
                                        }
                                      ]
                                    },
                                    {
                                      type: "nestedmenuitem",
                                      text: "Session",
                                      getSubmenuItems: () => [
                                        {
                                          text: "Title",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.session.title %>")
                                        },
                                        {
                                          text: "Start Time",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.session.startTime %>")
                                        },
                                        {
                                          text: "End Time",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.session.endTime %>")
                                        },
                                        {
                                          text: "Status",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.session.status %>")
                                        }
                                      ]
                                    },
                                    {
                                      type: "nestedmenuitem",
                                      text: "Option",
                                      getSubmenuItems: () => [
                                        {
                                          text: "Title",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.option.title %>")
                                        },
                                        {
                                          text: "Start Time",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.option.startTime %>")
                                        },
                                        {
                                          text: "End Time",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.option.endTime %>")
                                        },
                                        {
                                          text: "Status",
                                          type: "menuitem",
                                          onAction: () => editor.insertContent("<%= slot.option.status %>")
                                        }
                                      ]
                                    },
                                    {
                                      type: "nestedmenuitem",
                                      text: "Attendee",
                                      getSubmenuItems: () =>
                                        attendeeFields.map(af => {
                                          return {
                                            text: af.displayName,
                                            type: "menuitem",
                                            onAction: () =>
                                              editor.insertContent(`<%= slot.attendee.${af.internalName} %>`)
                                          }
                                        })
                                    },
                                    {
                                      type: "nestedmenuitem",
                                      text: "Guardian",
                                      getSubmenuItems: () =>
                                        guardianFields.map(gf => {
                                          return {
                                            text: gf.displayName,
                                            type: "menuitem",
                                            onAction: () =>
                                              editor.insertContent(`<%= slot.guardian.${gf.internalName} %>`)
                                          }
                                        })
                                    }
                                  ]
                                },
                                {
                                  type: "nestedmenuitem",
                                  text: "Discounts",
                                  getSubmenuItems: () => [
                                    {
                                      type: "menuitem",
                                      text: "Code",
                                      onAction: () => editor.insertContent("<%= discount.code %>")
                                    },
                                    {
                                      type: "menuitem",
                                      text: "Amount",
                                      onAction: () => editor.insertContent("<%= discount.amount %>")
                                    },
                                    {
                                      type: "menuitem",
                                      text: "Type",
                                      onAction: () => editor.insertContent("<%= discount.type %>")
                                    }
                                  ]
                                },
                                {
                                  type: "nestedmenuitem",
                                  text: "Additional Details",
                                  getSubmenuItems: () =>
                                    additionalFields.map(af => {
                                      return {
                                        text: af.displayName,
                                        type: "menuitem",
                                        onAction: () => editor.insertContent(`<%= form.${af.internalName} %>`)
                                      }
                                    })
                                },
                                {
                                  type: "nestedmenuitem",
                                  text: "Account",
                                  getSubmenuItems: () => [
                                    {
                                      type: "menuitem",
                                      text: "Name",
                                      onAction: () => editor.insertContent("<%= account.name %>")
                                    }
                                  ]
                                },
                                {
                                  type: "nestedmenuitem",
                                  text: "Settings",
                                  getSubmenuItems: () => [
                                    {
                                      type: "menuitem",
                                      text: "Emails",
                                      onAction: () => editor.insertContent("<%= settings.emails %>")
                                    }
                                  ]
                                }
                              ])
                            }
                          })
                        }
                      }}
                      onEditorChange={(newContent, editor) => setContent(newContent)}
                    />
                  )}
                </Col>
                <Col>
                  <Button
                    style={{ margin: "10px 0" }}
                    onClick={() => (isCreateMode ? createTemplate() : updateTemplate())}
                  >
                    Submit
                  </Button>
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { TemplateEditor }
