import React from 'react'
import HttpIcon from '../../../assets/img2/http-dark.svg'

const WebhookTrigger = ({ setSelectedTrigger, webhookUrl }) => {
  return (
    <div className="trigger-container">
      <div className="horizontalLine" />
      <div className="webhook">
        <span className="title">Webhook URL</span>
        <span className="subtitle">Trigger this Workflow by sending an HTTP request to:</span>
        <div>
          <div>POST</div>
          <input type="text" readOnly value={webhookUrl} />
        </div>
      </div>
    </div>
  )
}

export { WebhookTrigger }
