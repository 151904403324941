import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  processes: [],
  selectedProcess: null
}

const setLoading = (state = initialState, action) => {
  return {
    ...state,
    isLoading: action.payload
  }
}

const setProcesses = (state = initialState, action) => {
  return {
    ...state,
    processes: action.payload
  }
}

const setSelectedProcesses = (state = initialState, action) => {
  return {
    ...state,
    selectedProcess: action.payload
  }
}

const processSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    setLoading,
    setProcesses,
    setSelectedProcesses
  }
})

export default processSlice
