export const getBackgroundColor = (theme, icon, selectedTrigger) => {
  if (theme === 'dark') {
    if (icon === 'scheduled') {
      if (selectedTrigger === 'scheduled') {
        return '#81b9f9'
      } else {
        return '#575759'
      }
    } else {
      if (selectedTrigger === 'webhook') {
        return '#81b9f9'
      } else {
        return '#575759'
      }
    }
  } else {
    if (icon === 'scheduled') {
      if (selectedTrigger === 'scheduled') {
        return '#1f90ff'
      } else {
        return '#fafafa'
      }
    } else {
      if (selectedTrigger === 'webhook') {
        return '#1f90ff'
      } else {
        return '#fafafa'
      }
    }
  }
}

export const getIconColor = (theme, icon, selectedTrigger) => {
  if (theme === 'dark') {
    if (icon === 'scheduled') {
      if (selectedTrigger === 'scheduled') {
        return '#ffffff'
      } else {
        return '#81b9f9'
      }
    } else {
      if (selectedTrigger === 'webhook') {
        return '#ffffff'
      } else {
        return '#81b9f9'
      }
    }
  } else {
    if (icon === 'scheduled') {
      if (selectedTrigger === 'scheduled') {
        return '#ffffff'
      } else {
        return '#1f90ff'
      }
    } else {
      if (selectedTrigger === 'webhook') {
        return '#ffffff'
      } else {
        return '#1f90ff'
      }
    }
  }
}
