import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { useDrag, useDrop } from 'react-dnd'
import { useHistory } from 'react-router'

const Program = ({ program, duplicate, setEditModalOpen, setEditProgramId, remove, reorder }) => {
  const history = useHistory()
  const ref = useRef(null)

  const [isMouseIn, setIsMouseIn] = useState(false)

  const [{ isDragging }, draggable] = useDrag({
    item: { id: program.id, type: 'program' },
    type: 'program',
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  })

  const [{ isOver }, drop] = useDrop(
    {
      accept: ['program'],
      canDrop: () => {
        return true
      },
      drop: (obj, monitor) => {
        reorder(program.id, obj.id)
      },
      collect: monitor => {
        return {
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop()
        }
      }
    },
    [program] // dependencies
  )

  return (
    <tr
      ref={draggable(drop(ref))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        outline: isMouseIn || isDragging ? '1px dashed black' : 'none',
        backgroundColor: isOver ? '#f2f2f2' : '#fff'
      }}
      onMouseEnter={() => setIsMouseIn(true)}
      onMouseLeave={() => setIsMouseIn(false)}
    >
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          history.push(`programs/manager?programId=${program.id}`)
        }}
      >
        {program.title}
      </td>
      <td>{program.price}</td>
      <td>{program.status}</td>
      <td>
        <button
          className='btn btn-primary btn-xs'
          style={{ marginRight: '10px' }}
          onClick={() => {
            history.push(`programs/manager?programId=${program.id}`)
          }}
        >
          Manage
        </button>
        <button
          className='btn btn-primary btn-xs'
          style={{ marginRight: '10px' }}
          onClick={() => {
            const r = window.confirm(`Are you sure you want to duplicate ${program.title || program.id}`)
            if (r === true) {
              duplicate(program.id)
            }
          }}
        >
          Duplicate
        </button>

        <span
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => {
            setEditModalOpen(true)
            setEditProgramId(program.id)
          }}
        >
          <FontAwesomeIcon icon={faEdit} size='lg' />
        </span>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const r = window.confirm(`Are you sure you want to remove ${program.title || program.id}`)
            if (r === true) {
              remove(program.id)
            }
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} size='lg' />
        </span>
      </td>
    </tr>
  )
}

export { Program }
