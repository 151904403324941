import React, { useState , useEffect } from 'react'
import myClientsIcon from '../../../assets/icons/clientsIcon.svg'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { apps, deepCopy } from '../AppsOffered/OfferedApps'
import campminderIcon from '../../../assets/icons/campminderIcon.svg'
import slackIcon from '../../../assets/icons/slackIcon.svg'
import googleSheetsIcon from '../../../assets/icons/googleSheetsIcon.svg'
import googleFormsIcon from '../../../assets/icons/googleFormsIcon.svg'
import hubspotIcon from '../../../assets/icons/hubspotIcon.svg'
import mailchimpIcon from '../../../assets/icons/mailchimpIcon.svg'
import googleDriveIcon from '../../../assets/icons/googleDriveIcon.svg'
import googleCalendarIcon from '../../../assets/icons/googleCalendarIcon.svg'
import gmailIcon from '../../../assets/icons/gmailIcon.svg'
import salesforceIcon from '../../../assets/icons/salesforceIcon.svg'
import shopifyIcon from '../../../assets/icons/shopifyIcon.svg'
import sharpspringIcon from '../../../assets/icons/sharpspringIcon.svg'
import bigQueryIcon from '../../../assets/icons/bigQueryIcon.svg'
import campBrainIcon from '../../../assets/icons/campBrainIcon.svg'
import campSiteIcon from '../../../assets/icons/campSiteIcon.svg'
import campSpotIcon from '../../../assets/icons/campSpotIcon.svg'
import shipCalmIcon from '../../../assets/icons/shipCalmIcon.svg'
import searchIcon from '../../../assets/icons/graySearchIcon.svg'
import cancelRedIcon from '../../../assets/icons/cancelRed.svg'
import filterIcon from '../../../assets/icons/filterIcon.svg'
import FilterSidebar from './FilterSidebar'
import BackdropOverlay from './BackdropOverlay'
import ClientDetailsModal from './ClientDetailsModal'
import ClientRow from './ClientRow'
import EmptyContainer from './EmptyContainer'
import { useSelector } from 'react-redux'

const MyClients = () => {
  const allApps = deepCopy(apps).map((r, i) => {
    r.icon = [
      campminderIcon,
      gmailIcon,
      googleSheetsIcon,
      sharpspringIcon,
      slackIcon,
      googleFormsIcon,
      hubspotIcon,
      mailchimpIcon,
      googleDriveIcon,
      googleCalendarIcon,
      salesforceIcon,
      shopifyIcon,
      bigQueryIcon,
      campBrainIcon,
      campSiteIcon,
      campSpotIcon,
      shipCalmIcon
    ][i]
    return r
  })

  const {userData} = useSelector(state => state.auth)
  const { myClients: clients, isLoading } = useSelector(state => state.myClients)
  const [filterCategories, showFilterCategories] = useState(false)
  const [filterOptions, setFilterOptions] = useState([])
  const [shouldFilter, setShouldFilter] = useState(false)
  const [filteredClients, setFilteredClients] = useState([])
  const [clientDetails, showClientDetails] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [editableClient, setEditableClient] = useState( null )
  const [keywordSearch, setKeywordSearch] = useState('')
  const [newUserId, setNewUserId] = useState('')

  const addFilters = newApp => {
    if (filterOptions.includes(newApp)) {
      setFilterOptions(filterOptions.filter(app => app !== newApp))
    } else {
      setFilterOptions(filters => [...filters, newApp])
    }
  }

  const filterClients = () => {
    if (filterOptions.length === 0) {
      setShouldFilter(false)
    } else {
      setShouldFilter(true)
      showFilterCategories(false)
      setFilteredClients(
        clients.filter(client => filterOptions.map(s => !!client.apps?.find(app => app.app.slug === s))[0])
      )
    }
  }

  useEffect(() => { 
    if (keywordSearch.length > 0) {
      if (filteredClients?.length > 0) {
        setFilteredClients(
          filteredClients.filter(client => client?.email?.includes(keywordSearch))
        )
      } else {
        setShouldFilter(true)
        setFilteredClients(
          clients.filter(client => client?.email?.includes(keywordSearch))
        )
      }
    } else {
      setShouldFilter(false)
      setFilteredClients([])
    }
  }, [keywordSearch])

  useEffect(() => { 
    setNewUserId(editableClient?.userId)
  }, [editableClient])
    
  return (
    <>
      <div
        className='myClientsPage'
        onClick={(e) => {
          if(e.target.className === 'editUsername' || e.target.className === 'transparentButton'){
            return
          }
          if (editableClient) {
            setEditableClient(null)
            setNewUserId('')
          }
        }}
      >
        {isLoading ? (
          <div className='loader'>
            <div className='spinner' />
            <div className='text'>Loading, please wait</div>
          </div>
          ): (
            <>
              <PageTitle icon={myClientsIcon} title='Clients' subtitle='Mange your clients here' />
              <div className='filterArea'>
                <div className='inputArea'>
                  <input type='text' value={keywordSearch} className='filterByName' placeholder='Search Client by Email...' onChange={(e) => setKeywordSearch(e.target.value)} />
                  <img src={searchIcon} className='searchIcon' />
                </div>
                <div className='filterButtonArea'>
                  <button className='filterButton' onClick={() => showFilterCategories(true)}>
                    {' '}
                    <img src={filterIcon} className='icon' /> Filter by Apps{' '}
                  </button>
                  {shouldFilter
                    ? <button
                        className='filterCancelButton'
                        onClick={() => {
                          setFilterOptions([])
                          setShouldFilter(false)
                          setFilteredClients( [] )
                          setKeywordSearch('')
                        }}
                      >
                        <img src={cancelRedIcon} className='icon' /> Clear Filter by Apps ( {filteredClients.length} )
                      </button>
                      : null}
                </div>
              </div>
              <div className='bodyContainer'>
                {clients.length > 0
                  ? (
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className='tableHead'>Email Address</div>
                          </th>
                          <th>
                            <div className='tableHead'>User ID</div>
                          </th>
                          <th>
                            <div className='tableHead'># Apps</div>
                          </th>
                          <th>
                            <div className='tableHead'>Apps Integrated</div>
                          </th>
                          <th>
                            <div className='tableHead'>Errors</div>
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {shouldFilter
                          ? filteredClients.map((client, i) => (
                            <ClientRow
                              key={`${client.name}${i}`}
                              clientName={client.name}
                              client={client}
                              allApps={allApps}
                              apps={client.apps}
                              editableClient={editableClient}
                              setEditableClient={setEditableClient}
                              showClientDetails={showClientDetails}
                              setSelectedClient={setSelectedClient}
                              newUserId={newUserId}
                              setNewUserId={setNewUserId}
                              token={userData?.token}
                            />
                            ))
                          : clients.map((client, i) => (
                            <ClientRow
                              key={`${client.name}${i}`}
                              clientName={client.name}
                              client={client}
                              allApps={allApps}
                              apps={client.apps}
                              editableClient={editableClient}
                              setEditableClient={setEditableClient}
                              showClientDetails={showClientDetails}
                              setSelectedClient={setSelectedClient}
                              newUserId={newUserId}
                              setNewUserId={setNewUserId}
                              token={userData?.token}
                            />
                          ))}
                      </tbody>
                    </table>
                    ) : <EmptyContainer text="You don't have any clients yet." />
                  }
              </div>
            </>
        )}
      </div>
      {filterCategories && <BackdropOverlay hideBackdrop={showFilterCategories} />}
      {filterCategories && (
        <FilterSidebar
          showFilterCategories={showFilterCategories}
          filterClients={filterClients}
          setFilteredClients={setFilteredClients}
          setFilterOptions={setFilterOptions}
          setShouldFilter={setShouldFilter}
          filterOptions={filterOptions}
          addFilters={addFilters}
          allApps={allApps}
        />
      )}
      {clientDetails && <BackdropOverlay hideBackdrop={showClientDetails} />}
      {clientDetails && (
        <ClientDetailsModal
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          showClientDetails={showClientDetails}
          allApps={allApps}
        />
      )}
    </>
  )
}

export default MyClients