import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import { Form } from 'react-bootstrap'
import axios from 'axios'
import { config } from '../../config'
import { useSelector, useDispatch } from 'react-redux'
import { loadGeneralSettings } from '../../services/profile.service'

const GeneralSettings = () => {
  const { userData } = useSelector(state => state.auth)
  const { settings } = useSelector(state => state.profile)
  const dispatch = useDispatch()
  const timeZones = moment.tz.names()
  const defaultTimeZone = moment.tz.guess()

  const [companyName, setCompanyName] = useState('')
  const [timeZone, setTimeZone] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [isEditable, setEditable] = useState('')

  const timeZonesWithTime = timeZones.map((t, i) => {
    return (
      <option key={t} value={t}>
        (GMT{moment.tz(timeZones[i]).format('Z')}) {timeZones[i]}
      </option>
    )
  })

  const saveSettings = async (e) => {
    e.preventDefault()
    setErrMsg('Saving User Settings...')
    try {
      // validation
      if (companyName.length <= 3) {
        return setErrMsg(
          'Please enter valid company name. Company Name should be greater than 3 alphabets. Please contact support if you have any questions.'
        )
      }

      const formData = new FormData() // eslint-disable-line
      formData.append('name', companyName)
      formData.append('timeZone', timeZone)

      const response = await axios.put(`${config.apiUrl}/app/settings`, formData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
          'Content-Type': 'multipart/form-data',
          accountid: userData.account.accountId
        }
      })

      if (response && response.data) {
        setErrMsg('Settings updated successfully.')
        dispatch(loadGeneralSettings(userData.account.accountId, userData.token, false))
      } else {
        setErrMsg('Unable to save settings.')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to save settings. Please contact support for help!')
    }
  }

  const cancelClickHandler = (e) => {
    e.preventDefault()
    setCompanyName(settings?.account?.name || '')
    setEditable(false)
  }

  useEffect(() => {
    setCompanyName(settings?.account?.name)
    if (settings?.accountSettings?.timeZone === 'undefined') {
      setTimeZone(defaultTimeZone)
    } else {
      setTimeZone(settings?.accountSettings?.timeZone)
    }
    setErrMsg('')
  }, [settings])

  return (
    <div className='content'>
      <div className="titleArea">
        <div className="titleText">{isEditable ? 'Edit General Settings' : 'General Settings'}</div>
        <div className='profileMainActions'>
          {isEditable
            ? (
              <>
                <button
                  type='button'
                  className='cancelButton'
                  onClick={cancelClickHandler}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  disabled={!(settings?.account?.name !== companyName || timeZone !== settings?.accountSettings?.timeZone)}
                  onClick={saveSettings}
                >
                  Save
                </button>
              </>
              )
            : <button type='button' className='editButton' onClick={() => setEditable(true)}>Edit</button>}
        </div>
      </div>
      {isEditable ? (
        <div className='profileBody'>
          <div className='inputGroup'>
            <div className='labelTitle'>Company Name</div>
            <div className='inputArea'>
              <input
                type='text'
                className='input'
                placeholder='Enter your company name.'
                value={companyName}
                name='companyName'
                onChange={e => setCompanyName(e.target.value)}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className='inputGroup'>
            <div className='labelTitle'>Timezone</div>
            <div className='selectInputArea'>
              <select
                className='select'
                value={timeZone}
                name='timeZone'
                onChange={e => setTimeZone(e.target.value)}
                disabled={!isEditable}
              >
                {timeZonesWithTime}
              </select>
            </div>
          </div>
          <div style={{ color: 'red' }}>{errMsg}</div>
        </div>
      ): (
        <div className="notEditableContainer">
          <div className="column">
              <p className="title">Company Name</p>
              <div className="companyName">{companyName}</div>  
            </div>
            <div className="column">
              <p className="title">Timezone</p>
              <div className="timezoneText">{timeZone}</div>  
          </div>
        </div>
      )}
    </div>
  )
}

export default GeneralSettings
