import { createSlice } from '@reduxjs/toolkit'

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    selectedOrder: null,
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload
      }
    },
    setOrders: (state, action) => {
      return {
        ...state,
        orders: action.payload
      }
    },
    setSelectedOrder: (state, action) => {
      return {
        ...state,
        selectedOrder: action.payload
      }
    }
  }
})

export default orderSlice

export const { setLoading, setOrders, setSelectedOrder } = orderSlice.actions
