import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'

const AddCategoryModal = ({ authObj, modalOpen, setModalOpen, refreshTable }) => {
  // fields
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [status, setStatus] = useState('active')

  const [errMsg, setErrMsg] = useState('')

  const addCategory = async () => {
    setErrMsg('Adding Category...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const createObj = {
        title,
        description,
        imageUrl,
        status
      }

      const res = await axios.post(`${config.apiUrl}/app/categories`, createObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Category Added Successfully.')
        refreshTable()
        setModalOpen(false)
      } else {
        setErrMsg('Unable to add category (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to add category (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  return (
    <Modal
      style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen)
      }}
    >
      <ModalHeader>Add A New Category</ModalHeader>
      <ModalBody>
        <Form>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for='title'>
                  <strong className='required-field'>Title</strong>
                </Label>
                <Input
                  type='text'
                  name='title'
                  placeholder='Title'
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='description'>
                  <strong>Description</strong>
                </Label>
                <br />
                <textarea
                  name='description'
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value)
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='imageUrl'>
                  <strong>Image Url</strong>
                </Label>
                <Input
                  type='text'
                  name='imageUrl'
                  value={imageUrl}
                  onChange={e => {
                    setImageUrl(e.target.value)
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for='status'>
                  <strong>Status</strong>
                </Label>
                <Input
                  type='select'
                  name='status'
                  value={status}
                  onChange={e => {
                    setStatus(e.target.value)
                  }}
                >
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div style={{ color: 'red' }}>{errMsg}</div>
        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            addCategory()
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { AddCategoryModal }
