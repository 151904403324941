import React from 'react'
import { FormGroup, Label, Input, Row, Col, Form } from 'reactstrap'

const Settings = ({ field, updateFieldSettings, setFieldOrderFunc }) => {
  return (
    <Row key={field.id}>
      <Col md='12'>
        <Form>
          <div>Field type: Text</div>
          <FormGroup>
            <Label htmlFor={field.id + '_content'}>Content</Label>
            <Input
              type='textarea'
              key={field.id + '_content'}
              id={field.id + '_content'}
              name={field.id + '_content'}
              placeholder=''
              value={field.content}
              onChange={async e => {
                updateFieldSettings(field.id, { content: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_instructions'}>Instructions</Label>
            <Input
              type='textarea'
              name={field.id + '_instructions'}
              id={field.id + '_instructions'}
              value={field.instructions}
              onChange={e => {
                updateFieldSettings(field.id, { instructions: e.target.value })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_size'}>Size</Label>
            <select
              name={field.id + '_size'}
              id={field.id + '_size'}
              value={field.width}
              onChange={e => {
                updateFieldSettings(field.id, { width: e.target.value })
              }}
            >
              <option value={33}>small</option>
              <option value={50}>medium</option>
              <option value={100}>large</option>
            </select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor={field.id + '_order'}>Order</Label>
            <Input
              type='number'
              id={field.id + '_order'}
              name={field.id + '_order'}
              value={field.order}
              onChange={e => {
                setFieldOrderFunc(field.id, Number(e.target.value))
              }}
            />
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

export default Settings
