import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  Hidden,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardHeader
} from '@material-ui/core'
import { Col, Row } from 'reactstrap'
import { config } from '../../../config'
import axios from 'axios'
import RosterExpandedInfo from './RosterExpandedInfo'

const Rosters = ({ authObj }) => {
  const [errMsg, setErrMsg] = useState('')
  const [programs, setPrograms] = useState()
  const [sessions, setSessions] = useState()
  const [selectedProgram, setSelectedProgram] = useState()
  const [selectedSession, setSelectedSession] = useState()
  const [rosters, setRosters] = useState([])
  const [hidden, setHidden] = useState(true)
  // const columns = [
  //   { field: 'id', hide: true },
  //   { field: 'slotId', headerName: 'Slot', type: 'number', width: 160 },
  //   { field: 'attendeeFirstName', headerName: 'First Name', type: 'string', width: 200 },
  //   { field: 'attendeeLastName', headerName: 'Last Name', type: 'string', width: 200 },
  //   { field: 'attendeePhone', headerName: 'Phone', type: 'number', width: 200 },
  //   { field: 'totalPrice', headerName: 'Total', type: 'number', width: 200 },
  //   { field: 'guardianFirstName', headerName: 'Guardian Name', type: 'string', width: 220 }
  // ]

  useEffect(() => {
    getPrograms()
  }, [])

  useEffect(() => {
    if (selectedProgram) {
      getProgramSessions()
    }
  }, [selectedProgram])

  useEffect(() => {
    if (selectedSession) getSessionSlotInformation()
  }, [selectedSession])

  const getProgramSessions = async () => {
    setErrMsg('Fetching Sessions...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/reservations/rosters`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        },
        params: {
          programId: selectedProgram
        }
      })

      if (res && res.data) {
        const data = res.data.details.map((d, key) => {
          return { id: key, ...d }
        })
        setRosters(data)
        setHidden(false)
        setSessions(res.data.sessions)
        setErrMsg('')
      } else {
        setErrMsg('Unable to fetch sessions (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch sessions (2)')
    }
  }

  const getSessionSlotInformation = async () => {
    setErrMsg('Fetching Information...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/reservations/rosters`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        },
        params: {
          programId: selectedProgram,
          sessionId: selectedSession
        }
      })

      if (res && res.data) {
        setErrMsg('')
        const data = res.data.details.map((d, key) => {
          return { id: key, ...d }
        })
        setRosters(data)
        setHidden(false)
      } else {
        setErrMsg('Unable to fetch Information (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch Information (2)')
    }
  }

  const getPrograms = async () => {
    setErrMsg('Fetching programs...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/programs`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        },
        params: {
          active: true
        }
      })

      if (res && res.data) {
        setPrograms(res.data)
        setErrMsg('')
      } else {
        setErrMsg('Unable to fetch programs (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch programs (2)')
    }
  }

  return (
    <div>
      <div className='content'>
        <Typography variant='caption' color='error'>
          {errMsg}
        </Typography>
        <Row style={{ marginTop: '30px' }}>
          <Col xs={12} md={12}>
            <Card className='material-reset'>
              <CardContent>
                <Typography variant='h6'>Rosters</Typography>
                <Grid container item xs={12} md={12} spacing={2} style={{ paddingTop: '30px' }}>
                  <Hidden mdDown>
                    <Grid item md={1} />
                  </Hidden>
                  <Grid item container direction='column' xs={12} md={12} spacing={2}>
                    <Card>
                      <CardHeader
                        subheader={<Typography color='primary'>Filter Through Programs & Sessions</Typography>}
                      />
                      <CardContent>
                        <Grid item container xs={12} md={12} spacing={2}>
                          <Grid item md={6} xs={12}>
                            <FormControl variant='outlined' className='max-width'>
                              <Typography />
                              <InputLabel id='location-select'>Programs</InputLabel>
                              <Select
                                labelId='location-select'
                                id='location-select'
                                defaultValue=''
                                onChange={event => setSelectedProgram(event.target.value)}
                                label='Location'
                              >
                                <MenuItem value=''>
                                  <em>None</em>
                                </MenuItem>
                                {programs?.map(singleProgram => (
                                  <MenuItem key={singleProgram.id} value={singleProgram.id}>
                                    {singleProgram.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          {selectedProgram
                            ? (
                              <Grid item md={6} xs={12}>
                                <FormControl variant='outlined' className='max-width'>
                                  <InputLabel id='location-select'>Sessions</InputLabel>
                                  <Select
                                    labelId='location-select'
                                    id='location-select'
                                    defaultValue=''
                                    onChange={event => setSelectedSession(event.target.value)}
                                    label='Location'
                                  >
                                    <MenuItem value=''>
                                      <em>None</em>
                                    </MenuItem>
                                    {sessions?.map(singleSession => (
                                      <MenuItem key={singleSession.id} value={singleSession.id}>
                                        {singleSession.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              )
                            : (
                                ''
                              )}
                          <Grid item md={12} />
                        </Grid>
                      </CardContent>
                    </Card>
                    {/* End of Filters */}
                    {hidden
                      ? (
                          ''
                        )
                      : (
                        <Grid>
                          <div className='material-reset'>
                            <TableContainer component={Paper}>
                              <Table aria-label='table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align='center'>Attendees</TableCell>
                                    <TableCell align='center'>Guardians</TableCell>
                                    <TableCell align='center'>Session</TableCell>
                                    <TableCell align='center'>Option</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rosters.map(singleRoster => (
                                    <RosterExpandedInfo key={singleRoster.id} slot={singleRoster} />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </Grid>
                        )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Rosters }
