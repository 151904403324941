import { Row, Col, Spinner, Table } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { config } from '../../../config'

const ClientLookup = ({ authObj, client }) => {
  const [, setErrMsg] = useState('')
  const [programs, setPrograms] = useState(undefined)
  const [loader, setLoader] = useState(true)

  const getPrograms = async () => {
    try {
      const type = client.email ? 'guardian' : 'attendee'
      const url = `${config.apiUrl}/app/clients/client-lookup?clientId=${client.id}&type=${type}`
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setPrograms(res.data)
        setLoader(false)
      } else {
        setErrMsg('Unable to get clients (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get clients (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    if (client) {
      getPrograms()
    }
  }, [client])

  return (
    <Row>
      <Col md={12}>
        {(loader && (
          <div className='col-12' style={{ textAlign: 'center' }}>
            <Spinner color='primary' />
          </div>
        )) || (
          <Col>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Program</th>
                  <th>Session</th>
                  <th>Option</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {programs &&
                  programs.map(c => {
                    return (
                      <tr key={c.id}>
                        <td>{c.program}</td>
                        <td>{c.session}</td>
                        <td>{c.option}</td>
                        <td>{c.startTime}</td>
                        <td>{c.endTime}</td>
                        <td>{c.role}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </Col>
        )}
      </Col>
    </Row>
  )
}

export { ClientLookup }
