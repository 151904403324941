// this is to make sure we are not missing any variables from .env

if (!process.env.REACT_APP_ENV) {
  throw new Error('REACT_APP_ENV required from env')
}
// if (!process.env.REACT_APP_BASEDIR) {
//   throw new Error("REACT_APP_BASEDIR required from env")
// }
// if (!process.env.REACT_APP_IMGDIR) {
//   throw new Error("REACT_APP_IMGDIR required from env")
// }
// if (!process.env.NODE_PATH) {
//   throw new Error("NODE_PATH required from env")
// }

const config = {
  env: process.env.REACT_APP_ENV,
  appBaseDir: '',
  appImgDir: '',
  apiUrl: process.env.REACT_APP_API_URL,
  nodePath: process.env.NODE_PATH,
  clientUrl: process.env.REACT_APP_CLIENT_URL,
  widgetUrl: process.env.REACT_APP_WIDGET_URL,
  integrateUrl: process.env.REACT_APP_INTEGRATE_URL,
  alerts: Boolean(process.env.REACT_APP_ALERTS === 'true'),
  helpEmail: process.env.REACT_APP_HELP_EMAIL || 'support@champselect.ai'
}

module.exports.config = config
