/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import { ProtectedRoute } from './components/Auth/ProtectedRoute'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'

// public routes
import { ForgotPassword } from './views/public/ForgotPassword'
import { ResetPassword } from './views/public/ResetPassword'
import { UserInvite } from './views/public/UserInvite'
import { GoogleCallback } from './views/public/GoogleCallback'
import { Page404 } from './views/general/Pages/404'

// app routes
// import { Forms } from "./views/app/Forms"
// import { FormBuilder } from "./views/app/FormBuilder/FormBuilder"
import { ReservationViewer } from './views/app/Reservation/ReservationViewer'
import { IncompleteReservations } from './views/app/Reservation/IncompleteReservations'
import { ReservationDetail } from './views/app/Reservation/ReservationDetail'
// import { Calendar } from "./views/app/Calendar/Calendar"
import { Clients } from './views/app/Clients/Clients'
import { Categories } from './views/app/Categories/Categories'
import { Services } from './views/app/Services/Services'
import { Locations } from './views/app/Locations/Locations'
import { AddLocation } from './views/app/Locations/AddLocation'
import { Discounts } from './views/app/Discounts/Discounts'
import { Programs } from './views/app/Programs/Programs'
import { Manager as ProgramManager } from './views/app/Programs/Manager'
// import { UserProfile } from './views/app/UserProfile'
import { Users } from './views/app/Users/Users'
import { MCAccount } from './views/app/MCAccounts/MCAccount'
import { PodsoftRouter } from './views/app/PodsoftRouter'
import { EditLocation } from './views/app/Locations/EditLocation'
import { CalendarPage } from './views/app/Calendar/CalendarPage'
import { Reservations } from './views/app/Calendar/Reservations'
import { ClientDetail } from './views/app/Clients/ClientDetail'
import { Transfers } from './views/app/Transfers/Transfers'
import { Rosters } from './views/app/Rosters/Rosters'
import { Templates } from './views/app/Templates/Templates'
import { TemplateEditor } from './views/app/Templates/TemplateEditor'
import { Gift } from './views/app/Gift'
import { Export } from './views/app/Export/Export'
import { FormBuilder } from './views/app/FormBuilder/FormBuilder'
import { DataMappings } from './views/app/Destinations/DataMappings'
import { Step1 } from './views/app/Destinations/Step1'
import { Step2 } from './views/app/Destinations/Step2'
import { DestinationCallback } from './views/app/Destinations/DestinationCallback'
import { Billing } from './views/app/Billing/Billing'
import { Signup } from './views/public/Signup'
import { Login } from './views/public/Login'
import Profile from './views/app/Profile'
import Settings from './views/app/Settings/Settings'
import { WorkflowLegacy } from './views/app/Workflow/WorkflowLegacy'
import { CreateWorkflowLegacy } from './views/app/Workflow/CreateWorkflowLegacy'
import { Dashboard } from './views/app/Dashboard/Dashboard'
import { AppsOffered } from './views/app/AppsOffered/AppsOffered'
import { AppSettings } from './views/app/AppSettings/AppSettings'
import MyApps from './views/app/MyApps/MyApps'
import { Workflows } from './views/app/Workflow/Workflows'
import { CreateWorkflow } from './views/app/Workflow/CreateWorkflow'
import MyClients from './views/app/MyClients/Clients'
import { WorkflowHistory } from './views/app/Workflow/WorkflowHistory'
import { WorkflowDetails } from './views/app/Workflow/WorkflowDetails'
import Consult from './views/app/Consult/Consult'
import { Campminder } from './views/app/AppIntegrations/Campminder'
import { Sharpspring } from './views/app/AppIntegrations/Sharpspring'
import themeSlice from './redux/slice/theme.slice'
import { useSelector, useDispatch } from 'react-redux'
import Orders from './views/app/Orders/Orders'
import CreateOrder from './views/app/CreateOrder/CreateOrder'

const App = () => {
  const { theme } = useSelector(state => state?.themes)
  const dispatch = useDispatch()
  const location = useLocation()
  const [localStorageTheme, setLocalStorageTheme] = useState(localStorage.getItem('default-theme'))

  useEffect(() => {
    ReactGA.initialize('G-5XXCF161H7')
  }, [])

  useEffect(() => {
    ReactPixel.init('506153443292962')
    ReactPixel.pageView()
  }, [location])

  const isBrowserDefaulDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches

  const getDefaultTheme = () => {
    const localStorageTheme = localStorage.getItem('default-theme')
    const browserDefault = isBrowserDefaulDark() ? 'dark' : 'light'
    return localStorageTheme || browserDefault
  }

  useEffect(() => { 
    dispatch(themeSlice.actions.setTheme(getDefaultTheme()))
    localStorage.setItem('default-theme', getDefaultTheme())
  }, [])

  return (
    <div className={`theme-${theme}`}>
      <Switch>
        {/* Public Routes */}
        {/* <Route path='/login' component={Login} /> */}
        <Route path="/login" component={Login} />
        {/* <Route path="/signup" component={SignupWrapper} /> */}
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/invite" component={UserInvite} />
        <Route path="/404" component={Page404} />

        {/* App Routes */}
        <ProtectedRoute path="/router" component={PodsoftRouter} />
        <ProtectedRoute path="/mc-accounts" component={MCAccount} />
        {/* <ProtectedRoute path='/dashboard' component={Dashboard} /> */}
        {/* <ProtectedRoute path="/forms" component={Forms} exact /> */}
        <ProtectedRoute path="/forms/builder" component={FormBuilder} dnd />
        <ProtectedRoute path="/reservations" component={ReservationViewer} exact />
        <ProtectedRoute path="/incomplete-reservations" component={IncompleteReservations} exact />
        <ProtectedRoute path="/reservations/:reservationId" component={ReservationDetail} exact />
        <ProtectedRoute path="/clients" component={Clients} exact />
        <ProtectedRoute path="/clients/:clientId" component={ClientDetail} exact />
        <ProtectedRoute path="/calendar" component={CalendarPage} exact />
        <ProtectedRoute path="/categories" component={Categories} exact />
        <ProtectedRoute path="/services" component={Services} exact />
        <ProtectedRoute path="/locations" component={Locations} exact />
        <ProtectedRoute path="/locations/create" component={AddLocation} exact />
        <ProtectedRoute path="/locations/edit" component={EditLocation} exact />
        <ProtectedRoute path="/locations/reservations" component={Reservations} exact />
        <ProtectedRoute path="/discounts" component={Discounts} exact />
        <ProtectedRoute path="/programs" component={Programs} exact dnd />
        <ProtectedRoute path="/programs/manager" component={ProgramManager} exact dnd />
        <ProtectedRoute path="/transfers" component={Transfers} exact />
        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/users" component={Users} exact />
        <ProtectedRoute path="/settings" component={Settings} exact />
        <ProtectedRoute path="/rosters" component={Rosters} exact />
        <ProtectedRoute path="/templates" component={Templates} exact />
        <ProtectedRoute path="/templates/:templateId" component={TemplateEditor} exact />
        <ProtectedRoute path="/googleCallback" component={GoogleCallback} exact />
        <ProtectedRoute path="/gsheetCallback" component={DestinationCallback} exact />
        <ProtectedRoute path="/gift" component={Gift} withoutWrapper exact />
        <ProtectedRoute path="/export" component={Export} exact />
        <ProtectedRoute path="/data-mappings" component={DataMappings} exact />
        <ProtectedRoute path="/data-mappings/step1" component={Step1} exact />
        <ProtectedRoute path="/data-mappings/step2" component={Step2} />
        <ProtectedRoute path="/dashboard" component={Dashboard} exact />
        <ProtectedRoute path="/apps" component={AppsOffered} exact />
        <ProtectedRoute path="/apps/:appId" component={AppSettings} exact />
        <ProtectedRoute path="/my-applications" component={MyApps} exact />
        <ProtectedRoute path="/workflow-legacy" component={WorkflowLegacy} exact />
        <ProtectedRoute path="/workflow-legacy/create" component={CreateWorkflowLegacy} exact />
        <ProtectedRoute path="/my-clients" component={MyClients} exact />
        <ProtectedRoute path="/billing" component={Billing} exact />
        <ProtectedRoute path="/workflows" component={Workflows} exact />
        <ProtectedRoute path="/workflows/create" component={CreateWorkflow} exact />
        <ProtectedRoute path="/workflows/:workflowId" component={WorkflowDetails} exact />
        <ProtectedRoute path="/integrate/campminder" component={Campminder} exact />
        <ProtectedRoute path="/integrate/sharpspring" component={Sharpspring} exact />
        <ProtectedRoute path="/orders" component={Orders} exact />
        <ProtectedRoute path="/orders/create" component={CreateOrder} exact />
        <ProtectedRoute path="/orders/:orderId/consult" component={Consult} exact />

        <Redirect to="/router" />
      </Switch>
    </div>
  )
}

export default App
