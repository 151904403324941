import React from 'react'
import Status from './Status'
import CreatedTime from './CreatedTime'

import { getOrderIcon, formattedDate } from '../MiscFunctions/MiscFunction'

const OrderComponent = ({ index, order, showOrderDetails, onShowOrderDetails }) => {

  return (
    <div
      className='order'
      key={order?.id}
      onClick={() => { 
        showOrderDetails(true)
        onShowOrderDetails(order)
      }}
    >
      <div className="left">
        <img
          className='icon'
          src={getOrderIcon(order?.type)}
          alt='icon'
        />
        <div className="textArea">
          <div className="titleArea">
            <div className="title">
              {order?.type}
              
            </div>
            <CreatedTime isEditing={false} time={formattedDate(order?.updatedAt || order?.createdAt)} />
          </div>
          <div className="subtitle">
            {order?.description}
          </div>
        </div>
      </div>
      <Status status={order?.status} />
    </div>
  )
}

export default OrderComponent