import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { API } from '../../../api/API'
import campminderLogo from '../../../assets/icons/campminderIcon.png'
import campminderAdminLogin from '../../../assets/icons/campminderAdminLogin.png'

const Campminder = () => {
  const { userData } = useSelector(state => state.auth)
  const appId = new URLSearchParams(window.location.search).get('id')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const integrateApp = async () => {
    if (username.length === 0 || password.length === 0) {
      return setErrMsg('Fields cannot be empty')
    }
    setLoading(true)
    const res = await API.createMyApp(userData.account.accountId, userData.token, appId, {
      username,
      password
    })
    setLoading(false)
    if (res.err) {
      return setErrMsg('Unable to integrate app. Please try again.')
    }
    window.close()
  }

  if (isLoading) {
    return (
      <div className='loader'>
        <div className='spinner' />
        <div className='text'>Loading, please wait</div>
      </div>
    )
  }

  return (
    <div className='campminderLoginPage'>
      <div className='container'>
        <div className='mainArea'>
          <div className='logoArea'>
            <img className='logo' src={campminderLogo} alt='Campminder' />
          </div>
          <div className='verticalLine' />
          <div className='columnContainer'>
            <img className='adminIcon' src={campminderAdminLogin} alt='' />
            <div className='inputArea'>
              <label htmlFor='username'>username</label>
              <input type='text' id='username' autoFocus value={username} onChange={e => setUsername(e.target.value)} />
            </div>
            <div className='inputArea'>
              <label htmlFor='password'>password</label>
              <input type='text' id='password' value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <button className='submit' type='submit' onClick={() => integrateApp()}>
              Log In
            </button>
            <p className='errorMessage'>{errMsg}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Campminder }
