import axios from 'axios'
import billingSlice from '../redux/slice/billing.slice'
import { formatResponse } from './common.service'

import { config } from '../config'

export const loadBillingDetails = (accountId, token, limit, after, before) => dispatch => {
  dispatch(billingSlice.actions.setLoading(true))
  return axios
    .get(`${config.apiUrl}/app/subscribe/stripe-card`, {
      headers: {
        accountid: accountId,
        Authorization: `Bearer ${token}`
      }
    })
    .then(async res => {
      dispatch(billingSlice.actions.setCardData(res.data))
      if (!res.data?.msg) {
        await Promise.all([
          axios
            .get(`${config.apiUrl}/app/subscribe/upcoming-invoice?customer=${res.data.customer}`, {
              headers: {
                accountid: accountId,
                Authorization: `Bearer ${token}`
              }
            })
            .then(res => {
              dispatch(billingSlice.actions.setUpcomingInvoice(res.data))
              dispatch(billingSlice.actions.setLoading(false))
            })
            .catch(err => {
              dispatch(billingSlice.actions.setLoading(false))
              return formatResponse(null, err)
            }),
          axios
            .get(`${config.apiUrl}/app/subscribe/transaction-list?customer=${res.data.customer}`, {
              headers: {
                accountid: accountId,
                Authorization: `Bearer ${token}`
              },
              params: {
                limit,
                before,
                after
              }
            })
            .then(res => {
              dispatch(billingSlice.actions.setTransactionDetails(res.data))
              dispatch(billingSlice.actions.setLoading(false))
            })
            .catch(err => {
              dispatch(billingSlice.actions.setLoading(false))
              return formatResponse(null, err)
            })
        ])
      } else {
        dispatch(billingSlice.actions.setUpcomingInvoice(null))
        dispatch(billingSlice.actions.setTransactionDetails([]))
        dispatch(billingSlice.actions.setLoading(false))
      }
    })
    .catch(err => {
      dispatch(billingSlice.actions.setLoading(false))
      return formatResponse(null, err)
    })
}
