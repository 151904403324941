import React, { useState , useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PageTitle from '../../../components/PageTitle/PageTitle'
import EmptyOrder from './components/EmptyOrder'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedOrder } from '../../../redux/slice/order.slice'

// components
import OrderComponent from './components/OrderComponent'
import OrderSidebar from './components/OrderSidebar'

const Orders = () => {
  const dispatch = useDispatch()
  const { theme } = useSelector(state => state.themes)
  const { isLoading, orders, selectedOrder } = useSelector(state =>  state?.order)
  const history = useHistory()
  const [orderDetails, showOrderDetails] = useState(false)
  const [isEditing, setEditing] = useState(false)

  const hideModal = () => { 
    showOrderDetails(false)
    setEditing(false)
    setTimeout(() => { 
      dispatch(setSelectedOrder(null))
    }, 200)
  }

  const onShowOrderDetails = order => { 
    dispatch(setSelectedOrder(order))
  }

  useEffect(() => { 
    if (selectedOrder) { 
      dispatch(setSelectedOrder(orders?.find(o => o?.id === selectedOrder?.id)))
    }
  }, [orders])

  return (
    <>
      <div className='orderPage'>
        {isLoading ? (
          <div className='loader'>
            <div className='spinner' />
            <div className='text'>Loading, please wait</div>
          </div>
        ) : (
            <>
              <PageTitle page="orders" theme={theme} title='Orders Dashboard' subtitle='See your orders(s) for work done here.'>
                <button className='primaryButton' onClick={() => history.push('/orders/create')}>
                    Create an Order
                </button>
              </PageTitle>
              {orders?.length > 0 ? (
                <div className="ordersContainer">
                  {orders?.map((o, i) => (
                    <OrderComponent
                      key={o?.id}
                      index={i}
                      order={o}
                      showOrderDetails={showOrderDetails}
                      onShowOrderDetails={onShowOrderDetails}
                    />
                  ))}
                </div>
              ): (
                <div className="emptyOrderContainer">
                  <EmptyOrder />
                </div>
              )}
            </>
        )}
      </div>
      {orderDetails && selectedOrder ? <div className='appOverlay' onClick={() => hideModal()} /> : null}
      <OrderSidebar
        theme={theme}
        orderDetails={orderDetails}
        hideModal={hideModal}
        selectedOrder={selectedOrder}
        isEditing={isEditing}
        setEditing={setEditing}
      />
    </>
  )
}

export default Orders