import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  cardData: null,
  upcomingInvoice: null,
  transactionDetails: []
}

const setLoading = (state = initialState, action) => {
  return {
    ...state,
    isLoading: action.payload
  }
}

const setCardData = (state = initialState, action) => {
  return {
    ...state,
    cardData: action.payload
  }
}

const setUpcomingInvoice = (state = initialState, action) => {
  return {
    ...state,
    upcomingInvoice: action.payload
  }
}

const setTransactionDetails = (state = initialState, action) => {
  return {
    ...state,
    transactionDetails: action.payload
  }
}

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setLoading,
    setCardData,
    setUpcomingInvoice,
    setTransactionDetails
  }
})

export default billingSlice
