import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { Link, useLocation, useHistory } from 'react-router-dom'
import logo from '../../assets/img/logo.svg'
import { config } from '../../config'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const UserInvite = () => {
  const history = useHistory()

  const query = useQuery()
  const code = query.get('code')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [disabledCompleteButton, setDisabledCompleteButton] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  const completeInvite = async () => {
    setErrMsg('Completing Your Invitation...')
    try {
      // validation
      if (!password) {
        return setErrMsg('Password cannot be blank.')
      }
      if (password !== confirmPassword) {
        return setErrMsg('Passwords do not match.')
      }

      const response = await axios.post(
        `${config.apiUrl}/public/auth/complete-invite`,
        {
          code,
          firstName,
          lastName,
          password
        },
        {}
      )

      if (response && response.data && response.data.msg === 'success') {
        history.push('/login?invite=success')
      } else {
        return setErrMsg('Unable to complete invitation. Please contact support for help! (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to complete invitation. Please contact support for help! (2)')
    }
  }

  const validateCode = async () => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/public/auth/validate-invite-code`,
        {
          code
        },
        {}
      )

      if (response && response.data) {
        const resp = response.data
        setFirstName(resp.firstName)
        setLastName(resp.lastName)
        setEmail(resp.email)
      } else {
        setDisabledCompleteButton(true)
        return setErrMsg(`${response.data.msg}`)
      }
    } catch (err) {
      setDisabledCompleteButton(true)
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to complete invitation. Please contact support for help! Code 2')
    }
  }

  // initial load -- check if code is valid with the backend
  useEffect(() => {
    validateCode()
  }, [])

  return (
    <>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <div className='position-relative'>
                <Card className='overflow-hidden'>
                  <div className='bg-primary'>
                    <div className='text-primary text-center p-4'>
                      <h2 style={{ color: 'white' }}>Complete Your Invite To EdBridge</h2>
                      <p style={{ color: 'white', fontSize: '14px' }} />
                      <Link to='/' className='logo logo-admin'>
                        <img
                          src={logo}
                          style={{
                            padding: '10px',
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            margin: '0px auto',
                            width: '50px',
                            height: '50px',
                            lineHeight: '50px',
                            background: 'white',
                            borderRadius: '50%',
                            borderBottom: '2px solid'
                          }}
                          height='20'
                          alt='logo'
                        />
                      </Link>
                    </div>
                  </div>

                  <CardBody className='p-4'>
                    <div className='p-3'>
                      <form>
                        {!disabledCompleteButton && (
                          <>
                            <div className='form-row'>
                              <div className='col-md-12 mb-3'>
                                <label htmlFor='email'>Email</label>
                                <div className='input-group'>
                                  <div className='input-group-prepend'>
                                    <span className='input-group-text' id='inputGroupPrepend2'>
                                      @
                                    </span>
                                  </div>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='email'
                                    placeholder='your@email.com'
                                    required
                                    value={email}
                                    onChange={e => {
                                      setEmail(e.target.value)
                                    }}
                                    onKeyPress={e => {
                                      if (e.key === 'Enter') {
                                        completeInvite()
                                      }
                                    }}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-row'>
                              <div className='col-md-6 mb-3'>
                                <label htmlFor='firstName'>First Name</label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='firstName'
                                  placeholder='First Name'
                                  value={firstName}
                                  onChange={e => setFirstName(e.target.value)}
                                  onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                      completeInvite()
                                    }
                                  }}
                                />
                              </div>
                              <div className='col-md-6 mb-3'>
                                <label htmlFor='lastName'>Last Name</label>
                                <div className='input-group'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='lastName'
                                    placeholder='Last Name'
                                    required
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    onKeyPress={e => {
                                      if (e.key === 'Enter') {
                                        completeInvite()
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-row'>
                              <div className='col-md-12 mb-3'>
                                <label htmlFor='password'>Password</label>
                                <div className='input-group'>
                                  <input
                                    type='password'
                                    className='form-control'
                                    id='password'
                                    placeholder='******'
                                    required
                                    value={password}
                                    onChange={e => {
                                      setPassword(e.target.value)
                                    }}
                                    onKeyPress={e => {
                                      if (e.key === 'Enter') {
                                        completeInvite()
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-row'>
                              <div className='col-md-12 mb-3'>
                                <label htmlFor='confirmPassword'>Confirm Password</label>
                                <div className='input-group'>
                                  <input
                                    type='password'
                                    className='form-control'
                                    id='confirmPassword'
                                    placeholder='******'
                                    required
                                    value={confirmPassword}
                                    onChange={e => {
                                      setConfirmPassword(e.target.value)
                                    }}
                                    onKeyPress={e => {
                                      if (e.key === 'Enter') {
                                        completeInvite()
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div style={{ color: 'red' }}>{errMsg}</div>
                        <div className='d-flex justify-content-center mt-3 login_container'>
                          <button
                            className='btn login_btn'
                            type='button'
                            onClick={() => {
                              completeInvite()
                            }}
                            disabled={disabledCompleteButton}
                          >
                            Complete Invitation
                          </button>
                        </div>
                      </form>
                      <Row className='form-group mt-2 mb-0'>
                        <div className='col-12 mt-4'>
                          <Link to='/login'>
                            <i className='fa fa-arrow-left' /> Back to Login
                          </Link>
                        </div>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className='mt-5 text-center'>
                <p>
                  Don't have an account ?{' '}
                  <Link to='/signup' className='font-weight-medium text-primary'>
                    {' '}
                    Signup now{' '}
                  </Link>{' '}
                </p>
                <strong>
                  Copyright © 2022{' '}
                  <a href='https://edbridge.io' target='_blank' rel='noopener noreferrer'>
                    EdBridge.io
                  </a>
                  . {'  '}
                </strong>
                All rights reserved.
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export { UserInvite }
