import { Row, Col, Card, CardBody, CardTitle, CardDeck, CardText, Table } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../../config'
import { ClientLookup } from './clientLookup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { UpdateRelationModal } from './UpdateRelationModal'

const ClientDetail = ({ authObj }) => {
  const [errMsg, setErrMsg] = useState('')
  const [client, setClient] = useState(undefined)
  const [relatedClients, setRelatedClients] = useState(undefined)
  const [allRelations, setAllRelations] = useState([])
  const [selectedTarget, setSelectedTarget] = useState({})

  const [isUpdateRelationModalOpen, setIsUpdateRelationModalOpen] = useState(false)

  const { clientId } = useParams()
  const history = useHistory()

  const getAllRelations = async () => {
    try {
      const url = `${config.apiUrl}/public/clients/all-relations`
      const res = await axios.get(url)

      if (res && res.data) {
        setAllRelations([...res.data.AttendeeRoles, ...res.data.GuardianRoles])
      } else {
        setErrMsg('Unable to get relations (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get relations (2) : ${JSON.stringify(err)}`)
    }
  }

  const getClient = async () => {
    setErrMsg('Fetching client...')
    try {
      const url = `${config.apiUrl}/app/clients/by-id?clientId=${clientId}&includeRelations=true`
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setClient(res.data)
        setRelatedClients(res.data.client_relations)
      } else {
        setErrMsg('Unable to get clients (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get clients (2) : ${JSON.stringify(err)}`)
    }
  }

  const updateRelation = async (targetToSourceRelation, sourceToTargetRelation) => {
    if (!clientId || !selectedTarget) {
      return
    }
    setErrMsg('Updating relation...')
    try {
      const url = `${config.apiUrl}/app/clients/relations`
      const res = await axios.put(
        url,
        {
          source: clientId,
          target: selectedTarget.id,
          targetToSourceRelation,
          sourceToTargetRelation
        },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res && res.data) {
        setErrMsg('')
        setIsUpdateRelationModalOpen(false)
        getClient()
      } else {
        setErrMsg('Unable to update relation (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to update relation (2) : ${JSON.stringify(err)}`)
    }
  }

  useEffect(() => {
    getClient()
  }, [clientId])

  useEffect(() => {
    getAllRelations()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12}>
            <header className='panel_header'>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <h2 className='title float-left'>Client Details</h2>
            </header>
          </Col>
          <Col>
            <CardDeck>
              <Card>
                {client && (
                  <CardBody>
                    <CardTitle>
                      {client.firstName} {client.lastName ? client.lastName : ''}
                    </CardTitle>
                    <CardText>
                      {client.gender && <p>Gender: {client.gender}</p>}
                      {client.phone && <p>Phone: {client.phone}</p>}
                      {client.email && <p>Email: {client.email}</p>}
                      {client.address && <p>Address: {client.address}</p>}
                      {client.dob && <p>Date Of Birth: {client.dob}</p>}
                      {client.state && <p>State: {client.state}</p>}
                      {client.schoolName && <p>School Name: {client.schoolName}</p>}
                      {client.grade && <p>Grade: {client.grade}</p>}
                      {client.homeRoomTeacher && <p>Home Room Teacher: {client.homeRoomTeacher}</p>}
                      {client.status && <p>Status: {client.status}</p>}
                    </CardText>
                  </CardBody>
                )}
              </Card>
              <Card>
                <CardBody>
                  {relatedClients && (
                    <Table striped>
                      <thead>
                        <th>Name</th>
                        <th>Relationship</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </thead>
                      <tbody>
                        {relatedClients.map(rc => {
                          const c = rc.target
                          return (
                            <tr key={rc.id}>
                              <td
                                onClick={() => {
                                  history.push(`/clients/${c.id}`)
                                }}
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <a>
                                  {c.firstName} {c.lastName ? c.lastName : ''}
                                </a>
                              </td>
                              <td>
                                {rc.relation}{' '}
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    setSelectedTarget(c)
                                    setIsUpdateRelationModalOpen(true)
                                  }}
                                />
                              </td>
                              <td>{c.email ? c.email : ''}</td>
                              <td>{c.phone ? c.phone : ''}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </CardDeck>
          </Col>
        </Row>
        <br />
        {client && (
          <Card>
            <CardBody>
              <ClientLookup authObj={authObj} client={client} />
            </CardBody>
          </Card>
        )}
      </div>
      <UpdateRelationModal
        isUpdateRelationModalOpen={isUpdateRelationModalOpen}
        setIsUpdateRelationModalOpen={setIsUpdateRelationModalOpen}
        allRelations={allRelations}
        sourceName={client ? client.firstName : ''}
        targetName={selectedTarget.firstName}
        updateRelation={updateRelation}
      />
    </div>
  )
}

export { ClientDetail }
