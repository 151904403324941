import React from 'react'
import { FormGroup, Label, Input, Row, Col, Form } from 'reactstrap'

const Settings = ({ fieldGroup, updateFieldSettings, setFieldGroupOrder }) => {
  return (
    <Row key={fieldGroup.id}>
      <Col md='12'>
        <Form>
          <FormGroup>
            <Label htmlFor={fieldGroup.id + '_order'}>Order</Label>
            <Input
              type='number'
              id={fieldGroup.id + '_order'}
              name={fieldGroup.id + '_order'}
              value={fieldGroup.order}
              onChange={e => {
                setFieldGroupOrder(fieldGroup.id, Number(e.target.value))
              }}
            />
          </FormGroup>
          <FormGroup
            tag='fieldset'
            onChange={e => {
              updateFieldSettings(fieldGroup.id, { repeat: e.target.value })
            }}
          >
            <Label>Select repeats</Label>
            <FormGroup check>
              <Label check>
                <input
                  type='radio'
                  name='repeat'
                  value='none'
                  checked={fieldGroup.repeat === 'none'}
                  ref={e => {
                    if (e && e.style) {
                      e.style.setProperty('position', 'relative', 'important')
                    }
                  }}
                />{' '}
                Dont repeat
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <input
                  type='radio'
                  name='repeat'
                  value='attendees'
                  checked={fieldGroup.repeat === 'attendees'}
                  ref={e => {
                    if (e && e.style) {
                      e.style.setProperty('position', 'relative', 'important')
                    }
                  }}
                />{' '}
                Repeat for # of attendees
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <input
                  type='radio'
                  name='repeat'
                  value='guardians'
                  checked={fieldGroup.repeat === 'guardians'}
                  ref={e => {
                    if (e && e.style) {
                      e.style.setProperty('position', 'relative', 'important')
                    }
                  }}
                />{' '}
                Repeat for # of guardians
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={fieldGroup.id + '_alignment'}>Alignment</Label>
            <select
              onChange={e => {
                updateFieldSettings(fieldGroup.id, { alignment: e.target.value })
              }}
              value={fieldGroup.alignment}
            >
              <option value='left'>Left</option>
              <option value='center'>Center</option>
              <option value='right'>Right</option>
            </select>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

export default Settings
