import { config } from '../../../config'

export const apps = [
  {
    name: 'Campminder',
    icon: 'campminder.svg',
    isAdded: false,
    primaryColor: '#3B8383',
    slug: 'campminder',
    authUrl: `${config.integrateUrl}?app=campminder`
  },
  {
    name: 'Gmail',
    icon: 'gmail.svg',
    isAdded: false,
    primaryColor: '#EA4335',
    slug: 'gmail',
    getAuthLink: 'https://developers.google.com/identity/protocols/oauth2'
  },
  {
    name: 'Google Sheets',
    icon: 'googleSheet.svg',
    isAdded: false,
    primaryColor: '#23A566',
    slug: 'google_sheets',
    getAuthLink: 'https://developers.google.com/identity/protocols/oauth2'
  },
  {
    name: 'SharpSpring',
    icon: 'sharpspring.png',
    isAdded: false,
    primaryColor: '#85C33F',
    slug: 'sharpspring',
    authUrl: `${config.integrateUrl}?app=sharpspring`
  },
  {
    name: 'Slack',
    icon: 'slack.svg',
    isAdded: false,
    primaryColor: '#4A154B',
    slug: 'slack',
    authUrl: `${config.integrateUrl}?app=slack`,
    getAuthLink: 'https://api.slack.com/legacy/oauth'
  },
  {
    name: 'Google Forms',
    icon: 'googleForms.svg',
    isAdded: false,
    primaryColor: '#754CBD',
    slug: 'google_forms',
    authUrl: '/integrate/google_forms',
    getAuthLink: 'https://developers.google.com/identity/protocols/oauth2'
  },
  {
    name: 'Hubspot',
    icon: 'hubspot.svg',
    isAdded: false,
    primaryColor: '#FF7B57',
    slug: 'hubspot',
    authUrl: `${config.integrateUrl}?app=hubspot`,
    getAuthLink: 'https://developers.hubspot.com/docs/api/working-with-oauth'
  },
  {
    name: 'Mailchimp',
    icon: 'mailchimp.svg',
    isAdded: false,
    primaryColor: '#FFD14F',
    slug: 'mailchimp',
    authUrl: `${config.integrateUrl}?app=mailchimp`,
    getAuthLink: 'https://mailchimp.com/developer/marketing/guides/access-user-data-oauth-2/'
  },
  {
    name: 'Google Drive',
    icon: 'googleDrive.svg',
    isAdded: false,
    primaryColor: '#00AE3C',
    slug: 'google_drive',
    authUrl: '/integrate/google_drive',
    getAuthLink: 'https://developers.google.com/identity/protocols/oauth2'
  },
  {
    name: 'Google Calendar',
    icon: 'googleCalendar.svg',
    isAdded: false,
    primaryColor: '#4285F4',
    slug: 'google_calendar',
    authUrl: '/integrate/google_calendar',
    getAuthLink: 'https://developers.google.com/identity/protocols/oauth2'
  },
  {
    name: 'Salesforce',
    icon: 'salesforce.svg',
    isAdded: false,
    primaryColor: '#00A1E0',
    slug: 'salesforce',
    authUrl: `${config.integrateUrl}?app=salesforce`,
    getAuthLink:
      'https://developer.salesforce.com/docs/atlas.en-us.api_rest.meta/api_rest/intro_oauth_and_connected_apps.htm'
  },
  {
    name: 'Shopify',
    icon: 'shopify.svg',
    isAdded: false,
    primaryColor: '#95BF46',
    slug: 'shopify',
    authUrl: `${config.integrateUrl}?app=shopify`,
    getAuthLink: 'https://shopify.dev/apps/auth/oauth/getting-started'
  },
  {
    name: 'BigQuery',
    icon: 'bigquery.svg',
    isAdded: false,
    primaryColor: '#4386FA',
    slug: 'bigquery'
  },
  {
    name: 'CampBrain',
    icon: 'campbrain.svg',
    isAdded: false,
    primaryColor: '#547E2D',
    slug: 'campbrain'
  },
  {
    name: 'CampSite',
    icon: 'campsite.svg',
    isAdded: false,
    primaryColor: '#597C54',
    slug: 'campsite'
  },
  {
    name: 'CampSpot',
    icon: 'campspot.svg',
    isAdded: false,
    primaryColor: '#2ECC71',
    slug: 'campspot'
  },
  {
    name: 'ShipCalm',
    icon: 'shipcalm.svg',
    isAdded: false,
    primaryColor: '#1CAAE2',
    slug: 'shipcalm'
  }
]

export const deepCopy = obj => JSON.parse(JSON.stringify(obj))
