import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'

import { Row, Col, Button, Spinner, Input, Table } from 'reactstrap'

import { AddProgramModal } from './AddProgramModal'
import { EditProgramModal } from './EditProgramModal'
import { OrderingModal } from './OrderingModal'
import { Program } from './Program'

const Programs = ({ authObj }) => {
  const [programs, setPrograms] = useState([])
  const [orderChanged, setOrderChanged] = useState(false)

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(null)

  const [loader, setLoader] = useState(true)

  const [modalOpen, setModalOpen] = useState(false)
  const [orderingModalOpen, setOrderingModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editProgramId, setEditProgramId] = useState(null)
  const [errMsg, setErrMsg] = useState('')

  const getPrograms = async () => {
    setErrMsg('Fetching programs...')
    try {
      let url = `${config.apiUrl}/app/programs`
      if (search) {
        url += `?search=${search}`
      }
      const res = await axios.get(url, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')
        setPrograms(res.data)
        setLoader(false)
      } else {
        setErrMsg('Unable to get programs (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to get programs (2) : ${JSON.stringify(err)}`)
    }
  }

  const duplicate = async pId => {
    setErrMsg('Duplicating program...')
    try {
      const response = await axios.post(
        `${config.apiUrl}/app/programs/duplicate`,
        {
          programId: pId
        },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (response && response.data) {
        setErrMsg('Program duplicated successfully')
        getPrograms()
      } else {
        setErrMsg('Unable to duplicate program (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to duplicate program (2) : ${JSON.stringify(err)}`)
    }
  }

  const remove = async programId => {
    setErrMsg('Deleting programs...')
    try {
      const res = await axios.delete(`${config.apiUrl}/app/programs?programId=${programId}`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        // refresh table
        getPrograms()
      } else {
        setErrMsg('Unable to remove program (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to remove program (2) : ${JSON.stringify(err)}`)
    }
  }

  const saveOrder = async () => {
    try {
      const programIds = programs.map(p => {
        return p.id
      })
      const res = await axios.put(
        `${config.apiUrl}/app/programs/order`,
        { programIds },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (!res || !res.data) {
        setErrMsg('Unable to update program ordering (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update program ordering (2)')
    }
  }

  const reorder = (targetId, sourceId) => {
    if (targetId === sourceId) {
      return
    }
    setOrderChanged(true)
    setPrograms(currPrograms => {
      const sourceInd = currPrograms.findIndex(p => p.id === sourceId)
      if (sourceInd === -1) {
        return
      }
      const source = currPrograms[sourceInd]
      const newPrograms = currPrograms.filter(p => p.id !== sourceId)
      const targetInd = newPrograms.findIndex(p => p.id === targetId)
      if (targetInd === -1) {
        return
      }
      newPrograms.splice(sourceInd <= targetInd ? targetInd + 1 : targetInd, 0, source)
      return newPrograms
    })
  }

  useEffect(() => {
    if (orderChanged) {
      saveOrder()
    }
  }, [programs])

  useEffect(() => {
    if (search) {
      if (searching) {
        clearTimeout(searching)
      }
      const s = setTimeout(() => {
        setErrMsg('Searching...')
        getPrograms()
      }, 1000)
      setSearching(s)
    }
  }, [search])

  useEffect(() => {
    getPrograms()
  }, [])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <div className='col-12' style={{ paddingTop: '30px' }}>
              <section className='box '>
                <header className='panel_header'>
                  <h2 className='title float-left'>Programs</h2>
                  <div style={{ padding: '30px', float: 'right' }}>
                    {/* <Button
                      color="primary"
                      onClick={() => {
                        setOrderingModalOpen(true)
                      }}
                    >
                      Customize Ordering
                    </Button> */}
                    <Button
                      color='primary'
                      onClick={() => {
                        setModalOpen(true)
                      }}
                    >
                      Create Program
                    </Button>
                  </div>
                </header>
                <Col md={12} style={{ zIndex: '999' }}>
                  <div style={{ width: '100%', color: 'red' }}>{errMsg}</div>
                  <div
                    style={{
                      width: '250px',
                      paddingRight: '30px',
                      marginLeft: 'auto',
                      marginBottom: '20px'
                    }}
                  >
                    Search:
                    <Input
                      type='text'
                      placeholder=''
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value)
                      }}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  {(loader && (
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Spinner color='primary' />
                    </div>
                  )) || (
                    <Col>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {programs &&
                            programs.map(p => {
                              return (
                                <Program
                                  key={p.id}
                                  program={p}
                                  duplicate={duplicate}
                                  setEditModalOpen={setEditModalOpen}
                                  setEditProgramId={setEditProgramId}
                                  remove={remove}
                                  reorder={reorder}
                                />
                              )
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Col>
              </section>
            </div>
          </Col>
        </Row>
      </div>
      <AddProgramModal authObj={authObj} modalOpen={modalOpen} setModalOpen={setModalOpen} refreshTable={getPrograms} />
      <EditProgramModal
        authObj={authObj}
        programId={editProgramId}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        refreshTable={getPrograms}
      />
      <OrderingModal
        authObj={authObj}
        programs={programs}
        modalOpen={orderingModalOpen}
        setModalOpen={setOrderingModalOpen}
        refreshTable={getPrograms}
      />
    </div>
  )
}

export { Programs }
