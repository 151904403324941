import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { config } from '../../../config'

const DestinationCallback = ({ authObj }) => {
  const [errMsg, setErrMsg] = useState('')
  const location = useLocation()

  const integrateDestination = async code => {
    setErrMsg('Integrating destination...')
    try {
      const url = `${config.apiUrl}/app/destinations/sheets/integrate`
      const res = await axios.put(
        url,
        { code },
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res.status === 200) {
        setErrMsg('')
      } else {
        setErrMsg('Unable to integrate destination (1)')
      }
    } catch (err) {
      setErrMsg(`Unable to integrate destination (2) : ${err}`)
    }
  }

  useEffect(async () => {
    const code = new URLSearchParams(location.search).get('code')
    if (code) {
      await integrateDestination(code)
      window.close()
    }
  }, [])

  return <>{errMsg}</>
}

export { DestinationCallback }
