import React from 'react'
import cancelIcon from '../../../assets/icons/cancelIcon.svg'

const FilterSidebar = props => {
  return (
    <div className='filterCategoriesSidebar'>
      <div className='titleArea'>
        <h3 className='filterTitleText'>
          Filter by Apps
        </h3>
        <div className='cancelArea' onClick={() => props.showFilterCategories(false)}>
          <img src={cancelIcon} />
        </div>
      </div>
      <div className='buttonsArea'>
        <button
          className='button primary'
          onClick={() => props.filterClients()}
        >
          Apply Filter
        </button>
        <button
          className='button secondary'
          onClick={() => {
            props.setFilteredClients([])
            props.setFilterOptions([])
            props.setShouldFilter(false)
            setTimeout(() => {
              props.showFilterCategories(false)
            }, 400)
          }}
        >
          Reset
        </button>
      </div>
      <div className='appsArea'>
        {props.allApps.map(app => (
          <div className='appArea' key={app.slug}>
            <div className="rowLeft">
              <div className='iconArea'>
                <img src={app.icon} className='icon' />
              </div>
              <div className="appName">{app?.name}</div>
            </div>
            <div className='checkboxArea'>
              <input type='checkbox' checked={`${props.filterOptions.includes(app?.slug) ? 'checked' : ''}`} className='checkbox' onChange={() => { }} />
              <label className='checkmark' onClick={() => props.addFilters(app?.slug)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilterSidebar
