import axios from 'axios'

import { config } from '../config'

const API = {
  getReservationsList: async (accountId, token, start, end, searchType, search) => {
    try {
      let query = `start=${start}&end=${end}`
      if (search && searchType) {
        query += `&search=${search}&searchType=${searchType}`
      }
      const res = await axios.get(`${config.apiUrl}/app/reservations/reservation-viewer?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getIncompleteReservationsList: async (accountId, token, start, end, currentPage, limit) => {
    try {
      const query = `start=${start}&end=${end}&page=${currentPage}&pageSize=${limit}`
      const res = await axios.get(`${config.apiUrl}/app/reservations/incomplete-reservations?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getReservationLogs: async (accountId, token, reservationId) => {
    try {
      let query = ''
      if (accountId && reservationId) {
        query += `&reservationId=${reservationId}`
      }
      const res = await axios.get(`${config.apiUrl}/app/reservations/single-reservation-logs?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getReservationDetails: async (accountId, token, reservationId) => {
    try {
      let query = ''
      if (accountId && reservationId) {
        query += `&reservationId=${reservationId}`
      }
      const res = await axios.get(`${config.apiUrl}/app/reservations/by-id?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  refundTransaction: async (accountId, token, requestObj) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/reservations/refund`, requestObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  resendEmail: async (accountId, token, requestObj) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/reservations/resend-email`, requestObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getLocations: async (
    accountId,
    token,
    cache = true,
    includeUsers = false,
    includeRules = false,
    startDate = '',
    endDate = ''
  ) => {
    try {
      let query = ''
      if (includeRules) {
        query += `&includeRules=${includeRules}`
        query += `&startDate=${startDate}`
        query += `&endDate=${endDate}`
      }
      if (includeUsers) {
        query += `&includeUsers=${includeUsers}`
      }
      if (cache) {
        query += `&cache=${cache}`
      }
      const res = await axios.get(`${config.apiUrl}/app/locations?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getForms: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/public/forms`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getFields: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/public/fields`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getUUID: async accountId => {
    try {
      const res = await axios.get(`${config.apiUrl}/public/uuid`, {
        headers: {
          accountid: accountId
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createField: async (accountId, token, options = {}) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/fields`, options, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createForm: async (accountId, token, options = {}) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/forms`, options, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateForm: async (accountId, token, options = {}) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/forms`, options, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  deleteForm: async (accountId, token, formId) => {
    try {
      const res = await axios.delete(`${config.apiUrl}/app/forms?formId=${formId}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getSettings: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/settings`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getTemplates: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/templates`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  validateSheetsIntegration: async (accountId, token, search) => {
    try {
      let query = '?'
      if (search) {
        query += `search=${search}`
      }
      const res = await axios.get(`${config.apiUrl}/app/destinations/sheets${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getSheetById: async (accountId, token, spreadsheetId) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/destinations/sheets/by-id?spreadsheetId=${spreadsheetId}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getSheetFields: async (accountId, token, spreadsheetId, worksheet) => {
    try {
      const res = await axios.get(
        `${config.apiUrl}/app/destinations/sheets/fields?spreadsheetId=${spreadsheetId}&worksheet=${worksheet}`,
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getMappings: async (accountId, token, destMappingId) => {
    try {
      let query = ''
      if (destMappingId) {
        query += `destMappingId=${destMappingId}`
      }
      const res = await axios.get(`${config.apiUrl}/app/destinations/mapping?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createMapping: async (accountId, token, createObj) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/destinations/mapping`, createObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateMapping: async (accountId, token, updateObj) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/destinations/mapping`, updateObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getSubscriptionDetails: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/subscribe/stripe-subscription`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  cancelSubscription: async (accountId, token, subscriptionId, planId) => {
    try {
      const res = await axios.put(
        `${config.apiUrl}/app/subscribe/cancel`,
        {
          subscriptionId,
          planId
        },
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  postAppList: async (accountId, token, applist) => {
    try {
      const res = await axios.post(
        `${config.apiUrl}/app/gift/applist`,
        {
          applist
        },
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getAuthorizedGsheets: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/apps/authorized-gsheet`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getSpreadsheets: async (accountId, token, appId, search) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/apps/gsheet/spreadsheets?appId=${appId}&search=${search}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getGsheetDetails: async (accountId, token, spreadsheetId, appId) => {
    try {
      const res = await axios.get(
        `${config.apiUrl}/app/apps/gsheet/by-id?spreadsheetId=${spreadsheetId}&appId=${appId}`,
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getGsheetColumns: async (accountId, token, spreadsheetId, worksheet, appId) => {
    try {
      const res = await axios.get(
        `${config.apiUrl}/app/apps/gsheet/columns?spreadsheetId=${spreadsheetId}&worksheet=${worksheet}&appId=${appId}`,
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getApps: async (accountId, token, id) => {
    try {
      const params = {}
      if (id) {
        params.id = id
      }
      const res = await axios.get(`${config.apiUrl}/app/apps`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        },
        params
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getAuthUrls: async accountId => {
    try {
      const res = await axios.get(`${config.apiUrl}/public/apps-offered/auth-url`, {
        headers: {
          accountid: accountId
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createApp: async (accountId, token, createObj) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/apps`, createObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateApp: async (accountId, token, updateObj) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/apps`, updateObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getAuthorizedCM: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/apps/authorized-campminder`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getAppMappings: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/app-mappings`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createAppMapping: async (accountId, token, createObj) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/app-mappings`, createObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateAppMapping: async (accountId, token, updateObj) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/app-mappings`, updateObj, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getAutomations: async (accountId, token, options = {}) => {
    try {
      let query = ''
      if (options.startDate && options.endDate) {
        query += `&startDate=${options.startDate}&endDate=${options.endDate}`
      }
      if (options.workflowIds && options.workflowIds !== '') {
        query += `&workflowIds=${options.workflowIds}`
      }
      if (options.status && options.status !== '') {
        query += `&status=${options.status}`
      }
      const res = await axios.get(
        `${config.apiUrl}/app/automations?includeWorkflow=true&page=${options.page}&pageSize=${options.pageSize}${query}`,
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getAutomationCount: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/automations/count`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  replayAutomation: async (accountId, token, automationId) => {
    try {
      const res = await axios.post(
        `${config.apiUrl}/app/automations/replay`,
        {
          automationId
        },
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getCreditCardDetails: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/subscribe/stripe-card`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getCmSessions: async (accountId, token, appId, seasonId) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/app-mappings/cm-sessions?appId=${appId}&seasonId=${seasonId}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getProcesses: async (accountId, token, id) => {
    try {
      let query = ''
      if (id?.length !== 0) {
        query += `id=${id}`
      }
      const res = await axios.get(`${config.apiUrl}/app/processes?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createProcess: async (accountId, token, process) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/processes`, process, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateProcess: async (accountId, token, process) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/processes`, process, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getTransactions: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/subscribe/transactions`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  runCode: async (accountId, token, code, input) => {
    try {
      const res = await axios.post(
        `${config.apiUrl}/app/processes/run-code`,
        {
          code,
          input
        },
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getMyApps: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/integrated-apps`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createMyApp: async (accountId, token, appId, credentials) => {
    try {
      const res = await axios.post(
        `${config.apiUrl}/app/integrated-apps`,
        {
          appId,
          credentials,
          status: 'active'
        },
        {
          headers: {
            accountid: accountId,
            Authorization: `Bearer ${token}`
          }
        }
      )
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateMyApp: async (accountId, token, app) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/integrated-apps`, app, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getMyClients: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/workflow-clients`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getAppsOffered: async (accountId, token, slug) => {
    try {
      let query = ''
      if (slug) {
        query += `slug=${slug}`
      }
      const res = await axios.get(`${config.apiUrl}/app/apps-offered?${query}`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createAppsOffered: async (accountId, token, app) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/apps-offered`, app, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateAppsOffered: async (accountId, token, app) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/apps-offered`, app, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  getOrders: async (accountId, token) => {
    try {
      const res = await axios.get(`${config.apiUrl}/app/requests`, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  createOrder: async (accountId, token, body) => {
    try {
      const res = await axios.post(`${config.apiUrl}/app/requests`, body, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  },
  updateOrder: async (accountId, token, body) => {
    try {
      const res = await axios.put(`${config.apiUrl}/app/requests`, body, {
        headers: {
          accountid: accountId,
          Authorization: `Bearer ${token}`
        }
      })
      return formatResponse(res)
    } catch (err) {
      return formatResponse(null, err)
    }
  }
}

// format response will return a response object {data: {} or [], err: string}
const formatResponse = (res, err, defaultErrStr) => {
  const formattedRes = {
    data: undefined,
    err: undefined
  }
  if (res && res.data) {
    formattedRes.data = res.data
  } else if (err && err.response && err.response.data && err.response.data.msg) {
    formattedRes.err = `${err.response.data.msg}`
  } else if (defaultErrStr) {
    formattedRes.err = defaultErrStr
  } else {
    formattedRes.err = 'Unable to fetch from API'
  }
  return formattedRes
}

export { API }
