/* eslint-disable */
import React, { useState, useEffect } from "react"
import axios from "axios"
import moment from "moment"
import { Button, Row, Col, UncontrolledTooltip } from "reactstrap"

import { config } from "../../../config"

import "./MCAccount.scss"

const MCAccount = ({ authObj }) => {
  // account
  const [googleEmail, setGoogleEmail] = useState("")
  const [googlePassword, setGooglePassword] = useState("")
  const [name, setName] = useState("")

  // general
  const [collectSignInBonus, setCollectSignInBonus] = useState(false)
  const [activateVIP, setActivateVIP] = useState(false)
  const [dateBabe, setDateBabe] = useState(false)
  const [protectBabe, setProtectBabe] = useState(false)
  const [healCrew, setHealCrew] = useState(false)
  const [repairWall, setRepairWall] = useState(false)
  const [collectResources, setCollectResources] = useState(false)
  const [upgradeProductionBuildings, setUpgradeProductionBuildings] = useState(false)

  // clan
  const [collaboration, setCollaboration] = useState(false)
  const [donation, setDonation] = useState(false)

  // crew
  const [trainCrewTier, setTrainCrewTier] = useState(0)
  const [trainBulkers, setTrainBulkers] = useState(false)
  const [trainBikers, setTrainBikers] = useState(false)
  const [trainShooters, setTrainShooters] = useState(false)
  const [trainVehicles, setTrainVehicles] = useState(false)

  // gathering
  const [gatherTileLevel, setGatherTileLevel] = useState(0)
  const [gatherCargo, setGatherCargo] = useState(false)
  const [gatherCash, setGatherCash] = useState(false)
  const [gatherArms, setGatherArms] = useState(false)
  const [gatherMetal, setGatherMetal] = useState(false)

  // plantation
  const [feedDog, setFeedDog] = useState(false)
  const [plantSeeds, setPlantSeeds] = useState(false)
  const [collectSeeds, setCollectSeeds] = useState(false)
  const [stealPlants, setStealPlants] = useState(false)
  const [sellPlants, setSellPlants] = useState(false)

  // current info
  const [currentCargo, setCurrentCargo] = useState("")
  const [currentCash, setCurrentCash] = useState("")
  const [currentArms, setCurrentArms] = useState("")
  const [currentMetal, setCurrentMetal] = useState("")
  const [currentGold, setCurrentGold] = useState("")
  const [currentPower, setCurrentPower] = useState("")
  const [currentLevel, setCurrentLevel] = useState("")
  const [currentVipLevel, setCurrentVipLevel] = useState("")
  const [lastSnapshotTime, setLastSnapshotTime] = useState("")

  const [errMsg, setErrMsg] = useState("")

  const saveMCAccount = async () => {
    setErrMsg("Saving Your Account Preferences...")
    try {
      // validation
      if (!googleEmail) {
        return setErrMsg("Google email cannot be blank.")
      }
      if (!googlePassword) {
        return setErrMsg("Google password cannot be blank.")
      }

      const updateObj = {
        googleEmail,
        googlePassword,
        name,
        // general
        collectSignInBonus,
        activateVIP,
        dateBabe,
        protectBabe,
        healCrew,
        repairWall,
        collectResources,
        upgradeProductionBuildings,
        // clan
        collaboration,
        donation,
        // crew
        trainCrewTier,
        trainBulkers,
        trainBikers,
        trainShooters,
        trainVehicles,
        // gathering
        gatherTileLevel,
        gatherCargo,
        gatherCash,
        gatherArms,
        gatherMetal,
        // plantation
        feedDog,
        plantSeeds,
        collectSeeds,
        stealPlants,
        sellPlants
      }

      const response = await axios.put(`${config.apiUrl}/app/mc-accounts`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (response && response.data) {
        setErrMsg("Account preferences Saved Successfully.")
      } else {
        setErrMsg("Unable to save account preferences (1)")
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg("Unable to save account preferences. Please contact support for help!")
    }
  }

  const getMCAccount = async () => {
    setErrMsg("Fetching your account preferences...")
    try {
      const response = await axios.get(`${config.apiUrl}/app/mc-accounts`, {
        headers: {
          Authorization: `Bearer ${authObj.token}`,
          accountid: authObj.account.accountId
        }
      })

      if (response && response.data) {
        const mcAccount = response.data
        // on initial state
        if (!mcAccount || !mcAccount.googleEmail) {
          setErrMsg("")
          return
        }
        setGoogleEmail(mcAccount.googleEmail)
        setGooglePassword(mcAccount.googlePassword)
        setName(mcAccount.name)

        // general
        setCollectSignInBonus(mcAccount.collectSignInBonus)
        setActivateVIP(mcAccount.activateVIP)
        setDateBabe(mcAccount.dateBabe)
        setProtectBabe(mcAccount.protectBabe)
        setHealCrew(mcAccount.healCrew)
        setRepairWall(mcAccount.repairWall)
        setCollectResources(mcAccount.collectResources)
        setUpgradeProductionBuildings(mcAccount.upgradeProductionBuildings)
        // clan
        setCollaboration(mcAccount.collaboration)
        setDonation(mcAccount.donation)
        // crew
        setTrainCrewTier(mcAccount.trainCrewTier)
        setTrainBulkers(mcAccount.trainBulkers)
        setTrainBikers(mcAccount.trainBikers)
        setTrainShooters(mcAccount.trainShooters)
        setTrainVehicles(mcAccount.trainVehicles)
        // gathering
        setGatherTileLevel(mcAccount.gatherTileLevel)
        setGatherCargo(mcAccount.gatherCargo)
        setGatherCash(mcAccount.gatherCash)
        setGatherArms(mcAccount.gatherArms)
        setGatherMetal(mcAccount.gatherMetal)
        // plantation
        setFeedDog(mcAccount.feedDog)
        setPlantSeeds(mcAccount.plantSeeds)
        setCollectSeeds(mcAccount.collectSeeds)
        setStealPlants(mcAccount.stealPlants)
        setSellPlants(mcAccount.sellPlants)

        // current info
        setCurrentCargo(mcAccount.currentCargo)
        setCurrentCash(mcAccount.currentCash)
        setCurrentArms(mcAccount.currentArms)
        setCurrentMetal(mcAccount.currentMetal)
        setCurrentGold(mcAccount.currentGold)
        setCurrentPower(mcAccount.currentPower)
        setCurrentLevel(mcAccount.currentLevel)
        setCurrentVipLevel(mcAccount.currentVipLevel)
        setLastSnapshotTime(mcAccount.lastSnapshotTime)

        setErrMsg("")
      } else {
        setErrMsg("Unable to get your account information (1)")
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg("Unable to get your account information. Please contact support for help!")
    }
  }

  useEffect(() => {
    getMCAccount()
  }, [])

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title" />
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Preferences For Your Mafia City Account</h2>
                </header>
                {/* <Image src={profileImg} rounded /> */}

                <div className="content-body">
                  <Row>
                    <Col md={6} sm={12}>
                      <h4>Account Information</h4>
                      <span style={{ color: "red" }}>
                        IMPORTANT: For your safety, you may want to make a new google account for us to run your farm(s)
                        on. It is important that you already have your turf teleported to a place you desire.
                        <br />
                        It is recommended that you have 2FA turned off, so google doesn't try to validate your phone
                        when your account is being connected to Mafia City.
                        <a href="https://support.google.com/accounts/answer/1064203" target="_blank" rel="noreferrer">
                          https://support.google.com/accounts/answer/1064203
                        </a>
                        Otherwise, we'll have to be online at the same time so we can get the verification code from
                        you.
                      </span>
                      <Col md={12}>
                        <label htmlFor="googleEmail" className="col-form-label">
                          Google Email{" "}
                          <span className="tooltipSpan" id="toolTip_googleEmail">
                            <i className="fas fa-info" />
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            id="googleEmail"
                            value={googleEmail}
                            onChange={e => {
                              setGoogleEmail(e.target.value)
                            }}
                          />
                        </div>
                        <UncontrolledTooltip placement="right" target="toolTip_googleEmail">
                          The email that is linked to your mafia city account. The can be different from the email you
                          are using for EdBridge, or the email we use to communcation with you.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <label htmlFor="googlePassword" className="col-form-label">
                          Google Password{" "}
                          <span className="tooltipSpan" id="toolTip_googlePassword">
                            <i className="fas fa-info" />
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            id="googlePassword"
                            value={googlePassword}
                            onChange={e => {
                              setGooglePassword(e.target.value)
                            }}
                          />
                        </div>
                        <UncontrolledTooltip placement="right" target="toolTip_googlePassword">
                          The password attached to your email for your mafia city account.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <label htmlFor="name" className="col-form-label">
                          In Game Name{" "}
                          <span className="tooltipSpan" id="toolTip_name">
                            <i className="fas fa-info" />
                          </span>
                        </label>
                        <div>
                          <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={e => {
                              setName(e.target.value)
                            }}
                          />
                        </div>
                        <UncontrolledTooltip placement="right" target="toolTip_name">
                          The has to match the name of your Boss in game, so when stats are updated, we know who to
                          refer to.
                        </UncontrolledTooltip>
                      </Col>
                    </Col>
                    <Col md={6} sm={12}>
                      <h4>Your Account Info</h4>
                      <div>
                        Last AI Snapshot: {moment.unix(lastSnapshotTime).format("M/D/YYYY")} (
                        {moment.unix(lastSnapshotTime).fromNow()})
                      </div>
                      <br />
                      <div>Cargo: {currentCargo}</div>
                      <div>Cash: {currentCash}</div>
                      <div>Arms: {currentArms}</div>
                      <div>Metal: {currentMetal}</div>
                    </Col>
                    <Col md={12}>
                      <h4>General</h4>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={collectSignInBonus}
                          onChange={e => {
                            setCollectSignInBonus(e.target.checked)
                          }}
                        />
                        <span>
                          Collect Sign-In Bonus{" "}
                          <span className="tooltipSpan" id="toolTip_collectSignInBonus">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_collectSignInBonus">
                          This will collect the sign in bonus given to you by the game on reset.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={activateVIP}
                          onChange={e => {
                            setActivateVIP(e.target.checked)
                          }}
                        />
                        <span>
                          Activate VIP{" "}
                          <span className="tooltipSpan" id="toolTip_activateVIP">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_activateVIP">
                          The AI will try to activate VIP whenever available. However, it is recommended that you save
                          them until mansion 10 when you have your plantation; that way you won't have to ever worry
                          about running out of VIP.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={dateBabe}
                          onChange={e => {
                            setDateBabe(e.target.checked)
                          }}
                        />
                        <span>
                          Date Babe{" "}
                          <span className="tooltipSpan" id="toolTip_dateBabe">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_dateBabe">
                          Whenever possible, the AI will go into your mansion every day and randomly date a babe.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={protectBabe}
                          onChange={e => {
                            setProtectBabe(e.target.checked)
                          }}
                        />
                        <span>
                          Protect Babe{" "}
                          <span className="tooltipSpan" id="toolTip_protectBabe">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_protectBabe">
                          Whenever available, the AI will try to protect the babes waiting outside your mansion.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={healCrew}
                          onChange={e => {
                            setHealCrew(e.target.checked)
                          }}
                        />
                        <span>
                          Heal Crew{" "}
                          <span className="tooltipSpan" id="toolTip_healCrew">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_healCrew">
                          When your crew is wounded, the AI will heal up your crew given that you have enough resources
                          to heal them.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={repairWall}
                          onChange={e => {
                            setRepairWall(e.target.checked)
                          }}
                        />
                        <span>
                          Repair Wall{" "}
                          <span className="tooltipSpan" id="toolTip_repairWall">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_repairWall">
                          If your wall is damaged, the AI will repair it if available. Keep in mind that it will not put
                          out fires for you, so if you're burning at a rate faster than repair, you may get ported to
                          the desert.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={collectResources}
                          onChange={e => {
                            setCollectResources(e.target.checked)
                          }}
                        />
                        <span>
                          Auto Collect Resources{" "}
                          <span className="tooltipSpan" id="toolTip_collectResources">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_collectResources">
                          When you have resources to collect from your production buildings, the AI will automatically
                          collect them for you.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={upgradeProductionBuildings}
                          onChange={e => {
                            setUpgradeProductionBuildings(e.target.checked)
                          }}
                        />
                        <span>
                          Upgrade Production Buildings{" "}
                          <span className="tooltipSpan" id="toolTip_upgradeProductionBuildings">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_upgradeProductionBuildings">
                          Automatically upgrade your production buildings if there's a queue available and if you have
                          enough resources.
                        </UncontrolledTooltip>
                      </Col>
                    </Col>
                    <Col md={12}>
                      <h4>Clan</h4>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={collaboration}
                          onChange={e => {
                            setCollaboration(e.target.checked)
                          }}
                        />
                        <span>
                          Collaboration Tasks{" "}
                          <span className="tooltipSpan" id="toolTip_collaboration">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_collaboration">
                          The AI will accept clan collaboration tasks, assist other clan members, and collect rewards
                          when applicable.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={donation}
                          onChange={e => {
                            setDonation(e.target.checked)
                          }}
                        />
                        <span>
                          Donations{" "}
                          <span className="tooltipSpan" id="toolTip_donation">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_donation">
                          Given that you have enough resources, the AI will donate to the clan. It will hit the 4 hour
                          mark and stop, until it resets again.
                        </UncontrolledTooltip>
                      </Col>
                    </Col>
                    <Col md={12}>
                      <h4>Crew</h4>
                      <Col md={12}>
                        <span>Crew Tier </span>
                        <select
                          value={trainCrewTier}
                          onChange={e => {
                            setTrainCrewTier(e.target.value)
                          }}
                        >
                          <option value="0">Don't Train</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        <span className="tooltipSpan" id="toolTip_trainCrewTier">
                          <i className="fas fa-info" />
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_trainCrewTier">
                          The tier of crew to train. The AI will only train crew if a tier is selected and a crew type
                          is selected.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={trainBulkers}
                          onChange={e => {
                            setTrainBulkers(e.target.checked)
                          }}
                        />
                        <span>
                          Train Bulkers{" "}
                          <span className="tooltipSpan" id="toolTip_trainBulkers">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_trainBulkers">
                          If selected, the AI will train bulkers of the selected tier.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={trainBikers}
                          onChange={e => {
                            setTrainBikers(e.target.checked)
                          }}
                        />
                        <span>
                          Train Bikers{" "}
                          <span className="tooltipSpan" id="toolTip_trainBikers">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_trainBikers">
                          If selected, the AI will train bikers of the selected tier.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={trainShooters}
                          onChange={e => {
                            setTrainShooters(e.target.checked)
                          }}
                        />
                        <span>
                          Train Shooters{" "}
                          <span className="tooltipSpan" id="toolTip_trainShooters">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_trainShooters">
                          If selected, the AI will train shooters of the selected tier.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={trainVehicles}
                          onChange={e => {
                            setTrainVehicles(e.target.checked)
                          }}
                        />
                        <span>
                          Train Vehicles{" "}
                          <span className="tooltipSpan" id="toolTip_trainVehicles">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_trainVehicles">
                          If selected, the AI will train vehicles of the selected tier.
                        </UncontrolledTooltip>
                      </Col>
                    </Col>
                    <Col md={12}>
                      <h4>Gathering</h4>
                      <Col md={12}>
                        <span>Tile Level </span>
                        <select
                          value={gatherTileLevel}
                          onChange={e => {
                            setGatherTileLevel(e.target.value)
                          }}
                        >
                          <option value="0">Don't Gather</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        <span className="tooltipSpan" id="toolTip_gatherTileLevel">
                          <i className="fas fa-info" />
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_gatherTileLevel">
                          The level of resource tile to gather from. The AI will gather if a level is selected and a
                          resource type is selected.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={gatherCargo}
                          onChange={e => {
                            setGatherCargo(e.target.checked)
                          }}
                        />
                        <span>
                          Gather Cargo{" "}
                          <span className="tooltipSpan" id="toolTip_gatherCargo">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_gatherCargo">
                          If selected, the AI will gather cargo of the selected level.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={gatherCash}
                          onChange={e => {
                            setGatherCash(e.target.checked)
                          }}
                        />
                        <span>
                          Gather Cash{" "}
                          <span className="tooltipSpan" id="toolTip_gatherCash">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_gatherCash">
                          If selected, the AI will gather cash of the selected level.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={gatherArms}
                          onChange={e => {
                            setGatherArms(e.target.checked)
                          }}
                        />
                        <span>
                          Gather Arms{" "}
                          <span className="tooltipSpan" id="toolTip_gatherArms">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_gatherArms">
                          If selected, the AI will gather arms of the selected level. It is recommended that you only
                          gather arms after mansion 10, where you can product and gather arms.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={gatherMetal}
                          onChange={e => {
                            setGatherMetal(e.target.checked)
                          }}
                        />
                        <span>
                          Gather Metal{" "}
                          <span className="tooltipSpan" id="toolTip_gatherMetal">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_gatherMetal">
                          If selected, the AI will gather metal of the selected level. It is recommended that you only
                          gather metal after mansion 15, where you can product and gather metal.
                        </UncontrolledTooltip>
                      </Col>
                    </Col>
                    <Col md={12}>
                      <h4>Plantation (Mansion 10+)</h4>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={feedDog}
                          onChange={e => {
                            setFeedDog(e.target.checked)
                          }}
                        />
                        <span>
                          Auto Feed Dog{" "}
                          <span className="tooltipSpan" id="toolTip_feedDog">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_feedDog">
                          Should the AI automatically feed your dog when available?
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={plantSeeds}
                          onChange={e => {
                            setPlantSeeds(e.target.checked)
                          }}
                        />
                        <span>
                          Auto Plant Seeds{" "}
                          <span className="tooltipSpan" id="toolTip_plantSeeds">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_plantSeeds">
                          Automatically plant seeds you have available up to the number of plantations you have.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={collectSeeds}
                          onChange={e => {
                            setCollectSeeds(e.target.checked)
                          }}
                        />
                        <span>
                          Collect Daily Seeds{" "}
                          <span className="tooltipSpan" id="toolTip_collectSeeds">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_collectSeeds">
                          Collect FREE daily seeds?
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={stealPlants}
                          onChange={e => {
                            setStealPlants(e.target.checked)
                          }}
                        />
                        <span>
                          Steal Plants{" "}
                          <span className="tooltipSpan" id="toolTip_stealPlants">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_stealPlants">
                          If selected, the AI will try to steal plants from other plantations, up to 10 a day.
                        </UncontrolledTooltip>
                      </Col>
                      <Col md={12}>
                        <input
                          type="checkbox"
                          className="checkboxMC"
                          checked={sellPlants}
                          onChange={e => {
                            setSellPlants(e.target.checked)
                          }}
                        />
                        <span>
                          Sell Plants{" "}
                          <span className="tooltipSpan" id="toolTip_sellPlants">
                            <i className="fas fa-info" />
                          </span>
                        </span>
                        <UncontrolledTooltip placement="right" target="toolTip_sellPlants">
                          If selected, the AI will automatically sell the plants that have turned into flowers and the
                          plants that you steal from other plantations.
                        </UncontrolledTooltip>
                      </Col>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12}>
                      <div style={{ color: "red" }}>{errMsg}</div>
                      <div style={{ padding: "30px", float: "left" }}>
                        <Button
                          color="primary"
                          onClick={() => {
                            saveMCAccount()
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { MCAccount }
