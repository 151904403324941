import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../../config'

import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import { Calendar } from '../../../components/Calendar/Calendar'
import { RuleModal } from './RuleModal'

const AddLocation = ({ authObj }) => {
  const history = useHistory()

  // initialize
  const [availableUsers, setAvailableUsers] = useState([])

  // fields
  const [title, setTitle] = useState('')
  const [area, setArea] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [city] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [notes, setNotes] = useState('')
  const [status, setStatus] = useState('active')
  const [userIds, setUserIds] = useState([]) // staff
  const [rules, setRules] = useState([])

  // modal (rules)
  const [modalOpen, setModalOpen] = useState(false)
  const [ruleDate, setRuleDate] = useState(moment())

  // not used
  // const [editModalOpen, setEditModalOpen] = useState(false)
  // const [editLocationId, setEditLocationId] = useState(null)

  const [errMsg, setErrMsg] = useState('')

  // for calendar
  // calendarEvents is a map of events based on days in the format YYYY-MM-DD
  // it has an array of events for that day [{title, onClickFunc}]
  const [calendarEvents, setCalendarEvents] = useState({})

  const addLocation = async () => {
    setErrMsg('Adding Location...')
    try {
      // validation
      if (!title) {
        return setErrMsg('Title cannot be blank.')
      }

      const createObj = {
        title,
        area,
        phone,
        address,
        city,
        state,
        zip,
        country,
        description,
        imageUrl,
        notes,
        status,
        userIds,
        rules
      }

      const res = await axios.post(`${config.apiUrl}/app/locations`, createObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('Location Added Successfully.')
      } else {
        setErrMsg('Unable to add location (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to add location (2)')
    }
  }

  const getUsers = async () => {
    setErrMsg('Fetching Staff...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/users?active=true`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setErrMsg('')

        setAvailableUsers(res.data)
      } else {
        setErrMsg('Unable to fetch staff (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch staff (2)')
    }
  }

  const onRuleChange = rule => {
    const tempRules = rules
    // set userIds on new rule
    setUserIds([...rule.userIds])

    // @todo refactor this or move to helper class
    // if type day rule exists for this date, remove it
    tempRules.forEach((r, i) => {
      if (r.type === 'day' && r.date === rule.date) {
        tempRules.splice(i, 1)
      }
    })

    rule.accountId = authObj.accountId
    // fill in locationId through the api
    tempRules.push(rule)
    setRules([...tempRules])
  }

  useEffect(() => {
    setErrMsg('')
    getUsers()
  }, [])

  useEffect(() => {
    if (calendarEvents) {
      rules.forEach(rule => {
        if (!rule.intervals || rule.intervals.length < 1) {
          if (rule.type === 'day') {
            calendarEvents[rule.date] = [{ title: 'Unavailable', type: rule.type }]
          } else {
            calendarEvents[rule.date] = [
              {
                title: 'Unavailable',
                type: rule.type,
                repeats: rule.repeats,
                wday: rule.wday
              }
            ]
          }
        } else {
          calendarEvents[rule.date] = rule.intervals.map(i => {
            const intervals = i.split(',')
            if (rule.type === 'day') {
              return {
                title: `${intervals[0]} - ${intervals[1]}`,
                type: rule.type
              }
            } else {
              return {
                title: `${intervals[0]} - ${intervals[1]}`,
                type: rule.type,
                repeats: rule.repeats,
                wday: rule.wday
              }
            }
          })
        }
      })
      setCalendarEvents({ ...calendarEvents })
    }
  }, [rules])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <header className='panel_header'>
              <h2 className='title float-left'>Create Location</h2>
              <div style={{ padding: '30px', float: 'right' }}>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => {
                    history.push('/locations')
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size='sm'
                  color='primary'
                  onClick={() => {
                    addLocation()
                  }}
                >
                  Create
                </Button>
              </div>
            </header>
          </Col>
          <Col xs={12} md={12} style={{ padding: '30px' }}>
            <section className='box' style={{ padding: '30px' }}>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <Form>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='title'>
                        <strong className='required-field'>Title</strong>
                      </Label>
                      <Input
                        type='text'
                        name='title'
                        placeholder='Title'
                        value={title}
                        onChange={e => {
                          setTitle(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='area'>
                        <strong>Area</strong>
                      </Label>
                      <Input
                        type='text'
                        name='area'
                        value={area}
                        onChange={e => {
                          setArea(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='phone'>
                        <strong>Phone</strong>
                      </Label>
                      <Input
                        type='text'
                        name='phone'
                        value={phone}
                        onChange={e => {
                          setPhone(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='address'>
                        <strong>Address</strong>
                      </Label>
                      <Input
                        type='text'
                        name='address'
                        value={address}
                        onChange={e => {
                          setAddress(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for='state'>
                        <strong>State</strong>
                      </Label>
                      <Input
                        type='text'
                        name='state'
                        value={state}
                        onChange={e => {
                          setState(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for='zip'>
                        <strong>Zip</strong>
                      </Label>
                      <Input
                        type='text'
                        name='zip'
                        value={zip}
                        onChange={e => {
                          setZip(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='country'>
                        <strong>Country</strong>
                      </Label>
                      <Input
                        type='text'
                        name='country'
                        value={country}
                        onChange={e => {
                          setCountry(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='description'>
                        <strong>Description</strong>
                      </Label>
                      <br />
                      <textarea
                        name='description'
                        value={description}
                        onChange={e => {
                          setDescription(e.target.value)
                        }}
                        style={{ width: '100%' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='imageUrl'>
                        <strong>Image Url</strong>
                      </Label>
                      <Input
                        type='text'
                        name='imageUrl'
                        value={imageUrl}
                        onChange={e => {
                          setImageUrl(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='notes'>
                        <strong>Notes</strong>
                      </Label>
                      <br />
                      <textarea
                        name='notes'
                        value={notes}
                        onChange={e => {
                          setNotes(e.target.value)
                        }}
                        style={{ width: '100%' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='status'>
                        <strong>Status</strong>
                      </Label>
                      <Input
                        type='select'
                        name='status'
                        value={status}
                        onChange={e => {
                          setStatus(e.target.value)
                        }}
                      >
                        <option value='active'>Active</option>
                        <option value='inactive'>Inactive</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </section>
          </Col>
          <Col xs={12} md={12} style={{ padding: '30px' }}>
            <section className='box' style={{ padding: '30px' }}>
              <h4>Staff Members</h4>
              <div style={{ height: '200px', overflow: 'scroll' }}>
                {availableUsers.map(p => {
                  return (
                    <div key={p.id}>
                      <Label check htmlFor={`users_${p.id}`}>
                        <Input
                          style={{ margin: 0 }}
                          type='checkbox'
                          id={`users_${p.id}`}
                          name={`users_${p.id}`}
                          checked={userIds.indexOf(p.id) > -1}
                          onChange={e => {
                            const programPos = userIds.indexOf(p.id)
                            if (e.target.checked) {
                              // on check
                              if (programPos === -1) {
                                setUserIds(programs => [...programs, p.id])
                              }
                            } else {
                              // on uncheck
                              if (programPos > -1) {
                                const pId = userIds[programPos]
                                setUserIds(userIds.filter(programId => programId !== pId))
                              }
                            }
                          }}
                        />{' '}
                        {p.firstName || ''} {p.lastName || ''} - {p.email || ''}
                      </Label>
                    </div>
                  )
                })}
              </div>
            </section>
          </Col>
          <Col xs={12} md={12} style={{ padding: '30px' }}>
            <section className='box' style={{ padding: '30px' }}>
              <h4>Location Rules (PST)</h4>
              <Calendar
                dayClickedFunc={dateStr => {
                  setRuleDate(moment(dateStr, 'YYYY-MM-DD'))
                  setModalOpen(true)
                }}
                events={calendarEvents}
              />
            </section>
          </Col>
        </Row>
        <Row>
          <div className='panel_header' style={{ width: '100%' }}>
            <div style={{ padding: '30px', float: 'right' }}>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <Button
                size='sm'
                color='danger'
                onClick={() => {
                  history.push('/locations')
                }}
              >
                Cancel
              </Button>
              <Button
                size='sm'
                color='primary'
                onClick={() => {
                  addLocation()
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </Row>
      </div>
      <RuleModal
        authObj={authObj}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        availableUsers={availableUsers}
        onRuleChange={onRuleChange}
        ruleDate={ruleDate}
      />
    </div>
  )
}

export { AddLocation }
