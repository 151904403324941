import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Col,
  Table,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  ButtonGroup
} from 'reactstrap'
import { config } from '../../../config'

function Reservations ({ authObj }) {
  // for getting query params
  const location = useLocation()
  const [date, setDate] = useState(undefined) // YYYY-MM-DD
  const [locationId, setLocationId] = useState(undefined)
  const [ruleId, setRuleId] = useState(undefined)

  // rules, from getLocation
  const [rule, setRule] = useState(undefined)
  const [locationCountry, setLocationCountry] = useState(undefined)
  const [locationState, setLocationState] = useState(undefined)
  // userIds, from getLocation
  const [locationUserIds, setLocationUserIds] = useState(undefined)
  // availableUsers, from getUsers for mapping with userIds
  const [availableUsers, setAvailableUsers] = useState(undefined)
  // selected user & interval for creating reservation
  const [selectedUser, setSelectedUser] = useState(undefined)
  const [selectedInterval, setSelectedInterval] = useState(undefined)
  // mapping users with reservations
  const [userReservations, setUserReservations] = useState(undefined)
  const [errMsg, setErrMsg] = useState('')
  // starTime and endTime, for generating intervals
  const [startTime, setStartTime] = useState(undefined)
  const [endTime, setEndTime] = useState(undefined)
  // intervals genereated from startTime and endTime
  const [intervals, setIntervals] = useState(undefined)
  // reservations from getReservations
  const [reservations, setReservations] = useState(undefined)
  // reservation statuses from backend
  const [reservationStatus, setReservationStatus] = useState({})

  // all available services
  const [availableServices, setAvailableServices] = useState(undefined)
  // selected services
  const [services, setServices] = useState([])
  const [serviceSearch, setServiceSearch] = useState('')
  // total time and cost
  const [totalTime, setTotalTime] = useState(0)
  const [totalCost, setTotalCost] = useState(0)

  // client info
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  // toggle add client modal
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false)

  const getReservationStatus = async () => {
    const res = await axios.get(`${config.apiUrl}/public/statuses`)
    if (res && res.data) {
      setReservationStatus(res.data.ReservationServiceStatus)
    }
  }

  const addReservation = async createClient => {
    if (services.length === 0) {
      setErrMsg('Please select at least one service')
      return
    }
    setErrMsg('Adding reservation')
    if (!email) {
      setErrMsg('Enter an email')
      return
    }
    try {
      const startTime = moment(`${date} ${selectedInterval}`, 'YYYY-MM-DD HH:mm')
      const startUnix = startTime.unix()
      const endTime = startTime.add(30, 'minutes')
      const endUnix = endTime.unix()
      const createObj = {
        locationId,
        serviceIds: services,
        clientEmail: email,
        clientFirstName: firstName,
        clientLastName: lastName,
        password: password,
        userId: selectedUser,
        startTime: startUnix,
        endTime: endUnix,
        status: reservationStatus.reserved
      }
      const res = await axios.post(
        `${config.apiUrl}/app/reservation-services/admin?createClient=${createClient}`,
        createObj,
        {
          headers: { accountid: authObj.account.accountId, Authorization: `Bearer ${authObj.token}` }
        }
      )
      if (res && res.data) {
        getReservations()
        setErrMsg('')
        setFirstName('')
        setLastName('')
        setEmail('')
        setSelectedUser(undefined)
        setSelectedInterval(undefined)
        // setLoader(false)
      } else {
        setErrMsg('Unable to add reservation (1)')
      }
    } catch (err) {
      setIsAddClientModalOpen(true)
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to add reservation (2)')
    }
  }

  // for cancelling or archiving a reservation
  const updateReservation = async (reservationId, userId, interval, status) => {
    setErrMsg('Updating reservation')
    try {
      const startTime = moment(`${date} ${interval}`, 'YYYY-MM-DD HH:mm')
      const startUnix = startTime.unix()
      const endTime = startTime.add(30, 'minutes')
      const endUnix = endTime.unix()
      const updateObj = {
        reservationId,
        locationId,
        userId,
        startTime: startUnix,
        endTime: endUnix,
        status
      }
      const res = await axios.put(`${config.apiUrl}/app/reservation-services/admin`, updateObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })
      if (res) {
        getReservations()
        setErrMsg('')
        setSelectedUser(undefined)
        setSelectedInterval(undefined)
        // setLoader(false)
      } else {
        setErrMsg('Unable to archive reservation (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to archive reservation (2)')
    }
  }

  // for creating a new reservation with status blocked
  const blockReservation = async (userId, interval) => {
    if (services.length === 0) {
      setErrMsg('Please select at least one service')
      return
    }
    setErrMsg('Blocking reservation')
    try {
      const startTime = moment(`${date} ${interval}`, 'YYYY-MM-DD HH:mm')
      const startUnix = startTime.unix()
      const endTime = startTime.add(30, 'minutes')
      const endUnix = endTime.unix()
      const postObj = {
        locationId: locationId,
        userId: userId,
        serviceIds: services,
        startTime: startUnix,
        endTime: endUnix,
        status: reservationStatus.blocked
      }
      const res = await axios.post(`${config.apiUrl}/app/reservation-services/admin`, postObj, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })
      if (res && res.data) {
        getReservations()
        setErrMsg('')
        setSelectedUser(undefined)
        setSelectedInterval(undefined)
        // setLoader(false)
      } else {
        setErrMsg('Unable to block reservation (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to block reservation (2)')
    }
  }

  const getReservations = async () => {
    setErrMsg('Fetching reservations...')
    try {
      const thisDayUnix = moment(date, 'YYYY-MM-DD').unix()
      const nextDayUnix = moment(date, 'YYYY-MM-DD').add(1, 'day').unix()
      const res = await axios.get(
        `${config.apiUrl}/app/reservation-services/admin?locationId=${locationId}&getClients=true&startTime=${thisDayUnix}&endTime=${nextDayUnix}&archived=false&canceled=false`,
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res && res.data) {
        setReservations(res.data)

        setErrMsg('')
        // setLoader(false)
      } else {
        setErrMsg('Unable to fetch reservations (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch reservations (2)')
    }
  }

  // need this API call for mathcing the userIds received from getLocation
  const getUsers = async () => {
    setErrMsg('Fetching users...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/users`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        // only selecting the users that are available in this location
        setAvailableUsers(res.data.filter(u => locationUserIds.findIndex(lu => lu.userId === u.id) > -1))

        setErrMsg('')
        // setLoader(false)
      } else {
        setErrMsg('Unable to fetch users (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch users (2)')
    }
  }

  const getLocation = async () => {
    setErrMsg('Fetching Location...')
    try {
      const res = await axios.get(
        `${config.apiUrl}/app/locations/by-id?locationId=${locationId}&includeUsers=true&ruleId=${ruleId}`,
        {
          headers: {
            accountid: authObj.account.accountId,
            Authorization: `Bearer ${authObj.token}`
          }
        }
      )

      if (res && res.data) {
        // setTitle(res.data.title)
        // setArea(res.data.area)
        // setPhone(res.data.phone)
        // setAddress(res.data.address)
        // setCity(res.data.city)
        setLocationState(res.data.state)
        // setZip(res.data.zip)
        setLocationCountry(res.data.country)
        // setDescription(res.data.description)
        // setImageUrl(res.data.imageUrl)
        // setNotes(res.data.notes)
        // setStatus(res.data.status)
        // setRules(setRulesHelper(res.data.rules))
        if (res.data.rules.length > 0) setRule(res.data.rules[0])

        setErrMsg('')
        // setLoader(false)
      } else {
        setErrMsg('Unable to fetch location (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch location (2)')
    }
  }

  const getServices = async () => {
    setErrMsg('Fetching services...')
    try {
      const res = await axios.get(`${config.apiUrl}/app/services`, {
        headers: {
          accountid: authObj.account.accountId,
          Authorization: `Bearer ${authObj.token}`
        }
      })

      if (res && res.data) {
        setAvailableServices(res.data)

        setErrMsg('')
        // setLoader(false)
      } else {
        setErrMsg('Unable to fetch services (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to fetch services (2)')
    }
  }

  const toggleService = (e, serviceId) => {
    if (e.target.checked) {
      setServices([...services, serviceId])
    } else {
      setServices(services.filter(s => s !== serviceId))
    }
  }

  const toggleSelectedSlot = (userId, interval) => {
    if (selectedUser === userId && selectedInterval === interval) {
      setSelectedUser(undefined)
      setSelectedInterval(undefined)
    } else {
      setSelectedUser(userId)
      setSelectedInterval(interval)
    }
  }

  // setting time and cost for selected services
  useEffect(() => {
    if (availableServices) {
      let newTotalTime = 0
      let newTotalCost = 0
      availableServices.forEach(s => {
        if (services.indexOf(s.id) > -1) {
          if (s.timespan) {
            newTotalTime += s.timespan
          }
          if (s.price) {
            newTotalCost += s.price
          }
        }
      })
      setTotalTime(newTotalTime)
      setTotalCost(newTotalCost)
    }
  }, [services])

  // for creating a map of {userId:[clients]} for each interval
  useEffect(() => {
    if (reservations && intervals) {
      // temp array for storing maps for each interval
      const tempUserReservations = []
      intervals.forEach((interval, iIdx) => {
        // temporary map of {userId: [clients]} for current interval
        const tempMap = {}
        reservations.forEach((r, rIdx) => {
          const intervalMoment = moment(`${date} ${interval}`, 'YYYY-MM-DD HH:mm')
          const intervalUnix = moment(intervalMoment).unix()
          const intervalUnix30MinsLater = moment(intervalMoment).add(30, 'minutes').unix()
          if (
            r.reservation_services.length > 0 &&
            r.reservation_services[0].startTime >= intervalUnix &&
            r.reservation_services[0].startTime < intervalUnix30MinsLater
          ) {
            if (tempMap[r.reservation_services[0].userId]) {
              tempMap[r.reservation_services[0].userId].push(r)
            } else {
              tempMap[r.reservation_services[0].userId] = [r]
            }
          }
        })
        tempUserReservations.push(tempMap)
      })
      // setting array of {userId: [clients]} mapping
      setUserReservations(tempUserReservations)
    }
  }, [reservations, intervals])

  useEffect(() => {
    // looping through start to end to generate intervals
    if (!startTime || !endTime || !date) return
    let intervalStart = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm')
    const intervalEnd = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm')
    // this means that intervalEnd should be on the next day
    if (intervalEnd.isBefore(intervalStart)) intervalEnd.add(1, 'day')
    const newIntervals = []
    while (intervalStart.isSameOrBefore(intervalEnd)) {
      newIntervals.push(intervalStart.format('HH:mm'))
      intervalStart = intervalStart.add(30, 'minutes')
    }
    setIntervals(newIntervals)
  }, [date, startTime, endTime])

  useEffect(() => {
    // get users to map for the userIds from location
    if (locationUserIds) getUsers()
  }, [locationUserIds])

  useEffect(() => {
    // setting start and end time from rule
    if (rule) {
      setLocationUserIds(rule.users)
      const newStartTime = rule.intervals[0].interval.split(',')[0]
      const newEndTime = rule.intervals[0].interval.split(',')[1]
      setStartTime(newStartTime)
      setEndTime(newEndTime)
    }
  }, [rule])

  useEffect(() => {
    if (locationId && ruleId) {
      getLocation()
      getReservations()
    }
  }, [locationId, ruleId])

  useEffect(() => {
    // setting date, ruleId and calling getLocation on change in queryParams
    setDate(new URLSearchParams(location.search).get('date'))
    setRuleId(new URLSearchParams(location.search).get('ruleId'))
    setLocationId(new URLSearchParams(location.search).get('locationId'))
    getServices()
    getReservationStatus()
  }, [location])

  return (
    <div>
      <div className='content'>
        <Row>
          <Col xs={12} md={12}>
            <header className='panel_header'>
              <div style={{ color: 'red' }}>{errMsg}</div>
              <h2 className='title float-left'>Reservations</h2>
            </header>
          </Col>
          <Col xs={12} md={12}>
            <div style={{ textAlign: 'center', backgroundColor: '#fff', padding: '20px' }}>
              <h4>- {moment(date, 'YYYY-MM-DD').format('MMM DD, y')}</h4>
              <p>{`${locationCountry}/${locationState}`}</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <h4 style={{ backgroundColor: '#606060', color: '#fff', padding: '10px' }}>Time</h4>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>
                    <strong>#</strong>
                  </th>
                  {/* displaying users horizontally */}
                  {availableUsers &&
                    availableUsers.map(u => (
                      <th key={u.id}>
                        <strong>{u.firstName}</strong>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {/* loop for each interval */}
                {intervals &&
                  availableUsers &&
                  userReservations &&
                  intervals.map((interval, iIdx) => (
                    <tr key={interval}>
                      <th scope='row'>
                        <strong>{interval}</strong>
                      </th>
                      {availableUsers.map(u => {
                        // show clients for this interval and userId
                        let isBookedReservations = false
                        if (userReservations[iIdx][u.id]) {
                          isBookedReservations =
                            userReservations[iIdx][u.id].filter(
                              r => r.reservation_services[0].status === reservationStatus.reserved
                            ).length > 0
                        }
                        if (isBookedReservations) {
                          return (
                            <td key={`${iIdx} ${u.id}`}>
                              {userReservations[iIdx][u.id].map(r => {
                                if (r.reservation_services[0].client) {
                                  const client = r.reservation_services[0].client
                                  return (
                                    <ButtonGroup key={client.id}>
                                      <Button size='sm' onFocus={null}>
                                        {client.firstName} - {client.email}
                                      </Button>{' '}
                                      <Button
                                        size='sm'
                                        color='danger'
                                        onClick={() =>
                                          updateReservation(
                                            userReservations[iIdx][u.id][0].id,
                                            u.id,
                                            interval,
                                            reservationStatus.cancelled
                                          )}
                                      >
                                        X
                                      </Button>
                                    </ButtonGroup>
                                  )
                                }
                                return null
                              })}
                            </td>
                          )
                        }
                        // else show block/unblock button
                        return (
                          <td key={u.id}>
                            <ButtonGroup>
                              {(!userReservations[iIdx][u.id] ||
                                userReservations[iIdx][u.id][0].reservation_services[0].status !==
                                  reservationStatus.blocked) && (
                                    <Button
                                      size='sm'
                                      color={
                                    selectedUser === u.id && selectedInterval === interval ? 'success' : 'secondary'
                                  }
                                      onClick={() => toggleSelectedSlot(u.id, interval)}
                                    >
                                      +
                                    </Button>
                              )}
                              <Button
                                size='sm'
                                color='info'
                                onClick={() =>
                                  userReservations[iIdx][u.id]
                                    ? updateReservation(
                                        userReservations[iIdx][u.id][0].id,
                                        u.id,
                                        interval,
                                        reservationStatus.archived
                                      )
                                    : blockReservation(u.id, interval)}
                              >
                                {!userReservations[iIdx][u.id] ||
                                userReservations[iIdx][u.id][0].reservation_services[0].status !==
                                  reservationStatus.blocked
                                  ? 'Block'
                                  : 'Unblock'}
                              </Button>
                            </ButtonGroup>
                          </td>
                        )
                      })}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Col xs={12}>
              <div style={{ backgroundColor: '#fff' }}>
                <h4 style={{ backgroundColor: '#e6e6e6', padding: '5px 10px' }}>Select Services</h4>
                <div style={{ padding: '5px 30px' }}>
                  <InputGroup>
                    <Input
                      type='text'
                      placeholder='Filter Services...'
                      style={{ fontSize: '14px', padding: '2px 5px', minHeight: '30px' }}
                      value={serviceSearch}
                      onChange={e => setServiceSearch(e.target.value.toLowerCase())}
                    />
                    <InputGroupAddon addonType='append'>
                      <Button color='success' size='sm'>
                        Search
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <Table style={{ marginTop: '20px', backgroundColor: '#e6e6e6' }}>
                    <thead>
                      <tr>
                        <th />
                        <th>Type</th>
                        <th>Title</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {availableServices &&
                        availableServices.map(s => {
                          if (
                            serviceSearch === '' ||
                            (s.category && s.category.toLowerCase().includes(serviceSearch)) ||
                            (s.title && s.title.toLowerCase().includes(serviceSearch))
                          ) {
                            return (
                              <tr key={s.id}>
                                <td>
                                  <input
                                    type='checkbox'
                                    checked={services.indexOf(s.id) > -1}
                                    onChange={e => toggleService(e, s.id)}
                                  />
                                </td>
                                <td>{s.category}</td>
                                <td>{s.title}</td>
                                <td>{s.timespan}</td>
                              </tr>
                            )
                          }
                          return null
                        })}
                    </tbody>
                  </Table>
                  <Row>
                    <Col xs={12} md={6}>
                      <h4>
                        Total time: <strong>{`${totalTime} mins`}</strong>
                      </h4>
                    </Col>
                    <Col xs={12} md={6}>
                      <h4>
                        Total cost: <strong>{`$ ${totalCost}`}</strong>
                      </h4>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/* <Col xs={12}>
              <div style={{ backgroundColor: "#fff", marginTop: "20px" }}>
                <h4 style={{ backgroundColor: "#e6e6e6", padding: "5px 10px" }}>Select Your Option</h4>
                <div style={{ padding: "5px 30px" }}>
                  <Col xs={12}>
                    <Button color="info" size="sm">
                      Register new user
                    </Button>
                  </Col>
                  <Col xs={12} style={{ marginTop: "10px" }}>
                    <Button color="info" size="sm">
                      Book for existing user
                    </Button>
                  </Col>
                </div>
              </div>
            </Col> */}
            <Col xs={12}>
              <div style={{ backgroundColor: '#fff', marginTop: '20px' }}>
                <h4 style={{ backgroundColor: '#e6e6e6', padding: '5px 10px' }}>User Information</h4>
                <div style={{ padding: '5px 30px' }}>
                  <h5 style={{ backgroundColor: '#606060', color: '#fff', padding: '5px', textAlign: 'center' }}>
                    Email
                  </h5>
                  <Input
                    type='email'
                    placeholder='johndoe@example.com'
                    style={{ fontSize: '14px', padding: '5px 10px', minHeight: '30px' }}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Button
                    color='success'
                    size='sm'
                    style={{ marginTop: '20px', width: '100%' }}
                    onClick={() => addReservation(false)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
      <Modal isOpen={isAddClientModalOpen}>
        <ModalHeader>Add Client</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <Label for='firstName'>
                <Input
                  type='text'
                  required
                  name='firstName'
                  placeholder='John'
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </Label>
            </Col>
            <Col xs={12}>
              <Label for='lastName'>
                <Input
                  type='text'
                  required
                  name='lastName'
                  placeholder='Doe'
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Label>
            </Col>
            <Col xs={12}>
              <Label for='password'>
                <Input
                  type='password'
                  name='password'
                  placeholder='********'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size='sm'
            color='primary'
            onClick={() => {
              setIsAddClientModalOpen(false)
              addReservation(true)
            }}
          >
            Submit
          </Button>
          <Button size='sm' color='secondary' onClick={() => setIsAddClientModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export { Reservations }
