import React from 'react'

const Webhook = ({ backgroundColor, iconColor  }) => {
  return (
    <svg className='triggerIcon' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="6.4" fill={backgroundColor} />
      <path d="M38 19H33V29H35V26H38C38.5302 25.9992 39.0384 25.7882 39.4133 25.4133C39.7882 25.0384 39.9992 24.5302 40 24V21C39.9995 20.4697 39.7886 19.9613 39.4136 19.5864C39.0387 19.2114 38.5303 19.0005 38 19ZM35 24V21H38L38.001 24H35ZM18 21H20V29H22V21H24V19H18V21ZM31 19H25V21H27V29H29V21H31V19ZM14 19V23H11V19H9V29H11V25H14V29H16V19H14Z" fill={iconColor} />
    </svg>
  )
}

export default Webhook