import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { API } from '../../api/API'

const Gift = ({ authObj }) => {
  const history = useHistory()
  const [apps, setApps] = useState([])
  const [app, setApp] = useState('')

  const postAppList = () => {
    API.postAppList(authObj.account.accountId, authObj.token, apps)
  }

  const addApp = () => {
    setApps(apps => {
      const newApps = [...apps]
      newApps.push(app)
      return newApps
    })
  }

  const removeApp = index => {
    setApps(apps => {
      return apps.filter((a, i) => i !== index)
    })
  }

  useEffect(() => {
    setApp('')
  }, [apps])

  return (
    <>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col>
              <Row className='justify-content-center'>
                <h1 style={{ fontSize: '50px' }}>Welcome to EdBridge -- Exclusive Gifts!</h1>
              </Row>
              <Row className='justify-content-center'>
                <h1 style={{ fontSize: '40px' }}>Here's a gift for you meanwhile</h1>
              </Row>
              <Row className='justify-content-center'>
                <iframe
                  width='700'
                  height='350'
                  title='Welcome to EdBridge'
                  src='https://www.youtube.com/embed/axVsX5DCu8o'
                />
              </Row>
              <br />
              <Row className='justify-content-center'>Have a question? email me carl@edbridge.io</Row>
              <Row className='justify-content-center'>Text me: 626-466-6473</Row>
              <br />
              <Row className='justify-content-center'>
                Also we would love to know the apps you would like to connect
              </Row>
              <Row className='justify-content-center'>
                <div className='col-md-6' style={{ padding: '10px', background: '#fffefa' }}>
                  {apps.map((app, i) => (
                    <span
                      key={i}
                      style={{
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        background: '#fff',
                        padding: '5px'
                      }}
                    >
                      {app}
                      <span style={{ marginLeft: '5px' }} onClick={() => removeApp(i)}>
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </span>
                  ))}
                  <input
                    style={{
                      border: 'none',
                      outline: 'none',
                      padding: '0',
                      margin: '5px',
                      minHeight: '0',
                      background: 'inherit',
                      boxShadow: 'none'
                    }}
                    type='text'
                    placeholder='type and press enter'
                    value={app}
                    onChange={e => setApp(e.target.value)}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        addApp()
                      }
                    }}
                  />
                </div>
              </Row>
              <br />
              <Row className='justify-content-center'>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => {
                    postAppList()
                    history.replace('/settings')
                  }}
                >
                  Get Started <FontAwesomeIcon icon={faCog} />
                </button>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export { Gift }
