import React from 'react'

const ReRun = ({ theme }) => {
  return (
    <svg className='icon' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5001 9.16675C17.2791 9.16675 17.0671 9.25455 16.9108 9.41083C16.7545 9.56711 16.6668 9.77907 16.6668 10.0001C16.6767 11.5609 16.142 13.0764 15.1548 14.2855C14.1676 15.4945 12.7897 16.3215 11.2584 16.6239C9.72708 16.9264 8.13823 16.6854 6.7655 15.9424C5.39277 15.1994 4.32209 14.0011 3.73785 12.5536C3.15362 11.1062 3.09241 9.50035 3.56477 8.01265C4.03712 6.52494 5.01348 5.24853 6.32567 4.40323C7.63786 3.55793 9.20375 3.19668 10.7536 3.38167C12.3035 3.56667 13.7404 4.28635 14.8168 5.41675H12.8168C12.5957 5.41675 12.3838 5.50455 12.2275 5.66083C12.0712 5.81711 11.9834 6.02907 11.9834 6.25008C11.9834 6.4711 12.0712 6.68306 12.2275 6.83934C12.3838 6.99562 12.5957 7.08342 12.8168 7.08342H16.5918C16.8128 7.08342 17.0247 6.99562 17.181 6.83934C17.3373 6.68306 17.4251 6.4711 17.4251 6.25008V2.50008C17.4251 2.27907 17.3373 2.06711 17.181 1.91083C17.0247 1.75455 16.8128 1.66675 16.5918 1.66675C16.3707 1.66675 16.1588 1.75455 16.0025 1.91083C15.8462 2.06711 15.7584 2.27907 15.7584 2.50008V3.97508C14.3706 2.64846 12.5674 1.84171 10.6534 1.69119C8.73949 1.54067 6.83227 2.05562 5.25418 3.14899C3.67609 4.24236 2.52392 5.84709 1.99244 7.69191C1.46096 9.53673 1.58276 11.5085 2.33726 13.2739C3.09176 15.0392 4.43267 16.49 6.13333 17.3808C7.83399 18.2716 9.79008 18.5479 11.6709 18.163C13.5518 17.778 15.2421 16.7555 16.456 15.2681C17.67 13.7808 18.3331 11.9199 18.3334 10.0001C18.3334 9.77907 18.2456 9.56711 18.0893 9.41083C17.9331 9.25455 17.7211 9.16675 17.5001 9.16675Z" fill={ theme === 'dark' ? "#81B9F9" : "#1F90FF" }/>
    </svg>
  )
}

export default ReRun