import React, { useState } from 'react'
import { Col, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { API } from '../../../api/API'

const Campminder = ({ authObj }) => {
  const history = useHistory()

  const [loader, setLoader] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const createMapping = async () => {
    setLoader(true)
    const res = await API.createMapping(authObj.account.accountId, authObj.token, {
      title: 'CM',
      destination: 'campminder',
      cmUsername: username,
      cmPassword: password,
      password,
      status: 'active'
    })
    if (res && res.data) {
      history.push('/data-mappings')
    }
    setLoader(false)
  }

  return (
    <Col xs={12}>
      {loader && (
        <div className='col-12' style={{ textAlign: 'center' }}>
          <Spinner animation='border' variant='primary' />
        </div>
      )}
      <label>
        Username <input type='text' value={username} onChange={e => setUsername(e.target.value)} />
      </label>
      <label>
        Password <input type='password' value={password} onChange={e => setPassword(e.target.value)} />
      </label>
      <button className='btn btn-primary' color='primary' onClick={() => createMapping()}>
        Submit
      </button>
    </Col>
  )
}

export { Campminder }
