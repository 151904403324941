import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import { useSelector, useDispatch } from 'react-redux'
import { loadTeamUsers } from '../../../services/team.service'

const AddUserModal = ({ modalOpen, setModalOpen }) => {
  const dispatch = useDispatch()
  const { userData } = useSelector(state => state.auth)
  // fields
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role] = useState('staff')

  const [errMsg, setErrMsg] = useState('')

  const addUser = async e => {
    e.preventDefault()
    setErrMsg('Adding User...')
    try {
      // validation
      if (!email) {
        return setErrMsg('Email cannot be blank.')
      }

      const createObj = {
        firstName,
        lastName,
        email,
        role
      }

      const response = await axios.post(`${config.apiUrl}/app/users`, createObj, {
        headers: {
          accountid: userData.account.accountId,
          Authorization: `Bearer ${userData.token}`
        }
      })

      if (response && response.data) {
        setErrMsg('User Invited Successfully.')
        dispatch(loadTeamUsers(userData.account.accountId, userData.token))
        setModalOpen(false)
      } else {
        setErrMsg('Unable to invite user (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to invite user (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  return (
    <div className='overlay'>
      <div className='backDrop' onClick={() => setModalOpen(!modalOpen)} />
      <div className='userModalContainer'>
        <div className='userModal'>
          <h3 className='title'>Invite User</h3>
          <form onSubmit={addUser}>
            <div>
              <p className='label'>Email</p>
              <input
                autoFocus
                type='text'
                placeholder='Email'
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '100%', marginRight: '8px' }}>
                <p className='label'>First Name</p>
                <input
                  type='text'
                  placeholder='First Name'
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value)
                  }}
                />
              </div>
              <div style={{ width: '100%', marginLeft: '8px' }}>
                <p className='label'>Last Name</p>
                <input
                  type='text'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value)
                  }}
                />
              </div>
            </div>
            {errMsg && (
              <div className='errorArea' style={{ width: '100%', color: '#FD7062' }}>
                {errMsg}
              </div>
            )}
            <button className='submitButton' type='submit' onClick={addUser}>
              Invite User
            </button>
            <button className='cancelButton' onClick={() => setModalOpen(!modalOpen)}>
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export { AddUserModal }
