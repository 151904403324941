/* eslint-disable no-tabs */
import React from 'react'
import callIcon from '../../../assets/img2/greyCall.svg'
import messageIcon from '../../../assets/img2/greyMessage.svg'

const DeleteAI = ({ title, showDeleteProcessModal }) => {
  return (
    <div className='deleteModal'>
      <div className='title'>
        Remove AI for sure ?
      </div>
      <div className='subtitle'>
        Do you want to remove <span className='noUnderLine'>{title}</span> ?
      </div>
      <div className='contactDetails'>Got questions, contact us at:</div>
      <div className='row'>
        <img src={callIcon} className='icon' alt='' />
        <div className='contactDetails'>646-466-6473 (Text only)</div>
      </div>
      <div className='row'>
        <img src={messageIcon} className='icon' alt='' />
        <div className='contactDetails'>support@champselect.ai</div>
      </div>
      <div className='actionArea'>
        <button
          className='cancelButton'
          onClick={() => showDeleteProcessModal(false)}
        >
          Cancel
        </button>
        <button className='submitButton' onClick={() => showDeleteProcessModal(false)}>
          Schedule a call
        </button>
      </div>
    </div>
  )
}

export { DeleteAI }
