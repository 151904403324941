import React from 'react'
import { config } from '../../../config'
import CodeBoxImage from '../../../components/Icons/CodeBoxImage'

const CodeBox = props => {
  return (
    <div className='codeBoxArea'>
      <CodeBoxImage color={props?.theme === 'dark' ? '#2d2d31' : '#dadada'} theme={props?.theme} />
      <div className='codeBox'>
        <pre className='code'>{'<'}<span className='htmlBuiltin'>div</span> id=<span className='string'>"champselect-inline-widget"</span> </pre>
        <pre className='code'>    data-accountid=<span className='string'>"{props.accountId}"</span> data-email=<span className='string'>"{"{email}"}"</span></pre>
        <pre className='code'>    data-userid=<span className='string'>"{"{userid}"}"</span> {'>'}</pre>
        <pre className='code'>{'</'}<span className='htmlBuiltin'>div</span>{'>'}</pre>
        <pre className='code'>{'<'}<span className='htmlBuiltin'>link</span> href=<span className='string'>"{config.widgetUrl}/widget/widget.css"</span> </pre>
        <pre className='code'>    rel=<span className='string'>"stylesheet"</span> {'/>'}</pre>
        <pre className='code'>
          {'<'}
          <span className='htmlBuiltin'>script</span> src=<span className='string'>"{config.widgetUrl}/widget/widget.js"</span>{'>'}
        </pre>
        <pre className='code'>
          {'</'}<span className='htmlBuiltin'>script</span>{'>'}
        </pre>
      </div>
    </div>
  )
}

export default CodeBox
