/* eslint-disable no-tabs */
import React from 'react'
import reloadIcon from '../../../assets/img2/reload.svg'
import ignoreRed from '../../../assets/img2/ignoreRed.svg'
import infoIcon from '../../../assets/img2/infoIcon.svg'
import ReRun from '../../Icons/ReRun'

const HistoryDetail = ({ workflowsList, historyID, isLoading, reRunProcess, ignoreFailedRun, theme }) => {
  const { payload, outputPayload } = workflowsList.history.find(history => history.id === historyID) || {}

  return (
    <div className="historyDetailModal">
      <div className="historyDetailsInfoPage">
        <div className="titleText">{workflowsList?.title}</div>
        <div className="InformationContainer">
          <div className="InformationDetails">
            <div className="row">
              <div className="greyText">Information</div>
              {workflowsList.history.find(history => history.id === historyID)?.status !== 'success' && (
                <div className="actionsArea">
                  <div className="re-run" onClick={() => reRunProcess(historyID)}>
                    {/* <img src={reloadIcon} alt="reload" /> */}
                    <ReRun theme={theme} />
                    <span>Re-run</span>
                  </div>
                  <div className="ignore" onClick={() => ignoreFailedRun(historyID)}>
                    <img src={ignoreRed} alt="ignore" />
                    <span>Ignore</span>
                  </div>
                </div>
              )}
            </div>
            <div className="blackContainer">
              <div className="detailsContainer">
                {workflowsList.history.find(history => history.id === historyID)?.payload?.error && (
                  <div className="errorMessageContainer">
                    <img src={infoIcon} className="infoIcon" alt="" />
                    {workflowsList.history.find(history => history.id === historyID)?.payload?.error}
                  </div>
                )}
                {!isLoading && !workflowsList.history.find(history => history.id === historyID)?.payload ? (
                  <pre>Sorry, There are no data available to show for this process.</pre>
                ) : (
                  <pre>{JSON.stringify({ inputPayload: payload, outputPayload }, null, 2)}</pre>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HistoryDetail
