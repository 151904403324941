import React from 'react'
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Form,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
const ICON_TYPES = [
  'fas fa-users',
  'fas fa-clock',
  'fas fa-child',
  'fas fa-shopping-cart',
  'fas fa-check',
  'fas fa-list',
  'fas fa-ad',
  'fas fa-address-card',
  'fas fa-align-center',
  'fas fa-american-sign-language-interpreting',
  'fas fa-arrow-circle-right',
  'fas fa-arrow-circle-left',
  'fas fa-angle-double-down',
  'fas fa-angle-double-up',
  'fas fa-audio-description',
  'fas fa-at',
  'fas fa-address-book',
  'fas fa-adjust',
  'fas fa-ambulance'
]

const Settings = ({ step, updateFieldSettings, setStepOrder }) => {
  return (
    <Row key={step.id}>
      <Col md='12'>
        <Form>
          <FormGroup>
            <Label htmlFor={step.id + '_displayName'}>Display Name</Label>
            <Input
              type='text'
              id={step.id + '_displayName'}
              name={step.id + '_displayName'}
              value={step.displayName}
              onChange={e => {
                updateFieldSettings(step.id, { displayName: e.target.value })
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor={step.id + '_icon'}>Icon</Label>
            <UncontrolledDropdown size='sm' style={{ width: '100%' }}>
              <DropdownToggle style={{ width: '80%' }}>{step.icon}</DropdownToggle>
              <DropdownMenu
                style={{
                  maxHeight: '300px',
                  overflowY: 'scroll',
                  width: '80%'
                }}
              >
                {ICON_TYPES.map(d => (
                  <DropdownItem
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                    key={d}
                    onClick={() => updateFieldSettings(step.id, { icon: d })}
                  >
                    <span>{d}</span>
                    <i className={d} />
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={step.id + '_order'}>Order</Label>
            <Input
              type='number'
              id={step.id + '_order'}
              name={step.id + '_order'}
              value={step.order}
              onChange={e => {
                setStepOrder(step.id, Number(e.target.value))
              }}
            />
          </FormGroup>
          <FormGroup>
            <input
              type='checkbox'
              id={step.id + '_cart'}
              name={step.id + '_cart'}
              checked={step.showCart}
              onChange={e => {
                updateFieldSettings(step.id, { showCart: e.target.checked })
              }}
            />
            <Label htmlFor={step.id + '_cart'}>Show cart on this step</Label>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

export default Settings
