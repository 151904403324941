import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { FormGroup, Button } from 'reactstrap'

import Settings from './Settings'
import { FieldType } from '../../FieldTypes'

const FieldButton = ({
  field,
  removeFunc,
  setFieldOrderFunc,
  updateCurrentSetting,
  setFieldSettingsModalOpen,
  updateFieldSettings
}) => {
  const [isMouseIn, setIsMouseIn] = useState(false)

  // draggable
  const [{ isDragging }, draggable] = useDrag({
    item: { id: field.id, parentId: field.fieldGroupId, type: FieldType.BUTTON },
    type: FieldType.BUTTON,
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      }
    }
  })

  return (
    <div
      ref={draggable}
      style={{
        width: '100%',
        border: isMouseIn || isDragging ? '1px dashed black' : 'none',
        cursor: 'move',
        backgroundColor: isDragging ? 'lightblue' : 'white',
        display: 'inline-block'
      }}
    >
      <FormGroup
        onMouseEnter={() => {
          setIsMouseIn(true)
        }}
        onMouseLeave={() => {
          setIsMouseIn(false)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end'
          }}
        >
          <div style={{ display: 'flex' }}>
            <Button
              size='xs'
              color='primary'
              title='Settings'
              onClick={() => {
                updateCurrentSetting(
                  <Settings
                    field={field}
                    setFieldOrderFunc={setFieldOrderFunc}
                    updateFieldSettings={updateFieldSettings}
                  />
                )
                setFieldSettingsModalOpen(true)
              }}
            >
              <i className='fas fa-tools' />
            </Button>
            <Button
              size='xs'
              color='danger'
              title='Remove'
              onClick={() => {
                removeFunc(field.id)
              }}
            >
              <i className='fas fa-times' />
            </Button>
          </div>
        </div>
        <Button className='btn btn-default btn-edbridge' style={{ width: '100%', transform: 'none' }}>
          {field.displayName}
        </Button>
        <span>{field.instructions}</span>
      </FormGroup>
    </div>
  )
}

export default FieldButton
