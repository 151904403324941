import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Button } from 'reactstrap'
import { FieldTag, FieldType } from '../FieldTypes'
import Settings from './Settings'

const FieldGroup = ({
  fieldGroup,
  addField,
  formFieldsView,
  removeFieldGroup,
  setFieldGroupOrder,
  updateCurrentSetting,
  setFieldSettingsModalOpen,
  updateFieldSettings
}) => {
  const [{ isDragging }, draggable] = useDrag({
    item: { id: fieldGroup.id, type: FieldType.FORMFIELD },
    type: FieldType.FORMFIELD,
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      }
    }
  })

  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept: [
        FieldTag.SINGLELINEINPUT,
        FieldTag.PARAGRAPH,
        FieldTag.DROPDOWN,
        FieldTag.MULTIPLECHOICE,
        FieldTag.CHECKBOXES,
        FieldTag.BUTTON,
        FieldTag.DATE,
        FieldTag.PAYMENT,
        FieldTag.TEXT,
        FieldTag.SPACER,
        FieldTag.PROGRAMS,
        FieldTag.SESSIONS,
        FieldTag.CALENDAR_VIEW,
        FieldTag.TERMS_AND_CONDITIONS,
        FieldType.FORMFIELD
      ],
      canDrop: () => {
        return true
      },
      drop: (obj, monitor) => {
        if (obj.type === FieldType.FORMFIELD) {
          setFieldGroupOrder(obj.id, fieldGroup.order)
        } else {
          addField(obj.type, fieldGroup.id, obj.isComponent)
        }
      },
      collect: monitor => {
        return {
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop()
        }
      }
    },
    [fieldGroup, setFieldGroupOrder, addField] // dependencies
  )

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        display: 'inline-block'
      }}
    >
      <div
        ref={draggable}
        style={{
          minHeight: '200px',
          border: '1px dashed gray',
          padding: '10px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'start',
          justifyContent: fieldGroup.alignment || 'left',
          flexWrap: 'wrap',
          opacity: isDragging ? 0.5 : 1
        }}
      >
        <div style={{ width: '100%', textAlign: 'right', marginBottom: '20px' }}>
          <Button
            size='xs'
            color='primary'
            title='Settings'
            onClick={() => {
              updateCurrentSetting(
                <Settings
                  fieldGroup={fieldGroup}
                  updateFieldSettings={updateFieldSettings}
                  setFieldGroupOrder={setFieldGroupOrder}
                />
              )
              setFieldSettingsModalOpen(true)
            }}
          >
            <i className='fas fa-tools' />
          </Button>
          <Button
            size='xs'
            color='danger'
            title='Remove'
            onClick={() => {
              removeFieldGroup(fieldGroup.id)
            }}
          >
            <i className='fas fa-times' />
          </Button>
        </div>
        {formFieldsView}
        <br />
        {fieldGroup.repeat !== 'none' && (
          <h6 style={{ width: '100%', textDecoration: 'underline' }}>
            This section will repeat for number of {fieldGroup.repeat}
          </h6>
        )}
      </div>
      {isOver && canDrop && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'green'
          }}
        />
      )}
    </div>
  )
}

export const DropZone = ({ data, onDrop, width, children }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [
      FieldType.SINGLELINEINPUT,
      FieldType.PARAGRAPH,
      FieldType.DROPDOWN,
      FieldType.MULTIPLECHOICE,
      FieldType.CHECKBOXES,
      FieldType.BUTTON,
      FieldType.DATE,
      FieldType.PAYMENT,
      FieldType.TEXT,
      FieldType.SPACER,
      FieldType.PROGRAMS,
      FieldType.SESSIONS,
      FieldType.CALENDAR_VIEW,
      FieldType.TERMS_AND_CONDITIONS
    ],
    drop: (item, monitor) => {
      onDrop(data, item)
    },
    canDrop: (item, monitor) => {
      return item.parentId === data.parentId
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  const isActive = isOver && canDrop
  return (
    <div
      style={{
        width: `${width}%`,
        opacity: isActive && 0.5
      }}
      ref={drop}
    >
      {children}
    </div>
  )
}

export default FieldGroup
