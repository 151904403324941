import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import remove from '../../../assets/img2/removeRed.svg'
import { DeactivateUserModal } from './DeactivateUserModal'
import { useSelector, useDispatch } from 'react-redux'
import { loadTeamUsers } from '../../../services/team.service'

const EditUserModal = ({
  modalOpen,
  setModalOpen,
  updatingObject,
  setDeactivateAccountObject,
  setDeactivateModalOpen,
  deactivateModalOpen,
  removeUser
}) => {
  const dispatch = useDispatch()
  const { userData } = useSelector(state => state.auth)
  const [firstName, setFirstName] = useState(updatingObject.firstName)
  const [lastName, setLastName] = useState(updatingObject.lastName)
  const [email, setEmail] = useState(updatingObject.email)
  const [role] = useState('staff')
  const [status] = useState('active')

  const [errMsg, setErrMsg] = useState('')

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const editUser = async e => {
    e.preventDefault()
    setErrMsg('Editing User...')
    try {
      if (!email) {
        return setErrMsg('Email cannot be blank.')
      }

      const createObj = {
        userId: updatingObject.id,
        firstName,
        lastName,
        role,
        status
      }

      const response = await axios.put(`${config.apiUrl}/app/users`, createObj, {
        headers: {
          accountid: userData.account.accountId,
          Authorization: `Bearer ${userData.token}`
        }
      })

      if (response && response.data) {
        setErrMsg('User Updated Successfully.')
        dispatch(loadTeamUsers(userData.account.accountId, userData.token))
        setModalOpen(false)
      } else {
        setErrMsg('Unable to update user (1)')
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        return setErrMsg(`${err.response.data.msg}`)
      }
      return setErrMsg('Unable to update user (2)')
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  const formattedDate = dateString => {
    const updatedDate = new Date(dateString)
    return `${month[updatedDate.getMonth()]} ${updatedDate.getDate()}, ${updatedDate.getFullYear()}`
  }

  return (
    <div className='overlay'>
      <div className='backDrop' onClick={() => setModalOpen(!modalOpen)} />
      <div className='userModalContainer'>
        <div className='userModal'>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className='heading'>Update User</div>
            <div
              className='removeUser'
              onClick={() => {
                setDeactivateAccountObject(updatingObject)
                setDeactivateModalOpen(true)
              }}
            >
              <img style={{ marginRight: '11px' }} src={remove} />
              <p>Remove User</p>
            </div>
          </div>
          <div className='spanTag'>Updated on {formattedDate(updatingObject.updatedAt)}</div>
          <form onSubmit={editUser}>
            <div>
              <p className='label'>Email</p>
              <input
                disabled
                className='disabled'
                type='text'
                placeholder='Email'
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '100%', marginRight: '8px' }}>
                <p className='label'>First Name</p>
                <input
                  autoFocus
                  type='text'
                  placeholder='First Name'
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value)
                  }}
                />
              </div>
              <div style={{ width: '100%', marginLeft: '8px' }}>
                <p className='label'>Last Name</p>
                <input
                  type='text'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value)
                  }}
                />
              </div>
            </div>
            {errMsg && (
              <div className='errorArea' style={{ width: '100%', color: 'red' }}>
                {errMsg}
              </div>
            )}
            <button className='submitButton' type='submit' onClick={editUser}>
              Save Changes
            </button>
            <button className='cancelButton' onClick={() => setModalOpen(!modalOpen)}>
              Cancel
            </button>
          </form>
        </div>
      </div>
      {deactivateModalOpen && (
        <DeactivateUserModal
          modalOpen={deactivateModalOpen}
          setModalOpen={setDeactivateModalOpen}
          setDeactivateModalOpen={setDeactivateModalOpen}
          deactivateModalOpen={deactivateModalOpen}
          deactivateAccountObject={updatingObject}
          removeUser={removeUser}
        />
      )}
    </div>
  )
}

export { EditUserModal }
